import { Ellipse, Group, Path } from "react-konva";
import { lightColorAt } from "actions/generalActions";
import ChargeIcon from "./ChargeIcon";

export default function Downlight({
  x,
  y,
  size,
  statusL,
  statusA,
  lightLv,
  disabledStatus,
  // connectStatus,
  typeBatVI,
  typeIsDim,
  shadEnabled,
  // outline,
  // disabledConnectStatus
}) {
  const scale = size / 79.375;
  const lightColor = lightColorAt(
    disabledStatus,
    statusA,
    lightLv,
    statusL,
    typeIsDim
  );
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale} listening={false}>
      {/* <Rect
        x={-10}
        y={-10}
        width={100}
        height={100}
        fill="#fff"
        opacity={0.5}
        cornerRadius={5}
        stroke={outline ? lightColorDark : ""}
        perfectDrawEnabled={false}
        transformsEnabled={"position"}

      /> */}
      <Path
        fill="#6c5353"
        stroke="#666"
        strokeWidth={3}
        data="M55.02 14.016c1.435.058 2.233-.447 2.086-1.951V6.477h5.74v9.556c-.052 1.757-1.68 2.388-3.4 2.318H19.3c-3.734-.052-3.774-1.288-3.91-2.79l-.116-8.816 5.466-.003.08 4.97c-.09 2.923 1.707 2.253 2.9 2.304h31.3"
        perfectDrawEnabled={false}
        transformsEnabled={"position"}
      />
      <Path
        // fill="green"
        stroke="#917c6f"
        strokeWidth={3.511}
        data="M15.822 31.434l.12 32.87c8.979 15.381 34.987 16.621 46.863-.744l-.242-32.622c-15.822-20.301-44.688-9.468-46.74.496z"
        perfectDrawEnabled={false}
        transformsEnabled={"position"}
      />

      <Ellipse
        x={39.093}
        y={63.038}
        fill={statusA === 1 ? "#566573" : "#fff"}
        opacity={1}
        radiusX={20.05}
        radiusY={11.373}
        shadowEnabled={shadEnabled}
        shadowColor="black"
        shadowBlur={4}
        shadowOffset={{ x: 8, y: 8 }}
        shadowOpacity={0.3}
        perfectDrawEnabled={false}
        transformsEnabled={"position"}
      />
      {statusA === 0 && (
        <Ellipse
          x={39.093}
          y={63.038}
          fill={lightColor}
          opacity={typeIsDim ? lightLv / 100 : 1}
          radiusX={20.05}
          radiusY={11.373}
          stroke="#6c5353"
          strokeWidth={0.303}
          shadowEnabled={shadEnabled}
          shadowColor="black"
          shadowBlur={4}
          shadowOffset={{ x: 8, y: 8 }}
          shadowOpacity={0.3}
          perfectDrawEnabled={false}
          transformsEnabled={"position"}
        />
      )}

      <Path
        stroke="#aa8800"
        strokeWidth={3.265}
        data="M23.793 30.195l.242 21.861M31.282 26.209l.242 21.861"
        perfectDrawEnabled={false}
        transformsEnabled={"position"} />
      <Path
        stroke="#aa8800"
        strokeWidth={3.265}
        data="M39.018 24.886l.23 23.177"
        perfectDrawEnabled={false}
        transformsEnabled={"position"}
      />
      <Path
        stroke="#aa8800"
        strokeWidth={3.265}
        data="M46.5 25.847l.242 21.861M53.988 30.074l.242 21.861"
        perfectDrawEnabled={false}
        transformsEnabled={"position"}
      />
      {!disabledStatus && typeBatVI && (
        <ChargeIcon y={10} x={-25} statusL={statusL} />
      )}
    </Group>
  );
}
