import { useState, useContext, Fragment } from "react";
import { SMContext } from "context/smContext";
import { Button, Typography, Box } from "@mui/material";
import { queryOneLightSetting } from "actions/lightActions";
import ButtonSM from "components/ButtonSM";
import { Workspaces } from "@mui/icons-material";
import ModalSM from "components/ModalSM";
import DeviceActionLine from "components/DeviceActionLine";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import moment from "moment";
import DivInline from "components/DivInline";
import General from "@ecoenghk/general";
import { daliMultiLightToSingleLightCommand, queryDaliCtlOneLightSetting, queryDaliCtlOneSetting } from "actions/daliCtlActions";
import IconButtonRefresh from "components/IconButtonRefresh";
import { updateZcProperty } from "actions/zcActions";
import { updateSensorProperty } from "actions/sensorActions";
import { gatewayGroupActionCheck } from "actions/gatewayActions";
const gs = new General();


export default function DeviceGroupActionModal({ deviceObj, lightObj, settingNumArray = [1] }) {
    const [state, dispatch] = useContext(SMContext);
    const { daliCtlObjAll } = state;
    const [open, setOpen] = useState(false);
    const { enableGroupAction, zoneControlID, sensorID, tempGroupSetting, gatewayID } = deviceObj || {};
    const deviceID = zoneControlID || sensorID;
    const groupID = zoneControlID ? deviceID.replace("zc_", "") : deviceID;
    const type = deviceObj?.type;
    let confirmed = false;
    const { serial, shortAdd, } = lightObj || {};
    // const settingNumArray = settingNumArrayOf(type, deviceObj.gang);
    const daliCtlObj = daliCtlObjAll[lightObj.daliCtlID];
    const clearResult = () => {
        let newDeviceObj = { ...deviceObj };
        delete newDeviceObj.tempGroupSetting;
        const dispatchType = zoneControlID ? "UPDATED_ZONE_CONTROL_OBJ" : "UPDATE_SENSOR_OBJ"
        dispatch({ type: dispatchType, payload: newDeviceObj })
    }
    const handleQuery = async (n) => {
        clearResult();
        const queryPayload = { groupID, settingNum: n };
        if (!lightObj.daliCtlID) {
            await queryOneLightSetting(lightObj, 0x0321, queryPayload);
        } else {
            await queryDaliCtlOneSetting(daliCtlObj, 0x0b21, queryPayload);
        }
    }
    let modalTitle = `${deviceID} Group action of light ${serial}`;
    if (lightObj?.daliCtlID) modalTitle += ` (DALI controller ${lightObj?.daliCtlID} shortAdd ${lightObj?.shortAdd})`
    return (
        <>
            <ButtonSM onClick={() => {
                setOpen(true);
                clearResult();
            }}>
                <Workspaces color={confirmed ? "success" : "error"} />
            </ButtonSM>
            <ModalSM
                modalTitle={modalTitle}
                open={open}
                onClose={() => setOpen(false)}
                width={lightObj?.daliCtlID ? "75vw" : "65vw"}
                height={settingNumArray?.length > 1 ? "90vh" : "70vh"}
                onDelete={async () => {
                    if (zoneControlID) updateZcProperty(zoneControlID, { tempGroupSetting: {} });
                    else updateSensorProperty(sensorID, deviceObj, deviceObj.gatewayID, { tempGroupSetting: {} });
                }}
            >
                <Divider10 />
                {
                    settingNumArray.map(n => {
                        const controlledDeviceID = lightObj?.daliCtlID ? lightObj?.daliCtlID : lightObj?.serial;
                        const jsonCommand = deviceObj[`setting${n}`]?.[lightObj?.serial]
                        const tempGroupSerialSetting = tempGroupSetting?.[`${controlledDeviceID}_${n}`];
                        const { originalCommandStr, reportedCommandStr, originalCommandObj, reportedCommandObj } = tempGroupSerialSetting || {};
                        const shortenOriginalCommandStr = originalCommandStr?.slice(8, -4);
                        const tempGroupSettingMoment = moment(tempGroupSerialSetting?.timeStamp);
                        const originalSingleLightCmdObj = daliMultiLightToSingleLightCommand(originalCommandObj, daliCtlObj, shortAdd);
                        const reportedSingleLightCmdObj = daliMultiLightToSingleLightCommand(reportedCommandObj, daliCtlObj, shortAdd);
                        return (
                            <Fragment key={n}>
                                <DivInline>
                                    <IconButtonRefresh onBtnClick={async () => handleQuery(n)} />
                                    <Typography sx={{ width: "12vw" }}>{`Setting${n}`}</Typography>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <DeviceActionLine
                                            deviceSetting={jsonCommand}
                                            deviceObj={lightObj}
                                            mainDeviceObj={deviceObj}
                                            buttonNo={1}
                                        />
                                    </Box>
                                </DivInline>
                                <Divider10 />
                                <DivExist show={moment().diff(tempGroupSettingMoment, "minutes") < 2}>
                                    <Divider10 />
                                    <DivInline>
                                        <Typography variant="caption" sx={{ width: "24vw" }}>Current command:</Typography>
                                        <Typography variant="caption">{shortenOriginalCommandStr}</Typography>
                                    </DivInline>
                                    <Divider10 />
                                    <DivInline>
                                        <Typography variant="caption" sx={{ width: "24vw" }}>Reported command:</Typography>
                                        <Typography variant="caption">{reportedCommandStr}</Typography>
                                    </DivInline>
                                    <Divider10 />
                                    <DivExist show={reportedCommandStr && shortenOriginalCommandStr}>
                                        {
                                            reportedCommandStr === shortenOriginalCommandStr ?
                                                <Typography variant="caption">Command matched</Typography> :
                                                <Typography variant="caption">Reported command overwriten current command</Typography>
                                        }
                                    </DivExist>
                                    <Divider10 />
                                    <DivExist show={lightObj?.daliCtlID}>
                                        <DivInline>
                                            <Typography variant="caption" sx={{ width: "24vw" }}>Current command (single light):</Typography>
                                            <Typography variant="caption">{gs.jsonCommandToDtk(originalSingleLightCmdObj)}</Typography>
                                        </DivInline>
                                        <Divider10 />
                                        <DivInline>
                                            <Typography variant="caption" sx={{ width: "24vw" }}>Reported command (single light):</Typography>
                                            <Typography variant="caption">{gs.jsonCommandToDtk(reportedSingleLightCmdObj)}</Typography>
                                        </DivInline>
                                        <Divider10 />
                                    </DivExist>
                                </DivExist>
                            </Fragment>

                        )
                    })}

            </ModalSM>
        </>
    )
}