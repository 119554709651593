import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, IconButton, Tooltip } from "@mui/material";
import { titleText, dataText } from "asset/string/color";
import DivExist from "components/DivExist";
import SensorIcon from "components/SensorIcon";
import DivCol from "components/DivCol";
import { sensorStringsOfType, tspToDateTime } from "actions/generalActions";
import General from "@ecoenghk/general";
import { sensorManualExecuteSetting } from "actions/sensorActions";
import { mapAddSensor, mapRemoveSensor } from "actions/mapActions";
import AdminContainer from "components/AdminContainer";
import { Lock, TouchApp } from "@mui/icons-material";
import { mgFindOne } from "actions/mongoApiActions";
import { getDocFS } from "actions/firebaseMgActions";
import { alertWindow } from "actions/screenActions";
import DeviceMongoModal from "components/DeviceMongoModal";
const gs = new General();
export default function SelectedSensorInfo() {
  const [state, dispatch] = useContext(SMContext);
  const {
    selectedID,
    gatewayObjAll,
    sensorObjAll,
    serverObj,
    activeMapID,
    mapObjAll,
  } = state;
  const sensorObj = sensorObjAll[selectedID] || {};
  const {
    sensorID,
    timeStamp,
    dtkAdd,
    sensorName,
    type,
    gatewayID,
    setting1,
    motion_off_delay,
    motion_off_delay2,
    serverID,
    luxLv,
    shortAdd,
    instance,
    daliCtlID,
    gang,
    lockLevelSet1,
    releaseOnDelayEnd1,
  } = sensorObj || {};
  const lightConnected = Object.keys(setting1 || {}).length;
  const sensorTypeName = sensorStringsOfType(type)?.sensorTypeName;
  const nameLen = sensorName?.length || 0;
  const unknownDevice =
    Object.keys(sensorObj || {}).length === 0 ? true : false;
  const handleAddToMap = async () => {
    let sensorObj = await mgFindOne("sensor", "sensorID", selectedID);
    if (!sensorObj && serverObj.enableUploadFS) {
      sensorObj = await getDocFS(`SM_sensor/${selectedID}`);
    }
    if (!sensorObj) {
      alertWindow(
        dispatch,
        `Sensor ${selectedID} not found in local db and fs`
      );
      return;
    }
    const { x, y } = mapObjAll[activeMapID]?.sensorObj || {};
    await mapAddSensor(activeMapID, selectedID, sensorObj, x, y);
  };
  let delayAndLockLvStr = `\u{01f512}${lockLevelSet1 || 5
    }\u{0021e8}${motion_off_delay}s`;
  if (motion_off_delay2 > 0)
    delayAndLockLvStr += `\u{0021e8}${motion_off_delay2}s`;
  delayAndLockLvStr += `\u{0021e8}`;
  delayAndLockLvStr += releaseOnDelayEnd1
    ? `\u{01f513}5`
    : `\u{01f512}${lockLevelSet1}`;

  return (
    <>
      <DeviceMongoModal deviceObj={sensorObj} >
        <SensorIcon
          width={8}
          sizeUnit="vw"
          sensorObj={sensorObj}
          gatewayConnected={gatewayObjAll[gatewayID]?.gatewayConnected}
        />
      </DeviceMongoModal>
      <Typography variant="caption" sx={{ color: dataText }}>
        {sensorID}
      </Typography>

      <DivExist show={!unknownDevice}>
        <Typography sx={{ color: titleText }} align="center">
          {sensorTypeName}
        </Typography>
        <DivExist show={type === "lightswitch" || type === "scenebutton"}>
          {`${gang} gang`}
        </DivExist>
        <InfoDivider />
        <Typography
          sx={{ color: dataText }}
          variant={nameLen > 15 ? "caption" : "body1"}
          align="center"
        >
          {sensorName}
        </Typography>
        <InfoDivider />
        <Typography style={{ color: titleText }} variant="caption">
          Connected to gateway:
        </Typography>
        {gatewayID ? (
          <DivCol>
            <Typography variant="caption" style={{ color: dataText }}>
              {gatewayID}
            </Typography>
            <Typography variant="caption" style={{ color: dataText }}>
              {gatewayObjAll[gatewayID]?.description}
            </Typography>
          </DivCol>
        ) : (
          <Typography
            variant="caption"
            align="center"
            color="error"
            style={{ color: dataText }}
          >
            Not connected to any gateway
          </Typography>
        )}
        <InfoDivider />
        <DataSet title="Server ID:" data={serverID} hide={!serverID} />
        {
          sensorObj.dtkChannel > 0 ?
            <DataSet title="Dtk channel" data={sensorObj.dtkChannel} />
            :
            <DataSet title="Mesh channel" data={sensorObj.meshChannel} />
        }

        <DataSet title="Address" data={dtkAdd} hide={daliCtlID} />
        <DataSet title="Dali controller ID" data={daliCtlID} hide={!daliCtlID} />
        <DataSet title="Dali address" data={shortAdd} hide={!daliCtlID} />
        <DataSet title="Instance" data={instance} hide={!daliCtlID} />

        <DivExist show={type === "motion" || type === "daylightmotion"}>
          <DataSet
            title="Delay time and priority level"
            data={delayAndLockLvStr}
          />
        </DivExist>
        <DivExist show={sensorObj.enableGroupAction ? true : false}>
          <DataSet title="Group action" data="Enabled" />
        </DivExist>
        <DataSet title="Lights connected" data={lightConnected} />
        <DivExist show={type === "daylight"}>
          <DataSet title="Lux Level" data={luxLv || 0} />
          <DataSet
            title="Priority level"
            data={
              <>
                <Lock sx={{ fontSize: "1rem" }} />
                {lockLevelSet1}
              </>
            }
          />
          <DataSet
            title="Priority level after light action"
            data={
              <>
                <Lock fontSize="1em" />
                {releaseOnDelayEnd1 ? lockLevelSet1 : 5}
              </>
            }
          />
        </DivExist>
        <DataSet title="Latest alive time" data={tspToDateTime(timeStamp)} />
        <DataSet title="Version" data={sensorObj.deviceVersion} />
      </DivExist>
      <AdminContainer>
        <DivExist show={type === "motion"}>
          <Tooltip title="Manual execute light action">
            <IconButton
              size="small"
              onClick={() => sensorManualExecuteSetting(sensorObj, 1)}
            >
              <TouchApp sx={{ color: "#000" }} />
            </IconButton>
          </Tooltip>
        </DivExist>
      </AdminContainer>
      <DivExist show={unknownDevice}>
        <Typography sx={{ color: dataText }} align="center">
          Unknown sensor
        </Typography>
        <Typography sx={{ color: dataText }} align="center">{selectedID}</Typography>
        <InfoDivider />
        <Button
          sx={{ margin: "0.5vh" }}
          size="small"
          variant="outlined"
          onClick={handleAddToMap}
        >
          Add to map
        </Button>
        <InfoDivider />
        <Button
          sx={{ margin: "0.5vh" }}
          size="small"
          variant="outlined"
          onClick={async () => mapRemoveSensor(activeMapID, selectedID)}
        >
          Remove from map
        </Button>
      </DivExist>
    </>
  );
}

function InfoDivider() {
  return <hr color="lightgrey" size={1} width="90%" />;
}

function DataSet({ title, data, hide }) {
  if (hide) return null;
  return (
    <DivCol alignItems="center" style={{ width: "100%" }}>
      <Typography variant="caption" display="block" sx={{ color: titleText }}>
        {title}
      </Typography>
      <Typography sx={{ color: dataText }} align="center" variant="caption">
        {data}
      </Typography>
      <hr color="lightgrey" size={1} width="90%" />
    </DivCol>
  );
}
