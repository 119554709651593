import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import DivExist from "components/DivExist";
import Divider10 from "components/Divider10";
import {
    Typography,
    Box,
    Slider,
    Button,
    Tooltip,
    IconButton,
    Checkbox,
} from "@mui/material";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
import {
    FastForward,
    CopyAll,
    ArrowDownward,
    Repeat,
    SwipeRightAlt,
} from "@mui/icons-material";
import LightBulb from "asset/svgComp/LightBulb";
import LightIcon from "components/LightIcon";
import {
    sensorDisconnectLight,
} from "actions/sensorActions";
import NearFieldIcon from "asset/svgComp/NearFieldIcon";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import {
    alertWindow,
    confirmWindow,
    hideBigProgress,
    openSnackbar,
    showBigProgress,
} from "actions/screenActions";
import General from "@ecoenghk/general";
import Intermediate from "asset/svgComp/Intermediate";
import IconButtonDone from "components/IconButtonDone";
import SensorIcon from "components/SensorIcon";
import { secToMin } from "actions/generalActions";
import IconButtonAdd from "components/IconButtonAdd";
import SenNearFieldModal from "./SenNearFieldModal";
import { deviceGatewayIsDisconnected } from "actions/gatewayActions";
import SensorMapModalHeader from "./SensorMapModalHeader";
import DeviceActionLine from "components/DeviceActionLine";
const gs = new General();
const sxArrow = {
    outline: "1px solid #fff",
    margin: "1vh",
    padding: "0 0.5vw 0 0.5vw ",
};
export default function SenDimControllerLightModal({
    x,
    y,
    modalLocation,
    open,
    handleClose,
    layerProps,
    selectedLight,
    sensorID,
    sensorObj,
    scale,
}) {
    // const [settingObj, setSettingObj] = useState({});
    // const [activeNearFieldButton, setActiveNearFieldButton] = useState(0);
    // useEffect(() => {
    //     const { setting1, setting2 } = sensorObj || {};
    //     const obj = { setting1, setting2 };
    //     setSettingObj(obj);
    // }, [selectedLight, open, sensorObj]);
    const { iconSize, lightObjAll, dispatch, daliCtlObjAll, userObj } = layerProps;
    const lightObj = lightObjAll[selectedLight];
    const serialSetting = sensorObj?.setting1?.[selectedLight];
    const serialSetting2 = sensorObj?.setting2?.[selectedLight];
    // const { delaySec2, gang } = sensorObj || {};

    const updateSetting = async (settingNum, updateObj) => {
        // let serialSetting = settingObj[`setting${settingNum}`]?.[selectedLight];
        // serialSetting = { ...serialSetting, ...updateObj };
        // const newSettingObj = { ...settingObj };
        // newSettingObj[`setting${settingNum}`][selectedLight] = serialSetting;
        // // console.log(newSettingObj);
        // setSettingObj(newSettingObj);
    };
    // const handleSave = async () => {
    // const did = sensorObj.daliCtlID;
    // const gid = did ? daliCtlObjAll[did]?.gatewayID : sensorObj.gatewayID;
    // let updateObj = {};
    // Object.keys(settingObj || {}).forEach((settingN) => {
    //     if (settingObj[settingN])
    //         updateObj = { ...updateObj, [settingN]: settingObj[settingN] };
    // });
    // await updateSensorProperty(sensorID, sensorObj, gid, updateObj);
    // handleClose();
    // };
    const handleDisconnect = async () => {
        confirmWindow(
            dispatch,
            `Confirm disconnect light [${selectedLight}]?`,
            async () => {
                showBigProgress(dispatch);
                const did = sensorObj.daliCtlID;
                const gid = did ? daliCtlObjAll[did]?.gatewayID : sensorObj.gatewayID;
                await sensorDisconnectLight(sensorObj, gid, lightObj);
                hideBigProgress(dispatch);
                handleClose();
            }
        );
    };
    const onNfClick = async () => { }

    const modalWidth = window.innerWidth * 0.2;
    const modalHeight = window.innerHeight * 0.25
    const modalX = modalLocation.includes("right")
        ? x - modalWidth / scale - 2 * iconSize
        : x;
    const modalY = modalLocation.includes("bottom")
        ? y - modalHeight / scale - iconSize
        : y;
    return (
        <>
            <MapModal
                x={modalX}
                y={modalY}
                open={open}
                handleCloseModal={handleClose}
                disableCloseButton
                width={modalWidth}
                height={modalHeight}
                layerProps={layerProps}
            >
                <SensorMapModalHeader sensorObj={sensorObj} deviceObj={lightObj} handleClose={handleClose} layerProps={layerProps} />
                <Divider10 />
                <DeviceActionLine deviceSetting={serialSetting} deviceSetting2={serialSetting2} deviceObj={lightObj} mainDeviceObj={sensorObj} buttonNo={1} />

                <Divider10 space={20} />
                <IconButtonClose
                    tooltip="Close dialog without saving"
                    onBtnClick={handleClose}
                />

                <IconButtonDelete
                    tooltip="Disconnect light from sensor"
                    onBtnClick={handleDisconnect}
                />
                {userObj.level === 0 && (

                    <Tooltip title="Save near field action to sensor">
                        <IconButton onClick={onNfClick}>
                            <NearFieldIcon width={2} sizeUnit="vw" color={sensorObj?.nearFieldSetting?.[`${selectedLight}_1_${lightObj?.shortAdd || 0}`] ? "red" : "#fff"} />
                        </IconButton>
                    </Tooltip>
                )}
            </MapModal>
            {/* <SenNearFieldModal
                x={modalX}
                y={modalY}
                width={modalWidth}
                height={modalHeight}
                open={activeNearFieldButton > 0 ? true : false}
                handleClose={() => setActiveNearFieldButton(0)}
                layerProps={layerProps}
                sensorObj={sensorObj}
                deviceObj={lightObj}
                buttonNo={activeNearFieldButton}
            /> */}
        </>
    );
}
