import { IconButton, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";

export default function IconButtonAdd({ onBtnClick, tooltip, size }) {
  return (
    <IconButton onClick={onBtnClick} size={size || "large"}>
      <Tooltip title={tooltip || ""}>
        <Add />
      </Tooltip>
    </IconButton>
  );
}
