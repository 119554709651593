import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import DivInline from "components/DivInline";
import { Typography, Box, Tooltip } from "@mui/material";
import Divider10 from "components/Divider10";
import moment from "moment";
import cronstrue from "cronstrue";
import IconButtonRefresh from "components/IconButtonRefresh";
import { queryGatewayOneSetting } from "actions/gatewayActions";
import General from "@ecoenghk/general";
import DeviceIcon from "components/DeviceIcon";
import DeviceCmdMsgModal from "components/DeviceCmdMsgModal";
import JsonModalContainer from "components/JsonModalContainer";
const gs = new General();

export default function BindedTimeTableSettingPanel() {
  const [state, dispatch] = useContext(SMContext);
  const { activeGatewayID, gatewayObjAll, lightObjAll, sensorObjAll } = state;
  const gatewayObj = gatewayObjAll[activeGatewayID];
  let timeTableJobObj = gatewayObj.timeTableJobObj || {};
  const jobTsp = timeTableJobObj?.timeStamp;
  delete timeTableJobObj.timeStamp;
  let jobArray = Object.keys(timeTableJobObj || {}).map((tid) => {
    let obj = timeTableJobObj[tid] || {};
    obj.timeTableID = tid;
    return obj;
  });
  let jobObjByCron = {};
  jobArray.forEach((obj) => {
    const { cron } = obj;
    if (cron in jobObjByCron) jobObjByCron[cron].push(obj);
    else jobObjByCron[cron] = [obj];
  });
  let jobArrayByCron = Object.keys(jobObjByCron)
    .map((cron) => {
      return jobObjByCron[cron];
    })
    .sort((a, b) => moment(a[0].nextTime).diff(moment(b[0].nextTime)));
  return (
    <div>
      <DivInline justifyContent="center">
        <JsonModalContainer jsonData={timeTableJobObj}>
          <Typography variant="h6" align="center">Time Table Job</Typography>
        </JsonModalContainer>
        <IconButtonRefresh
          onBtnClick={() => queryGatewayOneSetting(gatewayObj, 0x0015)}
        />
      </DivInline>
      <Divider10 />
      {jobArrayByCron.map((arr, key) => {
        let timeTableIDAll = arr[0].timeTableID;
        let tarr = timeTableIDAll.split("_");
        const zcID = `${tarr[0]}_${tarr[1]}`;
        const tid = `${zcID}_${tarr[2]}`;
        const cronStr = arr[0].cron && cronstrue.toString(arr[0].cron);
        return (
          <Box key={key} sx={{ borderBottom: "0.5px solid grey" }}>
            <Tooltip title={`zoneControlID:${zcID}, timeTableID:${tid}`}>
              <Typography display="block" variant="body2">
                {`${moment(arr[0].nextTime).format("DD-MM-YYYY")} ${cronStr}`}
              </Typography>
            </Tooltip>
            {arr.map((obj, k) => {
              const { dtk_command } = obj;
              const cmdObj = gs.dtKStrToJson(dtk_command || "");
              const deviceID = obj.serial || obj.sensorID || obj.daliCtlID;
              const cmdMsg = gs.dtkActionCmdToMsg(cmdObj, deviceID);
              let deviceType = "light";
              if (obj.sensorID) deviceType = "sensor";
              if (obj.daliCtlID && isNaN(cmdObj.shortAdd)) deviceType = "daliCtl";
              if (obj.daliCtlID && cmdObj.shortAdd >= 0) {
                deviceType = "light";
                obj.serial = `${obj.daliCtlID}_${cmdObj.shortAdd}_LG`
              }
              const deviceStyle = deviceType === "light" ? lightObjAll[obj.serial]?.style : obj.sensorID ? sensorObjAll[obj.sensorID]?.type : "";
              return (
                <DeviceCmdMsgModal deviceType={deviceType} cmdObj={cmdObj} deviceID={deviceID} key={k} disableOpen={state.userObj.level > 0}  >
                  <DivInline >
                    <DeviceIcon
                      deviceType={deviceType}
                      width={1.4}
                      deviceStyle={deviceStyle}
                      gang={sensorObjAll[obj.sensorID]?.gang}
                    />
                    <Typography display="block" variant="caption">{cmdMsg}</Typography>
                  </DivInline>
                </DeviceCmdMsgModal>
              );
            })}
          </Box>
        );
      })}
      <Divider10 />
    </div>
  );
}
