import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import Divider10 from "components/Divider10";
import { Typography, Box, Slider, Tooltip, IconButton } from "@mui/material";
import DivInline from "components/DivInline";
import { CopyAll, ArrowDownward, SwipeRightAlt } from "@mui/icons-material";
import LightBulb from "asset/svgComp/LightBulb";
import LightIcon from "components/LightIcon";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import IconButtonDone from "components/IconButtonDone";
import {
  confirmWindow,
  hideBigProgress,
  showBigProgress,
} from "actions/screenActions";
import ZoneControlIcon from "components/ZoneControlIcon";
import {
  copyLightSettingInOneZc,
  zoneControlUpdateOneLightSetting,
  zoneControlDisconnectLight,
} from "actions/zcActions";
import DivExist from "components/DivExist";
import DivCol from "components/DivCol";
const sxArrow = {
  outline: "1px solid #fff",
  margin: "1vh",
  padding: "0 0.5vw 0 0.5vw ",
};
let pwmMarks = [];
for (let i = 0; i <= 100; i += 20) {
  const obj = { value: i, label: `${i}` };
  pwmMarks = [...pwmMarks, obj];
}
export default function ZcLightModalSceneButton({
  x,
  y,
  modalLocation,
  open,
  handleClose,
  layerProps,
  selectedLight,
  zoneControlObj,
}) {
  const [settingObj, setSettingObj] = useState({});
  useEffect(() => {
    const setting1 = zoneControlObj.setting1;
    const obj = { setting1 };
    setSettingObj(obj);
  }, [open, zoneControlObj.setting1]);
  const { iconSize, lightObjAll, dispatch, layerScale } = layerProps;
  const lightObj = lightObjAll[selectedLight];
  const {
    zoneControlName,
    type,
    delayed_action,
    delayed_action2,
    delaySec,
    delaySec2
  } = zoneControlObj || {};
  const serialSetting = settingObj.setting1?.[selectedLight] || {};

  const updateSetting = async (settingNum, updateObj) => {
    let serialSetting = settingObj[`setting${settingNum}`]?.[selectedLight];
    serialSetting = { ...serialSetting, ...updateObj };
    const newSettingObj = { ...settingObj };
    newSettingObj[`setting${settingNum}`][selectedLight] = serialSetting;
    setSettingObj(newSettingObj);
  };
  const handleSave = async () => {
    // const serialSetting = settingObj[`setting1`][selectedLight];
    // const serialSettingBySettingNum = { setting1: serialSetting }
    await zoneControlUpdateOneLightSetting(zoneControlObj, lightObj, settingObj)
    // await updateZcProperty(zoneControlID, settingObj);
    handleClose();
  };
  const handleDisconnect = async () => {
    confirmWindow(dispatch, `Confirm remove this light?`, async () => {
      showBigProgress(dispatch);
      await zoneControlDisconnectLight(zoneControlObj, selectedLight, lightObj);
      handleClose();
      hideBigProgress(dispatch);
    });
  };
  const handleCopyLightSetting = async () => {
    confirmWindow(
      dispatch,
      "Confirm copy setting to other lights?",
      async () => {
        await copyLightSettingInOneZc(zoneControlObj, selectedLight);
      }
    );
  };
  const modalWidth = window.innerWidth * 0.35;
  const modalHeight =
    window.innerHeight * (delaySec2 > 0 || delayed_action2 > 0 ? 0.48 : 0.38);
  const modalX = modalLocation.includes("right")
    ? x - modalWidth / layerScale - 2 * iconSize
    : x;
  const modalY = modalLocation.includes("bottom")
    ? y - modalHeight / layerScale - iconSize
    : y;
  return (
    <MapModal
      x={modalX}
      y={modalY}
      open={open}
      handleCloseModal={handleClose}
      disableCloseButton
      width={modalWidth}
      height={modalHeight}
      layerProps={layerProps}
    >
      <DivInline>
        <IconButtonClose onBtnClick={handleClose} />
        <ZoneControlIcon type={type} width={3} sizeUnit="vw" status={0} />
        <SwipeRightAlt sx={{ fontSize: "2rem" }} />
        <LightIcon
          lightStyle={lightObj?.style}
          width={3}
          sizeUnit="vw"
          disabledConnectStatus
          disabledStatus
        />
        <DivCol>
          <Typography variant="caption">{lightObj?.description}</Typography>
          <Typography variant="caption">{`[${selectedLight}]`}</Typography>
        </DivCol>
      </DivInline>

      <Divider10 />
      <LightSettingRow
        settingNum={1}
        updateSetting={updateSetting}
        serialSetting={serialSetting}
        onoffField="onoffcontrolA"
        pwmField="pwm"
        enablePwm={lightObj?.type?.pwm}
      />


      <DivExist show={delaySec > 0 || delayed_action > 0}>
        <DivInline justifyContent="center" alignItems="center">
          <Box sx={sxArrow}>
            <ArrowDownward sx={{ fontSize: "0.8rem" }} />
            <Typography variant="caption">{delaySec || delayed_action}s</Typography>
          </Box>
        </DivInline>
        <LightSettingRow
          settingNum={1}
          updateSetting={updateSetting}
          serialSetting={serialSetting}
          onoffField="delayedOnOff"
          pwmField="delayedPwm"
          enablePwm={lightObj?.type?.pwm}
        />
      </DivExist>
      <DivExist show={delaySec2 > 0 || delayed_action2 > 0}>
        <DivInline justifyContent="center" alignItems="center">
          <Box sx={sxArrow}>
            <ArrowDownward sx={{ fontSize: "0.8rem" }} />
            <Typography variant="caption">{delaySec2 || delayed_action2}s</Typography>
          </Box>
        </DivInline>
        <LightSettingRow
          settingNum={1}
          updateSetting={updateSetting}
          serialSetting={serialSetting}
          onoffField="delayedOnOff2"
          pwmField="delayedPwm2"
          enablePwm={lightObj?.type?.pwm}
        />
      </DivExist>

      <Divider10 />

      <IconButtonClose
        tooltip="Close dialog without saving"
        onBtnClick={handleClose}
      />
      <IconButtonDone
        tooltip="Save settings and close dialog"
        onBtnClick={handleSave}
      />
      <IconButtonDelete
        onBtnClick={handleDisconnect}
        tooltip={`Disconnect light from ${zoneControlName}`}
      />
      <IconButton onClick={handleCopyLightSetting} size="small">
        <Tooltip title="Copy setting to other lights">
          <CopyAll />
        </Tooltip>
      </IconButton>
    </MapModal>
  );
}

// function LightSettingRowDali({
//   lightObj,
//   layerProps,
//   settingObj,
//   daliUpdateSetting,
// }) {
//   const [latestPwm, setLatestPwm] = useState(10);
//   const { daliCtlObjAll } = layerProps;
//   const { daliCtlID, shortAdd } = lightObj;
//   const addList = daliCtlObjAll[daliCtlID]?.addList || [];
//   const shortAddIndex = addList.indexOf(shortAdd);
//   useEffect(() => {
//     const currentLv = settingObj[daliCtlID].lightLvArr[shortAddIndex];
//     setLatestPwm(currentLv);
//   }, [settingObj]);

//   const updateSetting = (lv) => {
//     setLatestPwm(lv);
//     let newSettingObj = { ...settingObj };
//     newSettingObj[daliCtlID].lightLvArr[shortAddIndex] = lv;
//     daliUpdateSetting(newSettingObj);
//   };
//   return (
//     <>
//       <DivInline style={{ width: "95%" }} justifyContent="space-around">
//         <Box
//           sx={{
//             outline: latestPwm > 0 ? "1px solid red" : "",
//           }}
//           onClick={() => updateSetting(100)}
//         >
//           <LightBulb onoff="on" width={2.5} sizeUnit="vw" />
//         </Box>
//         <Box
//           sx={{
//             outline: latestPwm > 0 ? "1px solid red" : "",
//           }}
//           onClick={() => updateSetting(0)}
//         >
//           <LightBulb onoff="on" width={2.5} sizeUnit="vw" />
//         </Box>
//         <Slider
//           sx={{ width: "50%" }}
//           size="small"
//           value={latestPwm || 0}
//           onChangeCommitted={(e, val) => updateSetting(val)}
//           marks={pwmMarks}
//           max={100}
//           min={0}
//           step={10}
//         />
//       </DivInline>
//     </>
//   );
// }

function LightSettingRow({
  settingNum,
  updateSetting,
  serialSetting,
  onoffField,
  pwmField,
  enablePwm,
}) {
  const [latestPwm, setLatestPwm] = useState(10);
  let pwmMarks = [];
  for (let i = 0; i <= 100; i += 20) {
    const obj = { value: i, label: `${i}` };
    pwmMarks = [...pwmMarks, obj];
  }
  return (
    <>
      <DivInline style={{ width: "95%" }} justifyContent="space-around">
        <Box
          sx={{
            outline: serialSetting[onoffField] === 0 ? "1px solid red" : "",
          }}
          onClick={() => {
            let newPwm =
              serialSetting[onoffField] === 1
                ? latestPwm
                : serialSetting[pwmField];
            const updateObj = {
              [onoffField]: 0,
              [pwmField]: newPwm,
            };
            updateSetting(settingNum, updateObj);
          }}
        >
          <LightBulb onoff="on" width={2.5} sizeUnit="vw" />
        </Box>
        <Box
          sx={{
            outline: serialSetting[onoffField] === 1 ? "1px solid red" : "",
          }}
          onClick={() => {
            setLatestPwm(serialSetting[pwmField]);
            updateSetting(settingNum, { [onoffField]: 1, [pwmField]: 0 });
          }}
        >
          <LightBulb onoff="off" width={2.5} sizeUnit="vw" />
        </Box>
        <DivExist show={enablePwm}>
          <Slider
            sx={{ width: "50%" }}
            size="small"
            value={serialSetting[pwmField] || 0}
            onChangeCommitted={(e, val) => {
              const newA = Number(val) > 0 ? 0 : 1;
              setLatestPwm(serialSetting[pwmField]);
              updateSetting(settingNum, {
                [onoffField]: newA,
                [pwmField]: Number(val),
              });
            }}
            marks={pwmMarks}
            max={100}
            min={0}
            step={10}
          />
        </DivExist>
      </DivInline>
    </>
  );
}
