import { useContext } from "react";
import { SMContext } from "../context/smContext";
// import Snackbar from "@mui/material/Snackbar";
import { Typography, Tooltip, Box } from "@mui/material";
import LightIcon from "./LightIcon";
import SensorIcon from "./SensorIcon";
import GatewayIcon from "./GatewayIcon";
import DaliCtlIcon from "./DaliCtlIcon";
import DivExist from "./DivExist";

export default function DeviceCard({
  deviceID,
  deviceType,
  margin = "0.3vw",
  onClick,
  width = "5vw",
  height = "9vh",
  backgroundColor = "#fff",
}) {
  const [state] = useContext(SMContext);
  const { lightObjAll, gatewayObjAll, sensorObjAll, daliCtlObjAll } = state;
  let deviceObj;
  if (deviceType === "serial") deviceObj = lightObjAll[deviceID];
  else if (deviceType === "gateway") deviceObj = gatewayObjAll[deviceID];
  else if (deviceType === "sensor") deviceObj = sensorObjAll[deviceID];
  else if (deviceType === "daliCtl") deviceObj = daliCtlObjAll[deviceID];
  const handleClick = () => {
    if (onClick) onClick();
  };
  return (
    <Box
      onClick={handleClick}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin,
        width,
        height,
        backgroundColor,
        cursor: onClick ? "pointer" : "default",
      }}
    >
      <DivExist show={deviceType === "serial"}>
        <LightIcon
          lightObj={deviceObj}
          width={3}
          sizeUnit="vw"
          gatewayConnected={
            gatewayObjAll[deviceObj?.gatewayID]?.gatewayConnected
          }
        />
      </DivExist>
      <DivExist show={deviceType === "sensor"}>
        <SensorIcon
          sensorObj={deviceObj}
          width={3}
          sizeUnit="vw"
          gatewayConnected={
            gatewayObjAll[deviceObj?.gatewayID]?.gatewayConnected
          }
        />
      </DivExist>
      <DivExist show={deviceType === "gateway"}>
        <GatewayIcon width={3} sizeUnit="vw" />
      </DivExist>
      <DivExist show={deviceType === "daliCtl"}>
        <DaliCtlIcon
          width={3}
          sizeUnit="vw"
          daliCtlObj={deviceObj}
          gatewayConnected={
            gatewayObjAll[deviceObj?.gatewayID]?.gatewayConnected
          }
        />
      </DivExist>
      <Typography variant="caption" sx={{ color: "#000" }}>
        {deviceID}
      </Typography>
    </Box>
  );
}
