import { useState, useContext, useMemo } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Tooltip } from "@mui/material";
import {
  // mapAddLight,
  // mapAddManyDaliCtls,
  // mapAddManyGateways,
  // mapAddManyLights,
  // mapAddManySensors,
  mapAddManyDevices,
  mapRemoveManyDevices,
  // mapAddSensor,
  // mapRemoveManyDaliCtls,
  // mapRemoveManyGateways,
  // mapRemoveManyLights,
  // mapRemoveManySensors,
} from "actions/mapActions";
import { SelectAll, ContentCopy, DriveFileMove } from "@mui/icons-material";
import ModalSM from "components/ModalSM";
import DivCol from "components/DivCol";
import IconButtonClose from "components/IconButtonClose";
import DivInline from "components/DivInline";
import DivExist from "components/DivExist";
// import BulkChangeLightPropModal from "containers/map/modal/BulkChangeLightPropModal";
// import BulkChangeSensorPropModal from "../modal/BulkChangeSensorPropModal";
import ThemeContainer from "components/ThemeContainer";
import { titleText } from "asset/string/color";
import {
  confirmWindow,
  showBigProgress,
  hideBigProgress,
} from "actions/screenActions";
import General from "@ecoenghk/general";
import { deleteZoneControl } from "actions/zcActions";
const gs = new General();

export default function ShiftSelectedDeviceControl() {
  const [state, dispatch] = useContext(SMContext);
  const {
    activeMapID,
    shiftSelectedArray,
    zoneControlObjAll,
    mapObjAll,
  } = state;
  const selectedLightArray = useMemo(() => {
    let arr = shiftSelectedArray
      .filter((obj) => obj.deviceType === "light")
      .map((obj) => obj.deviceID);
    return arr;
  }, [shiftSelectedArray]);
  const selectedSensorArray = useMemo(() => {
    let arr = shiftSelectedArray
      .filter((obj) => obj.deviceType === "sensor")
      .map((obj) => obj.deviceID);
    return arr;
  }, [shiftSelectedArray]);
  const selectedDaliCtlArray = useMemo(() => {
    let arr = shiftSelectedArray
      .filter((obj) => obj.deviceType === "daliCtl")
      .map((obj) => obj.deviceID);
    return arr;
  }, [shiftSelectedArray]);
  const selectedGatewayArray = useMemo(() => {
    let arr = shiftSelectedArray
      .filter((obj) => obj.deviceType === "gateway")
      .map((obj) => obj.deviceID);
    return arr;
  }, [shiftSelectedArray]);
  const selectedZoneControlArray = useMemo(() => {
    let arr = shiftSelectedArray
      .filter((obj) => obj.deviceType === "zoneControl")
      .map((obj) => obj.deviceID);
    return arr;
  }, [shiftSelectedArray]);
  const removeAllDevice = async () => {
    showBigProgress(dispatch);
    const selectedArrayObj = { gatewayArray: selectedGatewayArray, lightArray: selectedLightArray, sensorArray: selectedSensorArray, daliCtlArray: selectedDaliCtlArray };
    await mapRemoveManyDevices(mapObjAll[activeMapID], selectedArrayObj);
    // await mapRemoveManyLights(activeMapID, selectedLightArray);
    // await mapRemoveManySensors(activeMapID, selectedSensorArray);
    // await mapRemoveManyGateways(activeMapID, selectedGatewayArray);
    // await mapRemoveManyDaliCtls(activeMapID, selectedDaliCtlArray);
    hideBigProgress(dispatch);
    goBack();
  };

  function goBack() {
    unselectGateway();
    dispatch({ type: "CLEARED_SHIFT_SELECT" });
    dispatch({ type: "ALL_UNSELECTED" });
  }
  function unselectSensor() {
    dispatch({ type: "SET_SELECTED_SENSOR", payload: "" });
    dispatch({
      type: "SET_MAP_LAYER",
      payload: {
        normal: true,
        sensorSelect: false,
      },
    });
  }
  function unselectGateway() {
    dispatch({ type: "SET_SELECTED_GATEWAY", payload: "" });
    dispatch({
      type: "SET_MAP_LAYER",
      payload: {
        normal: true,
        gatewaySelect: false,
      },
    });
  }
  return (
    <ThemeContainer background="light">
      <SelectAll />
      <Typography align="center" style={{ color: titleText }}>
        Select multiple items
      </Typography>
      <hr color="lightgrey" size={1} width="90%" />
      <DivExist show={selectedGatewayArray.length > 0}>
        <Typography align="center" color="secondary">
          {selectedGatewayArray.length}
        </Typography>
        <Typography align="center" variant="body2">
          gateways
        </Typography>
        <hr color="lightgrey" size={1} width="90%" />
      </DivExist>
      <DivExist show={selectedLightArray.length > 0}>
        <Typography align="center" color="secondary">
          {selectedLightArray.length}
        </Typography>
        <Typography align="center" variant="body2">
          lights
        </Typography>
        <hr color="lightgrey" size={1} width="90%" />
      </DivExist>
      <DivExist show={selectedSensorArray.length > 0}>
        <Typography align="center" color="secondary">
          {selectedSensorArray.length}
        </Typography>
        <Typography align="center" variant="body2">
          sensors/switches
        </Typography>
        <hr color="lightgrey" size={1} width="90%" />
      </DivExist>
      <DivExist show={selectedDaliCtlArray.length > 0}>
        <Typography align="center" color="secondary">
          {selectedDaliCtlArray.length}
        </Typography>
        <Typography align="center" variant="body2">
          dali controllers
        </Typography>
        <hr color="lightgrey" size={1} width="90%" />
      </DivExist>
      <DivExist
        show={
          selectedLightArray.length > 0 ||
          selectedGatewayArray.length > 0 ||
          selectedSensorArray.length > 0 ||
          selectedDaliCtlArray.length > 0
        }
      >
        <Button
          variant="outlined"
          size="small"
          sx={{ marginBottom: "1vh" }}
          onClick={() => {
            confirmWindow(
              dispatch,
              "Confirm remove selected devices from this map?",
              async () => {
                await removeAllDevice();
              }
            );
          }}
        >
          Remove from this map
        </Button>
        <MoveCopyModal
          selectedLightArray={selectedLightArray}
          selectedSensorArray={selectedSensorArray}
          selectedDaliCtlArray={selectedDaliCtlArray}
          selectedGatewayArray={selectedGatewayArray}
          goBack={goBack}
        />
      </DivExist>

      <hr color="lightgrey" size={3} width="90%" />
      <DivExist show={selectedZoneControlArray.length > 0}>
        <Typography align="center" color="secondary">
          {selectedZoneControlArray.length}
        </Typography>
        <Typography align="center" variant="body2">
          Vitural button / timer
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{ marginBottom: "1vh" }}
          onClick={() => {
            confirmWindow(
              dispatch,
              "Confirm delete selected vitural button / timer?",
              async () => {
                showBigProgress(dispatch);
                await gs.asyncForEach(selectedZoneControlArray, async (z) => {
                  await deleteZoneControl(activeMapID, z, zoneControlObjAll[z]);
                  await gs.waitFor(500);
                });
                hideBigProgress(dispatch);
                goBack();
              }
            );
          }}
        >
          Delete selected Vitural button / timer
        </Button>
        <hr color="lightgrey" size={3} width="90%" />
      </DivExist>
      <IconButtonClose onBtnClick={goBack} />
    </ThemeContainer>
  );
}

function MoveCopyModal({
  selectedLightArray,
  selectedSensorArray,
  selectedDaliCtlArray,
  selectedGatewayArray,
  goBack,
}) {
  const [state, dispatch] = useContext(SMContext);
  const { mapObjAll, activeMapID, lightObjAll } = state;
  const [openMoveModal, setOpenMoveModal] = useState(false);
  async function moveToOtherMap(newMapID) {
    const selectedArrayObj = { gatewayArray: selectedGatewayArray, lightArray: selectedLightArray, sensorArray: selectedSensorArray, daliCtlArray: selectedDaliCtlArray };
    const currentMapObj = mapObjAll[activeMapID];
    confirmWindow(
      dispatch,
      `Confirm move selected devices to map [${mapObjAll[newMapID]?.mapName}]?`,
      async () => {
        await gs.waitFor(1000);
        confirmWindow(dispatch, "Same location in new map?", async () => {
          showBigProgress(dispatch);
          // await mapAddManyGateways(newMapID, selectedGatewayArray);
          // await mapAddManyLights(newMapID, selectedLightArray, lightDaliCtlMap);
          // await mapAddManySensors(newMapID, selectedSensorArray);
          // await mapAddManyDaliCtls(newMapID, selectedDaliCtlArray);
          await mapAddManyDevices(newMapID, selectedArrayObj, currentMapObj, lightDaliCtlMapOf(selectedLightArray));
          await gs.waitFor(1000);
          await mapRemoveManyDevices(currentMapObj, selectedArrayObj);
          // await mapRemoveManyLights(activeMapID, selectedLightArray);
          // await mapRemoveManySensors(activeMapID, selectedSensorArray);
          // await mapRemoveManyGateways(activeMapID, selectedGatewayArray);
          // await mapRemoveManyDaliCtls(activeMapID, selectedDaliCtlArray);
          hideBigProgress(dispatch);
          goBack();
        },
          async () => {
            await mapAddManyDevices(newMapID, selectedArrayObj, "", lightDaliCtlMapOf(selectedLightArray));
            await gs.waitFor(1000);
            await mapRemoveManyDevices(currentMapObj, selectedArrayObj);
            // await mapRemoveManyLights(activeMapID, selectedLightArray);
            // await mapRemoveManySensors(activeMapID, selectedSensorArray);
            // await mapRemoveManyGateways(activeMapID, selectedGatewayArray);
            // await mapRemoveManyDaliCtls(activeMapID, selectedDaliCtlArray);
            hideBigProgress(dispatch);
            goBack();
          });

      }
    );
  }
  async function copyToOtherMap(newMapID) {
    const selectedArrayObj = { gatewayArray: selectedGatewayArray, lightArray: selectedLightArray, sensorArray: selectedSensorArray, daliCtlArray: selectedDaliCtlArray };
    confirmWindow(
      dispatch,
      `Confirm copy selected devices to map [${mapObjAll[newMapID]?.mapName}]?`,
      async () => {
        await gs.waitFor(1000);
        confirmWindow(
          dispatch,
          "Same location in new map?",
          async () => {
            showBigProgress(dispatch);
            const currentMapObj = mapObjAll[activeMapID];
            // await mapAddManyGateways(newMapID, selectedGatewayArray, currentMapObj);
            // await mapAddManyLights(newMapID, selectedLightArray, currentMapObj, lightDaliCtlMapOf(selectedLightArray));
            // await mapAddManySensors(newMapID, selectedSensorArray, currentMapObj);
            // await mapAddManyDaliCtls(newMapID, selectedDaliCtlArray, currentMapObj);
            await mapAddManyDevices(newMapID, selectedArrayObj, currentMapObj, lightDaliCtlMapOf(selectedLightArray));
            hideBigProgress(dispatch);
            goBack();
          },
          async () => {
            showBigProgress(dispatch);
            // await mapAddManyGateways(newMapID, selectedGatewayArray, "");
            // await mapAddManyLights(newMapID, selectedLightArray, "", lightDaliCtlMapOf(selectedLightArray));
            // await mapAddManySensors(newMapID, selectedSensorArray, "");
            // await mapAddManyDaliCtls(newMapID, selectedDaliCtlArray, "");
            await mapAddManyDevices(newMapID, selectedArrayObj, "", lightDaliCtlMapOf(selectedLightArray));
            hideBigProgress(dispatch);
            goBack();
          }
        );

      }
    );
  }
  let modalTitle = "Move/Copy to other map - ";
  if (selectedLightArray.length > 0)
    modalTitle += `${selectedLightArray.length} lights, `;
  if (selectedSensorArray.length > 0)
    modalTitle += `${selectedSensorArray.length} sensors/switches, `;
  if (selectedGatewayArray.length > 0)
    modalTitle += `${selectedGatewayArray.length} gateways, `;
  if (selectedDaliCtlArray.length > 0)
    modalTitle += `${selectedDaliCtlArray.length} dali controllers, `;

  const lightDaliCtlMapOf = (lightArray) => {
    let map = {};
    lightArray.forEach(s => {
      let lightObj = lightObjAll[s];
      if (lightObj) map[s] = lightObj.daliCtlID || "";
    });
    return map;
  }
  return (
    <>
      <Button
        variant="outlined"
        sx={{ marginBottom: "1vh" }}
        onClick={() => setOpenMoveModal(true)}
        size="small"
      >
        Move/Copy to other map
      </Button>
      <ModalSM
        open={openMoveModal}
        modalTitle={modalTitle}
        onClose={() => setOpenMoveModal(false)}
        disableBottomClose
        width="85vw"
        height="80vh"
        backgroundColor="lightgrey"
      >
        <div
          style={{
            width: "85vw",
            display: "flex",
            flexWrap: "wrap",
            overflow: "auto",
          }}
        >
          {Object.keys(mapObjAll || {})
            .filter((mid) => mid !== activeMapID)
            .sort((a, b) =>
              mapObjAll[a].mapName.localeCompare(mapObjAll[b].mapName)
            )
            .map((mid) => {
              const { mapUrl, mapName } = mapObjAll[mid];
              return (
                <DivCol
                  key={mid}
                  style={{
                    margin: 10,
                    width: "12vw",
                  }}
                >
                  <img
                    src={mapUrl}
                    style={{ width: "12vw", height: "6vw" }}
                    alt=""
                  />
                  <Typography variant="caption">{mapName}</Typography>
                  <DivInline
                    justifyContent="space-around"
                    style={{ width: "100%" }}
                  >
                    <Tooltip title="Move to this map">
                      <DriveFileMove
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          await moveToOtherMap(mid);
                          setOpenMoveModal(false);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Copy to this map">
                      <ContentCopy
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          await copyToOtherMap(mid);
                          setOpenMoveModal(false);
                        }}
                      />
                    </Tooltip>
                  </DivInline>
                </DivCol>
              );
            })}
        </div>
      </ModalSM>
    </>
  );
}
