import { Group, Path } from "react-konva";

export default function RubbishBin({ x, y, size }) {
  const scale = size / 79.375;
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale} listening={false}>
      <Path
        fill="#736428"
        data="m13.095 72.562c0.10728 1.9843 2.2156 3.5503 4.7795 3.5503h44.658c2.5639 0 4.6722-1.566 4.7795-3.5503l3.1892-52.143h-60.596zm37.211-39.192a1.9431 1.5049 0 0 1 1.9431-1.5047h3.1078a1.9433 1.5051 0 0 1 1.9431 1.5047v29.791a1.9431 1.5049 0 0 1-1.9431 1.5047h-3.1078a1.9431 1.5049 0 0 1-1.9431-1.5047zm-13.599 0a1.9431 1.5049 0 0 1 1.9428-1.5047h3.1078a1.9431 1.5049 0 0 1 1.9428 1.5047v29.791a1.9426 1.5046 0 0 1-1.9428 1.5047h-3.1078a1.9426 1.5046 0 0 1-1.9428-1.5047zm-13.599 0a1.9431 1.5049 0 0 1 1.9428-1.5047h3.108a1.9431 1.5049 0 0 1 1.9428 1.5047v29.791a1.9428 1.5047 0 0 1-1.9428 1.5047h-3.108a1.9426 1.5046 0 0 1-1.9428-1.5047zm49.518-27.086h-20.602v-3.0167a1.0018 0.77589 0 0 0-1.0018-0.77589h-21.635a1.0018 0.77589 0 0 0-1.0016 0.77589v3.0165h-20.603c-1.658 0-3.0019 1.041-3.0019 2.3251v7.3042h70.847v-7.304c0-1.2841-1.3439-2.3251-3.0019-2.3251z"
        perfectDrawEnabled={false}
      ></Path>
    </Group>
  );
}
