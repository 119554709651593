import { lightColorAt } from "actions/generalActions";
import ChargeIcon from "./ChargeIcon";
import FingerPoint from "./FingerPoint";
import Bypass from "./Bypass";

export default function Tube({
  width,
  height,
  sizeUnit,
  lightLv,
  disabledStatus,
  statusA,
  statusL,
  connectStatus,
  disabledConnectStatus,
  typeBatVI,
  typeIsDim,
  bypass,
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  const lightColor = lightColorAt(
    disabledStatus,
    statusA,
    lightLv,
    statusL,
    typeIsDim
  );
  return (
    <svg viewBox="0 0 79.375 79.375" width={w + unit} height={h + unit}>
      {(statusA === 0 || statusL === 1) && (
        <>
          <ellipse
            cx={30.605}
            cy={61.1}
            fill="#fff"
            fillOpacity={1}
            rx={45}
            ry={25}
            transform="matrix(.75013 -.66129 .26663 .9638 0 0)"
          />
          <ellipse
            cx={30.605}
            cy={61.1}
            fill={lightColor}
            fillOpacity={typeIsDim ? lightLv / 100 : 1}
            rx={45}
            ry={25}
            transform="matrix(.75013 -.66129 .26663 .9638 0 0)"
          />
        </>
      )}

      <path
        fill="none"
        stroke="#22b3fd"
        strokeLinejoin="round"
        strokeOpacity={0.996}
        strokeWidth={2.737}
        d="M6.629 63.206l8.41 8.938 56.025-55.84-8.41-8.937z"
      ></path>
      <path
        fill="none"
        stroke="#918a6f"
        strokeLinejoin="round"
        strokeOpacity={0.996}
        strokeWidth={2.784}
        d="M2.255 67.522l4.374-4.316 8.41 8.938-4.494 4.607z"
      ></path>
      <path
        fill="none"
        stroke="#918a6f"
        strokeLinecap="round"
        strokeOpacity={0.996}
        strokeWidth={2.715}
        d="M6.19 76.906l1.672-1.622"
      ></path>
      <path
        fill="none"
        stroke="#918a6f"
        strokeLinecap="round"
        strokeOpacity={0.996}
        strokeWidth={2.715}
        d="M1.865 72.3l1.673-1.622"
      ></path>
      <path
        fill="none"
        stroke="#918a6f"
        strokeLinejoin="round"
        strokeOpacity={0.996}
        strokeWidth={2.783}
        d="M75.436 11.989l-4.374 4.316-8.409-8.938 4.494-4.608z"
      ></path>
      <path
        fill="none"
        stroke="#918a6f"
        strokeLinecap="round"
        strokeOpacity={0.996}
        strokeWidth={2.715}
        d="M72.09 2.494l-1.673 1.622"
      ></path>
      <path
        fill="none"
        stroke="#918a6f"
        strokeLinecap="round"
        strokeOpacity={0.996}
        strokeWidth={2.715}
        d="M75.826 7.21l-1.673 1.622"
      ></path>
      {!disabledConnectStatus && (
        <circle r={6} cx={10} cy={6} fill={connectStatus ? "green" : "red"} />
      )}
      {!disabledStatus && typeBatVI && (
        <ChargeIcon y={24} x={0} statusL={statusL} />
      )}
      {bypass === 1 && <Bypass x={45} y={40} scale={0.7} />}
    </svg>
  );
}
