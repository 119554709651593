import { useState, useRef, useEffect } from "react";
import { Group, Rect } from "react-konva";
import KonvaLabel from "containers/map/device/KonvaLabel";
import {
  daliCtlColorDark,
  gatewayColorDark,
  deviceConnected,
} from "actions/generalActions";
import { konvaShrinkAndBack } from "actions/konvaActions";
import { updateDevicePos } from "actions/mapActions";
import DaliCtl from "asset/mapComp/DaliCtl";
import ShiftSelectCircle from "./ShiftSelectCircle";
import DivExist from "components/DivExist";
export default function DeviceDaliCtl({
  x,
  y,
  layerProps,
  daliCtlID,
  daliCtlObj
}) {
  const {
    layerRef,
    activeMapID,
    lightObjAll,
    sensorObjAll,
    gatewayObjAll,
    mapLayer,
    mapObjAll,
    enablePan,
    mapScale,
    iconSize,
    selectedID,
    dispatch,
    socket,
    shiftSelectedArray,
    mapSize,
    layerScale,
    layerPos
  } = layerProps;
  const [showID, setShowID] = useState(false);
  const [scale, setScale] = useState(1);
  const [shadEnabled, setShadEnabled] = useState(false);
  const [toRender, setToRender] = useState(true);
  const deviceRef = useRef(null);
  useEffect(() => {
    if (deviceRef.current) {
      const p = deviceRef.current.absolutePosition();
      if (p.x < 0 || p.x > mapSize.x || p.y < 0 || p.y > mapSize.y) setToRender(false);
      else setToRender(true);
    }
  }, [layerScale, layerPos]);
  const activeMapObj = mapObjAll[activeMapID];
  const mapLightObj = activeMapObj?.lightObj;
  const mapSensorObj = activeMapObj?.sensorObj;
  const selected = selectedID === daliCtlID ? true : false;
  const shiftSelected = (shiftSelectedArray || [])
    .map((obj) => obj.deviceID)
    .includes(daliCtlID);
  const {
    description,
    gatewayID,
    zigbeeConnected,
    lightObj,
    sensorObj,
    timeStamp,
  } = daliCtlObj || {};
  const handleDragStart = () => {
    setShadEnabled(true);
  };
  const handleDragEnd = async (e) => {
    const unScaleX = e.target.x() / mapScale.x;
    const unScaleY = e.target.y() / mapScale.y;
    setShadEnabled(false);
    const newX = unScaleX > 1000 ? 970 : unScaleX < 0 ? 30 : unScaleX;
    const newY = unScaleY > 600 ? 570 : unScaleY < 0 ? 30 : unScaleY;
    await updateDevicePos(
      socket,
      activeMapID,
      "daliCtl",
      daliCtlID,
      newX,
      newY
    );
  };

  let strokeColor = selected ? daliCtlColorDark : "";
  const gatewayConnected = gatewayObjAll?.[gatewayID]?.gatewayConnected;
  const gatewayPos = activeMapObj?.gatewayObj[gatewayID];
  const iconBackground = activeMapObj?.iconBackground || "";
  let alertText = "";
  if (!gatewayID) {
    alertText += "Not connected to any gateway!\n";
  } else {
    if (Object.keys(activeMapObj?.gatewayObj).includes(gatewayID)) {
      if (!gatewayConnected) {
        alertText += "gateway disconnected!\n";
      }
    } else {
      alertText += "gateway not in this map!\n";
    }
  }
  const connected = deviceConnected(
    zigbeeConnected,
    timeStamp,
    gatewayConnected
  );
  function handleClickDevice(e) {
    // if (!disableClick) {
    e.evt.preventDefault();
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
    if (e.evt.shiftKey) {
      if (!shiftSelected) {
        dispatch({
          type: "SHIFT_SELECTED_DEVICE",
          payload: {
            deviceID: daliCtlID,
            deviceType: "daliCtl",
            deviceObj: daliCtlObj,
          },
        });
      } else {
        dispatch({
          type: "SHIFT_UNSELECTED_DEVICE",
          payload: {
            deviceID: daliCtlID,
          },
        });
      }
    } else {
      dispatch({
        type: "DEVICE_SELECTED",
        payload: {
          deviceID: daliCtlID,
          deviceType: "daliCtl",
          deviceObj: daliCtlObj,
          mapRightMode: "daliCtl",
          x,
          y,
          layerScale,
        },
      });
    }
    konvaShrinkAndBack(deviceRef, 0.8, 0.15);
  }
  return (
    <>
      <Group
        x={x}
        y={y}
        draggable={!enablePan}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onMouseEnter={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = enablePan ? "pointer" : "move";
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = enablePan ? "grab" : "default";
        }}
        ref={deviceRef}
      >
        {
          toRender &&
          <>
            <ShiftSelectCircle
              shiftSelected={shiftSelected}
              iconSize={iconSize}
              scale={scale}
            />
            <Group>
              <Rect
                width={iconSize * mapScale.x}
                height={iconSize * mapScale.y}
                stroke={selected ? gatewayColorDark : ""}
                strokeWidth={2 / scale || 2}
                cornerRadius={iconSize * 0.1}
                fill={iconBackground}
              />

              <DaliCtl
                size={
                  shadEnabled ? iconSize * mapScale.x * 1.2 : iconSize * mapScale.x
                }
                connectStatus={connected}
              />
              <Rect
                width={iconSize * mapScale.x}
                height={iconSize * mapScale.y}
                onClick={handleClickDevice}
                onTap={handleClickDevice}
                onMouseOver={() => {
                  const layer = layerRef.current;
                  const layerScale = layer.scaleX();
                  setScale(layerScale);
                  setShowID(true);
                }}
                onMouseLeave={() => setShowID(false)}
              />
            </Group>

            <DivExist show={showID && alertText}>
              <KonvaLabel
                x={iconSize * mapScale.x * 2.5}
                y={iconSize * mapScale.y * -0.8}
                text={alertText}
                textColor="red"
                fontSize={12}
                tagColor="transparent"
                scale={scale}
              />
            </DivExist>
            <DivExist show={showID}>
              <KonvaLabel
                x={0}
                y={iconSize * mapScale.y}
                text={description}
                fontSize={12}
                tagColor={gatewayColorDark}
                scale={scale}
              />
            </DivExist>

          </>
        }
      </Group>
    </>
  );
}
