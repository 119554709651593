import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import DivExist from "components/DivExist";
import Divider10 from "components/Divider10";
import {
  Typography,
  Box,
  Slider,
} from "@mui/material";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
import { FastForward, CopyAll, SwipeRightAlt } from "@mui/icons-material";
import LightBulb from "asset/svgComp/LightBulb";
import LightIcon from "components/LightIcon";
import {
  sensorConnectLight,
  sensorDisconnectLight,
  sensorRemoveDeviceSetting,
  updateSensorProperty,
} from "actions/sensorActions";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import {
  confirmWindow,
  hideBigProgress,
  showBigProgress,
} from "actions/screenActions";
import IconButtonDone from "components/IconButtonDone";
import Lightswitch from "asset/svgComp/Lightswitch";
import SensorIcon from "components/SensorIcon";
import General from "@ecoenghk/general";
import IconButtonAdd from "components/IconButtonAdd";
import SenLightSettingCheckModal from "./SenLightSettingCheckModal";

const gs = new General();
let pwmMarks = [];
for (let i = 20; i <= 100; i += 20) {
  const obj = { value: i, label: `${i}` };
  pwmMarks = [...pwmMarks, obj];
}
export default function SenLightSwLightModal({
  x,
  y,
  modalLocation,
  open,
  handleClose,
  layerProps,
  selectedLight,
  sensorID,
  sensorObj,
  scale,
}) {
  const [settingObj, setSettingObj] = useState({});
  useEffect(() => {
    let obj = {};
    for (let i = 1; i <= 6; i++) {
      const s = sensorObj?.[`setting${i}`] || {};
      if (s) obj[`setting${i}`] = s;
    }
    setSettingObj(obj);
  }, [open, selectedLight, sensorObj]);
  const { iconSize, lightObjAll, dispatch, daliCtlObjAll, socket } = layerProps;
  const lightObj = lightObjAll[selectedLight];

  const updateSetting = async (settingNum, updateObj) => {
    let serialSetting = settingObj[`setting${settingNum}`]?.[selectedLight];
    serialSetting = { ...serialSetting, ...updateObj };
    const newSettingObj = { ...settingObj };
    newSettingObj[`setting${settingNum}`][selectedLight] = serialSetting;
    setSettingObj(newSettingObj);
  };
  const handleSave = async () => {
    let updateObj = {};
    Object.keys(settingObj || {}).forEach((settingN) => {
      if (settingObj[settingN] && !gs.isEmptyJson(settingObj[settingN]))
        updateObj = { ...updateObj, [settingN]: settingObj[settingN] };
    });
    console.log(updateObj);
    const did = sensorObj.daliCtlID;
    const gid = did ? daliCtlObjAll[did]?.gatewayID : sensorObj.gatewayID;
    await updateSensorProperty(sensorID, sensorObj, gid, updateObj);
    handleClose();
  };
  const handleDisconnect = async () => {
    confirmWindow(
      dispatch,
      `Confirm disconnect light [${selectedLight}]?`,
      async () => {
        showBigProgress(dispatch);
        const did = sensorObj.daliCtlID;
        const gid = did ? daliCtlObjAll[did]?.gatewayID : sensorObj.gatewayID;
        await sensorDisconnectLight(sensorObj, gid, lightObj);
        hideBigProgress(dispatch);
        handleClose();
      }
    );
  };
  const modalWidth = window.innerWidth * 0.32;
  const modalHeight = window.innerHeight * 0.43;
  const modalX = modalLocation.includes("right")
    ? x - modalWidth / scale - 2 * iconSize
    : x;
  const modalY = modalLocation.includes("bottom")
    ? y - modalHeight / scale - iconSize
    : y;
  return (
    <MapModal
      x={modalX}
      y={modalY}
      open={open}
      handleCloseModal={handleClose}
      disableCloseButton
      width={modalWidth}
      height={modalHeight}
      layerProps={layerProps}
    >
      <div style={{ padding: "0.5vw" }}>
        <DivInline>
          <IconButtonClose onBtnClick={handleClose} />
          <SensorIcon
            gang={sensorObj?.gang}
            type="lightswitch"
            disabledConnectStatus
            status={0}
            status2={0}
            status3={0}
            status4={0}
            width={3}
            sizeUnit="vw"
          />
          <SwipeRightAlt sx={{ fontSize: "2rem" }} />
          <LightIcon
            lightStyle={lightObj?.style}
            width={3}
            sizeUnit="vw"
            disabledConnectStatus
            disabledStatus
          />
          <DivCol>
            <Typography variant="caption">{lightObj?.description}</Typography>
            <Typography variant="caption">{`[${selectedLight}]`}</Typography>
          </DivCol>
        </DivInline>
        <Divider10 />
        {gs.newArrayBetween(1, sensorObj?.gang || 3).map((i) => (
          <ButtonGroup
            key={i}
            swNo={i}
            updateSetting={updateSetting}
            settingA={sensorObj?.[`setting${i * 2 - 1}`]}
            settingB={sensorObj?.[`setting${i * 2}`]}
            sensorObj={sensorObj}
            lightObj={lightObj}
            layerProps={layerProps}
          />
        ))}

        <IconButtonClose
          tooltip="Close dialog without saving"
          onBtnClick={handleClose}
        />
        <IconButtonDone
          tooltip="Save settings and close dialog"
          onBtnClick={handleSave}
        />
        <IconButtonDelete
          tooltip="Disconnect light from sensor"
          onBtnClick={handleDisconnect}
        />
        {/* <IconButton onClick={handleCopyLightSetting} size="small">
          <Tooltip title="Copy setting to other lights">
            <CopyAll />
          </Tooltip>
        </IconButton> */}
      </div>
    </MapModal>
  );
}
function ButtonGroup({
  swNo,
  updateSetting,
  settingA,
  settingB,
  sensorObj,
  lightObj,
  layerProps,
}) {
  const { dispatch } = layerProps;
  const serial = lightObj?.serial;
  const settingNumA = swNo * 2 - 1;
  const settingNumB = swNo * 2;
  const serialSettingA = settingA?.[serial];
  const serialSettingB = settingB?.[serial];
  return (
    <div>
      <DivInline hide={sensorObj?.gang === 1}>
        <SenLightSettingCheckModal sensorObj={sensorObj} lightObj={lightObj} settingNumArray={[settingNumA, settingNumB]} layerProps={layerProps}>
          <Typography display="block" align="center">
            Switch {swNo}
          </Typography>
        </SenLightSettingCheckModal>
        {serialSettingA ? (
          <IconButtonDelete
            tooltip={`Remove from switch ${swNo}`}
            onBtnClick={async () => {
              confirmWindow(
                dispatch,
                `Remove from Switch ${swNo}?`,
                async () => {
                  await sensorRemoveDeviceSetting(sensorObj, lightObj, [settingNumA, settingNumB]);
                }
              );
            }}
          />
        ) : (
          <IconButtonAdd
            tooltip={`Connect to Switch ${swNo}`}
            onBtnClick={async () => {
              confirmWindow(
                dispatch,
                `Connect to Switch ${swNo}?`,
                async () => await sensorConnectLight(sensorObj, lightObj, swNo)
              );
            }}
          />
        )}
      </DivInline>
      <Divider10 />
      {serialSettingA ? (
        <>
          <ControlRow
            gang={sensorObj?.gang}
            settingNum={swNo * 2 - 1}
            serialSetting={serialSettingA}
            enablePwm={lightObj?.type?.pwm}
            updateSetting={updateSetting}
          />
          <ControlRow
            gang={sensorObj?.gang}
            settingNum={swNo * 2}
            serialSetting={serialSettingB}
            enablePwm={lightObj?.type?.pwm}
            updateSetting={updateSetting}
          />
        </>
      ) : null}
      <Divider10 />
    </div>
  );
}
function ControlRow({
  gang,
  // status,
  settingNum,
  enablePwm,
  updateSetting,
  serialSetting,
}) {
  const status = settingNum % 2 === 0 ? 1 : 0;
  return (
    <Box sx={{ width: "28vw" }}>
      <DivInline justifyContent="space-between">
        <Lightswitch
          status={status}
          status2={status}
          status3={status}
          status4={status}
          gang={gang}
          width={4.5}
          sizeUnit="vw"
          disabledConnectStatus
          disabledStatus={settingNum !== 1 && settingNum !== 2}
          disabledStatus2={settingNum !== 3 && settingNum !== 4}
          disabledStatus3={settingNum !== 5 && settingNum !== 6}
          disabledStatus4={settingNum !== 7 && settingNum !== 8}

        />

        <FastForward style={{ fontSize: "1.5em" }} />
        <Box
          sx={{
            outline: serialSetting?.pwm > 0 ? "1px solid red" : "",
            cursor: "pointer",
          }}
          onClick={() => {
            let updateObj = { onoffcontrolA: 0 };
            if (serialSetting?.pwm === 0 || !enablePwm)
              updateObj = { ...updateObj, pwm: 100 };
            updateSetting(settingNum, updateObj);
          }}
        >
          <LightBulb onoff="on" width={3} sizeUnit="vw" />
        </Box>
        <Box
          sx={{
            outline: serialSetting?.pwm === 0 ? "1px solid red" : "",
            cursor: "pointer",
          }}
          onClick={() => {
            let updateObj = { onoffcontrolA: 1, pwm: 0 };
            updateSetting(settingNum, updateObj);
          }}
        >
          <LightBulb onoff="off" width={3} sizeUnit="vw" />
        </Box>

        <DivExist show={enablePwm}>
          <Slider
            sx={{ width: window.innerWidth * 0.1 }}
            size="small"
            value={serialSetting?.pwm || 0}
            onChangeCommitted={(e, val) => {
              let updateObj = { pwm: Number(val) };
              updateObj.onoffcontrolA = Number(val) > 0 ? 0 : 1;
              updateSetting(settingNum, updateObj);
            }}
            marks={pwmMarks}
            max={100}
            min={0}
            step={10}
          />
        </DivExist>
      </DivInline>
    </Box>
  );
}
