import { useState, useContext } from "react";
import { SystemUpdate } from "@mui/icons-material";
import { Typography, Tooltip, IconButton } from "@mui/material";
import { SMContext } from "context/smContext";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import { useNavigate } from "react-router-dom";
import ModalSM from "components/ModalSM";
import { fetchAllMap, fetchOneMap, importMap } from "actions/mapActions";
import IconButtonDone from "components/IconButtonDone";
import { mgFindOne } from "actions/mongoApiActions";
import DivCol from "components/DivCol";
import { alertWindow } from "actions/screenActions";
import IconButtonBack from "components/IconButtonBack";
import IconButtonDelete from "components/IconButtonDelete";

export default function ImportMapModal() {
  const [state, dispatch] = useContext(SMContext);
  const navigate = useNavigate();
  const { mapObjAll, userObj, socket } = state;

  const [open, setOpen] = useState(false);
  const [mapID, setMapID] = useState("");
  const [mapObj, setMapObj] = useState({});
  const [showListCount, setShowListCount] = useState(0);
  const [mgMapObjAll, setMgMapObjAll] = useState({});
  const handleOpen = async () => {
    setOpen(true);
    setShowListCount(0);
    const mapAll = await fetchAllMap();
    setMgMapObjAll(mapAll);
    setMapObj({});
  };
  const checkMapID = async () => {
    const obj = await mgFindOne("map", "mapID", mapID);
    if (obj.result === "fail") {
      alertWindow(dispatch, `Map ID [${mapID}] not exists in database`);
    } else {
      setMapObj(obj);
    }
  };
  const handleImport = async () => {
    if (Object.keys(mapObjAll || {}).includes(mapID)) {
      alertWindow(
        dispatch,
        `Map ID [${mapID}] already exists in your map list`
      );
      return;
    }
    await importMap(socket, userObj, mapID, mapObj);
    await fetchOneMap(dispatch, mapID);
    dispatch({
      type: "SET_ACTIVE_MAP",
      payload: mapID,
    });
    dispatch({ type: "SET_ENABLE_PAN", payload: true });
    dispatch({ type: "SET_ENABLE_ZOOM", payload: "" });
    navigate("/MapPage");
  };

  return (
    <>
      <IconButton onClick={handleOpen} size="large">
        <Tooltip placement="top" title={<Typography>Import map</Typography>}>
          <SystemUpdate style={{ fontSize: "1.2em" }} />
        </Tooltip>
      </IconButton>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        minWidth="70vw"
        minHeight="70vh"
        onSave={handleImport}
        disabledSave={mapObj?.mapID ? false : true}
      >
        <DivInline>
          <IconButtonBack onBtnClick={() => setOpen(false)} />
          <SystemUpdate onClick={() => setShowListCount((n) => n + 1)} />
          <Typography>Import Map</Typography>
        </DivInline>
        <Divider10 />
        <SpaceBetweenInput
          title="Import map ID"
          data={mapID}
          width="40%"
          autoSelect
          onInput={(evt) => {
            setMapID(evt.target.value);
          }}
        />
        <DivInline justifyContent="flex-end">
          <IconButtonDone onBtnClick={checkMapID} />
        </DivInline>

        <Divider10 />
        {mapObj?.mapID && (
          <DivCol alignItems="center">
            <img
              src={mapObj?.mapUrl}
              style={{ width: "20vw", height: "16vh" }}
              alt=""
            />
            <Typography>{mapObj?.mapName}</Typography>
            <Typography variant="caption">{mapObj?.mapID}</Typography>
            <IconButtonDelete onBtnClick={() => setMapObj({})} />
          </DivCol>
        )}
        <DivExist
          show={showListCount > 5 && Object.keys(mapObj || {}).length === 0}
        >
          <Typography>Available map list</Typography>
          {Object.keys(mgMapObjAll)
            .filter((mapID) => {
              return !Object.keys(mapObjAll || {}).includes(mapID);
            })
            .map((mapID) => {
              const mapObj = mgMapObjAll[mapID];
              return (
                <DivInline
                  onClick={() => setMapID(mapID)}
                  key={mapID}
                  sx={{ outline: "0.5px solid #fff", cursor: "pointer" }}
                >
                  <Typography sx={{ width: "20vw" }}>
                    {mapObj?.mapName}
                  </Typography>
                  <Typography variant="caption">{`  [${mapObj?.mapID}]`}</Typography>
                </DivInline>
              );
            })}
        </DivExist>
      </ModalSM>
    </>
  );
}
