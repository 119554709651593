import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button } from "@mui/material";
import { titleText, dataText } from "asset/string/color";
import DivExist from "components/DivExist";
import DaliCtl from "asset/svgComp/DaliCtl";
import DivCol from "components/DivCol";
import {
  daliBroadcastLight,
} from "actions/daliCtlActions";
import moment from "moment";
import General from "@ecoenghk/general";
import { mapAddDaliCtl, mapRemoveDaliCtl } from "actions/mapActions";
import { mgFindOne } from "actions/mongoApiActions";
import { getDocFS } from "actions/firebaseMgActions";
import { alertWindow } from "actions/screenActions";
import DivInline from "components/DivInline";
import DeviceMongoModal from "components/DeviceMongoModal";
const gs = new General();
export default function SelectedDaliCtlInfo() {
  const [state, dispatch] = useContext(SMContext);
  const {
    selectedID,
    gatewayObjAll,
    daliCtlObjAll,
    serverObj,
    mapObjAll,
    activeMapID,
  } = state;
  const daliCtlObj = daliCtlObjAll[selectedID] || {};
  const {
    daliCtlID,
    timeStamp,
    idAddMap,
    serialMap,
    sensorMap,
    zigbeeConnected,
    description,
    serverID,
    gatewayID,
    dtkAdd,
    daliType,
  } = daliCtlObj || {};
  const lightConnected = Object.keys(serialMap || {}).length;
  const sensorConnected = Object.keys(sensorMap || {}).length;
  const descriptionLen = description?.length || 0;
  const unknownDevice =
    Object.keys(daliCtlObj || {}).length === 0 ? true : false;
  const handleAddToMap = async () => {
    let daliCtlObj = await mgFindOne("daliCtl", "daliCtlID", selectedID);
    if (!daliCtlObj && serverObj.enableUploadFS) {
      daliCtlObj = await getDocFS(`SM_daliCtl/${selectedID}`);
    }
    if (!daliCtlObj) {
      alertWindow(
        dispatch,
        `Dali controller ${selectedID} not found in local db and fs`
      );
      return;
    }
    const { x, y } = mapObjAll[activeMapID]?.daliCtlObj || {};
    await mapAddDaliCtl(activeMapID, selectedID, daliCtlObj, x, y);
  };
  return (
    <>
      <DivInline justifyContent="center">
        <DeviceMongoModal deviceObj={daliCtlObj} >
          <DaliCtl width={6} sizeUnit="vw" connectStatus={zigbeeConnected} />
        </DeviceMongoModal>
      </DivInline>
      <DataSet title="Dali controller ID" data={selectedID} />
      <DivExist show={!unknownDevice}>
        <Typography
          style={{ color: dataText }}
          variant={descriptionLen > 15 ? "caption" : "body1"}
        >
          {description}
        </Typography>
        <InfoDivider />
        <DataSet title="Server ID:" data={serverID} hide={!serverID} />
        <Typography style={{ color: titleText }} variant="caption">
          Connected to gateway:
        </Typography>
        {gatewayID ? (
          <DivCol>
            <Typography variant="caption" style={{ color: dataText }}>
              {gatewayID}
            </Typography>
            <Typography variant="body2" style={{ color: dataText }}>
              {gatewayObjAll[gatewayID]?.description}
            </Typography>
          </DivCol>
        ) : (
          <Typography
            variant="caption"
            align="center"
            color="error"
            style={{ color: dataText }}
          >
            Not connected to any gateway
          </Typography>
        )}
        <InfoDivider />
        <DataSet title="Server ID" data={serverID} />
        {
          daliCtlObj.dtkChannel > 0 ?
            <DataSet title="Dtk channel" data={daliCtlObj.dtkChannel} />
            :
            <DataSet title="Mesh channel" data={daliCtlObj.meshChannel} />
        }
        <DataSet title="Address" data={dtkAdd || ""} />
        <DataSet
          title="Latest alive time:"
          data={moment(timeStamp).format("YYYY-MM-DD,HH:mm:ss")}
        />
        <DataSet title="Version" data={daliCtlObj.deviceVersion} />
      </DivExist>
      <DivExist show={unknownDevice}>
        <Typography sx={{ color: dataText }} align="center">
          Unknown dali controller
        </Typography>
        <InfoDivider />
        <Button
          sx={{ margin: "0.5vh" }}
          size="small"
          variant="outlined"
          onClick={handleAddToMap}
        >
          Add to map
        </Button>
        <InfoDivider />
        <Button
          sx={{ margin: "0.5vh" }}
          size="small"
          variant="outlined"
          onClick={async () => mapRemoveDaliCtl(activeMapID, selectedID)}
        >
          Remove from map
        </Button>
      </DivExist>
      <Button
        sx={{ margin: "0.5vh" }}
        size="small"
        variant="outlined"
        onClick={async () => {
          await daliBroadcastLight(daliCtlObj, 0);
        }}
      >
        Broadcast OFF
      </Button>
      {[20, 40, 60, 80, 100].map((lv) => (
        <Button
          key={lv}
          sx={{ margin: "0.5vh" }}
          size="small"
          variant="outlined"
          onClick={async () => {
            let arc = ((254 - 80) * lv) / 100 + 80;
            if (arc >= 254) arc = 253;
            await daliBroadcastLight(daliCtlObj, arc);
          }}
        >
          Broadcast {lv}%
        </Button>
      ))}

      <InfoDivider />
    </>
  );
}

function DataSet({ title, data, hide }) {
  if (hide) return null;
  return (
    <>
      <Typography variant="caption" style={{ color: titleText }}>
        {title}
      </Typography>
      <Typography style={{ color: dataText }} variant="caption">
        {data}
      </Typography>
      <hr color="lightgrey" size={1} width="90%" />
    </>
  );
}
function InfoDivider() {
  return <hr color="lightgrey" size={1} width="90%" />;
}
