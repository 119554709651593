import {
  mgFindOne,
  mgUpdateOneUpsert,
  mgFindAll,
  mgFindQuery,
  mgFindByMatchAndRange,
} from "./mongoApiActions";
import { getDocsWhereFS } from "./firebaseMgActions";
import General from "@ecoenghk/general";
const gs = new General();
export const fetchOneLight = async (dispatch, serial) => {
  const lightObj = await mgFindOne("serial", "serial", serial);
  dispatch({
    type: "UPDATE_LIGHT_OBJ",
    payload: lightObj,
  });
};
export const fetchAllLights = async () => {
  const data = await mgFindAll("serial");
  console.log(data);
  let objAll = {};
  data.forEach((obj) => {
    const s = obj.serial;
    objAll[s] = obj;
  });
  return objAll;
};
export const updateSerialProperty = async (serial, lightObj, updateObj) => {
  const jsonData = {
    serial,
    gatewayID: lightObj.gatewayID,
    updateObj,
    lightObj,
    updateSetting: true,
    type: "updateSerialProperty",
  };
  console.log(
    `[COMMAND OUT] updateSerialProperty ${serial} ${JSON.stringify(updateObj)}`
  );
  global.socket.emit("fromWeb", jsonData);
};
export const requestLightCurrentData = async (serial, lightObj) => {
  let payload = {
    type: "control_single_light",
    gatewayID: lightObj.gatewayID,
    serial,
    dtkAdd: lightObj.dtkAdd,
    commandCode: 0x0201
  };
  console.log(`[COMMAND OUT] requestCurrentData ${serial}`);
  global.socket.emit("fromWeb", payload);
};
export const setPower = async (serial, lightObj, commandL) => {
  let payload = {
    type: "control_single_light",
    gatewayID: lightObj.gatewayID,
    serial,
    onoffcontrolL: commandL,
    // lockLevel: lockLevel || 100,
    dtkAdd: lightObj.dtkAdd,
    commandCode: 0x0206,
    ver: 1
  };
  console.log(`[COMMAND OUT] setPower ${serial} ${commandL}`);
  global.socket.emit("fromWeb", payload);
};
export const switchLight = async (
  serial,
  lightObj,
  commandA = 0,
  lightLv = 100,
  lockLevel,
  gatewayObj
) => {
  let payload = {
    type: "control_single_light",
    gatewayID: lightObj.gatewayID,
    serial,
    onoffcontrolA: commandA,
    pwm: lightLv ?? 0,
    lockLevel: lockLevel ?? 5,
    dtkAdd: lightObj.dtkAdd,
    commandCode: 0x0202,
    releaseOnDelayEnd: commandA === 0 ? 0 : 1,
    ver: 1
  };
  console.log(`[COMMAND OUT] switchLight ${serial} commandA:${commandA} lightLv:${lightLv}`);
  global.socket.emit("fromWeb", payload);
};
export const switchLightDali = async (
  serial,
  command,
  pwm,
  shortAdd,
  daliCtlObj
) => {
  const cmd = {
    type: "control_dali_ctl",
    serial,
    daliCtlID: daliCtlObj.daliCtlID,
    shortAdd,
    onoffcontrolA: command,
    pwm: pwm ?? 100,
    lockLevel: command === 1 ? 0 : 1,
    gatewayID: daliCtlObj.gatewayID,
    serverID: daliCtlObj.serverID,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a09
  };
  console.log(`[COMMAND OUT] switchLightDali ${serial} shortAdd:${shortAdd} command:${command} pwm:${pwm} lockLevel:${cmd.lockLevel}`);
  global.socket.emit("fromWeb", cmd);

};
export const doManualTest = async (
  serial,
  lightObj,
  time_pretest,
  time_cutOff,
  time_recharge,
  time_interval
) => {
  const ver = Number(lightObj.deviceVersion);
  let payload = {
    type: "manualTest_single",
    gatewayID: lightObj.gatewayID,
    serial,
    reportType: "check_manual",
    time_pretest,
    time_cutOff,
    time_recharge,
    time_interval,
    ver: ver >= 4.35 ? 1 : 0,
  };
  console.log(`[COMMAND OUT] doManualTest ${serial} reportType:check_manual`);
  global.socket.emit("fromWeb", payload);
};
export const terminateTest_A7 = async (serial, lightObj) => {
  //A7
  let payload = {
    type: "control_single_light",
    gatewayID: lightObj.gatewayID,
    serial,
    dtkAdd: lightObj.dtkAdd,
    commandCode: 0x020a,
  };
  console.log(`[COMMAND OUT] terminate test ${serial}`);
  global.socket.emit("fromWeb", payload);
};
export const restartLight = async (serial, lightObj) => {
  let payload = {
    type: "control_single_light",
    gatewayID: lightObj.gatewayID,
    serial,
    dtkAdd: lightObj.dtkAdd,
    commandCode: 0x021a,
  };
  console.log(`[COMMAND OUT] restart light ${serial}`);
  global.socket.emit("fromWeb", payload);
};
export const checkLightConnection = async (dispatch, serial, lightObj) => {
  dispatch({
    type: "UPDATED_LIGHT_PROPERTY",
    payload: {
      serial,
      updateObj: { zigbeeConnected: false },
    },
  });
  await gs.waitFor(800);
  await requestLightCurrentData(serial, lightObj);
};
export const updateLightsFsToLs = async (socket) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "serial",
  };
  await socket.emit("fromWeb", payload);
};
export const updateSingleLightFsToLs = async (socket, serial) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "singleSerial",
    serial,
  };
  await socket.emit("fromWeb", payload);
};
export const fetchOneReport = async (reportID) => {
  const data = await mgFindOne("report", "reportID", reportID);
  return data;
};
export const queryOneLightSetting = async (lightObj, commandCode, queryPayload) => {
  let payload = {
    type: "control_single_light",
    gatewayID: lightObj.gatewayID,
    serial: lightObj.serial,
    commandCode,
    dtkAdd: lightObj.dtkAdd,
    ...(queryPayload || {})
  };
  console.log(`[COMMAND OUT] queryOneLightSetting ${lightObj.serial} ${commandCode.toString(16)} - ${JSON.stringify(queryPayload)}`);
  global.socket.emit("fromWeb", payload);
};
export const changeOneLightSetting = async (
  lightObj,
  commandCode,
  valueObj
) => {
  const { serial, gatewayID, dtkAdd } = lightObj || {};
  let payload = {
    type: "control_single_light",
    gatewayID,
    serial,
    dtkAdd,
    commandCode,
    ...valueObj
  };
  console.log(`[COMMAND OUT] changeOneLightSetting ${serial} commandCode:0x${commandCode.toString(16)} valueObj:${JSON.stringify(valueObj)}`);
  global.socket.emit("fromWeb", payload);
};
export const checkLightUnderGateway = async (lightObj) => {
  const { serial } = lightObj;
  const gatewayArrWithLight = [];
  const gatewayArrWithLightFS = [];
  const gatewayObjArr = await mgFindAll("gateway");
  const gatewayObjArrFs = await getDocsWhereFS("SM_gateway", "array");
  console.log(gatewayObjArr);
  gatewayObjArr.forEach((gatewayObj) => {
    const { serialMap } = gatewayObj || {};
    if (Object.values(serialMap || {}).includes(serial)) {
      gatewayArrWithLight.push(gatewayObj.gatewayID);
    }
  });
  gatewayObjArrFs.forEach((gatewayObj) => {
    const { serialMap } = gatewayObj || {};
    if (Object.values(serialMap || {}).includes(serial)) {
      gatewayArrWithLightFS.push(gatewayObj.gatewayID);
    }
  });
  return { mongo: gatewayArrWithLight, firebase: gatewayArrWithLightFS };
};
export const lightDeletePermanently = async (lightObj) => {
  const payload = {
    type: "lightDeletePermanently",
    lightObj,
  };
  console.log(`[COMMAND OUT] deleteLightPermanently ${lightObj.serial}`);
  global.socket.emit("fromWeb", payload);
};
export const lightCheckLatestFirmware = async (lightObj) => {
  const apiName = "getDevice32FirmwareVer";
  const url = `${global.ip}/api/${apiName}?program=SM_light_DTK`;
  try {
    const res = await fetch(url);
    const response = await res.json();
    const latestVer = response.result;
    console.log(`[API OUT] lightCheckLatestFirmware [${url}] ${lightObj.serial} latestVer:${latestVer}`);
    return latestVer;
  } catch (e) {
    return null;
  }
};
export const lightQueryDtkInfo = async (lightObj) => {
  const payload = {
    type: "control_single_light",
    commandCode: 0x0380,
    gatewayID: lightObj.gatewayID,
    serial: lightObj.serial,
    dtkAdd: lightObj.dtkAdd,
  };
  console.log(
    `[COMMAND OUT] lightQueryDtkInfo ${lightObj.serial} [${lightObj.dtkAdd}]`,
    payload
  );
  global.socket.emit("fromWeb", payload);
};
// export const lightQueryDtkType = async (lightObj) => {
//   const payload = {
//     type: "control_single_light",
//     commandCode: 0x0381,
//     gatewayID: lightObj.gatewayID,
//     serial: lightObj.serial,
//     dtkAdd: lightObj.dtkAdd,
//   };
//   console.log(
//     `[COMMAND OUT] lightQueryDtkType ${lightObj.serial} [${lightObj.dtkAdd}]`
//   );
//   global.socket.emit("fromWeb", payload);
// };
// export const lightQueryDtkPanID = async (lightObj) => {
//   const payload = {
//     type: "control_single_light",
//     commandCode: 0x0382,
//     gatewayID: lightObj.gatewayID,
//     serial: lightObj.serial,
//     dtkAdd: lightObj.dtkAdd,
//   };
//   console.log(
//     `[COMMAND OUT] lightQueryDtkPanID ${lightObj.serial} [${lightObj.dtkAdd}]`
//   );
//   global.socket.emit("fromWeb", payload);
// };
// export const lightQueryDtkChannel = async (lightObj) => {
//   const payload = {
//     type: "control_single_light",
//     commandCode: 0x0383,
//     gatewayID: lightObj.gatewayID,
//     serial: lightObj.serial,
//     dtkAdd: lightObj.dtkAdd,
//   };
//   console.log(
//     `[COMMAND OUT] lightQueryDtkChannel ${lightObj.serial} [${lightObj.dtkAdd}]`
//   );
//   global.socket.emit("fromWeb", payload);
// };
export const lightChangeDtkInfo = async (lightObj, dtkInfo) => {
  const payload = {
    type: "control_single_light",
    commandCode: 0x0280,
    gatewayID: lightObj.gatewayID,
    serial: lightObj.serial,
    dtkAdd: lightObj.dtkAdd,
    dtkType: dtkInfo.dtkType,
    dtkPanID: dtkInfo.dtkPanID,
    dtkChannel: dtkInfo.dtkChannel,
    dtkTransferMode: dtkInfo.dtkTransferMode,
    definedAdd: dtkInfo.definedAdd,
    loraParameter: dtkInfo.loraParameter,
  };
  console.log(
    `[COMMAND OUT] lightChangeDtkInfo ${lightObj.serial} [${lightObj.dtkAdd
    }] ${JSON.stringify(payload)}`
  );
  global.socket.emit("fromWeb", payload);
};
export const lightChangeDtkType = async (lightObj, dtkType) => {
  const payload = {
    type: "control_single_light",
    commandCode: 0x0281,
    gatewayID: lightObj.gatewayID,
    serial: lightObj.serial,
    dtkAdd: lightObj.dtkAdd,
    dtkType,
  };
  console.log(
    `[COMMAND OUT] lightChangeDtkType ${lightObj.serial} [${lightObj.dtkAdd}] dtkType to ${dtkType}`
  );
  global.socket.emit("fromWeb", payload);
};
export const lightChangeDtkPanID = async (lightObj, dtkPanID) => {
  const payload = {
    type: "control_single_light",
    commandCode: 0x0282,
    gatewayID: lightObj.gatewayID,
    serial: lightObj.serial,
    dtkAdd: lightObj.dtkAdd,
    dtkPanID,
  };
  console.log(
    `[COMMAND OUT] lightChangeDtkPanID ${lightObj.serial} [${lightObj.dtkAdd}] dtkPanID to ${dtkPanID}`
  );
  global.socket.emit("fromWeb", payload);
};
export const lightChangeDtkChannel = async (lightObj, hexStrChannel) => {
  const dtkChannel = parseInt(hexStrChannel, 16);
  const payload = {
    type: "control_single_light",
    commandCode: 0x0283,
    gatewayID: lightObj.gatewayID,
    serial: lightObj.serial,
    dtkAdd: lightObj.dtkAdd,
    dtkChannel,
  };
  console.log(
    `[COMMAND OUT] lightChangeDtkChannel ${lightObj.serial} [${lightObj.dtkAdd}] new channel 0x${hexStrChannel}`
  );
  global.socket.emit("fromWeb", payload);
};
export const lightDeleteDtk = async (lightObj) => {
  const payload = {
    type: "lightDeleteDtk",
    serial: lightObj.serial,
  };
  console.log(
    `[COMMAND OUT] lightDeleteDtk ${lightObj.serial} [${lightObj.dtkAdd}]`
  );
  global.socket.emit("fromWeb", payload);
};
export const lightSettingMap = {
  lockLevel: 2,
  enableA: 3,
  enableL: 4,
  enableBat: 5,
  enableDim: 6,
  dimTransition: 7,
  dimTransitON: 8,
  dimTransitOFF: 9,
  cutDimOnBypass: 0x0a,
  reverseA: 0x0b,
  freeRam: 0x0c,
  controlAPin: 0x0d,
  controlLPin: 0x0e,
  delayCountDown: 0x0f,
  inputBypassPin: 0x10,
  controlBPin: 0x11,
  controlDPin: 0x12,
};
export const airconSwitchOn = async (lightObj) => {
  let payload = {
    type: "control_single_light",
    gatewayID: lightObj.gatewayID,
    serial: lightObj.serial,
    dtkAdd: lightObj.dtkAdd,
    commandCode: 0x021e,
    acType: lightObj.acType,
  };
  console.log("airconSwitchOn", payload);
  global.socket.emit("fromWeb", payload);
};
export const airconSwitchOff = async (lightObj) => {
  let payload = {
    type: "control_single_light",
    gatewayID: lightObj.gatewayID,
    serial: lightObj.serial,
    dtkAdd: lightObj.dtkAdd,
    commandCode: 0x021f,
    acType: lightObj.acType,
  };
  console.log("airconSwitchOff", payload);
  global.socket.emit("fromWeb", payload);
};
export const airconSetTemp = async (lightObj, acTemp) => {
  let payload = {
    type: "control_single_light",
    gatewayID: lightObj.gatewayID,
    serial: lightObj.serial,
    dtkAdd: lightObj.dtkAdd,
    commandCode: 0x0221,
    acType: lightObj.acType,
    acTemp,
  };
  console.log("airconSetTemp", payload);
  // global.socket.emit("fromWeb", payload);
};
export const enablingAirconControl = async (lightObj, enable) => {
  let payload = {
    type: "control_single_light",
    gatewayID: lightObj.gatewayID,
    serial: lightObj.serial,
    dtkAdd: lightObj.dtkAdd,
    commandCode: 0x0222,
    enableAirconControl: enable,
  };
  console.log("enablingAirconControl", payload);
  global.socket.emit("fromWeb", payload);
};
export const lightUpdateFirmware = async (lightObj) => {
  let payload = {
    type: "control_single_light",
    gatewayID: lightObj.gatewayID,
    serial: lightObj.serial,
    dtkAdd: lightObj.dtkAdd,
    commandCode: 0x0224,
  };
  console.log(
    `[COMMAND OUT] lightUpdateFirmware ${lightObj.serial
    } commandCode 0x${payload.commandCode.toString(16)}`
  );
  global.socket.emit("fromWeb", payload);
};
// export const queryLightFirmwareVer = async (lightObj) => {
//   const payload = {
//     type: "control_single_light",
//     lightObj,
//     serial: lightObj.serial,
//     gatewayID: lightObj.gatewayID,
//     dtkAdd: lightObj.dtkAdd,
//     commandCode: 0x0314,
//   };
//   console.log(
//     `[COMMAND OUT] queryLightFirmwareVer ${lightObj.serial} commandCode 0x0314`
//   );
//   global.socket.emit("fromWeb", payload);
// };
export const lightQueryMacAddress = async (lightObj) => {
  const commandCode = 0x0315;
  const payload = {
    type: "control_single_light",
    lightObj,
    serial: lightObj.serial,
    gatewayID: lightObj.gatewayID,
    dtkAdd: lightObj.dtkAdd,
    commandCode,
  };
  console.log(
    `[COMMAND OUT] light ${lightObj.serial} Query MacAddress  commandCode ${commandCode}`
  );
  global.socket.emit("fromWeb", payload);
};
export const lightChangeOtaSsid = async (lightObj, otaSsid) => {
  const commandCode = 0x0225;
  const payload = {
    type: "control_single_light",
    lightObj,
    serial: lightObj.serial,
    gatewayID: lightObj.gatewayID,
    dtkAdd: lightObj.dtkAdd,
    commandCode,
    otaSsid,
  };
  console.log(
    `[COMMAND OUT] light ${lightObj.serial} change otaSsid 0x0225 to ${otaSsid}`
  );
  global.socket.emit("fromWeb", payload);
};
export const lightChangeOtaPassword = async (lightObj, otaPassword) => {
  const commandCode = 0x0226;
  const payload = {
    type: "control_single_light",
    lightObj,
    serial: lightObj.serial,
    gatewayID: lightObj.gatewayID,
    dtkAdd: lightObj.dtkAdd,
    commandCode,
    otaPassword,
  };
  console.log(
    `[COMMAND OUT] light ${lightObj.serial} change otaPassword 0x0226 to ${otaPassword}`
  );
  global.socket.emit("fromWeb", payload);
};
export const lightTestSettingReadWrite = async (lightObj) => {
  let payload = {
    type: "control_single_light",
    gatewayID: lightObj.gatewayID,
    serial: lightObj.serial,
    dtkAdd: lightObj.dtkAdd,
    commandCode: 0x0228,
  };
  console.log(
    `[COMMAND OUT] lightTestSettingReadWrite 0x0625 ${lightObj.serial}`
  );
  global.socket.emit("fromWeb", payload);
};
export const lightChangeDisableEspNow = async (lightObj, disableEspNow) => {
  const commandCode = 0x0229;
  const payload = {
    type: "control_single_light",
    lightObj,
    serial: lightObj.serial,
    gatewayID: lightObj.gatewayID,
    dtkAdd: lightObj.dtkAdd,
    commandCode,
    disableEspNow,
  };
  console.log(
    `[COMMAND OUT] light ${lightObj.serial} change disableEspNow 0x0229 to ${disableEspNow}`
  );
  global.socket.emit("fromWeb", payload);
};
export const lightChangeEnableEspOut = async (lightObj, enableEspOut) => {
  const commandCode = 0x022d;
  const payload = {
    type: "control_single_light",
    lightObj,
    serial: lightObj.serial,
    gatewayID: lightObj.gatewayID,
    dtkAdd: lightObj.dtkAdd,
    commandCode,
    enableEspOut,
  };
  console.log(
    `[COMMAND OUT] light ${lightObj.serial} change enableEspOut 0x022d to ${enableEspOut}`
  );
  global.socket.emit("fromWeb", payload);
};
export const lightConvertToDaliCtl = async (lightObj) => {
  const payload = {
    type: "control_single_light",
    commandCode: 0x0231,
    gatewayID: lightObj.gatewayID,
    serial: lightObj.serial,
    dtkAdd: lightObj.dtkAdd,
    enableDali: 1
  };
  console.log(`[COMMAND OUT] [0231] light ${lightObj.serial} Convert To DaliCtl (changeEnableDali to 1)`);
  global.socket.emit("fromWeb", payload);

};
export const queryLightComType = async (lightObj) => {
  let payload = {
    type: "queryLightComType",
    serial: lightObj.serial,
    gatewayID: lightObj.gatewayID,
    dtkAdd: lightObj.dtkAdd,
  };
  console.log(`[COMMAND OUT] queryLightComType ${lightObj.serial}`);
  global.socket.emit("fromWeb", payload);
}
export const createSlaveLight = async (lightObj, lightX, lightY, mapObj) => {
  const { slaveSerialArray } = lightObj;
  let slaveSerial = `${lightObj.serial}_${gs.getRandomHexID(4)}`;
  if ((slaveSerialArray || []).includes(slaveSerial)) slaveSerial = `${lightObj.serial}_${gs.getRandomHexID(4)}`;
  const payload = {
    type: "createSlaveLight",
    lightObj,
    slaveSerial,
    lightX,
    lightY,
    mapObj,
  };
  console.log(`[COMMAND OUT] createSlaveLight ${slaveSerial}`, payload);
  global.socket.emit("fromWeb", payload);
}
export const deleteSlaveLight = async (lightObj, mapObj) => {
  const payload = {
    type: "deleteSlaveLight",
    slaveLightObj: lightObj,
    mapObj,
  };
  console.log(`[COMMAND OUT] deleteSlaveLight ${lightObj.serial}`);
  global.socket.emit("fromWeb", payload);
}
export const fetchLightHistory = async (deviceID, startTsp, endTsp) => {
  const arr = await mgFindByMatchAndRange(
    "deviceLogs",
    "deviceID",
    deviceID,
    "timeStamp",
    endTsp,
    startTsp
  );
  return arr;
}
