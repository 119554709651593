import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import DivExist from "components/DivExist";
import Divider10 from "components/Divider10";
import { Typography, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import DivInline from "components/DivInline";
import LightIcon from "components/LightIcon";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import IconButtonDone from "components/IconButtonDone";
import { confirmWindow } from "actions/screenActions";
import {
  updateZcProperty,
  zoneControlDisconnectAircon,
} from "actions/zcActions";
const sxArrow = {
  outline: "1px solid #fff",
  margin: "1vh",
  padding: "0 0.5vw 0 0.5vw ",
};

export default function ZcTimerAirconModal({
  x,
  y,
  modalLocation,
  open,
  handleClose,
  layerProps,
  selectedLight,
  zoneControlID,
  zoneControlObj,
}) {
  const [timerStartOnOff, setTimerStartOnOff] = useState("on");
  const [timerEndOnOff, setTimerEndOnOff] = useState("off");
  const { setting1, setting2 } = zoneControlObj || {};

  useEffect(() => {
    const objEnd = setting1?.[selectedLight] || {};
    const objStart = setting2?.[selectedLight] || {};
    setTimerStartOnOff(statusOfCode(objStart.commandCode));
    setTimerEndOnOff(statusOfCode(objEnd.commandCode));
  }, [selectedLight, open, setting1, setting2]);
  const { dispatch, lightObjAll, gatewayObjAll, iconSize, layerScale } =
    layerProps;
  const lightObj = lightObjAll[selectedLight];

  const handleDisconnect = async () => {
    confirmWindow(
      dispatch,
      `Confirm disconnect aircon [${selectedLight}]?`,
      async () => {
        await zoneControlDisconnectAircon(
          zoneControlObj,
          selectedLight,
          lightObj
        );
        handleClose();
      }
    );
  };
  const handleSave = async () => {
    let newSetting1 = { ...(setting1 || {}) };
    let newSetting2 = { ...(setting2 || {}) };
    let serialSetting1 = newSetting1?.[selectedLight] || {};
    let serialSetting2 = newSetting2?.[selectedLight] || {};
    serialSetting1 = {
      ...serialSetting1,
      serial: selectedLight,
      gatewayID: lightObj?.gatewayID,
      acType: lightObj?.acType,
      commandCode: codeOfStatus(timerEndOnOff),
      dtkAdd: lightObj?.dtkAdd,
    };
    serialSetting2 = {
      ...serialSetting2,
      serial: selectedLight,
      gatewayID: lightObj?.gatewayID,
      acType: lightObj?.acType,
      commandCode: codeOfStatus(timerStartOnOff),
      dtkAdd: lightObj?.dtkAdd,
    };
    newSetting1 = { ...newSetting1, [selectedLight]: serialSetting1 };
    newSetting2 = { ...newSetting2, [selectedLight]: serialSetting2 };
    await updateZcProperty(zoneControlID, {
      setting1: newSetting1,
      setting2: newSetting2,
    });
    handleClose();
  };
  const modalWidth = window.innerWidth * 0.33;
  const modalHeight = window.innerHeight * 0.33;
  const modalX = modalLocation.includes("right")
    ? x - modalWidth / layerScale
    : x;
  const modalY = modalLocation.includes("bottom")
    ? y - modalHeight / layerScale - iconSize
    : y;
  return (
    <MapModal
      x={modalX}
      y={modalY}
      open={open}
      handleCloseModal={handleClose}
      width={modalWidth}
      height={modalHeight}
      layerProps={layerProps}
      disableBottomAction
    >
      <DivInline>
        <IconButtonClose
          tooltip="Close dialog without saving"
          onBtnClick={handleClose}
        />
        <LightIcon
          lightObj={lightObj}
          width={40}
          gatewayConnected={
            gatewayObjAll[lightObj?.gatewayID]?.gatewayConnected
          }
        />
        <Typography
          variant="caption"
          onClick={() => console.log(zoneControlObj)}
        >
          {`${lightObj?.description} [${selectedLight}]`}
        </Typography>
      </DivInline>
      <Divider10 />
      <Typography>Timer Start</Typography>
      <RadioGroup
        row
        value={timerStartOnOff}
        onChange={(e) => {
          const val = e.target.value;
          setTimerStartOnOff(val);
        }}
      >
        <ButtonSizeRadio value="on" name="AC ON" />
        <ButtonSizeRadio value="off" name="AC OFF" />
        <ButtonSizeRadio value="noAction" name="No action" />
      </RadioGroup>
      <Divider10 />
      <Typography>Timer End</Typography>
      <RadioGroup
        row
        value={timerEndOnOff}
        onChange={(e) => {
          const val = e.target.value;
          setTimerEndOnOff(val);
        }}
      >
        <ButtonSizeRadio value="on" name="AC ON" />
        <ButtonSizeRadio value="off" name="AC OFF" />
        <ButtonSizeRadio value="noAction" name="No action" />
      </RadioGroup>
      <Divider10 />
      <IconButtonClose
        tooltip="Close dialog without saving"
        onBtnClick={handleClose}
      />
      <IconButtonDone
        tooltip="Save settings and close dialog"
        onBtnClick={handleSave}
      />
      <IconButtonDelete
        tooltip="Disconnect aircon from timer"
        onBtnClick={handleDisconnect}
      />
    </MapModal>
  );
}

function ButtonSizeRadio({ value, name }) {
  return (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={
        <div style={{ display: "flex" }}>
          <div
            style={{
              background: "lightgrey",
              marginRight: 5,
            }}
          />
          <Typography variant="body2">{name}</Typography>
        </div>
      }
    />
  );
}

function statusOfCode(code) {
  switch (code) {
    case 0x021e:
      return "on";
    case 0x021f:
      return "off";
    default:
      return "noAction";
  }
}
function codeOfStatus(status) {
  switch (status) {
    case "on":
      return 0x021e;
    case "off":
      return 0x021f;
    default:
      return 0x0201;
  }
}
