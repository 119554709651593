import { useState, useEffect, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Slider, Tooltip, IconButton } from "@mui/material";
import { LockOpen } from "@mui/icons-material";
import LightIcon from "components/LightIcon";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import ManualCheckStartModal from "./ManualCheckStartModal";
import {
  setPower,
  switchLight,
  switchLightDali,
  queryOneLightSetting,
  changeOneLightSetting,
} from "actions/lightActions";
import IconButtonRefresh from "components/IconButtonRefresh";
import { changeDaliCtlOneSetting, queryDaliCtlOneLightSetting, queryDaliCtlOneSetting } from "actions/daliCtlActions";
export default function LightControlPanel({ serial }) {
  const [state, dispatch] = useContext(SMContext);
  const [slidePwm, setSlidePwm] = useState(100);
  const [type, setType] = useState({});
  const [releaseButton, setReleaseButton] = useState(10);
  const {
    socket,
    activeSerial,
    lightObjAll,
    userObj,
    gatewayObjAll,
    daliCtlObjAll,
  } = state;
  const lightObj = lightObjAll?.[serial];
  const {
    currentData,
    lockLevel,
    zigbeeConnected,
    daliCtlID,
    shortAdd,
    gatewayID,
  } = lightObj || {};
  const { onoffstatusA, onoffstatusL } = currentData || {};
  useEffect(() => {
    setSlidePwm(lightObj?.currentData?.lightLv);
    setType(lightObj?.type);
  }, [lightObj]);
  const daliCtlObj = daliCtlObjAll[daliCtlID];
  const lightOffBtnColor = onoffstatusA === 1 ? "error" : "primary";
  const lightOnBtnColor = onoffstatusA === 0 ? "error" : "primary";
  const cutPowerBtnColor = onoffstatusL === 1 ? "error" : "primary";
  const resumePowerBtnColor = onoffstatusL === 0 ? "error" : "primary";
  let pwmMarks = [];
  const startMark = daliCtlID ? 0 : 10;
  for (let i = startMark; i <= 100; i += 10) {
    const obj = { value: i, label: `${i}` };
    pwmMarks = [...pwmMarks, obj];
  }
  const pwmDisable = onoffstatusA === 1 || onoffstatusL === 1 ? true : false;
  async function handleSwitchLight(command, pwm) {
    const lockLv = command === 0 ? 1 : 0;
    setSlidePwm(pwm);
    if (!daliCtlID) {
      const gatewayObj = gatewayObjAll[gatewayID];
      await switchLight(serial, lightObj, command, pwm, lockLv, gatewayObj);
    } else {
      // console.log("dali switch light");
      if (command === 1) setSlidePwm(0);
      await switchLightDali(serial, command, pwm, shortAdd, daliCtlObj);
    }
  }
  async function handleSetPower(command) {
    const lockLv = command === 1 ? 1 : 100;
    await setPower(serial, lightObj, command);
  }
  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <DivInline>
        <div
          onClick={() => {
            setReleaseButton((b) => b + 1);
            console.log(releaseButton);
          }}
        >
          <LightIcon
            lightObj={lightObj}
            daliCtlObj={daliCtlObj}
            width={8}
            sizeUnit="vw"
            gatewayConnected={gatewayObjAll[gatewayID]?.gatewayConnected}
          />
        </div>
      </DivInline>
      <DivExist show={daliCtlID && !daliCtlObj?.powerOnOffStatus}>
        <Typography color="secondary">Dali master cut off light power</Typography>
      </DivExist>
      <DivExist show={type?.A}>
        <DivInline style={{ height: "4vh" }}>
          <Typography>
            Priority level: {lockLevel === 100 ? 5 : lockLevel}
          </Typography>
          <IconButtonRefresh
            tooltip="Check light priority level"
            onBtnClick={() => {
              if (daliCtlID) {
                queryDaliCtlOneLightSetting(daliCtlObjAll[daliCtlID], shortAdd, 0x0b05);
              } else {
                queryOneLightSetting(lightObj, 0x0301);
              }

            }}
          />
          <DivExist show={lockLevel < 5}>
            <Tooltip title="Unlock light to lowest priority (5)">
              <IconButton
                onClick={async () => {
                  if (daliCtlID) {
                    await changeDaliCtlOneSetting(daliCtlObjAll[daliCtlID], 0x0a0d, { lockLevel: 5 })
                  } else {
                    await changeOneLightSetting(lightObj, 0x020c, { lockLevel: 5 });
                  }
                }}
              >
                <LockOpen />
              </IconButton>
            </Tooltip>
          </DivExist>
        </DivInline>
      </DivExist>
      <DivExist show={type?.A}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <Button
            style={{ width: "40%", height: "10vh" }}
            onClick={() => handleSwitchLight(0, 100)}
            variant="outlined"
            color={lightOnBtnColor}
            disabled={!zigbeeConnected && releaseButton < 5}
            size="large"
          >
            ON
          </Button>

          <Button
            style={{ width: "40%", height: "10vh" }}
            onClick={() => handleSwitchLight(1, 0)}
            color={lightOffBtnColor}
            variant="outlined"
            disabled={!zigbeeConnected && releaseButton < 5}
            size="large"
          >
            OFF
          </Button>
        </div>
      </DivExist>
      <DivExist show={type?.pwm}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography style={{ color: pwmDisable ? "darkgrey" : "white" }}>
            Light level {slidePwm}%
          </Typography>
          <Slider
            style={{
              width: "70%",
              color: pwmDisable ? "darkgrey" : "",
            }}
            value={slidePwm || 0}
            onChange={(evt, newVal) => {
              setSlidePwm(newVal);
            }}
            onChangeCommitted={(e, newVal) => {
              const cmd = newVal > 0 ? 0 : 1;
              handleSwitchLight(cmd, newVal);
            }}
            aria-labelledby="continuous-slider"
            marks={pwmMarks}
            max={100}
            min={daliCtlID ? 0 : 10}
            step={10}
            disabled={(!zigbeeConnected || pwmDisable) && releaseButton < 5}
          />
        </div>
      </DivExist>
      <DivExist show={type?.L}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <Button
            style={{ width: "40%", height: "10vh" }}
            onClick={() => handleSetPower(1)}
            color={cutPowerBtnColor}
            size="large"
            variant="outlined"
            disabled={!zigbeeConnected && releaseButton < 5}
          >
            Power Cut
          </Button>
          <Button
            style={{ width: "40%", height: "10vh" }}
            onClick={() => handleSetPower(0)}
            color={resumePowerBtnColor}
            size="large"
            variant="outlined"
            disabled={!zigbeeConnected && releaseButton < 5}
          >
            Power Resume
          </Button>
        </div>
      </DivExist>
      <DivExist show={type?.batVI && userObj.level < 2}>
        <ManualCheckStartModal />
      </DivExist>
    </div>
  );
}
