import { useContext, useState } from "react";
import { Button, Tooltip, Typography } from "@mui/material";
import { AccountCircle, ArrowForwardIos } from "@mui/icons-material";
import { SMContext } from "context/smContext";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import ModalSM from "components/ModalSM";
import Divider10 from "components/Divider10";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import { updateSingleUserFsToLs } from "actions/userActions";
import AdminContainer from "components/AdminContainer";
import ChangePasswordModal from "./ChangePasswordModal";
import AllUserManagementModal from "./AllUserManagementModal";
import DivExist from "components/DivExist";
import FellowUserManagementModal from "./FellowUserManagementModal";
import IconButtonDelete from "components/IconButtonDelete";
import DivInline from "components/DivInline";

export default function UserModal() {
  const [state, dispatch] = useContext(SMContext);
  const [open, setOpen] = useState(false);
  const [openChangePwModal, setOpenChangePwModal] = useState(false);
  const [superPw, setSuperPw] = useState("");
  const { userObj, socket } = state;
  const { email, uid, level } = userObj;
  return (
    <>
      <Tooltip title={<Typography>User details</Typography>} placement="bottom">
        <Button
          size="small"
          variant="outlined"
          color="inherit"
          onClick={() => setOpen(true)}
        >
          <AccountCircle />
        </Button>
      </Tooltip>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        modalTitle="User"
        modalIcon={<AccountCircle />}
        width="70vw"
        height="70vh"
      >

        <SpaceBetweenDiv title="User" data={email} />
        <SpaceBetweenDiv title="uid" data={uid} />
        <SpaceBetweenDiv title="Level" data={level} />
        <Divider10 />
        <SpaceBetweenButton title="Change password" btnContent={<ArrowForwardIos />} onBtnClick={() => setOpenChangePwModal(true)} />
        <Divider10 />

        <AdminContainer>
          <SpaceBetweenButton
            title="Update user info from cloud"
            btnContent={<ArrowForwardIos />}
            onBtnClick={async () => {
              await updateSingleUserFsToLs(socket, uid);
            }}
          />
          <Divider10 />
        </AdminContainer>
        <ChangePasswordModal open={openChangePwModal} setOpen={setOpenChangePwModal} />
        <DivExist show={level < 1}>
          <SpaceBetweenDiv title="Manage all users" data={
            <AllUserManagementModal />
          } />
          <Divider10 />
        </DivExist>
        {/* <DivExist show={level <= 1}>
          <SpaceBetweenDiv title="Manage fellow users" data={
            <FellowUserManagementModal />
          } />
          <Divider10 />
        </DivExist> */}

        <DivExist show={level < 1}>
          <SpaceBetweenButton title="Get a superpassword" btnContent={<ArrowForwardIos />} onBtnClick={async () => {
            const res = await fetch(`${global.ip}/api/getSuperPassword`);
            const resJson = await res.json();
            setSuperPw(resJson.superPassword);
          }} />
          <DivExist show={superPw}>
            <DivInline>
              <Typography variant="h4">{superPw}</Typography>
              <IconButtonDelete onBtnClick={() => setSuperPw("")} />
            </DivInline>
            <Divider10 />
          </DivExist>
        </DivExist>
      </ModalSM>
    </>
  );
}
