import { useContext } from "react";
import { SMContext } from "context/smContext";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import ConnectDevicesPanel from "./ConnectDevicesPanel";
import GatewayIcon from "components/GatewayIcon";
import DataHistoryPanel from "./DataHistoryPanel";
import SuperAdminPanel from "./SuperAdminPanel";
import ModalSM from "components/ModalSM";
import AdminContainer from "components/AdminContainer";
import BindedTimeTableSettingPanel from "./BindedTimeTableSettingPanel";
import { gatewayConnected } from "actions/generalActions";
import General from "@ecoenghk/general";
import DeviceMongoModal from "components/DeviceMongoModal";
import UnknwonDevicePanel from "./UnknwonDevicePanel";
import GatewayInfoPanel from "./GatewayInfoPanel";

const gs = new General();
const boxSx = {
  padding: "0.5vw",
  margin: "1vh 0.5vw 1vh 0.5vw",
  border: "0.5px solid grey",
  borderRadius: "1vw",
};
export default function GatewayDetailModal() {
  const [state, dispatch] = useContext(SMContext);
  const {
    openGatewayDetailModal,
    activeGatewayID,
    gatewayObjAll,
  } = state;
  const gatewayObj = gatewayObjAll[activeGatewayID];
  const handleClose = () => {
    dispatch({ type: "CLOSED_GATEWAY_DETAIL_MODAL" });
    dispatch({
      type: "GATEWAY_REPLIED_LATEST_FIRMWARE_VER",
      payload: {},
    });
  };
  const gwConnected = gatewayConnected(gatewayObj);
  return (
    <ModalSM
      open={openGatewayDetailModal}
      onClose={handleClose}
      width="96vw"
      height="95vh"
      disableBottomClose
      modalTitle={`${gatewayObj?.description} [${activeGatewayID}]`}
      modalIcon={
        <DeviceMongoModal deviceObj={gatewayObj}>
          <GatewayIcon
            width={3}
            sizeUnit="vw"
            connectStatus={gwConnected}
            backgroundColor="#fff"
          />
        </DeviceMongoModal>
      }
    >
      <DivInline>
        {/* /////////////Information///////////////////////////////Information///////////////////// */}
        <div
          style={{
            width: "36vw",
            height: "82vh",
            overflowY: "scroll",
            ...boxSx,
          }}
        >
          <GatewayInfoPanel />
          <AdminContainer>
            <SuperAdminPanel />
          </AdminContainer>
        </div>

        {/* /////////////Data & History///////////////////////////////Data & History///////////////////// */}
        <div
          style={{
            width: "30vw",
            height: "82vh",
            overflowY: "auto",
            ...boxSx,
          }}
        >
          <DataHistoryPanel />
          <Divider10 />
          <BindedTimeTableSettingPanel />
          <Divider10 />
          <UnknwonDevicePanel />
        </div>

        {/* /////////////Connect devices///////////////////////////////Connect devices///////////////////// */}
        <div
          style={{
            width: "30vw",
            height: "82vh",
            overflowY: "auto",
            ...boxSx,
          }}
        >
          <ConnectDevicesPanel />
        </div>
      </DivInline>
    </ModalSM>
  );
}
