import { useState, useContext } from "react";
import { Typography, Button } from "@mui/material";
import { Done } from "@mui/icons-material";
import { SMContext } from "context/smContext";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import { mapAddSensor } from "actions/mapActions";
import IconButtonSave from "components/IconButtonSave";
import { mgFindOne, mgPost } from "actions/mongoApiActions";
import SensorIcon from "components/SensorIcon";
import General from "@ecoenghk/general";
import { daliAddSensor } from "actions/daliCtlActions";
import { alertWindow } from "actions/screenActions";
const gs = new General();

export default function AddSensorToMapPanel({
  handleClose,
  nameOfDevice,
}) {
  const [state, dispatch] = useContext(SMContext);
  const { activeMapID, mapObjAll, daliCtlObjAll, socket } = state;
  const [sensorID, setSensorID] = useState("");
  const [sensorObj, setSensorObj] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const checkSensor = async () => {
    if (mapObjAll[activeMapID]?.sensorObj?.hasOwnProperty(sensorID)) {
      setErrorMsg(`Sensor ID. ${sensorID} already in this map.`);
    } else {
      const obj = await mgFindOne("sensor", "sensorID", sensorID);
      if (obj.result === "fail") {
        console.log(`Sensor ID [${sensorID}] not exist in mongo database`);
        const fsSensorObj = await mgPost("getDocFS", {
          docPath: `SM_sensor/${sensorID}`,
        });
        console.log("sensorObj from firestore", fsSensorObj);
        if (fsSensorObj.result === "fail") {
          setErrorMsg(`Sensor ID [${sensorID}] not exist`);
        } else {
          setErrorMsg("");
          setSensorObj(fsSensorObj);
        }
      } else {
        setErrorMsg("");
        setSensorObj(obj);
      }
    }
  };
  const handleSave = async () => {
    const daliCtlID = sensorObj.daliCtlID;
    let go = true;
    if (daliCtlID) {
      const daliCtlObj = daliCtlObjAll[daliCtlID];
      if (!daliCtlObj) {
        alertWindow(
          dispatch,
          "Sensor is under a dali controller that is not in this map, add fail"
        );
        go = false;
      }
      if (go) {
        await daliAddSensor(
          daliCtlObj,
          sensorObj.shortAdd,
          sensorObj.instance,
          sensorObj.type
        );
        await gs.waitFor(2000);
      }
    }
    if (go) {
      await mapAddSensor(activeMapID, sensorID, sensorObj);
    }
    handleClose();
  };
  return (
    <>
      <Typography variant="h6">Add {nameOfDevice} to map</Typography>
      <Divider10 />
      <SpaceBetweenInput
        title="ID / Serial no."
        data={sensorID}
        width="40%"
        onInput={(evt) => {
          setSensorID(evt.target.value);
          setErrorMsg("");
        }}
      />
      <SpaceBetweenDiv
        title=""
        data={
          <Button onClick={checkSensor}>
            <Done />
          </Button>
        }
      />
      {errorMsg && (
        <Typography sx={{ color: "red" }} align="right">
          {errorMsg}
        </Typography>
      )}

      <Divider10 />
      {sensorObj?.type && (
        <DivInline justifyContent="center">
          <SensorIcon
            width={4}
            sizeUnit="vw"
            type={sensorObj.type}
            status={0}
            disabledConnectStatus
            gang={sensorObj.type}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "2vw",
            }}
          >
            <Typography variant="caption">ID</Typography>
            <Typography variant="subtitle1">{sensorID}</Typography>
            <Typography variant="caption">Name</Typography>
            <Typography variant="subtitle1">{sensorObj?.sensorName}</Typography>
            <Typography variant="caption">Address</Typography>
            <Typography variant="subtitle1">{sensorObj?.zigbeeAdd}</Typography>
          </div>
        </DivInline>
      )}
      <Divider10 />
      <DivInline justifyContent="center">
        <IconButtonSave onBtnClick={handleSave} disabled={!sensorObj?.type} />
      </DivInline>
    </>
  );
}
