import { useState, useContext, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Typography, TextField, MenuItem } from "@mui/material";
import DivInline from "components/DivInline";
import { editAllSchedules } from "actions/scheduleActions";
import DivSpaceBetween from "components/DivSpaceBetween";
import moment from "moment-timezone";
// import LightIcon from "components/LightIcon";
// import { newArrayBetween } from "actions/generalActions";
import { hourMenu, minuteMenu } from "asset/string/string";
import Divider10 from "components/Divider10";
import ModalSM from "components/ModalSM";
import IconButtonClose from "components/IconButtonClose";
import IconButtonEdit from "components/IconButtonEdit";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonSave from "components/IconButtonSave";
import { deleteAllSchedules } from "actions/scheduleActions";
import { confirmWindow, openSnackbar } from "actions/screenActions";
export default function EditMultiScheduleModal({
  serial,
  schObjArr,
  handleUpdate,
}) {
  const [state, dispatch] = useContext(SMContext);
  const { lightObjAll, serverObj } = state;
  const [open, setOpen] = useState(false);
  const [lastDay, setLastDay] = useState(0);
  const [testDay, setTestDay] = useState(1);
  const [testHour, setTestHour] = useState(0);
  const [testMin, setTestMin] = useState(0);
  const lightObj = lightObjAll[serial] || {};
  const { batteryHour } = lightObj;
  useEffect(() => {
    if (open) {
      const m = moment(schObjArr[0]?.schedule_timeStamp);
      setTestHour(m.hour());
      setTestMin(m.minute());
      setTestDay(m.date());
    }
  }, [open, schObjArr]);

  const handleEdit = async () => {
    confirmWindow(
      dispatch,
      `Confirm update all schedule of light ${serial}?`,
      async () => {
        await editAllSchedules(
          serverObj,
          serial,
          schObjArr,
          testDay,
          lastDay,
          testHour,
          testMin
        );
        openSnackbar(dispatch, "Schedules updated");
      }
    );
  };
  const handleDelete = async () => {
    confirmWindow(
      dispatch,
      `Confirm delete all schedule of light ${serial}?`,
      async () => {
        await deleteAllSchedules(serverObj, serial);
        openSnackbar(dispatch, "Schedules deleted");
        handleClose();
      }
    );
  };
  const handleClose = async () => {
    setOpen(false);
    await handleUpdate();
  };

  return (
    <>
      <IconButtonEdit
        size="small"
        onBtnClick={() => setOpen(true)}
        tooltip="Edit schedules"
        disabled={schObjArr?.length === 0}
      />
      <ModalSM
        open={open}
        onClose={handleClose}
        minWidth="60vw"
        minHeight="88vh"
        maxHeight="88vh"
        modalTitle={`Edit all schedule for [${serial}]`}
        disableBottomClose
      >
        <DivSpaceBetween>
          <Typography>Test Date</Typography>
          <DivInline>
            <TextField
              select
              size="small"
              value={testDay}
              onChange={(e) => {
                setTestDay(e.target.value);
                setLastDay(0);
              }}
            >
              {[...Array(29).keys()].map((n) => (
                <MenuItem key={n} value={n}>
                  {n}
                </MenuItem>
              ))}
            </TextField>
          </DivInline>
        </DivSpaceBetween>
        <DivSpaceBetween>
          <Typography>...OR</Typography>
          <DivInline>
            <Typography>Last</Typography>
            <TextField
              select
              value={lastDay}
              onChange={(e) => {
                setLastDay(e.target.value);
                setTestDay(0);
              }}
              size="small"
            >
              {[...Array(5).keys()].map((n) => (
                <MenuItem key={n} value={n}>
                  {n}
                </MenuItem>
              ))}
            </TextField>
            <Typography> day of month</Typography>
          </DivInline>
        </DivSpaceBetween>
        <Divider10 />
        <DivSpaceBetween>
          <Typography>Test Time</Typography>
          <DivInline>
            <TextField
              size="small"
              select
              value={testHour}
              onChange={(e) => setTestHour(e.target.value)}
            >
              {hourMenu.map((h) => (
                <MenuItem key={h} value={h}>
                  {h < 10 ? "0" + h : h}
                </MenuItem>
              ))}
            </TextField>
            <Typography sx={{ margin: "1vw" }} variant="h5">
              :
            </Typography>
            <TextField
              size="small"
              select
              value={testMin}
              onChange={(e) => setTestMin(e.target.value)}
            >
              {Object.keys(minuteMenu).map((m) => (
                <MenuItem key={m} value={m}>
                  {m < 10 ? "0" + m : m}
                </MenuItem>
              ))}
            </TextField>
          </DivInline>
        </DivSpaceBetween>

        <Divider10 />
        <Typography display="block" variant="caption">
          Battery hour: {batteryHour + " hours"}
        </Typography>
        <Divider10 />
        <DivInline justifyContent="flex-end">
          <IconButtonClose onBtnClick={handleClose} />
          <IconButtonSave onBtnClick={handleEdit} />
          <IconButtonDelete onBtnClick={handleDelete} color="error" />
        </DivInline>
      </ModalSM>
    </>
  );
}
