import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";

import DivExist from "components/DivExist";
import Divider10 from "components/Divider10";
import {
    Typography,
    Box,
    Slider,
    Button,
    Tooltip,
    IconButton,
    Checkbox,
} from "@mui/material";
import ModalSM from "components/ModalSM";
import { queryGatewayOneSetting } from "actions/gatewayActions";
import IconButtonRefresh from "components/IconButtonRefresh";
import DivInline from "components/DivInline";

export default function SenLightSettingCheckModal({ sensorObj, lightObj, children, settingNumArray, layerProps }) {
    const [open, setOpen] = useState(false);
    const { gatewayObjAll } = layerProps;
    const { serial } = lightObj;
    const { sensorID } = sensorObj;
    const sensorGatewayObj = gatewayObjAll[sensorObj.gatewayID] || {};
    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    return (
        <>
            <Box onClick={handleOpen}>
                {children}
            </Box>
            <ModalSM
                open={open}
                onClose={handleClose}
                width="90vw"
                height="90vh"
                modalTitle={`Check sensor ${sensorID} - light ${serial} setting`}
            >
                <Typography>Sensor Gateway ID:{sensorObj.gatewayID} / Light gatewayID:{lightObj.gatewayID}</Typography>
                <Divider10 />
                {
                    (settingNumArray || []).map((settingNum, key) => {
                        const serialSetting = sensorObj?.[`setting${settingNum}`]?.[serial];
                        const gwSerialSetting = sensorGatewayObj?.bindedSensorSetting?.setting_sensor;
                        const settingKey = `setting_${settingNum}_sensor_${sensorID}_light_${serial}`;
                        // const settingInsideGw = JSON.parse(gwSerialSetting?.[settingKey] || "{}");
                        return (
                            <Box key={key}>
                                <Typography >
                                    Setting {settingNum}
                                </Typography>
                                <Divider10 />
                                <DivInline justifyContent="space-between">
                                    <Box>
                                        <Typography>Database</Typography>
                                        <Divider10 />
                                        {
                                            Object.keys(serialSetting || {}).map((field, j) => {
                                                return (
                                                    <DivInline key={j}>
                                                        <Typography sx={{ width: "24vw" }}>{field}</Typography>
                                                        {/* <Typography>{JSON.stringify(serialSetting[field])}</Typography> */}
                                                    </DivInline>
                                                )
                                            })

                                        }
                                    </Box>
                                    <Box>
                                        <DivInline>
                                            <Typography>Inside gateway</Typography>
                                            <IconButtonRefresh onBtnClick={(e) => {
                                                e.stopPropagation();
                                                queryGatewayOneSetting(sensorGatewayObj, 0x000d);
                                            }} />
                                        </DivInline>
                                        <Divider10 />
                                        {/* {
                                            Object.keys(settingInsideGw || {}).map((field, j) => {
                                                return (
                                                    <DivInline key={j}>
                                                        <Typography sx={{ width: "24vw" }}>{field}</Typography>
                                                        <Typography>{JSON.stringify(settingInsideGw[field])}</Typography>
                                                    </DivInline>
                                                )
                                            })
                                        } */}
                                    </Box>
                                </DivInline>


                                <Divider10 />
                            </Box>
                        )
                    })
                }
            </ModalSM>
        </>
    )
}