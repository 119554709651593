import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography } from "@mui/material";

import BatteryCharge from "asset/svgComp/BatteryCharge";

export default function CurrentWithPolarity({
  variant,
  serial,
  style,
  darkLight,
  iconSize,
}) {
  const [state] = useContext(SMContext);
  const { lightObjAll } = state;
  const { currentData, reversePolarityI } = lightObjAll[serial] || {};
  const { current } = currentData || {};
  let currentWithPolarity = "";
  let icon;
  if (reversePolarityI) {
    const currentP = current * -1;
    currentWithPolarity = currentP?.toFixed(2);
    icon = (
      <BatteryCharge
        darkLight={darkLight}
        status={currentP < 0 ? "discharge" : "charge"}
        width={iconSize || 30}
      />
    );
    // icon =
    //   currentP < 0 ? (

    //     <img
    //       src={IconBatteryDischargeWhite}
    //       width={iconSize}
    //       height={iconSize}
    //       alt=""
    //     />
    //   ) : (
    //     <img
    //       src={IconBatteryChargeWhite}
    //       width={iconSize}
    //       height={iconSize}
    //       alt=""
    //     />
    //   );
  } else {
    // currentWithPolarity =
    //   current < 0 ? `${current}A [charging]` : `${current}A [discharging]`;
    currentWithPolarity = current?.toFixed(2);
    icon = (
      <BatteryCharge
        darkLight={darkLight}
        status={current < 0 ? "charge" : "discharge"}
        width={iconSize || 30}
      />
    );

    //     icon =
    //       current < 0 ? (
    //         <img
    //           src={IconBatteryChargeWhite}
    //           width={iconSize}
    //           height={iconSize}
    //           alt=""
    //         />
    //       ) : (
    //         <img
    //           src={IconBatteryDischargeWhite}
    //           width={iconSize}
    //           height={iconSize}
    //           alt=""
    //         />
    //       );
  }
  if (current === 0) currentWithPolarity = "0";
  return (
    <Typography
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
      }}
      variant={variant ? variant : "body1"}
    >
      {currentWithPolarity}A{icon}
    </Typography>
  );
}
