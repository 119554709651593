/**
 *
 * @param {object} param0
 * @param {JSX.Element} [param0.children]
 * @param {"center"|"flex-start"|"flex-end"|"space-between"|"space-around"} [param0.justifyContent]
 * @param {"center"|"flex-start"|"flex-end"|"space-between"|"space-around"} [param0.alignItems]
 * @param {function} [param0.onClick]
 * @param {function} [param0.onMouseEnter]
 * @param {function} [param0.onMouseLeave]
 * @param {boolean} [param0.hide]
 * @param {object} [param0.style]
 * @returns
 */
export default function DivInline({
  children,
  justifyContent,
  alignItems,
  onClick,
  onMouseEnter,
  onMouseLeave,
  hide,
  style,
  sx,
}) {
  return (
    <>
      {!hide && (
        <div
          style={{
            display: "flex",
            alignItems: alignItems || "center",
            justifyContent: justifyContent || "flex-start",
            cursor: onClick ? "pointer" : "default",
            ...style,
            ...sx,
          }}
          onClick={onClick}
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
        >
          {children}
        </div>
      )}
    </>
  );
}
