import React from "react";
import { Text, Rect, Circle, Group, Path } from "react-konva";
import ChargeIcon from "./ChargeIcon";
// import { lightColorDark } from "actions/generalActions";

export default function Exit({
  x,
  y,
  size,
  statusL,
  // connectStatus,
  shadEnabled,
  // outline,
}) {
  const scale = size / 79.375;
  const lightColor = statusL === 0 ? "" : "yellow";

  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale}>
      {/* <Rect
        x={-10}
        y={-10}
        width={100}
        height={100}
        fill="#fff"
        opacity={0.5}
        cornerRadius={5}
        stroke={outline ? lightColorDark : ""}
        perfectDrawEnabled={false}
      /> */}
      <Rect fill={lightColor} width={79.375} height={79.375} perfectDrawEnabled={false} />
      <Path
        stroke="teal"
        data="M0.179 0.179H79.425V79.425H0.179z"
        strokeWidth={2}
        shadowEnabled={shadEnabled}
        shadowColor="black"
        shadowBlur={2}
        shadowOffset={{ x: 10, y: 10 }}
        shadowOpacity={0.5}
        perfectDrawEnabled={false}
      />
      <Text
        x={5}
        y={50}
        fill="green"
        fontFamily="Apple SD Gothic Neo"
        fontSize={30}
        fontStyle="800" //fontweight
        text="EXIT"
      />
      <Path stroke="green" strokeWidth={3.734} data="M26.08 46.258l-.1-5.18" perfectDrawEnabled={false} />
      <Path
        stroke="green"
        strokeWidth={3.734}
        data="M26.093 34.391V3.899l30.692-.2v42.289"
        perfectDrawEnabled={false}
      />
      <Circle fill="green" radius={4.299} x={47.388} y={12.897} perfectDrawEnabled={false} />
      <Path
        stroke="green"
        fill="green"
        data="M42.889 17.096H33.79l-4.299 8.097c-.51 1.012 1.584 3.347 3.4 1.5l2.699-5.798h3.099s-2.554 4.546-3.4 6.998c-.877 2.546-1.699 7.898-1.699 7.898s-4.78.104-8.03.172c-1.1.023-1.372 3.07-1.067 3.626l11.897-.1 2.999-5.698 8.098 12.597h3.499s-8-15.43-7.698-16.396c.385-1.234 3.299-6.998 3.299-6.998s3.344 4.09 4.599 4.599c.624.253 3.699-.1 3.699-.1v-3.6s-1.952.13-2.3 0c-.574-.212-5.798-5.398-5.798-5.398-1.1-.885-2.7-1.451-3.9-1.4z"
        perfectDrawEnabled={false}
      />
      <ChargeIcon y={10} x={-30} statusL={statusL} />
    </Group>
  );
}
