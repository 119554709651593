import IconButton from "@mui/material/IconButton";
import { Done } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

export default function IconButtonDone({
  onBtnClick,
  disabled,
  size = "large",
  color = "default",
  tooltip = "",
}) {
  return (
    <IconButton
      onClick={onBtnClick}
      disabed={disabled}
      size={size}
      color={color}
    >
      <Tooltip title={tooltip}>
        <Done />
      </Tooltip>
    </IconButton>
  );
}
