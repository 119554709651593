import { useState } from "react";
import ModalSM from "./ModalSM";
import { Button, Typography } from "@mui/material";
import DivExist from "./DivExist";
import DivInline from "./DivInline";
import General from "@ecoenghk/general";
const gs = new General();

export default function JsonModal({ show, jsonData, btnVariant, btnContent }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <DivExist show={show}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true)
          }}
          variant={btnVariant || "outlined"}
          size="small"
        >
          {btnContent || "open"}
        </Button>
        <ModalSM
          open={open}
          onClose={() => setOpen(false)}
          backgroundColor="#212F3C"
          height="80vh"
          width="80vw"
        >
          {Object.keys(jsonData || {})
            .sort((a, b) => a.localeCompare(b))
            .map((key) => {
              const value = jsonData[key];
              const valueIsJson =
                !Array.isArray(value) &&
                typeof value === "object" &&
                !gs.isEmptyJson(value);
              const valueIsArray = Array.isArray(value);
              return (
                <DivInline justifyContent="space-between" key={key}>
                  <Typography variant="body2" sx={{ width: "20vw" }}>
                    {key}
                  </Typography>
                  <DivExist show={!valueIsJson && !valueIsArray}>
                    <Typography variant="body2" sx={{ width: "60vw" }}>
                      {value}
                    </Typography>
                  </DivExist>
                  <DivExist show={valueIsArray}>
                    <Typography variant="body2" sx={{ width: "60vw" }}>
                      {JSON.stringify(value)}
                    </Typography>
                  </DivExist>
                  <DivExist show={valueIsJson}>
                    <Typography variant="body2" sx={{ width: "60vw" }}>
                      value is json
                    </Typography>
                    <JsonModal show jsonData={value} />
                  </DivExist>
                </DivInline>
              );
            })}
        </ModalSM>
      </DivExist>
    </>
  );
}
