import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, TextField, MenuItem } from "@mui/material";
import DivInline from "components/DivInline";
import { addYearSchedules } from "actions/scheduleActions";
import DivSpaceBetween from "components/DivSpaceBetween";
import moment from "moment-timezone";

import { hourMenu, minuteMenu } from "asset/string/string";
import Divider10 from "components/Divider10";
import ModalSM from "components/ModalSM";
import { confirmWindow, openSnackbar } from "actions/screenActions";

export default function AddMultipleScheduleModal({
  open,
  handleClose,
  serial,
}) {
  const [state, dispatch] = useContext(SMContext);
  const { lightObjAll, serverObj } = state;
  const [testDay, setTestDay] = useState(1);
  const [lastDay, setLastDay] = useState(0);
  const [testHour, setTestHour] = useState(0);
  const [testMin, setTestMin] = useState(0);
  const [startMonth, setStartMonth] = useState(moment().format("YYYY-MM"));
  const { batteryHour } = lightObjAll[serial] || {};

  const handleAdd = async () => {
    confirmWindow(dispatch, "Confirm to add 12 months schedule?", async () => {
      await addYearSchedules(
        serverObj,
        serial,
        testDay,
        lastDay,
        testHour,
        testMin,
        startMonth,
        batteryHour
      );
      openSnackbar(dispatch, "schedules added");
      handleClose();
    });
  };

  return (
    <ModalSM
      open={open}
      onClose={handleClose}
      minWidth="60vw"
      minHeight="88vh"
      maxHeight="88vh"
      modalTitle={`Add year schedule for [${serial}]`}
      onAdd={handleAdd}
    >
      <DivSpaceBetween>
        <Typography>Start month</Typography>
        <TextField
          size="small"
          value={startMonth}
          type="month"
          onChange={(e) => setStartMonth(e.target.value)}
        />
      </DivSpaceBetween>
      <Divider10 />
      <DivSpaceBetween>
        <Typography>Test day</Typography>
        <DivInline>
          <TextField
            select
            value={testDay}
            onChange={(e) => {
              setTestDay(e.target.value);
              setLastDay(0);
            }}
            size="small"
          >
            {[...Array(30).keys()].map((n) => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </TextField>
          <Typography>of each month</Typography>
        </DivInline>
      </DivSpaceBetween>
      <DivSpaceBetween>
        <Typography>...OR</Typography>
        <DivInline>
          <Typography>Last</Typography>
          <TextField
            select
            value={lastDay}
            onChange={(e) => {
              setLastDay(e.target.value);
              setTestDay(0);
            }}
            size="small"
          >
            {[...Array(5).keys()].map((n) => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </TextField>
          <Typography> day of month</Typography>
        </DivInline>
      </DivSpaceBetween>
      <Divider10 />
      <DivSpaceBetween>
        <Typography>Test Time</Typography>
        <DivInline>
          <TextField
            size="small"
            select
            value={testHour}
            onChange={(e) => setTestHour(e.target.value)}
          >
            {hourMenu.map((h) => (
              <MenuItem key={h} value={h}>
                {h < 10 ? "0" + h : h}
              </MenuItem>
            ))}
          </TextField>
          <Typography sx={{ margin: "1vw" }} variant="h5">
            :
          </Typography>
          <TextField
            size="small"
            select
            value={testMin}
            onChange={(e) => setTestMin(e.target.value)}
          >
            {Object.keys(minuteMenu).map((m) => (
              <MenuItem key={m} value={m}>
                {m < 10 ? "0" + m : m}
              </MenuItem>
            ))}
          </TextField>
        </DivInline>
      </DivSpaceBetween>

      <Divider10 />
      <Typography display="block" variant="caption">
        Battery hour: {batteryHour + " hours"}
      </Typography>
    </ModalSM>
  );
}
