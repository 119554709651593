import React, { useState, useContext } from "react";
import { Typography } from "@mui/material";
import { SMContext } from "context/smContext";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import Divider10 from "components/Divider10";
import IconButtonSave from "components/IconButtonSave";
import DivInline from "components/DivInline";
import { showBigProgress, hideBigProgress } from "actions/screenActions";
import { createZoneControl } from "actions/zcActions";

export default function AddZcToMapPanel({
  handleClose,
  deviceType,
  nameOfDevice,
}) {
  const [state, dispatch] = useContext(SMContext);
  const { activeMapID } = state;
  const [name, setName] = useState("");
  const handleSave = async () => {
    showBigProgress(dispatch);
    await createZoneControl(activeMapID, deviceType, name);
    hideBigProgress(dispatch);
    handleClose();
  };

  return (
    <>
      <Typography variant="h6">Create {nameOfDevice} to map</Typography>
      <Divider10 />
      <SpaceBetweenInput
        data={name}
        title="Name"
        onInput={(e) => setName(e.target.value)}
      />

      <Divider10 />
      <DivInline justifyContent="center">
        <IconButtonSave onBtnClick={handleSave} disabled={!name} />
      </DivInline>
    </>
  );
}
