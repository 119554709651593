import { useState, useContext, useMemo, Fragment } from "react";
import {
  Typography,
  Tooltip,
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Button,
} from "@mui/material";
import { SMContext } from "context/smContext";
import { ArrowDownward, ArrowUpward, CalendarMonth } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivCol from "components/DivCol";
import Space10 from "components/Space10";
import DivInline from "components/DivInline";
import LightIcon from "components/LightIcon";
import { cursor } from "asset/string/string";
import ModalSM from "components/ModalSM";
import IconButtonBack from "components/IconButtonBack";
import {
  fetchTimeTableByLightArray,
  deleteTestTimeTable,
  // deleteAllTestTimeTableOfSerial,
  // deleteSingleTimeTable,
} from "actions/timetableActions";
import moment from "moment";
import EditTestTimeTableModal from "./EditTestTimeTableModal";
import IconButtonDelete from "components/IconButtonDelete";
import {
  confirmWindow,
  hideBigProgress,
  showBigProgress,
} from "actions/screenActions";
import General from "@ecoenghk/general";
import DivExist from "components/DivExist";
import AdminContainer from "components/AdminContainer";
import JsonModal from "components/JsonModal";
import MapTopBtn from "containers/home/MapTopBtn";
const gs = new General();

export default function TestTimeTableModal({ disabled, btnSize }) {
  const [state, dispatch] = useContext(SMContext);
  const [open, setOpen] = useState(false);
  const [sortBy, setSortBy] = useState("nextMonthTestTsp");
  const [sortOrder, setSortOrder] = useState("asc");
  const {
    activeMapID,
    lightObjAll,
    // userObj,
    mapObjAll,
    timeTableObjAll,
    timeTableArrAll,
  } = state;
  const mapObj = mapObjAll[activeMapID];

  const lightObjArr = useMemo(() => {
    let arr = Object.keys(mapObj?.lightObj || {}).map((s) => {
      let lightObj = { ...lightObjAll[s] };
      if (lightObj?.type?.batVI) {
        const monthObj = (timeTableArrAll || []).find(
          (obj) =>
            obj.reportType === "check_1month" &&
            obj.rescheduleCount === 0 &&
            obj.serial === s
        );
        const nextMonthObj = getNextMonthTestTsp(monthObj);
        lightObj.nextMonthTestTsp = nextMonthObj.nextMonthTestTsp;
        const annualObj = (timeTableArrAll || []).find(
          (obj) =>
            obj.reportType === "check_12month" &&
            obj.rescheduleCount === 0 &&
            obj.serial === s
        );
        const nextAnnualObj = getNextAnnualTestTsp(annualObj);
        lightObj.nextAnnualTestTsp = nextAnnualObj.nextAnnualTestTsp;
        return lightObj;
      }
    });
    arr = arr.filter((obj) => obj);
    return arr;
  }, [mapObj, lightObjAll, timeTableArrAll]);

  const handleOpen = async () => {
    if (!disabled) {
      // handleFetchTestTimeTable();
      setOpen(true);
    }
  };
  const handleClose = async () => {
    setOpen(false);
  };

  const handleDeleteAllSchedules = async (
    serial,
    monthTimeTableID,
    annualTimeTableID
  ) => {
    confirmWindow(
      dispatch,
      `Delete test schedules of this light [${serial}]?`,
      async () => {
        showBigProgress(dispatch);
        await deleteTestTimeTable(monthTimeTableID, annualTimeTableID);
        let newTimeTableArrAll = timeTableArrAll.filter(
          (obj) =>
            obj.timeTableID !== monthTimeTableID &&
            obj.timeTableID !== annualTimeTableID
        );
        let newTimeTableObjAll = { ...timeTableObjAll };
        delete newTimeTableObjAll[monthTimeTableID];
        delete newTimeTableObjAll[annualTimeTableID];
        dispatch({
          type: "FETCHED_TIMETABLE_OBJ_ALL",
          payload: {
            objAll: newTimeTableObjAll,
            arrAll: newTimeTableArrAll,
          },
        });
        hideBigProgress(dispatch);
      }
    );
  };

  function SortBtn({ title, sortByField }) {
    return (
      <DivInline>
        <Typography>{title}</Typography>
        <ArrowUpward
          sx={{
            "&:hover": { color: "red" },
            color:
              sortOrder === "asc" && sortByField === sortBy ? "orange" : "",
          }}
          onClick={() => {
            setSortBy(sortByField);
            setSortOrder("asc");
          }}
        />
        <ArrowDownward
          sx={{
            "&:hover": { color: "red" },
            color:
              sortOrder === "desc" && sortByField === sortBy ? "orange" : "",
          }}
          onClick={() => {
            setSortBy(sortByField);
            setSortOrder("desc");
          }}
        />
      </DivInline>
    );
  }
  return (
    <>
      <MapTopBtn
        btnClick={handleOpen}
        tooltip="Light Test Time Table"
        disabled={disabled}
        btnSize={btnSize}
      >
        <CalendarMonth />
      </MapTopBtn>

      <ModalSM
        open={open}
        onClose={handleClose}
        width="95vw"
        height="96vh"
        disableBottomClose
      >
        <DivInline>
          <IconButtonBack onBtnClick={handleClose} />
          <CalendarMonth />
          <Typography>{`Light Test Time Table - ${mapObj?.mapName}`}</Typography>
        </DivInline>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                Light
                <SortBtn title="Description" sortByField="description" />
              </TableCell>
              <TableCell>
                <SortBtn title="Monthly test" sortByField="nextMonthTestTsp" />
              </TableCell>
              <TableCell>
                <SortBtn title="Annual test" sortByField="nextAnnualTestTsp" />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            {lightObjArr
              .sort((a, b) => {
                if (sortOrder === "asc")
                  return String(a[sortBy]).localeCompare(String(b[sortBy]));
                if (sortOrder === "desc")
                  return String(b[sortBy]).localeCompare(String(a[sortBy]));
              })
              .map((lightObj, key) => {
                const { zigbeeConnected, description, serial } = lightObj;
                const monthTestTimeTableObj = (timeTableArrAll || []).find(
                  (obj) =>
                    obj.reportType === "check_1month" &&
                    obj.rescheduleCount === 0 &&
                    obj.serial === serial
                );
                const nextMonthObj = getNextMonthTestTsp(monthTestTimeTableObj);
                const annualTestTimeTableObj = (timeTableArrAll || []).find(
                  (obj) =>
                    obj.reportType === "check_12month" &&
                    obj.rescheduleCount === 0 &&
                    obj.serial === serial
                );
                const nextAnnualObj = getNextAnnualTestTsp(
                  annualTestTimeTableObj
                );
                return (
                  <Fragment key={key}>
                    <TableRow>
                      <TableCell>
                        <DivInline>
                          <LightIcon
                            lightStyle={lightObj?.style}
                            disabledStatus
                            width={2.5}
                            sizeUnit="vw"
                            connectStatus={zigbeeConnected}
                          />
                          <Space10 />
                          <Tooltip title={serial}>
                            <Typography>{description}</Typography>
                          </Tooltip>
                        </DivInline>
                      </TableCell>
                      <TableCell>
                        <DivCol alignItems="flex-start">
                          <Typography>
                            {nextMonthObj.nextMonthTestTsr}
                          </Typography>
                          <Typography variant="caption">
                            {nextMonthObj.monthTestDescription}
                          </Typography>
                        </DivCol>
                      </TableCell>
                      <TableCell>
                        <DivCol alignItems="flex-start">
                          <Typography>
                            {nextAnnualObj.nextAnnualTestTsr}
                          </Typography>
                          <Typography variant="caption">
                            {nextAnnualObj.annualTestDescription}
                          </Typography>
                        </DivCol>
                      </TableCell>
                      <TableCell>
                        <EditTestTimeTableModal
                          serial={serial}
                          monthTimeTableObj={monthTestTimeTableObj}
                          annualTimeTableObj={annualTestTimeTableObj}
                          onSave={(monthObj, annualObj) => {
                            if (monthObj.timeTableID) {
                              if (monthObj) {
                                dispatch({
                                  type: "UPDATED_TIMETABLE_OBJ",
                                  payload: monthObj,
                                });
                              }
                              if (annualObj) {
                                dispatch({
                                  type: "UPDATED_TIMETABLE_OBJ",
                                  payload: annualObj,
                                });
                              }
                            } else {
                              fetchTimeTableByLightArray(
                                Object.keys(lightObjAll || {}),
                                dispatch
                              );
                            }
                          }}
                        />
                        <IconButtonDelete
                          tooltip={`Delete test schedules`}
                          onBtnClick={async () =>
                            await handleDeleteAllSchedules(
                              serial,
                              monthTestTimeTableObj.timeTableID,
                              annualTestTimeTableObj.timeTableID
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
      </ModalSM>
    </>
  );
}

const getNextMonthTestTsp = (monthObj) => {
  if (!monthObj) return {};
  let nextMonthMoment = moment()
    .date(monthObj.date[0])
    .hour(monthObj.hour[0])
    .minute(monthObj.minute[0]);
  if (nextMonthMoment.isBefore(moment()))
    nextMonthMoment = nextMonthMoment.add(1, "months");
  let returnObj = {};
  returnObj.nextMonthTestTsp = nextMonthMoment.valueOf();
  returnObj.nextMonthTestTsr = nextMonthMoment.format("YYYY-MMM-DD, HH:mm");
  // returnObj.monthTestTimeTableObj = monthObj;
  returnObj.monthTestDescription = `Day ${nextMonthMoment.format(
    "DD, HH:mm"
  )} every month`;
  return returnObj;
};
const getNextAnnualTestTsp = (annualObj) => {
  if (!annualObj) return {};
  let nextAnnualMoment = moment()
    .month(annualObj?.month[0] - 1)
    .date(annualObj?.date[0])
    .hour(annualObj?.hour[0])
    .minute(annualObj?.minute[0]);
  if (nextAnnualMoment.isBefore(moment()))
    nextAnnualMoment = nextAnnualMoment.add(1, "years");
  let returnObj = {};
  returnObj.nextAnnualTestTsp = nextAnnualMoment.valueOf();
  returnObj.nextAnnualTestTsr = nextAnnualMoment.format("YYYY-MMM-DD, HH:mm");
  // returnObj.annualTestTimeTableObj = annualObj;
  returnObj.annualTestDescription = `${nextAnnualMoment.format(
    "MMM-DD, HH:mm"
  )} every year`;
  return returnObj;
};
