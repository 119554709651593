import { useState, Fragment } from "react";
import { Line, Rect, Text } from "react-konva";
import DivExist from "components/DivExist";
import { alertWindow, confirmWindow } from "actions/screenActions";
import { zoneControlConnectSensor } from "actions/zcActions";
import { zoneCtlDefault } from "asset/string/string";
import ZcTimerSensorModal from "containers/map/modal/ZcTimerSensorModal";
const screenW = window.innerWidth;
const screenH = window.innerHeight;
export default function MapZcSensorGroup({ layerProps, x, y }) {
  const {
    iconSize,
    mapScale,
    sensorObjAll,
    zoneControlObjAll,
    dispatch,
    mapObjAll,
    activeMapID,
    enablePan,
    layerScale,
    selectedID,
  } = layerProps;
  const zoneControlObj = zoneControlObjAll[selectedID];
  const zcX = layerProps.selectedDeviceX;
  const zcY = layerProps.selectedDeviceY;
  const sensorMapObj = mapObjAll[activeMapID]?.sensorObj;
  const [selectedSensor, setSelectedSensor] = useState("");
  const [editModalX, setEditModalX] = useState(0);
  const [editModalY, setEditModalY] = useState(0);
  const [modalLocation, setModalLocation] = useState("topleft"); //topleft, topright, bottomleft, bottomright
  const [showEditModal, setShowEditModal] = useState(false);
  const { type, sensorMap } = zoneControlObj || {};
  function handleCloseModal() {
    setShowEditModal(false);
    setSelectedSensor("");
  }
  const handleClick = (e, sensorID, isUnderZc) => {
    e.evt.preventDefault();
    if (isUnderZc) {
      handleModalPostion(sensorID);
      setSelectedSensor(sensorID);
      setShowEditModal(true);
    } else {
      handleConnectSensor(sensorID);
    }
  };
  const handleModalPostion = (s) => {
    let xLight = (sensorMapObj[s]?.x + iconSize) * mapScale.x;
    let yLight = (sensorMapObj[s]?.y + iconSize) * mapScale.y;
    let pw = "left",
      ph = "top";
    if (screenW / 2 < xLight) pw = "right";
    if (screenH / 2 < yLight) ph = "bottom";
    const p = ph + pw;
    setModalLocation(p);
    setEditModalY(yLight);
    setEditModalX(xLight);
  };
  const handleConnectSensor = async (sensorID) => {
    confirmWindow(
      dispatch,
      `Connect ${sensorID} to this ${zoneCtlDefault[type].typeName} [${selectedID}]?`,
      async () => {
        const sensorObj = sensorObjAll[sensorID];
        if (!sensorObj.dtkAdd) {
          alert("Missing dtkAdd, connect fail");
          return;
        }
        if (!sensorObj.gatewayID) {
          alert("Missing gatewayID, connect fail");
          return;
        }
        await zoneControlConnectSensor(zoneControlObj, sensorObj);
      }
    );
  };
  const unitSize = iconSize * mapScale.x;

  return (
    <>
      <>
        {Object.keys(sensorMapObj).map((s) => {
          const netX = sensorMapObj?.[s]?.x * mapScale.x;
          const netY = sensorMapObj?.[s]?.y * mapScale.y;
          const isUnderZc = Object.values(sensorMap || {}).includes(s);
          const lineColor = "#1e90ff";
          const sensorObj = sensorObjAll[s];
          const sensorType = sensorObj?.type;
          const sensorIsDali = sensorObj?.daliCtlID ? true : false;
          return (
            <Fragment key={s}>
              {type === "timer" && !sensorIsDali ? (
                <>
                  <DivExist show={isUnderZc}>
                    <Line
                      points={[zcX, zcY, netX, netY]}
                      stroke={lineColor}
                      strokeWidth={2 / layerScale}
                    />
                  </DivExist>
                  <Rect
                    x={netX}
                    y={netY}
                    width={unitSize}
                    height={unitSize}
                    fill={isUnderZc ? "#F1948A" : "#52BE80"}
                    opacity={0.5}
                    onMouseOver={(e) => {
                      const container = e.target.getStage().container();
                      container.style.cursor = enablePan ? "pointer" : "move";
                    }}
                    onMouseLeave={(e) => {
                      const container = e.target.getStage().container();
                      container.style.cursor = enablePan ? "grab" : "default";
                    }}
                    onTap={(e) => handleClick(e, s, isUnderZc)}
                    onClick={(e) => handleClick(e, s, isUnderZc)}
                    stroke={selectedSensor === s ? "#2C3E50" : ""}
                    strokeWidth={6 / layerScale}
                  />
                </>
              ) : (
                <>
                  <Rect
                    x={netX}
                    y={netY}
                    width={iconSize * mapScale.x}
                    height={iconSize * mapScale.y}
                    fill="grey"
                    opacity={0.3}
                  />
                  <Line
                    x={netX}
                    y={netY}
                    points={[
                      0,
                      0,
                      iconSize * mapScale.x,
                      iconSize * mapScale.y,
                    ]}
                    stroke="red"
                    strokeWidth={2 / layerScale}
                  />
                </>
              )}
            </Fragment>
          );
        })}
      </>
      <ZcTimerSensorModal
        x={editModalX}
        y={editModalY}
        modalLocation={modalLocation}
        open={showEditModal && type === "timer"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedSensor={selectedSensor}
        zoneControlID={selectedID}
        zoneControlObj={zoneControlObj}
      />
    </>
  );
}
