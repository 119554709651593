import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import DivInline from "components/DivInline";
import General from "@ecoenghk/general";
import Divider10 from "components/Divider10";
import SensorIcon from "components/SensorIcon";
import IconButtonBack from "components/IconButtonBack";
import IconButtonRefresh from "components/IconButtonRefresh";
import {
    sensorQueryNearFieldSetting,
    sensorDeleteNearFieldSetting,
    sensorSaveNearFieldSetting,
    sensorExecuteNearFieldLightSetting,
    sensorSaveBatchNearFieldSetting
} from "actions/sensorActions";
import IconButtonDelete from "components/IconButtonDelete";
import DivExist from "components/DivExist";
import IconButtonAdd from "components/IconButtonAdd";
import { Commit } from "@mui/icons-material";
import { alertWindow, confirmWindow } from "actions/screenActions";
import { mgFindOne } from "actions/mongoApiActions";
import DeviceIcon from "components/DeviceIcon";
import { deviceInfoOfObj } from "actions/generalActions";
const gs = new General();

export default function SensorNearFieldPanel({ sensorObj, deviceObj, handleClose, dispatch, buttonNo }) {
    const { sensorID, nearFieldSetting, type } = sensorObj || {};
    const deviceInfo = deviceInfoOfObj(deviceObj);
    const deviceID = deviceInfo.deviceID;
    const shortAdd = deviceObj.shortAdd || 0;
    let hasNearFieldSetting = false;
    let settingNumArray = [];
    let title = `${sensorID} - Near Field Setting`;
    if (type === 'motion') {
        settingNumArray = [1];
        hasNearFieldSetting = nearFieldSetting?.[`${deviceID}_1_${shortAdd}`] ? true : false;
    } else if (type === "scenebutton") {
        settingNumArray = [buttonNo];
        title += ` Button ${buttonNo}`;
        hasNearFieldSetting = nearFieldSetting?.[`${deviceID}_${buttonNo}_${shortAdd}`] ? true : false;
    } else if (type === "lightswitch") {
        settingNumArray = [buttonNo * 2 - 1, buttonNo * 2];
        title += ` Button ${buttonNo}`;
        hasNearFieldSetting = nearFieldSetting?.[`${deviceID}_${buttonNo * 2 - 1}_${shortAdd}`] ? true : false;
    } else if (type === "daylight") {
        settingNumArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        hasNearFieldSetting = nearFieldSetting?.[`${deviceID}_1_${shortAdd}`] ? true : false;
    }
    title += " to ";

    const getDeviceWifiApMacAddArr = async () => {
        if (deviceObj.wifiApMacAddress && !deviceObj.daliCtlID) return deviceObj.wifiApMacAddressArray;
        const daliCtlObj = await mgFindOne("daliCtl", "daliCtlID", deviceObj.daliCtlID);
        return daliCtlObj?.wifiApMacAddressArray;
    }
    const handleQueryNearField = async (settingNum) => {
        const macAddArr = await getDeviceWifiApMacAddArr();
        if (!macAddArr || macAddArr.length === 0) {
            alertWindow(dispatch, "Deivce missing wifiApMacAddress, no action");
            return;
        }
        sensorQueryNearFieldSetting(sensorObj, macAddArr, deviceObj, settingNum);
    }
    const handleSaveNearField = async () => {
        const macAddArr = await getDeviceWifiApMacAddArr();
        if (!macAddArr || macAddArr.length === 0) {
            alertWindow(dispatch, "Deivce missing wifiApMacAddress, no action");
            return;
        }
        if (type !== "daylight") {
            await gs.asyncForEach(settingNumArray, async n => {
                await sensorSaveNearFieldSetting(sensorObj, macAddArr, deviceObj, n);
                await gs.waitFor(1200);
            });
        } else {
            await sensorSaveBatchNearFieldSetting(sensorObj, macAddArr, deviceObj, settingNumArray);
        }
    }
    const handleDeleteNearFieldSetting = async () => {
        confirmWindow(dispatch, "Are you sure to delete all near field setting?", async () => {
            const macAddArr = await getDeviceWifiApMacAddArr();
            if (!macAddArr || macAddArr.length === 0) {
                alertWindow(dispatch, "Deivce missing wifiApMacAddress, no action");
                return;
            }
            await gs.asyncForEach(settingNumArray, async n => {
                await sensorDeleteNearFieldSetting(sensorObj, macAddArr, deviceObj, n);
                await gs.waitFor(1200);
            });
        });
    }
    return (
        <Box>
            <DivInline>
                <IconButtonBack onBtnClick={handleClose} />
                <SensorIcon
                    width={3}
                    sizeUnit='vw'
                    type={sensorObj.type}
                    status={buttonNo === 1 ? 1 : 0}
                    status2={buttonNo === 2 ? 1 : 0}
                    status3={buttonNo === 3 ? 1 : 0}
                    status4={buttonNo === 4 ? 1 : 0}
                    disabledConnectStatus
                    gang={sensorObj.gang}
                />
                <Typography variant="body2">{title}</Typography>
                <DeviceIcon width={3} sizeUnit="vw" deviceType={deviceInfo.deviceType} deviceStyle={deviceObj.style || deviceObj.type} gang={deviceObj.gang} />
                <Typography variant="body2">{deviceID}</Typography>
            </DivInline>
            <Divider10 />

            <DivInline hide={hasNearFieldSetting}>
                <Typography variant="body2">No near field setting</Typography>
                <IconButtonAdd tooltip="Add near field setting" onBtnClick={async () => handleSaveNearField()} />
            </DivInline>
            <DivExist show={hasNearFieldSetting}>
                {
                    (settingNumArray || []).map(n => {
                        const nearFieldSettingObj = nearFieldSetting?.[`${deviceID}_${n}_${shortAdd}`];
                        if (!nearFieldSettingObj) return null;
                        const { msgArray, macArray } = nearFieldSettingObj || {};
                        const macAddHex = (macArray || []).map(m => m.toString(16).padStart(2, '0')).join(':');
                        const msgArrayHex = (msgArray || []).map(m => m.toString(16).padStart(2, '0')).join("");
                        // const lenByte = (msgArray.length + 1).toString(16).padStart(2, '0');
                        const lenByteCS = (msgArray.length + 2).toString(16).padStart(2, '0');
                        let msgArrayHexStr = "ed" + lenByteCS + "0000" + msgArrayHex;
                        const cs = gs.getChecksum_dtk(msgArrayHexStr);
                        msgArrayHexStr = "ed" + lenByteCS + "0000" + msgArrayHex + cs + "ff";
                        const msgJson = gs.dtKStrToJson(msgArrayHexStr);
                        return (
                            <Box key={n}>
                                <DivInline>
                                    <Typography>Setting {n}</Typography>
                                    <Tooltip title="Execute near field setting manually">
                                        <IconButton onClick={async () => {
                                            await sensorExecuteNearFieldLightSetting(sensorObj, deviceObj, n);
                                        }}>
                                            <Commit />
                                        </IconButton>
                                    </Tooltip>
                                </DivInline>
                                <Typography variant='body2'>Mac address {macAddHex}</Typography>
                                <Typography variant='body2'>{gs.dtkActionCmdToMsg(msgJson, deviceID)}</Typography>
                                <Typography variant='caption'>Command: {msgArrayHexStr}</Typography>
                                <Divider10 />
                            </Box>
                        )
                    })

                }


            </DivExist>
            <Divider10 />

            <IconButtonRefresh
                tooltip="Verify near field setting stored inside sensor"
                onBtnClick={async () => {
                    await gs.asyncForEach(settingNumArray, async n => {
                        await handleQueryNearField(n);
                        await gs.waitFor(1200);
                    })
                }}
            />
            <IconButtonDelete
                hide={!hasNearFieldSetting}
                tooltip="Delete near field setting"
                onBtnClick={handleDeleteNearFieldSetting}
            />
        </Box>
    )
}