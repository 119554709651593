import { useState, useEffect, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Tooltip, Checkbox, Button } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
// import {  } from "actions/timetableActions";
import { weekDayIsoMenu } from "asset/string/string";
import General from "@ecoenghk/general";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { deleteZcTimeSetting, updateZcTimeSetting } from "actions/zcActions";
import { confirmWindow } from "actions/screenActions";
import ModalSM from "components/ModalSM";
import SmallButton from "components/SmallButton";
import Divider10 from "components/Divider10";
import AdminContainer from "components/AdminContainer";
import { manualExecuteTimeTable } from "actions/timetableActions";
import DeviceMongoModal from "components/DeviceMongoModal";
const gs = new General();

export default function EditTimeSettingModal({
  open,
  onClose,
  timeTableObj1,
  timeTableObj2,
}) {
  const [state, dispatch] = useContext(SMContext);
  const { activeZoneControlID, zoneControlObjAll, timeTableObjAll } = state;
  const zcObj = zoneControlObjAll[activeZoneControlID];
  const [momentS, setMomentS] = useState(moment());
  const [momentE, setMomentE] = useState(moment());
  const [weekArrS, setWeekArrS] = useState([]);
  const [weekArrE, setWeekArrE] = useState([]);
  const [excludeHoliday, setExcludeHoliday] = useState(false);
  const [onlyHoliday, setOnlyHoliday] = useState(false);
  const [cronStrS, setCronStrS] = useState("");
  const [cronStrE, setCronStrE] = useState("");
  const add0 = (n) => {
    const s = n < 10 ? "0" + n : n;
    return s;
  };
  useEffect(() => {
    const sHr = timeTableObj2?.hour?.[0];
    const sMin = timeTableObj2?.minute?.[0];
    const eHr = timeTableObj1?.hour?.[0];
    const eMin = timeTableObj1?.minute?.[0];
    setMomentS(
      moment()
        .hour(sHr ?? 0)
        .minute(sMin ?? 0)
    );
    setMomentE(
      moment()
        .hour(eHr ?? 0)
        .minute(eMin ?? 0)
    );
    setWeekArrS(timeTableObj2?.isoWeekday);
    setWeekArrE(timeTableObj1?.isoWeekday);
    setExcludeHoliday(timeTableObj1?.excludeHoliday);
    setOnlyHoliday(timeTableObj1?.onlyHoliday);
  }, [
    open,
    timeTableObj1?.hour,
    timeTableObj1?.minute,
    timeTableObj1?.isoWeekday,
    timeTableObj2?.hour,
    timeTableObj2?.minute,
    timeTableObj2?.isoWeekday,
    timeTableObj1?.excludeHoliday,
    timeTableObj1?.onlyHoliday,
  ]);
  useEffect(() => {
    const hrS = momentS.hour();
    const minS = momentS.minute();
    const cronS = `${minS} ${hrS} * * ${weekArrS?.[0] === 100 ? "*" : weekArrS?.join(",")
      }`;
    const hrE = momentE.hour();
    const minE = momentE.minute();
    const cronE = `${minE} ${hrE} * * ${weekArrE?.[0] === 100 ? "*" : weekArrE?.join(",")
      }`;
    setCronStrS(cronS);
    setCronStrE(cronE);
  }, [momentS, momentE, weekArrS, weekArrE]);

  const handleSave = async () => {
    let updateObjE = {
      ...timeTableObj1,
      hour: [momentE.hour()],
      minute: [momentE.minute()],
      isoWeekday: weekArrE,
      excludeHoliday: excludeHoliday || false,
      onlyHoliday: onlyHoliday || false,
      cron: cronStrE,
    };
    let updateObjS = {
      ...timeTableObj2,
      hour: [momentS.hour()],
      minute: [momentS.minute()],
      isoWeekday: weekArrS,
      excludeHoliday: excludeHoliday || false,
      onlyHoliday: onlyHoliday || false,
      cron: cronStrS,
    };
    delete updateObjE._id;
    delete updateObjS._id;
    const updateObjArray = [updateObjE, updateObjS];
    console.log(updateObjArray);
    await updateZcTimeSetting(
      activeZoneControlID,
      zcObj,
      updateObjE,
      updateObjS
    );
    // await updateManyTimeTables(socket, updateObjArray);
    onClose();
  };
  const handleDelete = async () => {
    confirmWindow(dispatch, `Confirm delete this time setting?`, async () => {
      await deleteZcTimeSetting(
        activeZoneControlID,
        zcObj,
        timeTableObj1.timeTableID,
        timeTableObj2.timeTableID
      );
      onClose();
    });
  };
  return (
    <ModalSM
      width="75vw"
      height="90vh"
      open={open}
      onClose={onClose}
      onSave={handleSave}
      onDelete={handleDelete}
      modalTitle={`Change time setting for ${zcObj.zoneControlName} [${zcObj?.zoneControlID}]`}
    >
      <DivInline justifyContent="center">
        <DivCol>
          <Tooltip title={timeTableObj2.timeTableID || ""}>
            <Typography>Timer Start</Typography>
          </Tooltip>
          <AdminContainer>
            <DeviceMongoModal deviceObj={timeTableObjAll[timeTableObj2.timeTableID]}>
              <Typography variant="caption">{timeTableObj2.timeTableID}</Typography>
            </DeviceMongoModal>
          </AdminContainer>

          <AdminContainer>
            <div>{cronStrS}</div>
            <Button
              size="small"
              variant="outlined"
              onClick={() => manualExecuteTimeTable(timeTableObj2)}
            >
              Manual execute
            </Button>
          </AdminContainer>
          <TimePicker
            sx={{ margin: "2vh 0" }}
            label="Start time"
            value={momentS}
            onChange={(newValue) => setMomentS(newValue)}
            ampm={false}
          />

          <DivInline sx={{ marginTop: "1vh" }}>
            {Object.keys(weekDayIsoMenu).map((i) => {
              return (
                <Wbutton
                  key={i}
                  content={weekDayIsoMenu[i]}
                  selected={weekArrS?.includes(Number(i))}
                  onClick={() => {
                    let newArr = [];
                    if (!weekArrS?.includes(Number(i))) {
                      newArr = [...weekArrS, Number(i)];
                    } else {
                      newArr = weekArrS.filter((j) => j !== Number(i));
                    }
                    newArr = newArr.sort((a, b) => a - b);
                    setWeekArrS(newArr);
                  }}
                />
              );
            })}
          </DivInline>
          <Wbutton
            content="All week"
            width="6vw"
            selected={gs.arrayEquals(weekArrS || [], [1, 2, 3, 4, 5, 6, 7])}
            onClick={() => {
              setWeekArrS([1, 2, 3, 4, 5, 6, 7]);
            }}
          />
        </DivCol>
        <ArrowForward />
        <DivCol>
          <Tooltip title={timeTableObj1.timeTableID || ""}>
            <Typography>Timer End</Typography>
          </Tooltip>
          <AdminContainer>
            <DeviceMongoModal deviceObj={timeTableObjAll[timeTableObj1.timeTableID]}>
              <Typography variant="caption">{timeTableObj1.timeTableID}</Typography>
            </DeviceMongoModal>
          </AdminContainer>
          <AdminContainer>
            <div>{cronStrE}</div>
            <Button
              size="small"
              variant="outlined"
              onClick={() => manualExecuteTimeTable(timeTableObj1)}
            >
              Manual execute
            </Button>
          </AdminContainer>
          <TimePicker
            sx={{ margin: "2vh 0" }}
            label="End time"
            value={momentE}
            onChange={(newValue) => setMomentE(newValue)}
            ampm={false}
          />
          <DivInline sx={{ marginTop: "1vh" }}>
            {Object.keys(weekDayIsoMenu).map((i) => {
              return (
                <Wbutton
                  key={i}
                  content={weekDayIsoMenu[i]}
                  selected={weekArrE?.includes(Number(i))}
                  onClick={() => {
                    let newArr = [];
                    if (!weekArrE?.includes(Number(i))) {
                      newArr = [...weekArrE, Number(i)];
                    } else {
                      newArr = weekArrE?.filter((j) => j !== Number(i));
                    }
                    newArr = newArr.sort((a, b) => a - b);
                    setWeekArrE(newArr);
                  }}
                />
              );
            })}
          </DivInline>
          <Wbutton
            content="All week"
            width="6vw"
            selected={gs.arrayEquals(weekArrE || [], [1, 2, 3, 4, 5, 6, 7])}
            onClick={() => {
              setWeekArrE([1, 2, 3, 4, 5, 6, 7]);
            }}
          />
        </DivCol>
      </DivInline>
      <Divider10 />
      <DivInline justifyContent="center" sx={{ marginTop: "1vh" }}>
        <Button
          size="small"
          sx={{ marginRight: "8vw" }}
          variant={excludeHoliday ? "contained" : "outlined"}
          onClick={() => {
            let newStatus = !excludeHoliday;
            setExcludeHoliday(newStatus);
            if (newStatus) setOnlyHoliday(!newStatus);
          }}
        >
          Exclude public holiday
        </Button>
        <Button
          size="small"
          variant={onlyHoliday ? "contained" : "outlined"}
          onClick={() => {
            let newStatus = !onlyHoliday;
            setOnlyHoliday(newStatus);
            if (newStatus) setExcludeHoliday(!newStatus);
          }}
        >
          Only public holiday
        </Button>
      </DivInline>
    </ModalSM>
  );
}

function Wbutton({ content, onClick, selected, width = "4vw" }) {
  const color = selected ? "rgb(159,220,249)" : "rgb(140,140,140)";
  return (
    <SmallButton
      textColor={color}
      borderColor={color}
      height="3vh"
      width={width}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
      btnContent={content}
    />
  );
}
