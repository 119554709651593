import React, { useState, useContext } from "react";
import { Typography, Button } from "@mui/material";
import { SMContext } from "context/smContext";
import { Done } from "@mui/icons-material";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import Divider10 from "components/Divider10";
import { mapAddGateway } from "actions/mapActions";
import IconButtonSave from "components/IconButtonSave";
import Gateway from "asset/svgComp/Gateway";
import DivInline from "components/DivInline";
import { mgFindOne, mgPost } from "actions/mongoApiActions";
import General from "@ecoenghk/general";
const gs = new General();
export default function AddGatewayToMapPanel({ handleClose }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeMapID, mapObjAll, socket } = state;
  const [gatewayID, setGatewayID] = useState("GW");
  const [gatewayObj, setGatewayObj] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const checkGateway = async () => {
    if (mapObjAll[activeMapID]?.gatewayObj?.hasOwnProperty(gatewayID)) {
      alert(`${gatewayID} already in this map`);
    } else {
      const obj = await mgFindOne("gateway", "gatewayID", gatewayID);
      console.log(obj);
      if (obj.result === "fail") {
        console.log(`Gateway [${gatewayID}] not exist in mongo database`);
        const fsGwObj = await mgPost("getDocFS", {
          docPath: `SM_gateway/${gatewayID}`,
        });
        console.log("gatewayObj from firestore", fsGwObj);
        if (fsGwObj.result === "fail") {
          setErrorMsg(`Gateway ID ${gatewayID} not exist in database`);
        } else {
          setErrorMsg("");
          setGatewayObj(fsGwObj);
        }
      } else {
        setErrorMsg("");
        setGatewayObj(obj);
      }
    }
  };
  const handleSave = async () => {
    await mapAddGateway(activeMapID, gatewayID, gatewayObj);
    handleClose();
  };
  return (
    <>
      <Typography variant="h6">Add Gateway to map</Typography>
      <Divider10 />
      <SpaceBetweenInput
        title="Gateway ID"
        data={gatewayID}
        width="40%"
        onInput={(evt) => {
          setGatewayID(evt.target.value);
          setErrorMsg("");
        }}
      />
      <Divider10 />
      <SpaceBetweenDiv
        title=""
        data={
          <Button onClick={checkGateway}>
            <Done />
          </Button>
        }
      />
      {errorMsg && (
        <Typography sx={{ color: "red" }} align="right">
          {errorMsg}
        </Typography>
      )}
      <Divider10 />
      {gatewayObj?.gatewayID && (
        <DivInline justifyContent="center">
          <Gateway width={8} sizeUnit="vw" disabledConnectStatus />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "2vw",
            }}
          >
            <Typography variant="caption">Gateway ID</Typography>
            <Typography variant="subtitle1">{gatewayID}</Typography>
            <Typography variant="caption">mapName</Typography>
            <Typography variant="subtitle1">
              {gatewayObj?.description}
            </Typography>
            <Typography variant="caption">Address</Typography>
            <Typography variant="subtitle1">{gatewayObj?.zigbeeAdd}</Typography>
          </div>
        </DivInline>
      )}
      <Divider10 />
      <DivInline justifyContent="center">
        <IconButtonSave
          onBtnClick={handleSave}
          disabled={!gatewayObj?.gatewayID}
        />
      </DivInline>
    </>
  );
}
