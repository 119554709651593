global.ip = "";
global.socket = null;
global.skymonCloudServerIP = "123.176.96.226:3005";

/*

npm version patch
sh uploadServer.sh

//copy the zip file to compute engine: skymon-localserver folder "downloadFile"




*/
