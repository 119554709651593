import { useState, useRef, useEffect } from "react";
import { Group, Circle } from "react-konva";
import MapIconSensor from "./MapIconSensor";
import { sensorColorDark, deviceConnected } from "actions/generalActions";
import WarningSign from "asset/mapComp/WarningSign";
import { konvaShrinkAndBack } from "actions/konvaActions";
import { updateDevicePos } from "actions/mapActions";
import KonvaLabel from "./KonvaLabel";
import ShiftSelectCircle from "./ShiftSelectCircle";
export default function DeviceSensor({
  layerProps,
  x,
  y,
  sensorID,
  sensorObj,
}) {
  const {
    mapScale,
    mapObjAll,
    activeMapID,
    selectedID,
    gatewayObjAll,
    enablePan,
    dispatch,
    layerRef,
    socket,
    iconSize,
    shiftSelectedArray,
    mapSize,
    layerScale,
    layerPos
  } = layerProps;
  const activeMapObj = mapObjAll[activeMapID];
  const [showID, setShowID] = useState(false);
  const [scale, setScale] = useState(1);
  const [shadEnabled, setShadEnabled] = useState(false);
  const [toRender, setToRender] = useState(true);
  const deviceRef = useRef(null);
  useEffect(() => {
    if (deviceRef.current) {
      const p = deviceRef.current.absolutePosition();
      // setAbPos(p);
      if (p.x < 0 || p.x > mapSize.x || p.y < 0 || p.y > mapSize.y) setToRender(false);
      else setToRender(true);
    }
  }, [layerScale, layerPos]);
  const selected = selectedID === sensorID ? true : false;
  const shiftSelected = (shiftSelectedArray || [])
    .map((obj) => obj.deviceID)
    .includes(sensorID);
  let strokeColor = selected ? sensorColorDark : "";
  const {
    gatewayID,
    type,
    currentStatus,
    currentStatus2,
    currentStatus3,
    currentStatus4,
    luxLv,
    zigbeeConnected,
    timeStamp,
    gang,
    disabledSensor,
    sensorName,
  } = sensorObj || {};
  const handleDragStart = () => {
    setShadEnabled(true);
  };
  const handleDragEnd = async (e) => {
    e.evt.preventDefault();
    const unScaleX = e.target.x() / mapScale.x;
    const unScaleY = e.target.y() / mapScale.y;
    setShadEnabled(false);
    const newX = unScaleX > 1000 ? 970 : unScaleX < 0 ? 30 : unScaleX;
    const newY = unScaleY > 600 ? 570 : unScaleY < 0 ? 30 : unScaleY;
    await updateDevicePos(socket, activeMapID, "sensor", sensorID, newX, newY);
  };

  const gatewayConnected = gatewayObjAll?.[gatewayID]?.gatewayConnected;
  const iconBackground = activeMapObj?.iconBackground || "";

  let alertText = "";
  if (!gatewayID) {
    alertText += "Not connected to any gateway!\n";
  } else {
    if (Object.keys(activeMapObj?.gatewayObj).includes(gatewayID)) {
      if (!gatewayConnected) {
        alertText += "gateway disconnected!\n";
      }
    } else {
      alertText += "gateway not in this map!\n";
    }
  }

  const connected = deviceConnected(
    zigbeeConnected,
    timeStamp,
    gatewayConnected
  );

  function handleClickDevice(e) {
    e.evt.preventDefault();
    // if (!disableClick) {
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
    if (e.evt.shiftKey) {
      if (!shiftSelected) {
        dispatch({
          type: "SHIFT_SELECTED_DEVICE",
          payload: {
            deviceID: sensorID,
            deviceType: "sensor",
            deviceObj: sensorObj,
          },
        });
      } else {
        dispatch({
          type: "SHIFT_UNSELECTED_DEVICE",
          payload: {
            deviceID: sensorID,
          },
        });
      }
    } else {
      dispatch({
        type: "DEVICE_SELECTED",
        payload: {
          deviceID: sensorID,
          deviceType: "sensor",
          deviceObj: sensorObj,
          mapRightMode: "sensor",
          x,
          y,
          layerScale,
        },
      });
    }
    konvaShrinkAndBack(deviceRef, 0.8, 0.15);
  }
  return (
    <>
      <Group
        x={x}
        y={y}
        draggable={!enablePan}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >

        <Group ref={deviceRef}>
          {
            toRender && (<>
              <ShiftSelectCircle
                shiftSelected={shiftSelected}
                iconSize={iconSize}
                scale={scale}
              />
              <MapIconSensor
                type={type}
                status={currentStatus}
                status2={currentStatus2}
                status3={currentStatus3}
                status4={currentStatus4}
                disabledSensor={disabledSensor}
                luxLv={luxLv}
                shadEnabled={shadEnabled}
                layerProps={layerProps}
                onClick={handleClickDevice}
                onMouseOver={(e) => {
                  const layer = layerRef.current;
                  const layerScale = layer.scaleX();
                  setScale(layerScale);
                  setShowID(true);
                  const container = e.target.getStage().container();
                  container.style.cursor = enablePan ? "pointer" : "move";
                }}
                onMouseLeave={(e) => {
                  setShowID(false);
                  const container = e.target.getStage().container();
                  container.style.cursor = enablePan ? "grab" : "default";
                }}
                strokeColor={strokeColor}
                connectStatus={connected}
                timeStamp={timeStamp}
                scale={scale}
                iconBackground={iconBackground}
                gang={gang}
              />
            </>)
          }

        </Group>
        {showID && (
          <KonvaLabel
            x={0}
            y={iconSize * mapScale.y}
            text={sensorName}
            fontSize={12}
            tagColor={sensorColorDark}
            scale={scale}
          />
        )}
        {/* <DivExist show={alertText && !activeMapObj.disableAlert ? true : false}>
          <WarningSign
            x={Number(iconSize * mapScale.x)}
            y={0}
            size={iconSize * mapScale.x * 0.55}
          />
        </DivExist> */}
      </Group>
    </>
  );
}
