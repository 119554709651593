import { useContext } from "react";
import Typography from "@mui/material/Typography";
import { SMContext } from "context/smContext";
import packageJson from "../../package.json";
import SkymonLogo from "asset/svgComp/SkymonLogo";
import DivInline from "components/DivInline";

export default function Footer({ textColor = "#fff" }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        color: textColor,
      }}
    >
      <DivInline style={{ marginLeft: "1vw" }}>
        <SkymonLogo background="dark" width={4} sizeUnit="vw" />
        <Typography sx={{ marginLeft: "0.5vw" }} variant="caption">
          Skymon Systems Limited 2024
        </Typography>
      </DivInline>
      <Typography variant="caption" style={{ marginRight: "2vw" }}>
        SKYLIGHT local webapp ver {packageJson.version}
      </Typography>
    </div>
  );
}
