import { useState, useContext, useMemo, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Typography } from "@mui/material";
// import {  Edit } from "@mui/icons-material";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import Divider10 from "components/Divider10";
import { confirmWindow } from "actions/screenActions";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import { daliDeleteSensor, daliUpdateSensor } from "actions/daliCtlActions";
import ModalSM from "components/ModalSM";
import { mapAddSensor, mapRemoveSensor } from "actions/mapActions";
import SpaceBetweenSelect from "components/SpaceBetweenSelect";
import SensorIcon from "components/SensorIcon";
import { fetchOneSensor } from "actions/sensorActions";
import { updateSensorProperty } from "actions/sensorActions";

export default function EditDaliSensorModal({
  daliSensorObj,
  onClose,
  daliCtlObj,
}) {
  const [state, dispatch] = useContext(SMContext);
  const [sensorType, setSensorType] = useState("");
  const { daliCtlID } = daliCtlObj || {};
  const { activeMapID, sensorObjAll, mapObjAll, socket } = state;
  const { sensorID, shortAdd, instance } = daliSensorObj;
  const mapObj = mapObjAll[activeMapID];
  const sensorObj = sensorObjAll[sensorID];
  useEffect(() => {
    setSensorType(daliSensorObj.type);
  }, [sensorID, daliSensorObj]);

  const handleAddToMap = async () => {
    let sensorObj = await fetchOneSensor(dispatch, sensorID);
    let updateObj = {
      sensorName: `DaliSlave_${sensorID}`,
      type: sensorType,
    };
    if (sensorType === "scenebutton") updateObj = { ...updateObj, gang: 1 };
    if (sensorObj) {
      if (!sensorObj.sensorName) {
        await updateSensorProperty(
          sensorID,
          sensorObj,
          daliCtlObj.gatewayID,
          updateObj
        );
      }
      sensorObj = { ...sensorObj, ...updateObj };
      await mapAddSensor(activeMapID, sensorID, sensorObj);
    }
  };
  const handleDelete = async () => {
    confirmWindow(
      dispatch,
      `Confirm to delete this sensor [short address ${shortAdd}] from dali controller?`,
      async () => {
        await daliDeleteSensor(daliCtlObj, sensorID, shortAdd, instance);
        await mapRemoveSensor(activeMapID, sensorID);
        onClose();
      }
    );
  };

  // const daliRepDec = daliReply?.reply || 0;
  // const daliRepHex = "0x" + daliRepDec.toString(16);
  // const daliRepBin = "0b" + daliRepDec.toString(2);
  // const daliCmdHex = "0x" + daliReply?.daliCmd?.toString(16);
  const handleChangeSensorType = async (e) => {
    const newType = e.target.value;
    setSensorType(newType);
    await daliUpdateSensor(
      socket,
      daliCtlObj,
      sensorID,
      shortAdd,
      instance,
      newType
    );
  };
  return (
    <ModalSM
      open={sensorID ? true : false}
      onClose={onClose}
      modalTitle={`Dali sensor control panel`}
      modalIcon={
        <SensorIcon
          width={4}
          height={4}
          sizeUnit="vh"
          disabledConnectStatus
          type={daliSensorObj?.type}
          status={sensorObj?.status}
          status2={sensorObj?.status2}
          status3={sensorObj?.status3}
          status4={sensorObj?.status4}
          luxLv={sensorObj?.luxLv}
        />
      }
      width="60vw"
      height="60vh"
    >
      <SpaceBetweenDiv title="Sensor ID" data={sensorID} />
      <SpaceBetweenDiv title="Dali short address" data={shortAdd} />
      <SpaceBetweenDiv title="Dali instance" data={instance} />
      <SpaceBetweenSelect
        title="Sensor type"
        data={sensorType || ""}
        onChange={handleChangeSensorType}
        menuObj={{
          motion: "motion",
          daylight: "daylight",
          scenebutton: "scene button",
          lightswitch: "light switch",
        }}
      />
      {Object.keys(mapObj.sensorObj || []).includes(sensorID) ? (
        <SpaceBetweenDiv
          title="In Map"
          data={
            <div>
              {(sensorObjAll[sensorID]?.mapID || [])
                ?.filter((id) => Object.keys(mapObjAll).includes(id))
                ?.map((id) => (
                  <Typography
                    align="right"
                    display="block"
                    variant="caption"
                    key={id}
                  >
                    {mapObjAll[id]?.mapName} [{id}]
                  </Typography>
                ))}
            </div>
          }
        />
      ) : (
        <SpaceBetweenButton
          marginTop="0.5vh"
          title="Add to map"
          btnContent="Add"
          onBtnClick={handleAddToMap}
        />
      )}
      {/* <SpaceBetweenButton
        title="Change short address"
        btnContent="change"
        onBtnClick={handleChangeShortAdd}
      /> */}
      {/* <SpaceBetweenButton
        title="General dali query"
        btnContent="send"
        onBtnClick={handleDaliGeneralQuery}
      /> */}

      {/* <DivExist show={daliReply?.daliCmd && daliRepDec}>
        <DivInline>
          <Box sx={{ width: "70%", outline: "1px solid #fff" }}>
            <Typography>
              Dali command {daliReply?.daliCmd} / {daliCmdHex}
            </Typography>
            <Typography>
              Reply {daliRepDec} / {daliRepHex} / {daliRepBin}
            </Typography>
          </Box>
          <IconButtonDelete onBtnClick={() => clearDaliReply(daliCtlObj)} />
        </DivInline>
      </DivExist> */}

      <Divider10 />
      <SpaceBetweenButton
        title="Delete sensor from dali controller"
        btnContent="delete"
        onBtnClick={handleDelete}
        color="secondary"
      />
    </ModalSM>
  );
}
