/**
 *
 * @param {object} props
 * @param {JSX.Element} props.children
 * @param {boolean} props.show
 * @returns
 */

export default function DivExist({ children, show }) {
  if (show) {
    return <>{children}</>;
  } else {
    return <></>;
  }
}
