import { useContext } from "react";
import { SMContext } from "context/smContext";

export default function AdminContainer({ children }) {
  const [state] = useContext(SMContext);
  const { userObj } = state;
  const { level } = userObj;
  if (level > 0) return null;
  return <>{children}</>;
}
