import moment from "moment";
import { getSchBoxColorText } from "actions/scheduleActions";
import { Typography, Popover, Box } from "@mui/material";

export default function SchBoxBig({ schObj, selected }) {
  const { no, reportType, schedule_timeStamp, rescheduleCount } = schObj;
  const { backgroundColor, tooltipText } = getSchBoxColorText(reportType);

  return (
    <Box
      sx={{
        width: "8vw",
        minHeight: "14vh",
        margin: "0.3vw",
        backgroundColor,
        padding: "0.1vw",
        cursor: "pointer",
        border: selected ? "3px solid red" : "",
      }}
    >
      <Typography variant="subtitle1">{no}</Typography>
      <Typography variant="caption">{reportType}</Typography>

      <Typography display="block" variant="caption">
        {/* <DialogInputScheduleTime
          title={`${description} - ${no} ${reportType}`}
          initialVal={schedule_timeStamp || 0}
          handleSave={handleChangeScheduleTime}
        /> */}

        {moment(schedule_timeStamp).format("YYYY-MM-DD")}
      </Typography>
      <Typography display="block" variant="caption">
        {moment(schedule_timeStamp).format("HH:mm")}
      </Typography>

      {rescheduleCount > 0 && (
        <Typography display="block" variant="caption">
          Reschedule:{rescheduleCount}
        </Typography>
      )}
    </Box>
  );
}
