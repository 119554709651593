import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, TextField, MenuItem } from "@mui/material";
import DivInline from "components/DivInline";
import { getReportTypeAndTestTime } from "actions/scheduleActions";
import DivSpaceBetween from "components/DivSpaceBetween";
import moment from "moment-timezone";
import Divider10 from "components/Divider10";
import ModalSM from "components/ModalSM";
import IconButtonEdit from "components/IconButtonEdit";
import General from "@ecoenghk/general";
import {
  saveTestTimeTable,
  deleteTestTimeTable,
} from "actions/timetableActions";
import DivCol from "components/DivCol";
import TypoCaption from "components/TypoCaption";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import {
  confirmWindow,
  hideBigProgress,
  showBigProgress,
} from "actions/screenActions";
const gs = new General();

export default function EditTestTimeTableModal({
  serial,
  monthTimeTableObj,
  annualTimeTableObj,
  onSave,
}) {
  const [state, dispatch] = useContext(SMContext);
  const { lightObjAll, serverObj, socket } = state;
  const [open, setOpen] = useState(false);
  const [monthTestDate, setMonthTestDate] = useState(1);
  const [monthTestHour, setMonthTestHour] = useState(0);
  const [monthTestMin, setMonthTestMin] = useState(0);
  const [annualTestMonth, setAnnualTestMonth] = useState(1);
  const [annualTestDate, setAnnualTestDate] = useState(1);
  const [annualTestHour, setAnnualTestHour] = useState(0);
  const [annualTestMin, setAnnualTestMin] = useState(0);
  const { batteryHour } = lightObjAll[serial] || {};
  const monthTestDuration = getReportTypeAndTestTime(
    "ed5",
    batteryHour || 2,
    1
  );
  const annualTestDuration = getReportTypeAndTestTime(
    "ed5",
    batteryHour || 2,
    11
  );

  const handleOpen = () => {
    setMonthTestDate(monthTimeTableObj?.date[0]);
    setMonthTestHour(monthTimeTableObj?.hour[0]);
    setMonthTestMin(monthTimeTableObj?.minute[0]);
    setAnnualTestMonth(annualTimeTableObj?.month[0]);
    setAnnualTestDate(annualTimeTableObj?.date[0]);
    setAnnualTestHour(annualTimeTableObj?.hour[0]);
    setAnnualTestMin(annualTimeTableObj?.minute[0]);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    showBigProgress(dispatch);
    let newMonthTestMonthArr = [];
    for (let m = 1; m <= 12; m++) {
      if (m !== annualTestMonth) newMonthTestMonthArr.push(m);
    }
    let newMonthTimeTableObj = {
      ...monthTimeTableObj,
      month: newMonthTestMonthArr,
      date: [monthTestDate || 1],
      hour: [monthTestHour || 0],
      minute: [monthTestMin || 0],
    };
    delete newMonthTimeTableObj._id;
    let newAnnualTimeTableObj = {
      ...annualTimeTableObj,
      month: [annualTestMonth || 1],
      date: [annualTestDate || 1],
      hour: [annualTestHour || 0],
      minute: [annualTestMin || 0],
    };
    delete newAnnualTimeTableObj._id;
    console.log("newMonthTimeTableObj", newMonthTimeTableObj);
    console.log("newAnnualTimeTableObj", newAnnualTimeTableObj);
    await saveTestTimeTable(
      socket,
      serial,
      batteryHour,
      newMonthTimeTableObj,
      newAnnualTimeTableObj
    );
    onSave(newMonthTimeTableObj, newAnnualTimeTableObj);
    hideBigProgress(dispatch);
    handleClose();
  };
  const handleDelete = async () => {
    confirmWindow(dispatch, "Delete this schedued test?", async () => {
      await deleteTestTimeTable(
        monthTimeTableObj?.scheduleID,
        annualTimeTableObj?.scheduleID
      );
      await gs.waitFor(500);
      // await refresh();
      handleClose();
    });
  };
  return (
    <>
      <IconButtonEdit tooltip="Edit light test time" onBtnClick={handleOpen} />
      <ModalSM
        open={open}
        onClose={handleClose}
        minWidth="60vw"
        minHeight="95vh"
        maxHeight="95vh"
        modalTitle={`Edit test time table for [${serial}]`}
        onSave={handleSave}
        onDelete={handleDelete}
        disabledDelete={!monthTimeTableObj && !annualTimeTableObj}
      >
        <SpaceBetweenDiv
          title="Monthly Test"
          data={`Schedule ID: ${monthTimeTableObj?.scheduleID || "-"}`}
        />
        <Divider10 />
        <DivSpaceBetween>
          <Typography>Date of each month</Typography>
          <TextField
            size="small"
            select
            value={monthTestDate || 1}
            onChange={(e) => setMonthTestDate(e.target.value)}
          >
            {gs.newArrayBetween(1, 28).map((d) => (
              <MenuItem key={d} value={d}>
                {d < 10 ? "0" + d : d}
              </MenuItem>
            ))}
          </TextField>
        </DivSpaceBetween>

        <DivSpaceBetween>
          <Typography>Time</Typography>
          <DivInline>
            <TextField
              size="small"
              select
              value={monthTestHour || 0}
              onChange={(e) => setMonthTestHour(e.target.value)}
            >
              {gs.newArrayBetween(0, 23).map((h) => (
                <MenuItem key={h} value={h}>
                  {h < 10 ? "0" + h : h}
                </MenuItem>
              ))}
            </TextField>
            <Typography sx={{ margin: "1vw" }} variant="h5">
              :
            </Typography>
            <TextField
              size="small"
              select
              value={monthTestMin || 0}
              onChange={(e) => setMonthTestMin(e.target.value)}
            >
              {gs.newArrayBetween(0, 59).map((m) => (
                <MenuItem key={m} value={m}>
                  {m < 10 ? "0" + m : m}
                </MenuItem>
              ))}
            </TextField>
          </DivInline>
        </DivSpaceBetween>
        <DivCol alignItems="flex-end">
          <TypoCaption>Battery hour: {batteryHour || 2 + " hours"}</TypoCaption>
          <TypoCaption>
            Data interval: {monthTestDuration?.time_interval} second
          </TypoCaption>
          <TypoCaption>
            Time pretest: {monthTestDuration?.time_pretest} min
          </TypoCaption>
          <TypoCaption>
            Time cut off: {monthTestDuration?.time_cutOff} min
          </TypoCaption>
          <TypoCaption>
            Time recharge: {monthTestDuration?.time_recharge} min
          </TypoCaption>
        </DivCol>
        <Divider10 />
        <SpaceBetweenDiv
          title="Annual Test"
          data={`Schedule ID: ${annualTimeTableObj?.scheduleID || "-"}`}
        />
        <Divider10 />
        <DivSpaceBetween>
          <Typography>Month</Typography>
          <TextField
            size="small"
            select
            value={annualTestMonth || 1}
            onChange={(e) => setAnnualTestMonth(e.target.value)}
          >
            {gs.newArrayBetween(1, 12).map((m) => (
              <MenuItem key={m} value={m}>
                {moment()
                  .month(m - 1)
                  .format("MMM")}
              </MenuItem>
            ))}
          </TextField>
        </DivSpaceBetween>
        <DivSpaceBetween>
          <Typography>Date</Typography>
          <TextField
            size="small"
            select
            value={annualTestDate || 1}
            onChange={(e) => setAnnualTestDate(e.target.value)}
          >
            {gs.newArrayBetween(1, 28).map((d) => (
              <MenuItem key={d} value={d}>
                {d < 10 ? "0" + d : d}
              </MenuItem>
            ))}
          </TextField>
        </DivSpaceBetween>

        <DivSpaceBetween>
          <Typography>Time</Typography>
          <DivInline>
            <TextField
              size="small"
              select
              value={annualTestHour || 0}
              onChange={(e) => setAnnualTestHour(e.target.value)}
            >
              {gs.newArrayBetween(0, 23).map((h) => (
                <MenuItem key={h} value={h}>
                  {h < 10 ? "0" + h : h}
                </MenuItem>
              ))}
            </TextField>
            <Typography sx={{ margin: "1vw" }} variant="h5">
              :
            </Typography>
            <TextField
              size="small"
              select
              value={annualTestMin || 0}
              onChange={(e) => setAnnualTestMin(e.target.value)}
            >
              {gs.newArrayBetween(0, 59).map((m) => (
                <MenuItem key={m} value={m}>
                  {m < 10 ? "0" + m : m}
                </MenuItem>
              ))}
            </TextField>
          </DivInline>
        </DivSpaceBetween>
        <DivCol alignItems="flex-end">
          <TypoCaption>Battery hour: {batteryHour || 2 + " hours"}</TypoCaption>
          <TypoCaption>
            Data interval: {annualTestDuration?.time_interval} second
          </TypoCaption>
          <TypoCaption>
            Time pretest: {annualTestDuration?.time_pretest} min
          </TypoCaption>
          <TypoCaption>
            Time cut off: {annualTestDuration?.time_cutOff} min
          </TypoCaption>
          <TypoCaption>
            Time recharge: {annualTestDuration?.time_recharge} min
          </TypoCaption>
        </DivCol>
      </ModalSM>
    </>
  );
}
