import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { ArrowCircleDown, ArrowForward, Block, CheckCircleOutline, Lock, LockOpen, MultipleStop, ArrowCircleUp, Repeat } from "@mui/icons-material";
import DeviceIcon from "./DeviceIcon";
import DivInline from "./DivInline";
import { Typography, Tooltip } from "@mui/material";
import Space10 from "./Space10";
import { purple, green } from "@mui/material/colors";
import SensorIcon from "./SensorIcon";
import LightIcon from "./LightIcon";
import DivExist from "./DivExist";
import IconIntermediateAction from "asset/iconPng/icon_intermediate_action.png";
import React from "react";
import ModalSM from "./ModalSM";
import DivCol from "./DivCol";
import Divider10 from "./Divider10";
import General from "@ecoenghk/general";
import ZoneControlIcon from "./ZoneControlIcon";
const gs = new General();


export default function DeviceActionLine({ deviceSetting, deviceSetting2, deviceObj, mainDeviceObj, buttonNo = 1 }) {
    const { commandCode } = deviceSetting || {};
    let mainSensorObj, mainZoneControObj;
    if (mainDeviceObj.zoneControlID) mainZoneControObj = mainDeviceObj;
    else mainSensorObj = mainDeviceObj;
    const mainType = mainSensorObj?.type || mainZoneControObj?.type;
    const { gang = 1 } = mainSensorObj || {};
    if (commandCode === 0x062c) {
        return (
            <SettingModal deviceSetting={deviceSetting} deviceSetting2={deviceSetting2}>
                <DivInline>
                    <DeviceIcon deviceType="sensor" deviceStyle={deviceObj.type} width={2} sizeUnit="vw" />
                    <Space10 />
                    <Tooltip title="device enable">
                        <CheckCircleOutline sx={{ color: green[300] }} />
                    </Tooltip>

                    <DivExist show={deviceSetting.delaySec > 0}>
                        <Space10 />
                        <ArrowForward />
                        <Space10 />
                        <Typography sx={{ outline: "1px solid #fff", padding: '2px' }} variant="body2">{deviceSetting.delaySec}s</Typography>
                        <Space10 />
                        <ArrowForward />
                        <Space10 />
                        <Tooltip title="device disable">
                            <Block sx={{ color: purple[200] }} />
                        </Tooltip>
                    </DivExist>
                </DivInline>
            </SettingModal>
        )
    }
    if (commandCode === 0x062d) {
        return (
            <SettingModal deviceSetting={deviceSetting} deviceSetting2={deviceSetting2}>
                <DivInline>
                    <DeviceIcon deviceType="sensor" deviceStyle={deviceObj.type} width={2} sizeUnit="vw" />
                    <Space10 />
                    <Tooltip title="device disable">
                        <Block sx={{ color: purple[200] }} />
                    </Tooltip>
                    <DivExist show={deviceSetting.delaySec > 0}>
                        <Space10 />
                        <ArrowForward />
                        <Space10 />
                        <Typography sx={{ outline: "1px solid #fff", padding: '2px' }} variant="body2">{deviceSetting.delaySec}s</Typography>
                        <Space10 />
                        <ArrowForward />
                        <Space10 />
                        <Tooltip title="device enable">
                            <CheckCircleOutline sx={{ color: green[300] }} />
                        </Tooltip>

                    </DivExist>
                </DivInline>
            </SettingModal>
        )
    }
    if (commandCode === 0x0204 || commandCode === 0x0203 || commandCode === 0x0202 || commandCode === 0x0a09) {
        const { lockLevel, releaseOnDelayEnd, delaySec, delaySec2, delayed_action, delayed_action2 } = deviceSetting;
        return (
            <SettingModal deviceSetting={deviceSetting} deviceSetting2={deviceSetting2}>
                <DivInline justifyContent="space-between" >
                    <DivInline>
                        <Lock />
                        <Typography variant="caption">{deviceSetting.lockLevel}</Typography>
                    </DivInline>
                    {
                        mainSensorObj ?
                            <SensorIcon status={buttonNo === 1 ? 1 : 0} status2={buttonNo === 2 ? 1 : 0} status3={buttonNo === 3 ? 1 : 0} status4={buttonNo === 4 ? 1 : 0}
                                type={mainType}
                                width={2}
                                sizeUnit="vw"
                                disabledConnectStatus
                                gang={gang}
                            />
                            : mainZoneControObj ?
                                <ZoneControlIcon type={mainType} width={2} sizeUnit="vw" />
                                : null
                    }
                    <ArrowForward sx={{ margin: "0 3px" }} />
                    <LightIconWithLv lightLv={deviceSetting.pwm} lightStyle={deviceObj.style} lightObj={deviceObj} />

                    <DivExist show={(delaySec || delayed_action) > 0}>
                        <ArrowForward sx={{ margin: "0 3px" }} />
                        <Typography sx={{ outline: "1px solid #fff", padding: '2px' }} variant="body2">{delaySec || delayed_action}s</Typography>
                        <ArrowForward sx={{ margin: "0 3px" }} />
                        {(delaySec2 || delayed_action2) > 0 ?
                            <img src={IconIntermediateAction} width={30} height={30} alt="" />
                            :
                            mainSensorObj ?
                                <SensorIcon status={0} status2={0} status3={0} status4={0} type={mainType} width={2} sizeUnit="vw" disabledConnectStatus gang={gang} />
                                : mainZoneControObj ?
                                    <ZoneControlIcon type={mainType} width={2} sizeUnit="vw" />
                                    : null
                        }
                        <LightIconWithLv lightLv={deviceSetting.delayedPwm} lightStyle={deviceObj.style} lightObj={deviceObj} />
                        <DivExist show={(delaySec2 || delayed_action2) > 0}>
                            <ArrowForward sx={{ margin: "0 3px" }} />
                            <Typography sx={{ outline: "1px solid #fff", padding: '2px' }} variant="body2">{delaySec2 || deviceSetting.delayed_action2}s</Typography>
                            <ArrowForward sx={{ margin: "0 3px" }} />
                            {mainSensorObj ?
                                <SensorIcon status={0} status2={0} status3={0} status4={0} type={mainType} width={2} sizeUnit="vw" disabledConnectStatus gang={gang} />
                                : mainZoneControObj ?
                                    <ZoneControlIcon type={mainType} width={2} sizeUnit="vw" />
                                    : null
                            }
                            <LightIconWithLv lightLv={deviceSetting.delayedPwm2} lightStyle={deviceObj.style} lightObj={deviceObj} />
                        </DivExist>
                    </DivExist>
                    {releaseOnDelayEnd === 1 || lockLevel >= 5 ? (
                        <Tooltip title="Release light on action end">
                            <LockOpen />
                        </Tooltip>
                    ) : (
                        <Tooltip title={`Lock light to level ${lockLevel} on action end`}>
                            <Typography variant="caption">
                                <Lock />
                                {lockLevel === 100 ? 5 : lockLevel}
                            </Typography>
                        </Tooltip>
                    )}
                </DivInline>
            </SettingModal>
        )
    }
    if (commandCode === 0x0205 || commandCode === 0x0a34 || commandCode === 0x0a36) { //toggle
        return (
            <SettingModal deviceSetting={deviceSetting} deviceSetting2={deviceSetting2}>
                <DivInline>
                    <Lock />
                    <Typography variant="caption">{deviceSetting.lockLevel}</Typography>
                    <SensorIcon
                        status={buttonNo === 1 ? 1 : 0}
                        status2={buttonNo === 2 ? 1 : 0}
                        status3={buttonNo === 3 ? 1 : 0}
                        status4={buttonNo === 4 ? 1 : 0}
                        type={mainSensorObj?.type}
                        width={2}
                        sizeUnit="vw"
                        disabledConnectStatus
                        gang={mainSensorObj.gang}
                    />
                    <LightIcon statusA={0}
                        lightStyle={deviceObj.style}
                        lightLv={100}
                        width={2}
                        sizeUnit="vw"
                        disabledConnectStatus
                    />
                    <Repeat />
                    <LightIcon statusA={1}
                        lightStyle={deviceObj.style}
                        lightLv={0}
                        width={2}
                        sizeUnit="vw"
                        disabledConnectStatus
                    />
                </DivInline>
            </SettingModal>
        )
    }
    if (commandCode === 0x0611 && mainType === "timer") {
        return (
            <SettingModal deviceSetting={deviceSetting} deviceSetting2={deviceSetting2}>
                <DivInline>
                    <Typography>Start</Typography>
                    <Space10 />
                    <DeviceIcon deviceType="sensor" deviceStyle={deviceObj.type} width={2} sizeUnit="vw" />
                    {
                        deviceSetting2.disabledSensor === 0x33 ?
                            <Tooltip title="device disable">
                                <Block sx={{ color: purple[200] }} />
                            </Tooltip> :
                            <Tooltip title="device enable">
                                <CheckCircleOutline sx={{ color: green[300] }} />
                            </Tooltip>
                    }

                    <Space10 />
                    <ArrowForward />

                    <Space10 />
                    <Typography>End</Typography>

                    <Space10 />
                    <DeviceIcon deviceType="sensor" deviceStyle={deviceObj.type} width={2} sizeUnit="vw" />
                    {
                        deviceSetting.disabledSensor === 0x33 ?
                            <Tooltip title="device disable">
                                <Block sx={{ color: purple[200] }} />
                            </Tooltip> :
                            <Tooltip title="device enable">
                                <CheckCircleOutline sx={{ color: green[300] }} />
                            </Tooltip>
                    }


                </DivInline>
            </SettingModal>
        )
    }
    if (commandCode === 0x0235 || commandCode === 0x0a33 || commandCode === 0x0a35) { //step dim up/down
        return (
            <SettingModal deviceSetting={deviceSetting} deviceSetting2={deviceSetting2}>
                <DivInline>
                    <SensorIcon
                        status={1}
                        status2={0}
                        type="dimcontroller"
                        width={2}
                        sizeUnit="vw"
                        disabledConnectStatus
                    />

                    {deviceSetting?.stepUp ? <ArrowCircleUp /> : <ArrowCircleDown />}
                    <Typography variant="caption"> {deviceSetting?.dimStepSize}%</Typography>
                    <MultipleStop />
                    <SensorIcon
                        status={0}
                        status2={1}
                        type="dimcontroller"
                        width={2}
                        sizeUnit="vw"
                        disabledConnectStatus
                    />

                    {deviceSetting2?.stepUp ? <ArrowCircleUp /> : <ArrowCircleDown />}
                    <Typography variant="caption"> {deviceSetting2?.dimStepSize}%</Typography>
                </DivInline>
            </SettingModal>
        )
    }


    return (
        <SettingModal deviceSetting={deviceSetting} deviceSetting2={deviceSetting2}>
            <Typography>Unknown Action</Typography>
        </SettingModal>
    )

}

function SettingModal({ children, deviceSetting, deviceSetting2 }) {
    const [state] = useContext(SMContext);
    const [open, setOpen] = useState(false);
    const cmd = gs.jsonCommandToDtk(deviceSetting || {});
    const cmd2 = gs.jsonCommandToDtk(deviceSetting2 || {});
    const commandName = gs.dtKStrToJson(cmd || "")?.commandName;
    const commandName2 = gs.dtKStrToJson(cmd2 || "")?.commandName;
    return (
        <>
            <div onClick={() => {
                if (state.userObj.level < 1) setOpen(true);
            }}>
                {children}
            </div>
            <ModalSM
                open={open}
                onClose={() => setOpen(false)}
                modalTitle="Device Setting"
                disableBottomClose
                width="70vw" height="75vh"
            >
                <DivInline>
                    <DivCol sx={{ width: "50%" }} alignItems="flex-start">
                        <Typography>Setting1: Command code:0x{deviceSetting?.commandCode?.toString(16).padStart(4, "0")}</Typography>
                        <Typography>{commandName}</Typography>
                        <Divider10 />
                        {
                            Object.keys(deviceSetting || {})
                                .sort((a, b) => a.localeCompare(b))
                                .map((key) =>
                                    <Typography key={key}>{key}:{deviceSetting[key]}</Typography>
                                )
                        }
                        <Divider10 />
                        <Typography>{cmd}</Typography>
                    </DivCol>
                    <DivCol sx={{ width: "50%" }} hide={!deviceSetting2} alignItems="flex-start">
                        <Typography>Setting2: Command code:0x{deviceSetting2?.commandCode?.toString(16).padStart(4, "0")}</Typography>
                        <Typography>{commandName2}</Typography>
                        <Divider10 />
                        {
                            Object.keys(deviceSetting2 || {})
                                .sort((a, b) => a.localeCompare(b))
                                .map((key) =>
                                    <Typography key={key}>{key}:{deviceSetting2[key]}</Typography>
                                )
                        }
                        <Divider10 />
                        <Typography>{cmd2}</Typography>
                    </DivCol>
                </DivInline>
            </ModalSM>
        </>
    )

}
function LightIconWithLv({ lightLv, lightStyle, width = 2, lightObj }) {
    return (
        <DivInline>
            <LightIcon statusA={lightLv > 0 ? 0 : 1}
                lightStyle={lightStyle}
                lightLv={lightLv}
                width={width}
                sizeUnit="vw"
                disabledConnectStatus
            />
            <DivExist show={lightObj?.type?.pwm && lightLv > 0}>
                <Typography variant="caption">{lightLv}%</Typography>
            </DivExist>
        </DivInline>
    )
}