import { useContext } from "react";
import { SMContext } from "context/smContext";
import {
  Money,
  AvTimer,
  WifiTethering,
  TextFields,
  Contactless,
  Layers,
  Share,
} from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import AdminContainer from "components/AdminContainer";

export default function MapLeftToolbar() {
  const [state, dispatch] = useContext(SMContext);
  const { mapLayer } = state;

  return (
    <>
      <Tooltip
        title={<Typography>toogle device serial number</Typography>}
        placement="right"
      >
        <IconButton
          style={{ marginTop: 50 }}
          color={mapLayer.serialNo ? "warning" : "inherit"}
          onClick={() =>
            dispatch({
              type: "SET_MAP_LAYER",
              payload: {
                serialNo: !mapLayer.serialNo,
              },
            })
          }
          size="large"
        >
          <Money />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={<Typography>toogle device name</Typography>}
        placement="right"
      >
        <IconButton
          color={mapLayer.deviceName ? "warning" : "inherit"}
          onClick={() =>
            dispatch({
              type: "SET_MAP_LAYER",
              payload: {
                deviceName: !mapLayer.deviceName,
              },
            })
          }
          size="large"
        >
          <TextFields />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={<Typography>Light priority level</Typography>}
        placement="right"
      >
        <IconButton
          color={mapLayer.lockLevel ? "warning" : "inherit"}
          onClick={() =>
            dispatch({
              type: "SET_MAP_LAYER",
              payload: {
                lockLevel: !mapLayer.lockLevel,
              },
            })
          }
          size="large"
        >
          <Layers />
        </IconButton>
      </Tooltip>
      {/* <Tooltip
        title={<Typography>toogle device address</Typography>}
        placement="right"
      >
        <IconButton
          color={mapLayer.zigbeeAdd ? "warning" : "inherit"}
          onClick={() =>
            dispatch({
              type: "SET_MAP_LAYER",
              payload: {
                zigbeeAdd: !mapLayer.zigbeeAdd,
              },
            })
          }
          size="large"
        >
          <WifiTethering />
        </IconButton>
      </Tooltip> */}

      <Tooltip
        title={<Typography>toogle device latest alive time</Typography>}
        placement="right"
      >
        <IconButton
          color={mapLayer.dataTime ? "warning" : "inherit"}
          onClick={
            () =>
              dispatch({
                type: "SET_MAP_LAYER",
                payload: {
                  dataTime: !mapLayer.dataTime,
                },
              })
            // dispatch({
            //   type: "SET_MAP_DISPLAY_INFO",
            //   payload: {
            //     infoType: "dataTime",
            //     status: !mapDisplayInfo.dataTime,
            //   },
            // })
          }
          size="large"
        >
          <AvTimer />
        </IconButton>
      </Tooltip>
      {/* <Tooltip title="device error message">
        <IconButton
          color={mapDisplayInfo.error ? "warning" : "inherit"}
          onClick={() =>
            dispatch({
              type: "SET_MAP_DISPLAY_INFO",
              payload: {
                infoType: "error",
                status: !mapDisplayInfo.error,
              },
            })
          }
        >
          <Warning />
        </IconButton>
      </Tooltip> */}
      <AdminContainer>
        {/* <Tooltip
          title={<Typography>RSSI diagram (old)</Typography>}
          placement="right"
        >
          <IconButton
            color={mapMode === mapModeStr.rssiLine ? "warning" : "inherit"}
            onClick={() =>
              dispatch({
                type: "SET_MAP_MODE",
                payload:
                  mapMode === mapModeStr.rssiLine
                    ? mapModeStr.normal
                    : mapModeStr.rssiLine,
              })
            }
          >
            <Contactless />
          </IconButton>
        </Tooltip> */}

        <Tooltip title="Mesh255 strength info">
          <IconButton
            color={mapLayer.rssi255Web ? "warning" : "inherit"}
            onClick={() =>
              dispatch({
                type: "SET_MAP_LAYER",
                payload: {
                  normal: mapLayer.rssi255Web,
                  rssi255Web: !mapLayer.rssi255Web
                },
              })
            }
            size="large"
          >
            <Contactless />
          </IconButton>
        </Tooltip>
        <Tooltip title="ESP now route info">
          <IconButton
            color={mapLayer.espWeb ? "warning" : "inherit"}
            onClick={() => {
              dispatch({
                type: "ALL_UNSELECTED"
              });
              dispatch({
                type: "SET_MAP_LAYER",
                payload: {
                  normal: mapLayer.espWeb,
                  espWeb: !mapLayer.espWeb,
                },
              });
            }
            }
            size="large"
          >
            <Share />
          </IconButton>
        </Tooltip>
      </AdminContainer>
    </>
  );
}
