import { useState, useContext } from "react";
import { Typography } from "@mui/material";
import { SMContext } from "context/smContext";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import Divider10 from "components/Divider10";
// import { addDaliCtlToMap } from "actions/mapActions";
import IconButtonSave from "components/IconButtonSave";
import { alertWindow } from "actions/screenActions";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
import IconButtonDone from "components/IconButtonDone";
import DaliCtl from "asset/svgComp/DaliCtl";
import { mgFindOne, mgPost } from "actions/mongoApiActions";
import { mapAddDaliCtl } from "actions/mapActions";
import General from "@ecoenghk/general";
const gs = new General();

export default function AddDaliCtlToMapPanel({ handleClose }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeMapID, mapObjAll } = state;
  const activeMapObj = mapObjAll[activeMapID];
  const [daliCtlID, setDaliCtlID] = useState("");
  const [daliCtlObj, setDaliCtlObj] = useState({});
  const checkDaliCtl = async () => {
    if (activeMapObj?.daliCtlObj?.hasOwnProperty(daliCtlID)) {
      alertWindow(dispatch, `${daliCtlID} already in this map`);
    } else {
      const obj = await mgFindOne("daliCtl", "daliCtlID", daliCtlID);
      if (obj.result === "fail") {
        console.log(`DaliCtl ID [${daliCtlID}] not exist in mongo database`);
        const fsDaliCtlObj = await mgPost("getDocFS", {
          docPath: `SM_daliCtl/${daliCtlID}`,
        });
        console.log("daliCtlObj from firestore", fsDaliCtlObj);
        if (fsDaliCtlObj.result === "fail") {
          alertWindow(
            dispatch,
            `Dali controller ID ${daliCtlID} not exist in database`
          );
        } else {
          setDaliCtlObj(fsDaliCtlObj);
        }
      } else {
        setDaliCtlObj(obj);
      }
    }
  };
  const handleSave = async () => {
    await mapAddDaliCtl(activeMapID, daliCtlID, daliCtlObj);
    handleClose();
  };
  return (
    <>
      <Typography variant="h6">Add Dali controller to map</Typography>
      <Divider10 />
      <SpaceBetweenInput
        title="Dali controller ID"
        data={daliCtlID}
        width="40%"
        onInput={(evt) => setDaliCtlID(evt.target.value)}
        autoSelect
      />
      <DivInline justifyContent="flex-end">
        <IconButtonDone onBtnClick={checkDaliCtl} />
      </DivInline>

      <Divider10 />
      {daliCtlObj?.daliCtlID && (
        <DivInline justifyContent="center" alignItems="center">
          <div
            style={{ border: "1px solid #fff", margin: 20, borderRadius: 10 }}
          >
            <DaliCtl disabledConnectStatus width={8} sizeUnit="vw" />
          </div>
          <DivCol>
            <Typography variant="caption">ID</Typography>
            <Typography variant="subtitle1">{daliCtlObj?.daliCtlID}</Typography>
            <Typography variant="caption">Description</Typography>
            <Typography variant="subtitle1">
              {daliCtlObj.description}
            </Typography>
            {/* <Typography variant="caption">Address</Typography>
            <Typography variant="subtitle1">{gatewayObj.zigbeeAdd}</Typography> */}
          </DivCol>
        </DivInline>
      )}
      <Divider10 />
      <DivInline justifyContent="center">
        <IconButtonSave
          onBtnClick={handleSave}
          disabled={!daliCtlObj.daliCtlID}
        />
      </DivInline>
    </>
  );
}
