import { Group, Path, Ellipse } from "react-konva";

export default function SensorGeneral({ x, y, size }) {
  const scale = size / 79.375;
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale}>
      <Path
        fill="#42c8db"
        stroke="#42c8db"
        strokeWidth={5.922}
        data="M51.747 59.993c-.61-.084-1.787-.333-2.618-.553-4.294-1.136-7.738-3.249-10.621-6.514-2.313-2.618-3.703-5.28-4.523-8.663-.336-1.387-.386-1.92-.389-4.216-.003-2.32.042-2.813.385-4.215 1.916-7.83 7.828-13.493 16.108-15.43 2.29-.534 6.676-.54 9.079-.012 8.158 1.796 14.246 7.619 16.146 15.442.338 1.393.385 1.911.385 4.216s-.047 2.822-.385 4.215c-.823 3.386-2.212 6.046-4.524 8.664-3.042 3.443-6.847 5.672-11.507 6.741-1.732.397-5.757.571-7.535.326z"
      ></Path>
      <Group strokeWidth={0.269}>
        <Ellipse
          x={54.738}
          y={39.894}
          fill="#a6e8f0"
          radiusX={15.137}
          radiusY={14.46}
        ></Ellipse>
        <Ellipse
          cx={54.771}
          cy={39.752}
          fill="#fd871a"
          radiusX={8.013}
          radiusY={7.408}
        ></Ellipse>
        <Path
          fill="#fd871a"
          data="M1.22 36.084h15.535l4.204 9.952L33.022 4.48l11.332 31.43h6.58l3.107 3.142-2.925 4.54H38.87l-5.117-13.095-11.332 39.46-11.88-26.364-8.956-.175z"
        ></Path>
      </Group>
    </Group>
  );
}
