import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button } from "@mui/material";
import SensorIcon from "components/SensorIcon";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import General from "@ecoenghk/general";
import { sensorManualExecuteSetting } from "actions/sensorActions";
import DivCol from "components/DivCol";
import ButtonSM from "components/ButtonSM";
const gs = new General();

export default function ManualControlPanelSceneButton() {
  const [state] = useContext(SMContext);
  const { activeSensorID, sensorObjAll } = state;
  const sensorObj = sensorObjAll[activeSensorID];
  const { type, gang } = sensorObj || {};
  return (
    <div>
      <Divider10 />
      <Typography variant="h6" display="block" align="center">
        Manual Control
      </Typography>
      <Divider10 />
      <DivInline justifyContent="space-around">
        {gs.newArrayBetween(1, gang || 1).map((n) => {
          const disabled =
            Object.keys(sensorObj[`setting${n}`] || {}).length === 0
              ? true
              : false;
          return (
            // <DivCol alignItems="center">
            <ButtonSM
              // variant="outlined"
              disabled={disabled}
              key={n}
              margin="1vw"
              onClick={() => sensorManualExecuteSetting(sensorObj, n)}
              contentDirection="column"
              padding="1vh 2vw"
            >
              <SensorIcon
                gang={gang}
                type={type}
                width={7}
                sizeUnit="vh"
                disabledConnectStatus
                status={n === 1 ? 1 : 0}
                status2={n === 2 ? 1 : 0}
                status3={n === 3 ? 1 : 0}
              />
              <Typography>{`Button ${n}`}</Typography>
            </ButtonSM>
            // </DivCol>
          );
        })}
      </DivInline>
    </div>
  );
}
