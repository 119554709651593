import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Paper } from "@mui/material";
import DivInline from "components/DivInline";
import ZoneControlIcon from "components/ZoneControlIcon";
import ZcInfoPanel from "./ZcInfoPanel";
import ControlDevicesZcTimerPanel from "./ControlDevicesZcTimerPanel";
import ZcPanelTimeSetting from "./ZcPanelTimeSetting";
import ModalSM from "components/ModalSM";
import DivExist from "components/DivExist";
import ZcDetailSceneBtnDelayPanel from "./ZcDetailSceneBtnDelayPanel";
import ControlDevicesZcSceneButtonPanel from "./ControlDevicesZcSceneButtonPanel";
import DeviceMongoModal from "components/DeviceMongoModal";
import ZcSuperAdminPanel from "./ZcSuperAdminPanel";
import Divider10 from "components/Divider10";
import { zoneCtlDefault } from "asset/string/string";
import ControlDevicesZcSwitchPanel from "./ControlDevicesZcSwitchPanel";
import ZcDetailSceneBtnControlPanel from "./ZcDetailSceneBtnControlPanel";
export default function ZoneControlDetailModal() {
  const [state, dispatch] = useContext(SMContext);
  const { activeZoneControlID, zoneControlObjAll, openZoneControlDetailModal } =
    state;
  const zcObj = zoneControlObjAll[activeZoneControlID];
  const { zoneControlName, type } = zcObj || {};
  const handleClose = () => {
    dispatch({ type: "CLOSED_ZC_DETAIL_MODAL" });
  };
  const typeName = zoneCtlDefault[type]?.typeName || "";

  return (
    <ModalSM
      open={openZoneControlDetailModal}
      onClose={handleClose}
      minWidth="98vw"
      minHeight="96vh"
      disableBottomClose
      modalTitle={`${typeName} - ${zoneControlName}`}
      modalIcon={
        <DeviceMongoModal deviceObj={zcObj}>
          <ZoneControlIcon
            width={3.5}
            sizeUnit="vw"
            type={type}
            plateColor={zcObj?.plateColor}
            status={zcObj?.activeSetting === 2 ? 1 : 0}
            tooltip={activeZoneControlID}
          />
        </DeviceMongoModal>
      }
    >
      <DivInline>
        <div
          style={{
            width: "33vw",
            height: "84vh",
            margin: "0.3vw",
            padding: "0.5vw",
            border: "0.5px solid grey",
            borderRadius: "1vw",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ZcInfoPanel />
          <Divider10 />
          <ZcSuperAdminPanel />
        </div>

        <div
          style={{
            width: "35vw",
            height: "84vh",
            margin: "0.3vw",
            padding: "0.5vw",
            border: "0.5px solid grey",
            borderRadius: "8px",
            overflow: "auto",
          }}
        >
          <DivExist show={type === "zcSceneButton"}>
            <ZcDetailSceneBtnDelayPanel />
            <ZcDetailSceneBtnControlPanel />
          </DivExist>
          {/* {type === "dimming" && <ZcPanelDimming />}*/}
          {type === "timer" && <ZcPanelTimeSetting />}
          {/* {type === "delaySwitch" && <ZcPanelDelaySwitch />}  */}
        </div>
        <div
          style={{
            width: "32vw",
            height: "84vh",
            margin: "0.3vw",
            padding: "0.5vw",
            border: "0.5px solid grey",
            borderRadius: "8px",
            overflow: "auto",
          }}
        >
          <DivExist show={type === "timer"}>
            <ControlDevicesZcTimerPanel />
          </DivExist>
          {/* <DivExist show={type === "zcSceneButton"}> */}
          {type === "zcSceneButton" && <ControlDevicesZcSceneButtonPanel />}
          {/* </DivExist> */}
          <DivExist show={type === "zcSwitch"}>
            <ControlDevicesZcSwitchPanel />
          </DivExist>
          {/*  {type === "timer" && <ZcPanelConnectDevicesTimer />}
          {type === "delaySwitch" && <ZcPanelConnectDevicesDelaySwitch />} */}
        </div>
      </DivInline>
    </ModalSM>
  );
}
