import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import DivExist from "components/DivExist";
import Divider10 from "components/Divider10";
import {
  Typography,
  Box,
  Tooltip,
  Slider,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import DivInline from "components/DivInline";
import { CopyAll, FastForward, ArrowDownward } from "@mui/icons-material";
import SensorIcon from "components/SensorIcon";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import IconButtonDone from "components/IconButtonDone";
import { confirmWindow } from "actions/screenActions";
import {
  zoneControlDisconnectSensor,
  updateZcTimerDeviceSetting,
} from "actions/zcActions";
import DeviceMongoModal from "components/DeviceMongoModal";
const sxArrow = {
  outline: "1px solid #fff",
  margin: "1vh",
  padding: "0 0.5vw 0 0.5vw ",
};

export default function ZcTimerSensorModal({
  x,
  y,
  modalLocation,
  open,
  handleClose,
  layerProps,
  selectedSensor,
  zoneControlID,
  zoneControlObj,
}) {
  const [ableMode, setAbleMode] = useState("startEnable-endDisable"); //startEnable-endDisable, startDisable-endEnable, noAction
  const { setting1, setting2 } = zoneControlObj || {};
  const { dispatch, sensorObjAll, iconSize, layerScale, timeTableObjAll } = layerProps;
  const sensorObj = sensorObjAll[selectedSensor];
  useEffect(() => {
    const objEnd = setting1?.[selectedSensor] || {};
    const objStart = setting2?.[selectedSensor] || {};
    if (sensorObj?.dtkAdd) {
      if (objStart.disabledSensor === 0x33 && objEnd.disabledSensor === 0x66)
        setAbleMode("startDisable-endEnable");
      if (objStart.disabledSensor === 0x66 && objEnd.disabledSensor === 0x33)
        setAbleMode("startEnable-endDisable");
    }
    if (sensorObj?.zigbeeAdd) {
      if (objStart.settingVal === 1 && objEnd.settingVal === 0)
        setAbleMode("startDisable-endEnable");
      if (objStart.settingVal === 0 && objEnd.settingVal === 1)
        setAbleMode("startEnable-endDisable");
    }
  }, [selectedSensor, open, setting1, setting2]);

  const handleDisconnect = async () => {
    confirmWindow(
      dispatch,
      `Confirm disconnect sensor [${selectedSensor}]?`,
      async () => {
        await zoneControlDisconnectSensor(zoneControlObj, sensorObj);
        handleClose();
      }
    );
  };
  const handleSave = async () => {
    let sensorSetting1 = setting1?.[selectedSensor] || {};
    let sensorSetting2 = setting2?.[selectedSensor] || {};
    if (sensorObj.dtkAdd) {
      sensorSetting1 = {
        ...sensorSetting1,
        disabledSensor: ableMode === "startEnable-endDisable" ? 0x33 : 0x66,
        dtkAdd: sensorObj.dtkAdd,
        commandCode: 0x0611,
      };
      sensorSetting2 = {
        ...sensorSetting2,
        disabledSensor: ableMode === "startEnable-endDisable" ? 0x66 : 0x33,
        dtkAdd: sensorObj.dtkAdd,
        commandCode: 0x0611,
      };
    }
    if (sensorObj.zigbeeAdd) {
      sensorSetting1 = {
        ...sensorSetting1,
        settingVal: ableMode === "startEnable-endDisable" ? 1 : 0,
        controlMode: "9C",
        settingType: 5,
        zigbeeAdd: sensorObj.zigbeeAdd,
      };
      sensorSetting2 = {
        ...sensorSetting2,
        settingVal: ableMode === "startEnable-endDisable" ? 0 : 1,
        controlMode: "9C",
        settingType: 5,
        zigbeeAdd: sensorObj.zigbeeAdd,
      };
    }
    await updateZcTimerDeviceSetting(zoneControlObj, selectedSensor, sensorObj.gatewayID, sensorSetting1, sensorSetting2, timeTableObjAll);
    handleClose();
  };
  const modalWidth = window.innerWidth * 0.33;
  const modalHeight = window.innerHeight * 0.33;
  const modalX = modalLocation.includes("right")
    ? x - modalWidth / layerScale
    : x;
  const modalY = modalLocation.includes("bottom")
    ? y - modalHeight / layerScale - iconSize
    : y;
  const sensorSettingObj = {
    setting1: setting1?.[selectedSensor] || {},
    setting2: setting2?.[selectedSensor] || {},
  };
  return (
    <MapModal
      x={modalX}
      y={modalY}
      open={open}
      handleCloseModal={handleClose}
      width={modalWidth}
      height={modalHeight}
      layerProps={layerProps}
      disableBottomAction
    >
      <DivInline>
        <IconButtonClose
          tooltip="Close dialog without saving"
          onBtnClick={handleClose}
        />
        <DeviceMongoModal deviceObj={sensorSettingObj}>
          <SensorIcon
            type={sensorObj?.type}
            width={3}
            sizeUnit="vw"
            disabledConnectStatus
          />
        </DeviceMongoModal>
        <Typography
          variant="caption"
        // onClick={() => console.log(sensorSettingObj)}
        >
          {`${sensorObj?.sensorName} [${selectedSensor}]`}
        </Typography>
      </DivInline>
      <Divider10 />
      <RadioGroup
        value={ableMode}
        onChange={(e) => {
          const val = e.target.value;
          setAbleMode(val);
        }}
      >
        <ButtonSizeRadio
          mode="startEnable-endDisable"
          name="ENABLE on timer start, DISABLE on timer end"
        />
        <ButtonSizeRadio
          mode="startDisable-endEnable"
          name="DISABLE on timer start, ENABLE on timer end"
        />
        {/* <ButtonSizeRadio mode="noAction" name="No action" /> */}
      </RadioGroup>
      <Divider10 />
      <IconButtonClose
        tooltip="Close dialog without saving"
        onBtnClick={handleClose}
      />
      <IconButtonDone
        tooltip="Save settings and close dialog"
        onBtnClick={handleSave}
      />
      <IconButtonDelete
        tooltip="Disconnect sensor from timer"
        onBtnClick={handleDisconnect}
      />
    </MapModal>
  );
}

function ButtonSizeRadio({ mode, name }) {
  return (
    <FormControlLabel
      value={mode}
      control={<Radio />}
      label={
        <div style={{ display: "flex" }}>
          <div
            style={{
              background: "lightgrey",
              marginRight: 5,
            }}
          />
          <Typography variant="body2">{name}</Typography>
        </div>
      }
    />
  );
}
