import { useState, useContext, useEffect, useMemo } from "react";
import {
  Typography,
  Tooltip,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import { SMContext } from "context/smContext";
import {
  Edit,
  Event,
  Schedule,
  Search,
  PictureInPicture,
  Add,
} from "@mui/icons-material";
import Divider10 from "components/Divider10";
import Space10 from "components/Space10";
import DivInline from "components/DivInline";
import DivExist from "components/DivExist";
import LightIcon from "components/LightIcon";
import { weekDayIsoMenu, cursor } from "asset/string/string";
import SchBox from "./SchBox";
import ModalSM from "components/ModalSM";
import SchBoxBig from "./SchBoxBig";
import AddSingleScheduleModal from "./AddSingleScheduleModal";
import AddMultipleScheduleModal from "./AddMultipleScheduleModal";
import EditSingleScheduleModal from "./EditSingleScheduleModal";
import EditMultiScheduleModal from "./EditMultipleScheduleModal";
import IconButtonBack from "components/IconButtonBack";
import CopyScheduleModal from "./CopyScheduleModal";
import { fetchScheduleByLightArray } from "actions/scheduleActions";
import IconButtonAdd from "components/IconButtonAdd";

export default function TestScheduleModal({ disabled, btnSize }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeMapID, lightObjAll, userObj, mapObjAll, serverObj } = state;
  const [open, setOpen] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [scheduleObjAll, setScheduleObjAll] = useState({});
  const [lightArray, setLightArray] = useState([]);
  const [openAddSchModal, setOpenAddSchModal] = useState(false);
  const [openAddMultSchModal, setOpenAddMultSchModal] = useState(false);
  const [openEditSchModal, setOpenEditSchModal] = useState(false);
  const [activeSchObj, setActiveSchObj] = useState({});
  const [searchText, setSearchText] = useState("");
  const [showBigSchBox, setShowBigSchBox] = useState(false);
  const [modalSerial, setModalSerial] = useState("");
  const [selectedSchArr, setSelectedSchArr] = useState([]);
  const mapObj = mapObjAll[activeMapID];

  useEffect(() => {
    if (open) {
      fetchSchedule();
      setModalSerial("");
      setShowBigSchBox(false);
      setSelectedSchArr([]);
      setSearchText("");
    }
  }, [open]);

  const fetchSchedule = async () => {
    const lightArr = Object.keys(mapObj?.lightObj || {}).filter(
      (s) => lightObjAll[s]?.type?.batVI === true
    );
    setLightArray(lightArr);
    const objAll = await fetchScheduleByLightArray(lightArr);
    setScheduleObjAll(objAll);
  };

  const lightArrayF = useMemo(() => {
    let arr = [...lightArray].sort((a, b) =>
      lightObjAll[a]?.description?.localeCompare(lightObjAll[b]?.description)
    );
    if (searchText) {
      arr = lightArray.filter(
        (s) =>
          s.includes(searchText) ||
          lightObjAll[s]?.description?.includes(searchText)
      );
    }
    return arr;
  }, [searchText, scheduleObjAll, lightArray]);

  const handleChooseSchBox = (e, serial, obj) => {
    setModalSerial(serial);
    // if (e.shiftKey) {
    //   let arr = [...selectedSchArr];
    //   const sid = obj.scheduleID;
    //   if (selectedSchArr.includes(sid)) {
    //     arr = arr.filter((a) => a !== sid);
    //   } else {
    //     arr = [...arr, sid];
    //   }
    //   setSelectedSchArr(arr);
    // } else {
    setActiveSchObj(obj);
    setOpenEditSchModal(true);
    // }
  };

  const handleOpen = async () => {
    if (!disabled) {
      setOpen(true);
    }
  };
  const handleClose = async () => {
    setOpen(false);
  };
  return (
    <>
      <Tooltip title={<Typography>Light battery test schedules</Typography>}>
        <Schedule
          sx={{
            margin: "5px",
            width: btnSize,
            height: btnSize,
            cursor: disabled ? cursor.notAllowed : cursor.pointer,
            color: disabled ? "grey" : "",
          }}
          onClick={handleOpen}
        />
      </Tooltip>

      <ModalSM
        open={open}
        onClose={handleClose}
        minWidth="95vw"
        maxWidth="95vw"
        minHeight="96vh"
        maxHeight="96vh"
        disableBottomClose
      >
        <DivInline justifyContent="space-between">
          <DivInline>
            <IconButtonBack onBtnClick={handleClose} />
            <Schedule />
            <Typography>{`Light Test Schedule - ${mapObjAll[activeMapID]?.mapName}`}</Typography>
          </DivInline>
          <DivInline>
            <Search />
            <TextField
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              size="small"
            />
            <Space10 />
            <Tooltip title="Toggle big schedule data box">
              <IconButton
                color={showBigSchBox ? "secondary" : "default"}
                onClick={() => setShowBigSchBox(!showBigSchBox)}
                size="large"
              >
                <PictureInPicture />
              </IconButton>
            </Tooltip>
            {/* <DivExist show={selectedSchArr.length > 0}>
              <Button variant="outlined" onClick={() => setSelectedSchArr([])}>
                Unselect all
              </Button>
            </DivExist> */}
          </DivInline>
        </DivInline>
        <Divider10 />

        <div
          style={{
            width: "100%",
            height: "77%",
            overflowY: "scroll",
          }}
        >
          {(lightArrayF || []).map((s) => {
            const lightObj = lightObjAll[s];
            const {
              enableWeekTest,
              weekTestWeekday,
              weekTestHour,
              weekTestMin,
              zigbeeConnected,
            } = lightObj || {};
            return (
              <div key={s}>
                <DivInline>
                  <LightIcon
                    lightStyle={lightObj?.style}
                    disabledStatus
                    width={2.5}
                    sizeUnit="vw"
                    connectStatus={zigbeeConnected}
                  />
                  <Space10 />
                  <Typography>{lightObj?.description}</Typography>
                  <Space10 />
                  <Typography variant="caption">[{s}]</Typography>
                  <EditMultiScheduleModal
                    schObjArr={scheduleObjAll[s]}
                    serial={s}
                    handleUpdate={fetchSchedule}
                  />
                  <DivExist show={enableWeekTest}>
                    <Tooltip
                      title={`Weekly test -Every
                        ${weekDayIsoMenu[weekTestWeekday]} ${weekTestHour}:
                        ${weekTestMin < 9 ? "0" + weekTestMin : weekTestMin}`}
                    >
                      <Event />
                    </Tooltip>
                  </DivExist>
                  <IconButtonAdd
                    tooltip="Add one year schedule"
                    size="small"
                    onBtnClick={() => {
                      setModalSerial(s);
                      setOpenAddMultSchModal(true);
                    }}
                  />

                  <CopyScheduleModal
                    serial={s}
                    schObjArr={scheduleObjAll[s]}
                    handleUpdate={fetchSchedule}
                  />
                </DivInline>
                <DivInline>
                  {scheduleObjAll[s]?.length > 0 ? (
                    <DivInline>
                      {scheduleObjAll[s]
                        .sort(
                          (a, b) => a.schedule_timeStamp - b.schedule_timeStamp
                        )
                        .map((schObj, key) => {
                          const selected = selectedSchArr.includes(
                            schObj.scheduleID
                          );
                          return (
                            <div
                              style={{ userSelect: "none" }}
                              key={key}
                              onMouseDown={(e) => {
                                handleChooseSchBox(e, s, schObj);
                              }}
                            >
                              {showBigSchBox ? (
                                <SchBoxBig
                                  schObj={schObj}
                                  selected={selected}
                                />
                              ) : (
                                <SchBox schObj={schObj} selected={selected} />
                              )}
                            </div>
                          );
                        })}
                    </DivInline>
                  ) : (
                    <Typography color="error">
                      No schedule for this light
                    </Typography>
                  )}
                  <DivExist show={userObj.level < 1}>
                    <Tooltip title="Add single schedule">
                      <Add
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setModalSerial(s);
                          setOpenAddSchModal(true);
                        }}
                      />
                    </Tooltip>
                  </DivExist>
                </DivInline>
                <Divider10 />
              </div>
            );
          })}
        </div>

        {/* <DivInline justifyContent="center">
          <Button
            disabled={pageNo === 0 ? true : false}
            onClick={() => {
              setPageNo((n) => n - 1);
            }}
            variant="contained"
            color="secondary"
            size="large"
          >
            Last Page
          </Button>
          <Typography style={{ marginLeft: 20, marginRight: 20 }}>
            Page {pageNo + 1}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            disabled={
              pageNo > Object.keys(scheduleObjAllInMap).length / 8 - 1
                ? true
                : false
            }
            onClick={() => {
              setPageNo((n) => n + 1);
            }}
            size="large"
          >
            Next Page
          </Button>
        </DivInline> */}
      </ModalSM>
      <AddSingleScheduleModal
        serial={modalSerial}
        open={openAddSchModal}
        handleClose={async () => {
          setOpenAddSchModal(false);
          await fetchSchedule();
        }}
      />
      <EditSingleScheduleModal
        serial={modalSerial}
        open={openEditSchModal}
        schObj={activeSchObj}
        handleClose={async () => {
          setOpenEditSchModal(false);
          await fetchSchedule();
        }}
      />
      <AddMultipleScheduleModal
        serial={modalSerial}
        open={openAddMultSchModal}
        handleClose={async () => {
          setOpenAddMultSchModal(false);
          await fetchSchedule();
        }}
      />
    </>
  );
}
