import { useState, useContext, useMemo } from "react";
import { SMContext } from "context/smContext";
import {
  Cancel,
  ZoomOutMap,
  ZoomIn,
  ZoomOut,
  OpenWith,
  PanTool,
  Search,
  SearchOff,
} from "@mui/icons-material";
import { Typography, Tooltip, InputAdornment, TextField } from "@mui/material";
import { cursor } from "asset/string/string";
import DivInline from "components/DivInline";
import { useNavigate } from "react-router-dom";
import MapSettingModal from "../modal/MapSettingModal";
import AddDeviceToMapModal from "../modal/AddDeviceToMapModal";
import ThemeContainer from "components/ThemeContainer";
import TestScheduleModal from "containers/testReport/TestScheduleModal";
import GroupReportModal from "../modal/GroupReportModal";
import DivExist from "components/DivExist";
import AdminContainer from "components/AdminContainer";
import TestTimeTableModal from "containers/testReport/TestTimeTableModal";
import DeviceListModal from "../modal/DeviceListModal";
import LockLevelSettingModal from "../modal/LockLevelSettingModal";
import { isTablet } from "react-device-detect";
import MongoAdminModal from "containers/server/MongoAdminModal";
import MapTopBtn from "containers/home/MapTopBtn";
import SearchModal from "containers/home/SearchModal";
import General from "@ecoenghk/general";
import MapDeviceMessageModal from "../modal/MapDeviceMessageModal";
import CommandConvertModal from "../modal/CommandConvertModal";
import IconButtonDelete from "components/IconButtonDelete";
const gs = new General();

export default function MapTopToolbar({ vw, vh, layerRef }) {
  const [state, dispatch] = useContext(SMContext);
  let navigate = useNavigate();
  const {
    enablePan,
    enableZoom,
    lightObjAll,
    gatewayObjAll,
    sensorObjAll,
    daliCtlObjAll,
    zoneControlObjAll,
    mapObjAll,
    activeMapID,
    mapLayer,
    serverID,
    userObj,
  } = state;
  const { level } = userObj;
  const [searchText, setSearchText] = useState("");
  const closeMap = async () => {
    dispatch({
      type: "ALL_UNSELECTED",
    });
    navigate("/HomePage");
    resetMap();
  };
  const btnSize = isTablet ? `${vh * 0.6}vh` : "25px";
  const resetMap = () => {
    const layer = layerRef.current;
    layer?.position({ x: 0, y: 0 });
    layer?.scale({ x: 1, y: 1 });
    layer?.batchDraw();
    dispatch({
      type: "SET_LAYER_SCALE",
      payload: {
        layerScale: 1,
        layerPos: { x: 0, y: 0 }
      },
    });
  };
  const zoomIn = () => {
    dispatch({ type: "SET_ENABLE_ZOOM", payload: "in" });
  };
  const zoomOut = () => {
    dispatch({ type: "SET_ENABLE_ZOOM", payload: "out" });
  };
  const handleSelectDevice = (deviceID, deviceType, deviceObj) => {
    dispatch({ type: "ALL_UNSELECTED" });
    dispatch({
      type: "DEVICE_SELECTED",
      payload: { deviceID, deviceType, deviceObj },
    });
  };
  const batLightArr = useMemo(() => Object.keys(lightObjAll || {}).filter(s => lightObjAll[s]?.type?.batVI), [lightObjAll]);
  const handleSearch = (enteredText) => {
    setSearchText(enteredText);
    if (enteredText === "") {
      dispatch({ type: "ALL_UNSELECTED" });
      return;
    }
    const lightArr = Object.keys(mapObjAll[activeMapID]?.lightObj || {});
    const arrL = lightArr.filter(
      (s) =>
        s?.includes(enteredText) ||
        lightObjAll[s]?.description?.includes(enteredText) ||
        lightObjAll[s]?.zigbeeAdd?.includes(enteredText) ||
        lightObjAll[s]?.dtkAdd?.includes(enteredText)
    );
    const gatewayArr = Object.keys(mapObjAll[activeMapID]?.gatewayObj || {});
    const arrG = gatewayArr.filter(
      (g) =>
        g?.includes(enteredText) ||
        gatewayObjAll[g]?.description?.includes(enteredText) ||
        gatewayObjAll[g]?.zigbeeAdd?.includes(enteredText) ||
        gatewayObjAll[g]?.dtkAdd?.includes(enteredText)
    );
    const sensorArray = Object.keys(mapObjAll[activeMapID]?.sensorObj || {});
    const arrS = sensorArray.filter(
      (s) =>
        s?.includes(enteredText) ||
        sensorObjAll[s]?.sensorName?.includes(enteredText) ||
        sensorObjAll[s]?.zigbeeAdd?.includes(enteredText) ||
        sensorObjAll[s]?.dtkAdd?.includes(enteredText)
    );
    const daliCtlArray = Object.keys(mapObjAll[activeMapID]?.daliCtlObj || {});
    const arrD = daliCtlArray.filter(
      (d) =>
        d?.includes(enteredText) ||
        daliCtlObjAll[d]?.description?.includes(enteredText) ||
        daliCtlObjAll[d]?.zigbeeAdd?.includes(enteredText) ||
        daliCtlObjAll[d]?.dtkAdd?.includes(enteredText)
    );
    const zcArray = Object.keys(mapObjAll[activeMapID]?.zoneControlObj || {});
    const arrZ = zcArray.filter(
      (z) =>
        z?.includes(enteredText) ||
        zoneControlObjAll[z]?.zoneControlName?.includes(enteredText)
    );
    if (enteredText.length > 2) {
      if (arrL.length === 1) {
        handleSelectDevice(arrL[0], "light", lightObjAll[arrL[0]]);
      } else if (arrG.length === 1) {
        handleSelectDevice(arrG[0], "gateway", gatewayObjAll[arrG[0]]);
      } else if (arrS.length === 1) {
        handleSelectDevice(arrS[0], "sensor", sensorObjAll[arrS[0]]);
      } else if (arrD.length === 1) {
        handleSelectDevice(arrD[0], "daliCtl", daliCtlObjAll[arrD[0]]);
      } else if (arrZ.length === 1) {
        handleSelectDevice(arrZ[0], "zoneControl", zoneControlObjAll[arrZ[0]]);
      }
    }
  };
  return (
    <div
      style={{
        width: vw + "vw",
        height: vh + "vh",
      }}
    >
      <DivInline>
        <MapTopBtn btnClick={closeMap} tooltip="Close" btnSize={btnSize}>
          <Cancel />
        </MapTopBtn>
        <MapTopBtn
          btnClick={resetMap}
          tooltip="Reset zoom[R]"
          btnSize={btnSize}
        >
          <ZoomOutMap />
        </MapTopBtn>
        <MapTopBtn
          btnClick={() => dispatch({ type: "SET_ENABLE_ZOOM", payload: "" })}
          tooltip="No Zoom[N]"
          iconColor={enableZoom === "" ? "goldenrod" : ""}
          btnSize={btnSize}
        >
          <SearchOff />
        </MapTopBtn>
        <MapTopBtn
          btnClick={zoomIn}
          tooltip="Zoom In when click on map[I]"
          iconColor={enableZoom === "in" ? "goldenrod" : ""}
          btnSize={btnSize}
        >
          <ZoomIn />
        </MapTopBtn>
        <MapTopBtn
          btnClick={zoomOut}
          tooltip="Zoom Out when click on map[O]"
          iconColor={enableZoom === "out" ? "goldenrod" : ""}
          btnSize={btnSize}
        >
          <ZoomOut />
        </MapTopBtn>
        <MapTopBtn
          btnClick={() => dispatch({ type: "SET_ENABLE_PAN", payload: false })}
          tooltip="Move device [M]"
          iconColor={!enablePan ? "goldenrod" : ""}
          btnSize={btnSize}
        >
          <OpenWith />
        </MapTopBtn>
        <MapTopBtn
          btnClick={() => dispatch({ type: "SET_ENABLE_PAN", payload: true })}
          tooltip="Pan [P]"
          iconColor={enablePan ? "goldenrod" : ""}
          btnSize={btnSize}
        >
          <PanTool />
        </MapTopBtn>
        <div
          style={{
            width: "1vw",
            height: "80%",
            borderRight: "1px solid lightgrey",
          }}
        />
        <div
          style={{
            width: "1vw",
            height: "80%",
          }}
        />
        <DivExist show={level < 2}>
          <AddDeviceToMapModal btnSize={btnSize} />
        </DivExist>
        <DeviceListModal btnSize={btnSize} />
        <AdminContainer>
          <DivExist show={!serverID}>
            <TestScheduleModal btnSize={btnSize} />
          </DivExist>
        </AdminContainer>

        <DivExist show={level < 3 && batLightArr.length > 0}>
          <TestTimeTableModal btnSize={btnSize} />
          <GroupReportModal btnSize={btnSize} />
        </DivExist>

        <DivExist show={level < 2}>
          <MapSettingModal btnSize={btnSize} />
          <LockLevelSettingModal
            disabled={!mapLayer.normal}
            btnSize={btnSize}
          />

        </DivExist>
        <AdminContainer>
          <MapDeviceMessageModal btnSize={btnSize} />
          <CommandConvertModal />
        </AdminContainer>
        <div
          style={{
            width: "1vw",
            height: "80%",
            borderRight: "1px solid lightgrey",
          }}
        />
        <div
          style={{
            width: "1vw",
            height: "80%",
          }}
        />
        <ThemeContainer background="light">
          <Tooltip title={<Typography>Search device in this map</Typography>}>
            <TextField
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={searchText}
              inputProps={{ style: { textAlign: "right" } }}
              onFocus={(e) => e.target.select()}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Tooltip>
          <IconButtonDelete sx={{ marginRight: "2vw" }} onBtnClick={() => handleSearch("")} size="small" />
        </ThemeContainer>
        <AdminContainer>
          <MongoAdminModal isMapButton={true} />
          <SearchModal
            btnSize={btnSize}
            isMapBtn={true}
            chooseMap={async (mid) => {
              closeMap();
              await gs.waitFor(1000);
              dispatch({
                type: "SET_ACTIVE_MAP",
                payload: mid,
              });
              dispatch({ type: "SET_ENABLE_PAN", payload: true });
              dispatch({ type: "SET_ENABLE_ZOOM", payload: "" });
              navigate("/MapPage");
            }}
          />
        </AdminContainer>
      </DivInline>
    </div>
  );
}

