import { lightColorAt } from "actions/generalActions";
import ChargeIcon from "./ChargeIcon";
import Bypass from "./Bypass";
export default function Downlight({
  width,
  height,
  sizeUnit,
  lightLv,
  disabledStatus,
  statusA,
  statusL,
  connectStatus,
  disabledConnectStatus,
  typeBatVI,
  typeIsDim,
  bypass,
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  const lightColor = lightColorAt(
    disabledStatus,
    statusA,
    lightLv,
    statusL,
    typeIsDim
  );
  return (
    <svg viewBox="0 0 79.375 79.375" width={w + unit} height={h + unit}>
      <path
        fill="#666"
        stroke="#666"
        strokeWidth="0.284"
        d="M66.096 9.304c1.685.064 2.622-.49 2.448-2.134V1.06h6.738v10.45c-.06 1.921-1.972 2.611-3.99 2.534H24.164c-4.384-.056-4.43-1.409-4.59-3.052l-.135-9.64 6.416-.003.093 5.435c-.105 3.196 2.004 2.464 3.404 2.52h36.743"
      ></path>
      <path
        fill="none"
        stroke="#6c5353"
        strokeWidth="3.979"
        d="M20.081 28.352l.142 35.947c10.54 16.82 41.07 18.176 55.011-.814l-.283-35.676c-18.574-22.2-52.46-10.354-54.87.543z"
      ></path>
      <ellipse
        cx="47.399"
        cy="62.914"
        fill={statusA === 1 ? "#566573" : "#fff"}
        stroke="#6c5353"
        strokeLinejoin="round"
        strokeWidth={0.343}
        rx="23.537"
        ry="12.438"
      ></ellipse>
      {statusA === 0 && (
        <ellipse
          cx="47.399"
          cy="62.914"
          fill={lightColor}
          fillOpacity={typeIsDim ? lightLv / 100 : 1}
          stroke="#6c5353"
          strokeLinejoin="round"
          strokeWidth={0.343}
          rx="23.537"
          ry="12.438"
        ></ellipse>
      )}
      <path
        fill="none"
        stroke="#6c5353"
        strokeLinecap="round"
        strokeWidth={3.699}
        d="M29.439 26.997l.283 23.907"
      ></path>
      <path
        fill="none"
        stroke="#6c5353"
        strokeLinecap="round"
        strokeWidth={3.699}
        d="M38.23 22.638l.283 23.907"
      ></path>
      <path
        fill="none"
        stroke="#6c5353"
        strokeLinecap="round"
        strokeWidth={3.713}
        d="M47.311 21.191l.27 25.347"
      ></path>
      <path
        fill="none"
        stroke="#6c5353"
        strokeLinecap="round"
        strokeWidth={3.699}
        d="M56.094 22.242l.284 23.907"
      ></path>
      <path
        fill="none"
        stroke="#6c5353"
        strokeLinecap="round"
        strokeWidth={3.699}
        d="M64.884 26.865l.284 23.907"
      ></path>
      {!disabledConnectStatus && (
        <circle r="6" cx="10" cy="12" fill={connectStatus ? "green" : "red"} />
      )}
      {!disabledStatus && typeBatVI && (
        <ChargeIcon y={28} x={0} statusL={statusL} />
      )}
      {bypass === 1 && <Bypass x={45} y={40} scale={0.7} />}
    </svg>
  );
}
