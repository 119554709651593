import React, { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Tooltip, IconButton } from "@mui/material";
import { TouchApp } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
import { sensorManualExecuteSetting } from "actions/sensorActions";
import DivSpaceBetween from "components/DivSpaceBetween";
import SensorChangeLockLvModal from "./SensorChangeLockLvModal";
import DeviceIdAddGwLine from "components/DeviceIdAddGwLine";
import DeviceActionLine from "components/DeviceActionLine";
import ButtonSM from "components/ButtonSM";
import NearFieldIcon from "asset/svgComp/NearFieldIcon";
import ModalSM from "components/ModalSM";
import SensorNearFieldPanel from "./SensorNearFieldPanel";
import GroupActionConfirmOneDevice from "components/GroupActionConfirmOneDevice";
import General from "@ecoenghk/general";
import GroupActionConfirmOneGroup from "components/GroupActionConfirmOneGroup";
import AdminContainer from "components/AdminContainer";
const gs = new General();
export default function ControlDevicesSceneButton({ show }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeSensorID, sensorObjAll } = state;
  const sensorObj = sensorObjAll[activeSensorID];
  const { gang } = sensorObj || {};
  const settingNumArray = gs.newArrayBetween(1, gang || 1);
  if (!show) return null;
  return (
    <DivCol>
      <DivInline justifyContent="center">
        <Typography variant="h6">Control Devices</Typography>
        <GroupActionConfirmOneGroup groupDeviceObj={sensorObj} settingNumArray={settingNumArray} />
      </DivInline>
      <Divider10 />
      <div></div>
      <ButtonGroup buttonNo={1} />
      <DivExist show={gang > 1}>
        <ButtonGroup buttonNo={2} />
        <DivExist show={gang > 2}>
          <ButtonGroup buttonNo={3} />
          <DivExist show={gang > 3}>
            <ButtonGroup buttonNo={4} />
          </DivExist>
        </DivExist>
      </DivExist>
    </DivCol>
  );
}

function ButtonGroup({ buttonNo }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeSensorID, lightObjAll, sensorObjAll, activeMapID, mapObjAll } =
    state;
  const activeMapObj = mapObjAll[activeMapID] || {};
  const sensorObj = sensorObjAll[activeSensorID];
  const lockLvSet = sensorObj[`lockLevelSet${buttonNo}`];
  const releaseOnDelayEnd = sensorObj[`releaseOnDelayEnd${buttonNo}`];
  const setting = sensorObj[`setting${buttonNo}`];
  const lightArr = Object.keys(setting || {})
    .filter((s) => Object.keys(activeMapObj?.lightObj || {}).includes(s))
    .sort((a, b) =>
      lightObjAll[a]?.description?.localeCompare(lightObjAll[b]?.description)
    );
  const sensorArr = Object.keys(setting || {})
    .filter((s) => Object.keys(activeMapObj?.sensorObj || {}).includes(s))
    .sort((a, b) => sensorObjAll[a]?.sensorName?.localeCompare(sensorObjAll[b]?.sensorName));

  return (
    <div style={{ width: "100%" }}>
      <DivInline justifyContent="space-between">
        <DivInline>
          <Typography>Button {buttonNo}</Typography>
          <Tooltip title={`Manual run Button ${buttonNo}`}>
            <div>
              <IconButton
                size="small"
                disabled={lightArr.length === 0}
                onClick={() => sensorManualExecuteSetting(sensorObj, buttonNo)}
              >
                <TouchApp />
              </IconButton>
            </div>
          </Tooltip>
        </DivInline>
        <DivSpaceBetween>
          <Typography variant="caption">Priority Level</Typography>
          <SensorChangeLockLvModal
            existingLv={lockLvSet}
            existingRelease={releaseOnDelayEnd}
            buttonNo={buttonNo}
            sensorObj={sensorObj}
          />
        </DivSpaceBetween>
      </DivInline>
      <Divider10 />
      {lightArr.map((s) => {
        return (
          <LightRow
            key={s}
            serial={s}
            lightObj={lightObjAll[s]}
            settingObj={setting}
            sensorObj={sensorObj}
            buttonNo={buttonNo}
          />
        );
      })}

      {sensorArr.map((s) => {
        const connectSensorObj = sensorObjAll[s];
        return (
          <div key={s}>
            <Typography variant="caption">{connectSensorObj.sensorName}</Typography>
            <DeviceIdAddGwLine deviceID={s} address={connectSensorObj.zigbeeAdd || connectSensorObj.dtkAdd} gatewayID={setting[s].gatewayID} />
            <DeviceActionLine deviceSetting={setting[s]} deviceObj={connectSensorObj} mainDeviceObj={sensorObj} buttonNo={buttonNo} />
          </div>
        );
      }
      )}
      <Divider10 />
    </div>
  );
}

function LightRow({ serial, lightObj, settingObj, sensorObj, buttonNo }) {
  const [state, dispatch] = useContext(SMContext);
  const [openNearField, setOpenNearField] = useState(false);
  const { nearFieldSetting } = sensorObj || {};
  const serialSetting = settingObj[serial] || {};
  const { dtkAdd, gatewayID } = serialSetting;
  return (
    <div>
      <DivInline>
        <Typography sx={{ marginRight: "1vw" }} onClick={() => alert(JSON.stringify(serialSetting))} variant="caption">{lightObj?.description}</Typography>
        <GroupActionConfirmOneDevice deviceObj={sensorObj} controlledDeviceObj={lightObj} settingNumArray={[buttonNo]} />
      </DivInline>
      <DivInline>
        <DeviceIdAddGwLine deviceID={serial} address={dtkAdd} gatewayID={gatewayID} />
        <AdminContainer>
          <ButtonSM
            tooltip="Near field command setting"
            margin={0}
            onClick={() => setOpenNearField(true)}>
            <NearFieldIcon width={2} sizeUnit={'vw'} color={nearFieldSetting?.[`${serial}_${buttonNo}_${lightObj.shortAdd || 0}`] ? "red" : "#fff"} />
          </ButtonSM>
        </AdminContainer>
        {/* <DeviceGroupActionModal deviceObj={sensorObj} lightObj={lightObj} settingNumArray={[buttonNo]} /> */}
      </DivInline>
      <DeviceActionLine deviceSetting={serialSetting} deviceObj={lightObj} mainDeviceObj={sensorObj} buttonNo={buttonNo} />
      <AdminContainer>
        <ModalSM open={openNearField} onClose={() => setOpenNearField(false)} disableBottomClose >
          <SensorNearFieldPanel
            sensorObj={sensorObj}
            deviceObj={lightObj}
            handleClose={() => setOpenNearField(false)}
            dispatch={dispatch}
            buttonNo={buttonNo}
          />
        </ModalSM>
      </AdminContainer>
    </div>
  );
}
