import DaliCtl from "asset/svgComp/DaliCtl";
import { deviceConnected } from "actions/generalActions";

/**
 *
 * @param {number} param0.width
 * @param {"vw"|"px"} param0.sizeUnit
 * @param {boolean} param0.connectStatus use this if daliCtlObj is not provided
 * @param {boolean} param0.disabledConnectStatus
 * @param {Object} param0.daliCtlObj use together with gatewayConnected
 * @param {boolean} param0.gatewayConnected
 * @returns
 */
export default function DaliCtlIcon({
  width = 3,
  connectStatus,
  sizeUnit = "vw",
  disabledConnectStatus,
  daliCtlObj,
  gatewayConnected,
}) {
  let connectStatusUse;
  if (daliCtlObj) {
    connectStatusUse = deviceConnected(
      daliCtlObj?.zigbeeConnected,
      daliCtlObj?.timeStamp,
      gatewayConnected
    );
  } else {
    connectStatusUse = connectStatus;
  }

  return (
    <div>
      <DaliCtl
        width={width}
        sizeUnit={sizeUnit}
        connectStatus={connectStatusUse}
        disabledConnectStatus={disabledConnectStatus}
      />
    </div>
  );
}
