import { useState, Fragment } from "react";
import { Line, Rect, Circle, Text } from "react-konva";
import DivExist from "components/DivExist";
import MapModal from "./MapModal";
import { Typography } from "@mui/material";
import LightIcon from "components/LightIcon";
import SensorIcon from "components/SensorIcon";
// import { ArrowBack, ArrowForward } from "@mui/icons-material";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import DivCol from "components/DivCol";
import { alertWindow, confirmWindow } from "actions/screenActions";
import { sensorConnectLight } from "actions/sensorActions";
import SenMotionLightModal from "../modal/SenMotionLightModal";
import SenDaylightLightModal from "../modal/SenDaylightLightModal";
import SenLightSwLightModal from "../modal/SenLightSwLightModal";
import { darkColorArr, sensorStringsOfType } from "actions/generalActions";
import SenDaylightMotionLightModal from "../modal/SenDaylightMotionLightModal";
import General from "@ecoenghk/general";
import SenSceneButtonLightModal from "../modal/SenSceneButtonLightModal";
import { sensorTypeOf } from "asset/string/string";
import ButtonNo from "./ButtonNo";
import SenDimControllerLightModal from "../modal/SenDimControllerLightModal";
const gs = new General();
const screenW = window.innerWidth;
const screenH = window.innerHeight;
export default function MapSensorLightGroup({
  layerProps,
  sensorID,
  x,
  y,
  sensorObj,
}) {
  const {
    iconSize,
    mapScale,
    lightObjAll,
    dispatch,
    mapObjAll,
    activeMapID,
    enablePan,
    layerScale,
  } = layerProps;
  const lightMapObj = mapObjAll[activeMapID]?.lightObj;
  const [selectedLight, setSelectedLight] = useState("");
  const [editModalX, setEditModalX] = useState(0);
  const [editModalY, setEditModalY] = useState(0);
  const [modalLocation, setModalLocation] = useState("topleft"); //topleft, topright, bottomleft, bottomright
  const [showEditModal, setShowEditModal] = useState(false);
  const [chooseGangSerial, setChooseGangSerial] = useState("");
  const { type, serialMap, gang } = sensorObj || {};
  const sensorTypeName = sensorStringsOfType(type)?.sensorTypeName;
  function handleCloseModal() {
    setShowEditModal(false);
    setSelectedLight("");
  }
  const handleClick = (e, serial, isUnderSensor) => {
    e.evt.preventDefault();
    if (isUnderSensor) {
      handleOpenLightModal(serial);
    } else {
      handleConnectLight(serial);
    }
  };
  const handleOpenLightModal = (s) => {
    handleModalPostion(s);
    setSelectedLight(s);
    setShowEditModal(true);
  };
  const handleModalPostion = (s) => {
    let xLight = (lightMapObj[s]?.x + iconSize) * mapScale.x;
    let yLight = (lightMapObj[s]?.y + iconSize) * mapScale.y;
    let pw = "left",
      ph = "top";
    if (screenW / 2 < xLight) pw = "right";
    if (screenH / 2 < yLight) ph = "bottom";
    const p = ph + pw;
    setModalLocation(p);
    setEditModalY(yLight);
    setEditModalX(xLight);
  };
  const handleConnectLight = async (s) => {
    const lightObj = lightObjAll[s];
    if (!lightObj.gatewayID) {
      alertWindow(dispatch, "Please assign gateway to light first.");
      return;
    }
    if (!sensorObj.gatewayID) {
      alertWindow(dispatch, `Please assign gateway to this ${sensorTypeName} first.`);
      return;
    }
    if (gang > 1 && (type === "scenebutton" || type === "lightswitch")) {
      handleModalPostion(s);
      setChooseGangSerial(s);
    } else {
      confirmWindow(dispatch, `Connect ${s} to this ${sensorTypeName}?`, async () => {
        await sensorConnectLight(sensorObj, lightObj, 1);
      });
    }
  };
  const unitSize = iconSize * mapScale.x;

  return (
    <>
      <>
        {Object.keys(lightMapObj || {}).map((s) => {
          const netX = lightMapObj?.[s]?.x * mapScale.x;
          const netY = lightMapObj?.[s]?.y * mapScale.y;
          const serialArray = Object.values(serialMap || {}) || [];
          const isUnderSensor = serialArray?.includes(s);
          const lightObj = lightObjAll[s];
          const lightType = lightObj?.type;
          let canConnect = true;
          if (!lightType?.A || lightObj.masterSerial) canConnect = false;
          if (type === "dimcontroller") {
            if (!lightType?.pwm) canConnect = false;
          }
          return (
            <Fragment key={s}>
              <DivExist show={lightType?.A}>
                <Rect
                  x={netX}
                  y={netY}
                  width={unitSize}
                  height={unitSize}
                  fill={isUnderSensor ? "#F1948A" : "#52BE80"}
                  opacity={0.5}
                  onMouseOver={(e) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = enablePan ? "pointer" : "move";
                  }}
                  onMouseLeave={(e) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = enablePan ? "grab" : "default";
                  }}
                  onTap={(e) => handleClick(e, s, isUnderSensor)}
                  onClick={(e) => handleClick(e, s, isUnderSensor)}
                  stroke={selectedLight === s ? "#2C3E50" : ""}
                  strokeWidth={6 / layerScale}
                />
              </DivExist>
              <DivExist show={!canConnect}>
                <Rect
                  x={netX}
                  y={netY}
                  width={iconSize * mapScale.x}
                  height={iconSize * mapScale.y}
                  fill="grey"
                  opacity={0.3}
                />
                <Line
                  x={netX}
                  y={netY}
                  points={[0, 0, iconSize * mapScale.x, iconSize * mapScale.y]}
                  stroke="red"
                  strokeWidth={2 / layerScale}
                />
              </DivExist>
              <DivExist
                show={isUnderSensor && type === "scenebutton"}
              >
                {gs.newArrayBetween(1, 4).map((n) => (
                  <ButtonNo
                    show={sensorObj?.[`setting${n}`]?.[s] ? true : false}
                    layerProps={layerProps}
                    x={netX}
                    y={netY}
                    buttonNo={n}
                    key={n}
                  />
                ))}
              </DivExist>
              <DivExist
                show={isUnderSensor && type === "lightswitch"}
              >
                {gs.newArrayBetween(1, 4).map((n, i) => (
                  <ButtonNo
                    show={sensorObj?.[`setting${n * 2 - 1}`]?.[s] ? true : false}
                    layerProps={layerProps}
                    x={netX}
                    y={netY}
                    buttonNo={n}
                    key={n}
                  />

                ))}
              </DivExist>
              <DivExist show={chooseGangSerial === s}>
                <Circle
                  radius={iconSize * mapScale.x}
                  x={netX + (iconSize * mapScale.x) / 2}
                  y={netY + (iconSize * mapScale.y) / 2}
                  stroke={darkColorArr[0]}
                  strokeWidth={2 / layerScale}
                />
              </DivExist>
            </Fragment>
          );
        })}
      </>
      <ChooseGangModal
        open={chooseGangSerial ? true : false}
        handleClose={() => setChooseGangSerial("")}
        x={editModalX}
        y={editModalY}
        modalLocation={modalLocation}
        serial={chooseGangSerial}
        sensorObj={sensorObj}
        layerProps={layerProps}
      />
      <SenMotionLightModal
        x={editModalX}
        y={editModalY}
        modalLocation={modalLocation}
        open={showEditModal && type === "motion"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedLight={selectedLight}
        sensorID={sensorID}
        sensorObj={sensorObj}
        scale={layerScale}
      />
      <SenDaylightLightModal
        x={editModalX}
        y={editModalY}
        modalLocation={modalLocation}
        open={showEditModal && type === "daylight"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedLight={selectedLight}
        sensorID={sensorID}
        sensorObj={sensorObj}
        scale={layerScale}
      />
      <SenLightSwLightModal
        x={editModalX}
        y={editModalY}
        modalLocation={modalLocation}
        open={showEditModal && type === "lightswitch"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedLight={selectedLight}
        sensorID={sensorID}
        sensorObj={sensorObj}
        scale={layerScale}
      />
      <SenSceneButtonLightModal
        x={editModalX}
        y={editModalY}
        modalLocation={modalLocation}
        open={showEditModal && type === "scenebutton"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedLight={selectedLight}
        sensorID={sensorID}
        sensorObj={sensorObj}
        scale={layerScale}
      />
      <SenDimControllerLightModal
        x={editModalX}
        y={editModalY}
        modalLocation={modalLocation}
        open={showEditModal && type === "dimcontroller"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedLight={selectedLight}
        sensorID={sensorID}
        sensorObj={sensorObj}
        scale={layerScale}
      />
      <SenDaylightMotionLightModal
        x={editModalX}
        y={editModalY}
        modalLocation={modalLocation}
        open={showEditModal && type === "daylightmotion"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedLight={selectedLight}
        sensorID={sensorID}
        sensorObj={sensorObj}
        scale={layerScale}
      />
    </>
  );
}

function ChooseGangModal({
  open,
  handleClose,
  x,
  y,
  modalLocation,
  serial,
  sensorObj,
  layerProps,
}) {
  const [hoverSw, setHoverSw] = useState(0);
  const { dispatch, lightObjAll, iconSize } = layerProps;
  const { gang } = sensorObj || {};
  const lightObj = lightObjAll[serial];
  const handleConnect = async (swNo) => {
    confirmWindow(
      dispatch,
      `Connect ${serial} to switch no ${swNo}?`,
      async () => {
        await sensorConnectLight(sensorObj, lightObj, swNo);
        handleClose();
      }
    );
  };
  const modalWidth = window.innerWidth * 0.28;
  const modalHeight = window.innerHeight * 0.3;
  // const modalX = modalLocation.includes("right")
  //   ? x - modalWidth - 2 * iconSize
  //   : x;
  // const modalY = modalLocation.includes("bottom")
  //   ? y - modalHeight - iconSize
  //   : y;
  return (
    <MapModal
      // x={modalX}
      // y={modalY}
      x={x}
      y={y}
      open={open}
      handleCloseModal={handleClose}
      width={modalWidth}
      height={modalHeight}
      layerProps={layerProps}
    >
      <DivInline>
        <LightIcon
          lightStyle={lightObjAll[serial]?.style}
          width={3}
          sizeUnit="vw"
          disabledConnectStatus
        />
        <Typography>[{serial}] connect to button no:</Typography>
      </DivInline>
      <Divider10 />
      <DivInline justifyContent="space-around">
        {gs.newArrayBetween(1, gang || 2).map((n) => (
          <DivCol
            key={n}
            onClick={() => handleConnect(n)}
            style={{
              cursor: "pointer",
              outline: "1px solid #fff",
              padding: "0.5vw",
              borderRadius: "0.3vw",
              backgroundColor: hoverSw === n ? "darkgrey" : "",
            }}
            onMouseEnter={() => setHoverSw(n)}
            onMouseLeave={() => setHoverSw(0)}
          >
            <SensorIcon
              type={sensorObj?.type}
              width={4}
              sizeUnit="vw"
              disabledConnectStatus
              status={n === 1 ? 1 : 0}
              status2={n === 2 ? 1 : 0}
              status3={n === 3 ? 1 : 0}
              status4={n === 4 ? 1 : 0}
              gang={gang}
            />
            <Typography>No.{n}</Typography>
          </DivCol>
        ))}
      </DivInline>
    </MapModal>
  );
}
