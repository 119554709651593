import { useContext, useMemo, useState, useEffect } from "react";
import { Button, Paper, Tooltip, Box, Typography } from "@mui/material";
import DivInline from "components/DivInline";
import { Logout } from "@mui/icons-material";
import { SMContext } from "context/smContext";
import { useNavigate } from "react-router-dom";
import { useAutoBackToRoot, useFetchServerObj } from "hooks/generalHooks";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import MapPanel from "./MapPanel";
import Footer from "components/Footer";
import UserModal from "./UserModal";
import AddMapModal from "./AddMapModal";
import ImportMapModal from "./ImportMapModal";
import MongoAdminModal from "containers/server/MongoAdminModal";
import ServerSettingModal from "containers/server/ServerSettingModal";
import AdminContainer from "components/AdminContainer";
import { useFetchMapsInUser } from "hooks/mapHooks";
import SkylightLogo from "asset/svgComp/SkylightLogo";
import SearchModal from "./SearchModal";
import {
  getLatestServerFirmwareVerByApi,
  updateServerFirmwareByApi,
} from "actions/serverActions";
import DivExist from "components/DivExist";
import { alertWindow, confirmWindow } from "actions/screenActions";
import ZcAdminModal from "./ZcAdminModal";
import CommandCodeModal from "./CommandCodeModal";
import MapManagementModal from "./MapManagementModal";
import { useSocketListenToWebAtHome } from "hooks/socketHooks";

export default function HomePage() {
  const [state, dispatch] = useContext(SMContext);
  const { userObj, mapObjAll, serverObj } = state;
  const [mapIDArray, setMapIDArray] = useState([]);
  const [currentMapID, setCurrentMapID] = useState("");
  const [verObj, setVerObj] = useState({
    latestVer: "",
    currentVer: "",
    toUpdate: false,
  });
  const navigate = useNavigate();
  // const { uid, } = userObj;
  useSocketListenToWebAtHome(navigate);
  useAutoBackToRoot();
  useFetchServerObj();
  useFetchMapsInUser();
  const fetchLatestFwVer = async (url) => {
    const result = await getLatestServerFirmwareVerByApi(url);
    setVerObj(result);
  };
  useEffect(() => {
    fetchLatestFwVer(serverObj?.localserverUrl);
  }, [serverObj?.localserverUrl]);
  useEffect(() => {
    let arr = userObj.mapArray || [];
    if (arr.length === 0) arr = Object.keys(mapObjAll || {});
    arr = arr.filter(m => !(userObj.hideMapArray || []).includes(m));
    setMapIDArray(arr);
  }, [mapObjAll, userObj.mapArray, userObj.hideMapArray]);

  const mapIDArrayForSlideshow = useMemo(() => {
    let arr = [...(mapIDArray || [])];
    if ((mapIDArray || []).length === 1) {
      arr = [...arr, mapIDArray[0]];
    }
    return arr;
  }, [mapIDArray]);
  const properties = {
    scale: 1.4, //for zoom
    duration: 15000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true,
    onChange: (oldIndex, newIndex) => {
      //   console.log(`slide transition from ${oldIndex} to ${newIndex}`);
      setCurrentMapID(mapIDArray[newIndex]);
    },
  };
  const logout = () => {
    dispatch({
      type: "RESET_REDUCER",
    });
    navigate("/");
  };
  const chooseMap = (mid) => {
    dispatch({
      type: "SET_ACTIVE_MAP",
      payload: mid,
    });
    dispatch({ type: "SET_ENABLE_PAN", payload: true });
    dispatch({ type: "SET_ENABLE_ZOOM", payload: "" });
    navigate("/MapPage");
  };
  return (
    <Paper sx={{ background: "transparent" }}>
      <DivInline style={{ marginBottom: "2vh" }}>
        <Box onClick={() => console.log(state)}>
          <SkylightLogo width={14} height={4.5} background="dark" sizeUnit="vw" />
        </Box>
        <Typography
          display="inline"
          align="left"
          style={{ flexGrow: 1, marginLeft: "1vw" }}
        >
          Computerized Lighting Management System
        </Typography>

        <AdminContainer>
          <DivExist show={verObj?.toUpdate}>
            <Button
              onClick={async () => {
                confirmWindow(
                  dispatch,
                  `Update server firmware from ${verObj.currentVer} to ${verObj.latestVer}?`,
                  async () => {
                    const res = await updateServerFirmwareByApi(
                      serverObj.localserverUrl
                    );
                    if (res.result === "OK") {
                      alertWindow(dispatch, "Update success, please refresh the page");
                    } else {
                      alertWindow(dispatch, "Update fail");
                    }
                  }
                );
              }}
            >
              Update to {verObj?.latestVer}
            </Button>
          </DivExist>
          <MongoAdminModal />
          <ServerSettingModal />
          <SearchModal chooseMap={(mid) => chooseMap(mid)} />
          <ZcAdminModal />
          {/* <CommandCodeModal /> */}
        </AdminContainer>
        <UserModal />
        <Tooltip title={<Typography>Log out</Typography>} placement="bottom">
          <Button
            size="small"
            onClick={logout}
            color="inherit"
            variant="outlined"
          >
            <Logout />
          </Button>
        </Tooltip>
      </DivInline>

      <DivInline
        justifyContent="center"
        alignItems="center"
        style={{ width: "100vw", height: "58vh" }}
      >
        <div style={{ width: "80vw" }}>
          <Zoom {...properties}>
            {(mapIDArrayForSlideshow || []).map((mid, key) => {
              return (
                <MapPanel
                  key={key}
                  mapID={mid}
                  mapObj={mapObjAll[mid]}
                  chooseMap={(mid) => chooseMap(mid)}
                />
              );
            })}
          </Zoom>
        </div>
      </DivInline>
      <DivInline
        justifyContent="center"
        alignItems="center"
        style={{ width: "100vw", height: "6vh", marginBottom: "1vh" }}
      >
        <AddMapModal />
        <ImportMapModal />
        <AdminContainer>
          <MapManagementModal />
        </AdminContainer>
      </DivInline>
      <div style={{ width: "100vw", height: "1vh" }}></div>
      <div
        style={{
          width: "98vw",
          height: "15vh",
          display: "flex",
          overflowX: "auto",
        }}
      >
        {(mapIDArray || []).map((mid, key) => (
          <SmallMapPanel
            key={key}
            mapID={mid}
            mapObj={mapObjAll[mid]}
            chooseMap={(mid) => chooseMap(mid)}
            currentMapID={currentMapID}
          />
        ))}
      </div>
      <Footer />
    </Paper>
  );
}

function SmallMapPanel({ mapID, mapObj, chooseMap, currentMapID }) {
  const [state, dispatch] = useContext(SMContext);
  let bgUrl = mapObj?.mapFileName
    ? `${global.ip}/img/${mapObj.mapFileName}`
    : mapObj?.mapUrl;
  if (state.enableFS) bgUrl = mapObj?.mapUrl;
  return (
    <Box
      sx={{
        margin: "0.5vw 0.5vw 0 0.5vw",
        cursor: "pointer",
        flexShrink: 0,
        flexBasis: "12vw",
        height: "11vh",
        width: "15vw",
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7) ), url(${bgUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        outline: currentMapID === mapID ? "1.5px solid #fff" : "",
        "&:hover": {
          outline: "1.5px solid #fff",
          outlineOffset: "-1px",
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4) ), url(${bgUrl})`,
        },
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        padding: "0.5vw",
      }}
      onClick={() => chooseMap(mapID)}
    >
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
        <Typography sx={{ maxWidth: "14vw", wordWrap: "break-word", textAlign: "right" }} variant="caption">{mapObj?.mapName}</Typography>
      </div>
    </Box>
  );
}
