import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography } from "@mui/material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
import ChangeLockLvLightSwModal from "./ChangeLockLvLightSwModal";
import DeviceIdAddGwLine from "components/DeviceIdAddGwLine";
import DeviceGroupActionModal from "containers/zoneCtl/DeviceGroupActionModal";
import DeviceActionLine from "components/DeviceActionLine";
import GroupActionConfirmOneGroup from "components/GroupActionConfirmOneGroup";
import GroupActionConfirmOneDevice from "components/GroupActionConfirmOneDevice";
import General from "@ecoenghk/general";
const gs = new General();

export default function ControlDevicesLightSwPanel({ show }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeSensorID, sensorObjAll } = state;

  const sensorObj = sensorObjAll[activeSensorID];
  const { gang } = sensorObj || {};
  const settingNumArray = gs.newArrayBetween(1, gang * 2 || 2);
  return (
    <DivExist show={show}>
      <DivCol>
        <DivInline justifyContent="center">
          <Typography variant="h6" align="center">
            Control Devices
          </Typography>
          <GroupActionConfirmOneGroup groupDeviceObj={sensorObj} settingNumArray={settingNumArray} />
        </DivInline>
        <Divider10 />
        <ButtonGroup buttonNo={1} />
        <Divider10 />
        <DivExist show={gang > 1}>
          <ButtonGroup buttonNo={2} />
          <Divider10 />
        </DivExist>
        <DivExist show={gang > 2}>
          <ButtonGroup buttonNo={3} />
          <Divider10 />
        </DivExist>
        <DivExist show={gang > 3}>
          <ButtonGroup buttonNo={4} />
          <Divider10 />
        </DivExist>
      </DivCol>
    </DivExist>
  );
}

function ButtonGroup({ buttonNo }) {
  const [state] = useContext(SMContext);
  const { activeSensorID, lightObjAll, sensorObjAll } = state;
  const sensorObj = sensorObjAll[activeSensorID];
  const { serialMap } = sensorObj || {};
  const setNo = buttonNo * 2 - 1;
  const setting = sensorObj[`setting${setNo}`];
  let lightArr = Object.values(serialMap || {})
    .filter((s) => (setting?.[s] ? true : false))
    .sort((a, b) => a.localeCompare(b));
  return (
    <div style={{ width: "100%" }}>
      <DivInline justifyContent="space-between">
        <Typography>Switch {buttonNo}</Typography>

        <Typography variant="caption">Priority Level</Typography>
        <ChangeLockLvLightSwModal swNo={buttonNo} sensorObj={sensorObj} />
      </DivInline>
      <Divider10 />
      {lightArr.map((s) => {
        return (
          <div key={s}>
            <LightRow
              serial={s}
              lightObj={lightObjAll[s]}
              settingNum={setNo}
              sensorObj={sensorObj}
              buttonNo={buttonNo}
            />
          </div>
        );
      })}
      <DivExist show={lightArr.length === 0}>
        <Typography variant="caption" align="center">
          No light connected
        </Typography>
      </DivExist>
    </div>
  );
}

function LightRow({ serial, lightObj, settingNum, sensorObj, buttonNo }) {
  const settingOff = sensorObj[`setting${settingNum}`]; //example setting1, setting3, setting5
  const serialSettingOff = settingOff?.[serial] || {};
  const settingOn = sensorObj[`setting${settingNum + 1}`]; //example setting2, setting4, setting6
  const serialSettingOn = settingOn?.[serial] || {};
  const serialSetting = { on: serialSettingOn, off: serialSettingOff }
  return (
    <div>
      <DivInline>
        <Typography sx={{ marginRight: "1vw" }} onClick={() => alert(JSON.stringify(serialSetting))} variant="caption">{lightObj?.description}</Typography>
        <GroupActionConfirmOneDevice deviceObj={sensorObj} controlledDeviceObj={lightObj} settingNumArray={[settingNum, settingNum + 1]} />
      </DivInline>
      <DivInline >
        <DeviceIdAddGwLine deviceID={serial} address={serialSettingOn.dtkAdd} gatewayID={serialSettingOn.gatewayID} />
        <DeviceGroupActionModal deviceObj={sensorObj} lightObj={lightObj} settingNumArray={[settingNum, settingNum + 1]} />
      </DivInline>
      <DeviceActionLine deviceSetting={serialSettingOff} deviceSetting2={serialSettingOn} deviceObj={lightObj} mainDeviceObj={sensorObj} buttonNo={buttonNo} />


    </div>
  );
}

