import { mgFindAll, mgFindOne } from "./mongoApiActions";
import socketIOClient from "socket.io-client";
import { alertWindow } from "./screenActions";
import axios from "axios";
import moment from "moment";

export const handleTestServer = async () => {
  console.log("Test server... ", global.ip);
  try {
    const res = await axios.get(`${global.ip}/api/test1`, {
      timeout: 2000,
    });
    return res.data;
  } catch (err) {
    return "connect server fail";
  }
};

export const loginServer = async (dispatch, navigate, email, password) => {
  const serverRes = await handleTestServer();
  console.log(serverRes.result);
  if (serverRes.result === "test1 OK") {
    console.log("serverOK");
    const userObj = await mgFindOne("user", "email", email);
    console.log(userObj);
    if (userObj?.result === "fail") {
      alertWindow(dispatch, `${email} not exist`);
    } else if (userObj?.password !== password) {
      alertWindow(dispatch, "Wrong password");
    } else {
      dispatch({
        type: "FETCHED_USER",
        payload: {
          ...userObj,
          ip: global.ip,
        },
      });
      const socketParam = {
        extraHeaders: {
          webUser: `${userObj.email}_${moment().format("YYYYMMDD_HHmmss")}`,
        },
      };

      let socket = socketIOClient(`${global.ip}`, socketParam);
      dispatch({
        type: "SET_SOCKET",
        payload: socket,
      });
      global.socket = socket;
      navigate("/HomePage");
    }
  } else {
    alertWindow(dispatch, "Server connect fail");
  }
};

export const updateSingleUserFsToLs = async (socket, uid) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "singleUser",
    uid: uid,
  };
  await socket.emit("fromWeb", payload);
};

export const fetchAllUsers = async () => {
  const data = await mgFindAll("user");
  console.log(data);
  let objAll = {};
  data.forEach((obj) => {
    const u = obj.uid;
    objAll[u] = obj;
  });
  return objAll;
};
export const updateUsersFsToLs = async (socket) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "user",
  };
  await socket.emit("fromWeb", payload);
};
export const updateUserProperty = async (userObj, updateObj) => {
  const jsonData = {
    type: "updateUserProperty",
    userObj,
    updateObj,
  };
  console.log(`[SOCKET OUT] updateUserProperty ${userObj.uid}, userObj:${JSON.stringify(updateObj)}`);
  global.socket.emit("fromWeb", jsonData);
}
