import React from "react";

/**
 *
 * @param {object} param0
 * @param {number} param0.width
 * @param {number} param0.height
 * @param {string} param0.sizeUnit
 * @param {"on"|"off"} param0.onoff
 * @returns
 */
export default function LightBulb({ width, height, sizeUnit, onoff }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <path
        fill={onoff === "on" ? "#ff0" : "#00aad4"}
        stroke={onoff === "on" ? "#d4aa00" : "#006680"}
        strokeWidth={4.2}
        d="M31.41 58.786c-1.677-1.257-1.707-4.01-2.56-6.426-3.308-4.19-7.565-9.006-7.982-14.497-.385-5.076.95-10.413 5.271-14.347 4.633-4.278 7.225-4.085 12.241-4.407 4.224-.271 8.85 1.308 12.005 4.108 3.399 3.014 5.86 7.731 5.873 12.255.019 6.002-2.019 10.147-7.694 16.309-1.096 2.237.306 5.942-2.095 7.304-5.576 3.164-10.579 3.06-15.06-.3z"
      />
      <path
        fill="none"
        stroke="#666"
        strokeLinecap="round"
        strokeWidth={4}
        d="M31.023 64.694c5.64 1.981 11.058 2.565 15.929-.287M31.378 69.562c5.64 1.981 11.058 2.565 15.929-.287M31.45 74.645c5.64 1.981 11.058 2.565 15.929-.287"
      />
      {onoff === "on" && (
        <path
          fill="none"
          stroke="#ff7f2a"
          strokeLinecap="round"
          strokeWidth={4.2}
          d="M15.386 50.759L8.285 55.23M3.682 38.135h7.759M14.86 24.591l-6.838-3.945M61.411 50.628l8.153 4.734M65.619 38.267l8.81.526M61.937 25.511l7.364-4.734M52.074 14.86l4.208-6.312M38.135 11.441l.395-7.496M25.117 14.991l-4.208-6.707"
        />
      )}
    </svg>
  );
}
