import { useState, useEffect, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Box } from "@mui/material";
import { Add, Forward } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import SpaceBetweenCheckbox from "components/SpaceBetweenCheckbox";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import DivInline from "components/DivInline";
import { fetchTimeTableByZc } from "actions/timetableActions";
import General from "@ecoenghk/general";
import { addZcTimeSetting, updateZcProperty } from "actions/zcActions";
import DivCol from "components/DivCol";
import EditTimeSettingModal from "./EditTimeSettingModal";
const gs = new General();

export default function ZcPanelTimeSetting() {
  const [state, dispatch] = useContext(SMContext);
  const { activeZoneControlID, zoneControlObjAll, timeTableObjAll } = state;
  const zcObj = zoneControlObjAll[activeZoneControlID];
  // timeTableID1 - setting1 - Timer end
  // timeTableID2 - setting2 - Timer start
  const [openEdit, setOpenEdit] = useState(false);
  const [editTobj1, setEditTobj1] = useState({});
  const [editTobj2, setEditTobj2] = useState({});
  const { tcEnable, timeTableID1, timeTableID2 } = zcObj || {};
  useEffect(() => {
    if (timeTableID1 && timeTableID2) {
      fetchTimeTable();
    }
  }, [timeTableObjAll]);
  const fetchTimeTable = async () => {
    const objAll = await fetchTimeTableByZc(activeZoneControlID);
    dispatch({
      type: "FETCHED_MANY_TIMETABLE_OBJ",
      payload: objAll,
    });
  };

  const add0 = (n) => {
    const s = n < 10 ? "0" + n : n;
    return s;
  };
  const weekdayList = (arr) => {
    let str = "";
    (arr || []).forEach((n) => {
      const s = gs.weekDayStringOf(n);
      str = str + s + " ";
    });
    return str;
  };
  return (
    <div>
      <SpaceBetweenCheckbox
        title="Enable timer"
        checkboxColor="secondary"
        data={tcEnable || false}
        onCheck={async (e) => {
          const updateObj = { tcEnable: Boolean(e.target.checked) };
          await updateZcProperty(activeZoneControlID, updateObj);
        }}
      />
      <SpaceBetweenButton
        title="Add new time"
        btnContent={<Add />}
        onBtnClick={async () => addZcTimeSetting(activeZoneControlID, zcObj)}
      />
      <Divider10 />
      <DivExist show={tcEnable}>
        {(timeTableID1 || []).map((tid1, key) => {
          const tid2 = timeTableID2[key];
          const tobj1 = timeTableObjAll?.[tid1];
          const tobj2 = timeTableObjAll?.[tid2];
          const weekday1 = tobj1?.isoWeekday;
          const weekday2 = tobj2?.isoWeekday;
          return (
            <Box
              key={key}
              onClick={() => {
                setOpenEdit(true);
                setEditTobj1(tobj1);
                setEditTobj2(tobj2);
              }}
              sx={{
                margin: "10px 5px 10px 5px",
                padding: "0.5vh",
                border: "0.5px solid transparent",
                "&:hover": { borderColor: "#fff" },
              }}
            >
              <DivInline justifyContent="space-around">
                <DivCol
                  sx={{
                    border: "1px solid rgb(159,220,249)",
                    borderRadius: "0.5vw",
                    cursor: "pointer",
                    padding: "0.5vw",
                  }}
                >
                  <Typography variant="caption">{tid2}</Typography>
                  <Typography>{`${add0(tobj2?.hour[0])}:${add0(
                    tobj2?.minute[0]
                  )}`}</Typography>
                  <Typography variant="caption">
                    {weekdayList(weekday2)}
                  </Typography>
                  <DivExist show={tobj2?.excludeHoliday}>
                    <Typography variant="caption">Exclude holiday</Typography>
                  </DivExist>
                  <DivExist show={tobj2?.onlyHoliday}>
                    <Typography variant="caption">Only holiday</Typography>
                  </DivExist>
                </DivCol>
                <Box>
                  <Forward style={{ fontSize: "2em" }} />
                </Box>
                <DivCol
                  sx={{
                    border: "1px solid rgb(159,220,249)",
                    borderRadius: "0.5vw",
                    padding: "0.5vw",
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="caption">{tid1}</Typography>
                  <Typography>{`${add0(tobj1?.hour[0])}:${add0(
                    tobj1?.minute[0]
                  )}`}</Typography>
                  <Typography variant="caption">
                    {weekdayList(weekday1)}
                  </Typography>
                  <DivExist show={tobj1?.excludeHoliday}>
                    <Typography variant="caption">Exclude holiday</Typography>
                  </DivExist>
                  <DivExist show={tobj1?.onlyHoliday}>
                    <Typography variant="caption">Only holiday</Typography>
                  </DivExist>
                </DivCol>
              </DivInline>
            </Box>
          );
        })}
      </DivExist>
      <EditTimeSettingModal
        open={openEdit}
        onClose={() => {
          setOpenEdit(false);
          setEditTobj1({});
          setEditTobj2({});
        }}
        timeTableObj1={editTobj1}
        timeTableObj2={editTobj2}
      />
    </div>
  );
}
