import { mgFindOne, mgFindAll, mgFindByArray } from "./mongoApiActions";
import General from "@ecoenghk/general";
const gs = new General();

export const fetchOneMap = async (dispatch, mapID) => {
  const obj = await mgFindOne("map", "mapID", mapID);
  dispatch({
    type: "UPDATED_MAP_OBJ",
    payload: obj,
  });
};
export const fetchAllMap = async () => {
  const data = await mgFindAll("map");
  console.log("fetchAllMap", data);
  let objAll = {};
  data.forEach((obj) => {
    const mapID = obj.mapID;
    objAll[mapID] = obj;
  });
  return objAll;
};
export const createMap = async (socket, userObj, sizeX, sizeY, mapName) => {
  const mapID = "map_" + gs.getRandomHexID(4);
  const jsonData = {
    type: "createMap",
    userObj,
    sizeX,
    sizeY,
    mapID,
    mapName,
  };
  console.log(
    `[SOCKET OUT] createMap ${mapID} ${mapName} at user uid ${userObj.uid}`
  );
  socket.emit("fromWeb", jsonData);
  return mapID;
};
export const importMap = async (socket, userObj, mapID, mapObj) => {
  const jsonData = {
    type: "importMap",
    userObj,
    mapID,
    mapObj,
  };
  console.log(`[SOCKET OUT] import map ${mapID} to user uid ${userObj.uid}`);
  socket.emit("fromWeb", jsonData);

  // let mapInUserArr = [...mapObj.inUser];
  // if (!mapInUserArr.includes(userObj.uid))
  //   mapInUserArr = [...mapInUserArr, userObj.uid];
  // await mgUpdateOneUpsert("map", { mapID: mapID }, { inUser: mapInUserArr });
  // let newUserMapArr = [...(userObj.mapArray || [])];
  // if (!newUserMapArr.includes(mapID)) newUserMapArr = [...newUserMapArr, mapID];
  // await mgUpdateOneUpsert(
  //   "user",
  //   { uid: userObj.uid },
  //   { mapArray: newUserMapArr }
  // );
  // const newUserObj = { ...userObj, mapArray: newUserMapArr };
  // const newMapObj = { ...mapObj, inUser: mapInUserArr };
  // dispatch({
  //   type: "MAP_ADDED",
  //   payload: { newMapObj, newUserObj },
  // });
};
export const removeMap = async (userObj, mapObj) => {
  const jsonData = {
    type: "removeMap",
    userObj,
    mapID: mapObj.mapID,
    mapObj,
  };
  console.log(`[SOCKET OUT] removeMap ${mapObj.mapID} from user uid ${userObj.uid}`);
  global.socket.emit("fromWeb", jsonData);
};
export const updateMapProperty = async (mapID, updateObj) => {
  // const res = await mgUpdateOneUpsert(
  //   "map",
  //   { mapID: mapID },
  //   { [field]: value }
  // );
  // console.log(res);
  // dispatch({
  //   type: "UPDATED_MAP_PROPERTY",
  //   payload: { mapID, field, value },
  // });
  // if (enableUploadFS) {
  //   await uploadMapFS(mapID);
  // }
  const jsonData = {
    type: "mapUpdateProperty",
    mapID,
    updateObj,
  };
  console.log(`[SOCKET OUT] mapUpdateProperty ${mapID}`);
  global.socket.emit("fromWeb", jsonData);
};
export const updateDevicePos = async (
  socket,
  mapID,
  deviceType,
  deviceID,
  x,
  y
) => {
  const jsonData = {
    type: "mapUpdateDevicePos",
    mapID,
    deviceType,
    deviceID,
    x,
    y,
  };
  console.log(
    `[SOCKET OUT] ${mapID} ${deviceType} ${deviceID} move to [${x},${y}]`
  );
  socket.emit("fromWeb", jsonData);
};
export const mapRemoveLight = async (mapID, serial) => {
  const type = "mapRemoveLight";
  const jsonData = {
    serial,
    mapID,
    type,
  };
  console.log(`[SOCKET OUT] ${type}`, jsonData);
  global.socket.emit("fromWeb", jsonData);
};
// export const mapRemoveManyLights = async (mapID, serialArray) => {
//   if (serialArray.length === 0 || !mapID || !serialArray) return;
//   const type = "mapRemoveManyLights";
//   const jsonData = {
//     serialArray,
//     mapID,
//     type,
//   };
//   console.log(`[SOCKET OUT] ${type}`, jsonData);
//   global.socket.emit("fromWeb", jsonData);
// };
export const mapRemoveSensor = async (mapID, sensorID) => {
  const jsonData = {
    type: "mapRemoveSensor",
    mapID,
    sensorID,
  };
  console.log("[SOCKET OUT] mapRemoveSensor", jsonData);
  global.socket.emit("fromWeb", jsonData);
};
// export const mapRemoveManySensors = async (mapID, sensorIDArray) => {
//   if (sensorIDArray.length === 0 || !mapID || !sensorIDArray) return;
//   const jsonData = {
//     type: "mapRemoveManySensors",
//     mapID,
//     sensorIDArray,
//   };
//   console.log("[SOCKET OUT] mapRemoveManySensors", jsonData);
//   global.socket.emit("fromWeb", jsonData);
// };
export const mapRemoveGateway = async (mapID, gatewayID, gatewayObj) => {
  const type = "mapRemoveGateway";
  const jsonData = {
    gatewayID,
    gatewayObj,
    mapID,
    type,
  };
  console.log(`[COMMAND OUT] map ${mapID} remove gateway ${gatewayID}`);
  global.socket.emit("fromWeb", jsonData);
};
// export const mapRemoveManyGateways = async (mapID, gatewayIDArray) => {
//   if (gatewayIDArray.length === 0 || !mapID || !gatewayIDArray) return;
//   const jsonData = {
//     gatewayIDArray,
//     mapID,
//     type: "mapRemoveManyGateways",
//   };
//   console.log(`[COMMAND OUT] map ${mapID} remove many gateways`);
//   global.socket.emit("fromWeb", jsonData);
// };
export const mapRemoveDaliCtl = async (mapID, daliCtlID) => {
  const type = "mapRemoveDaliCtl";
  const jsonData = {
    type,
    mapID,
    daliCtlID,
  };
  console.log(`[COMMAND OUT] map ${mapID} remove daliCtl ${daliCtlID}`);
  global.socket.emit("fromWeb", jsonData);
};
// export const mapRemoveManyDaliCtls = async (mapID, daliCtlIDArray) => {
//   if (daliCtlIDArray.length === 0 || !mapID || !daliCtlIDArray) return;
//   const type = "mapRemoveManyDaliCtls";
//   const jsonData = {
//     type,
//     mapID,
//     daliCtlIDArray,
//   };
//   console.log(`[COMMAND OUT] map ${mapID} remove many daliCtls`);
//   global.socket.emit("fromWeb", jsonData);
// };
export const mapAddManyDevices = async (mapID, deviceArrayObj, currentMapObj, lightDaliCtlMap) => {
  if (!mapID || !deviceArrayObj) return;
  const jsonData = {
    mapID,
    deviceArrayObj, //{light:[xxx,xxx], sensor:[xxx,xxx], gateway:[], daliCtl:[]}
    type: "mapAddManyDevices",
    currentMapObj,
    lightDaliCtlMap
  };
  console.log(`[COMMAND OUT] map ${mapID} AddManyDevices ~ `, jsonData);
  global.socket.emit("fromWeb", jsonData);
}
export const mapRemoveManyDevices = async (mapObj, deviceArrayObj) => {
  if (!mapObj || !deviceArrayObj) return;
  const jsonData = {
    mapObj,
    deviceArrayObj, //{light:[xxx,xxx], sensor:[xxx,xxx], gateway:[], daliCtl:[]}
    type: "mapRemoveManyDevices",
  };
  console.log(`[COMMAND OUT] map ${mapObj.mapID} RemoveManyDevices ~ `, jsonData);
  global.socket.emit("fromWeb", jsonData);

}
export const mapAddLight = async (mapID, serial, lightObj, x, y) => {
  const type = "mapAddLight";
  const jsonData = {
    serial,
    lightObj,
    mapID,
    type,
    x: x ?? gs.randomInteger(140, 180),
    y: y ?? gs.randomInteger(140, 180),
  };
  console.log(`[SOCKET OUT] ${type}`, jsonData);
  global.socket.emit("fromWeb", jsonData);
};
// export const mapAddManyLights = async (mapID, serialArray, currentMapObj, lightDaliCtlMap) => {
//   if (serialArray.length === 0 || !mapID || !serialArray) return;
//   const type = "mapAddManyLights";
//   const jsonData = {
//     serialArray,
//     mapID,
//     type,
//     lightDaliCtlMap,
//     currentMapObj,
//   };
//   console.log(`[SOCKET OUT] mapAddManyLights`, jsonData);
//   global.socket.emit("fromWeb", jsonData);
// };
export const mapAddSensor = async (mapID, sensorID, sensorObj, x, y) => {
  const jsonData = {
    type: "mapAddSensor",
    gatewayID: sensorObj.gatewayID || "",
    mapID,
    sensorID,
    sensorObj,
    x: x ?? gs.randomInteger(190, 230),
    y: y ?? gs.randomInteger(190, 230),
  };
  console.log("[SOCKET OUT] mapAddSensor", jsonData);
  global.socket.emit("fromWeb", jsonData);
};
// export const mapAddManySensors = async (mapID, sensorIDArray, currentMapObj) => {
//   if (sensorIDArray.length === 0 || !mapID || !sensorIDArray) return;
//   const jsonData = {
//     type: "mapAddManySensors",
//     mapID,
//     sensorIDArray,
//     currentMapObj,
//   };
//   console.log("[SOCKET OUT] mapAddManySensors", jsonData);
//   global.socket.emit("fromWeb", jsonData);
// };
export const mapAddGateway = async (mapID, gatewayID, gatewayObj, x, y) => {
  const type = "mapAddGateway";
  const jsonData = {
    gatewayID,
    gatewayObj,
    mapID,
    type,
    x: x ?? gs.randomInteger(180, 220),
    y: y ?? gs.randomInteger(180, 220),
  };
  console.log(`[COMMAND OUT] map ${mapID} add gateway ${gatewayID}`);
  global.socket.emit("fromWeb", jsonData);
};
// export const mapAddManyGateways = async (mapID, gatewayIDArray, currentMapObj) => {
//   if (gatewayIDArray.length === 0 || !mapID || !gatewayIDArray) return;
//   const type = "mapAddManyGateways";
//   const jsonData = {
//     gatewayIDArray,
//     mapID,
//     type,
//     currentMapObj,
//   };
//   console.log(`[COMMAND OUT] map ${mapID} add many gateways`, jsonData);
//   global.socket.emit("fromWeb", jsonData);
// };
export const mapAddDaliCtl = async (mapID, daliCtlID, daliCtlObj, x, y) => {
  const type = "mapAddDaliCtl";
  const jsonData = {
    daliCtlID,
    daliCtlObj,
    mapID,
    type,
    x: x ?? gs.randomInteger(100, 140),
    y: y ?? gs.randomInteger(100, 140),
  };
  console.log(`[COMMAND OUT] map ${mapID} add daliCtl ${daliCtlID}`);
  global.socket.emit("fromWeb", jsonData);
};
// export const mapAddManyDaliCtls = async (mapID, daliCtlIDArray, currentMapObj) => {
//   if (daliCtlIDArray.length === 0 || !mapID || !daliCtlIDArray) return;
//   const type = "mapAddManyDaliCtls";
//   const jsonData = {
//     daliCtlIDArray,
//     mapID,
//     type,
//     currentMapObj,
//   };
//   console.log(`[COMMAND OUT] map ${mapID} add many daliCtls`, jsonData);
//   global.socket.emit("fromWeb", jsonData);
// };
export const updateMapsFsToLs = async (socket) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "map",
  };
  await socket.emit("fromWeb", payload);
};
export const updateSingleMapFsToLs = async (socket, mapID) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "singleMap",
    mapID: mapID,
  };
  await socket.emit("fromWeb", payload);
};
// export const fetchRssiByGwArr = async (dispatch, gwArr) => {
//   console.log({ gwArr });
//   const arr = await mgFindByArray("rssi", "gatewayID", gwArr);
//   console.log(arr);
//   dispatch({
//     type: "SET_RSSIWEB",
//     payload: arr,
//   });
// };
// export const deleteRssiWeb = async (gatewayArray) => {
//   const type = "mapDeleteRssiWeb";
//   const jsonData = {
//     type,
//     gatewayArray,
//   };
//   console.log(`[COMMAND OUT] deleteRssiWeb ${gatewayArray}`);
//   global.socket.emit("fromWeb", jsonData);
// };
