import {
  deleteFsDocs_arrayContains,
  deleteFsDocs_equal,
  mgDeleteMany,
  mgFindByArray,
  mgFindByMatch,
  mgUpdateOneUpsert,
} from "./mongoApiActions";
import moment from "moment";
import General from "@ecoenghk/general";
const gs = new General();

export const fetchTimeTableByLightArray = async (lightArr, dispatch) => {
  const arr = await mgFindByArray("timeTable", "serial", lightArr || []);
  let objAll = {};
  let arrAll = [];
  lightArr.forEach((s) => {
    objAll[s] = [];
  });
  arr.forEach((obj) => {
    const timeTableID = obj.timeTableID;
    objAll[timeTableID] = obj;
    arrAll.push(obj);
  });
  console.log("fetchTimeTableByLightArray", objAll);
  dispatch({
    type: "FETCHED_TIMETABLE_OBJ_ALL",
    payload: { objAll, arrAll },
  });
};

export const saveTestTimeTable = async (
  socket,
  serial,
  batteryHour,
  monthTimeTableObj,
  annualTimeTableObj
) => {
  const jsonData = {
    type: "updateELightTestTimeTable",
    serial,
    batteryHour,
    monthTimeTableObj,
    annualTimeTableObj,
  };
  console.log(`[SOCKET OUT] ${serial} updateELightTestTimeTable`);
  socket.emit("fromWeb", jsonData);
};
// export const deleteAllTestTimeTableOfSerial = async (serial) => {
//   // const mgQuery = {
//   //   $and: [{ serial: serial }, { timeActionType: "scheduledTest_single" }],
//   // };
//   const resultMg = await mgDeleteMany("timeTable", mgQuery);
//   console.log(resultMg);
//   if (enableUploadFS) {
//     await gs.asyncForEach(timeTableObjArr, async (obj) => {
//       const { scheduleID } = obj;
//       await deleteFsDocs_equal(
//         "SM_timeTable",
//         "scheduleID",
//         scheduleID,
//         "scheduleID"
//       );
//       await gs.waitFor(300);
//     });
//   }
// };
export const deleteTestTimeTable = async (
  monthTimeTableID,
  annualTimeTableID
) => {
  const type = "deleteManyTimeTables";
  const jsonData = {
    type,
    timeTableIDArray: [monthTimeTableID, annualTimeTableID],
  };
  console.log(
    `[COMMAND OUT] delete Test TimeTables ${monthTimeTableID} ${annualTimeTableID}`
  );
  global.socket.emit("fromWeb", jsonData);
  // const resultMg1 = await mgDeleteMany("timeTable", {
  //   scheduleID: monthScheduleID,
  // });
  // await gs.waitFor(500);
  // const resultMg2 = await mgDeleteMany("timeTable", {
  //   scheduleID: annualScheduleID,
  // });
  // console.log({ resultMg1, resultMg2 });
  // if (serverObj.enableUploadFS) {
  //   const result1 = await deleteFsDocs_equal(
  //     "SM_timeTable",
  //     "scheduleID",
  //     monthScheduleID,
  //     "scheduleID"
  //   );
  //   const result2 = await deleteFsDocs_equal(
  //     "SM_timeTable",
  //     "scheduleID",
  //     annualScheduleID,
  //     "scheduleID"
  //   );
  //   console.log({ result1, result2 });
  // }
};
export const deleteSingleTimeTable = async (timeTableID, enableUploadFS) => {
  const result = await mgDeleteMany("timeTable", { scheduleID: timeTableID });
  console.log(result);
  if (enableUploadFS) {
    const resultFS = await deleteFsDocs_equal(
      "SM_timeTable",
      "scheduleID",
      timeTableID,
      "scheduleID"
    );
    console.log(resultFS);
  }
};
export const fetchTimeTableByZc = async (zcID) => {
  const arr = await mgFindByMatch("timeTable", "zoneControlID", zcID);
  let objAll = {};
  arr.forEach((obj) => {
    const key = obj.timeTableID;
    objAll[key] = obj;
  });
  console.log("fetchTimeTableByZc", objAll);
  return objAll;
};

export const updateManyTimeTables = async (socket, updateObjArray) => {
  const type = "updateManyTimeTables";
  const jsonData = {
    type,
    updateObjArray,
  };
  let msg = `[SOCKET OUT] updateManyTimeTables`;
  updateObjArray.forEach((obj) => {
    msg += ` ${obj.timeTableID},`;
  });
  console.log(msg);
  socket.emit("fromWeb", jsonData);
};
export const manualExecuteTimeTable = async (timeTableObj) => {
  const type = "manualExecuteTimeTable";
  const jsonData = {
    type,
    timeTableObj,
  };
  console.log(
    `[COMMAND OUT] manualExecuteTimeTable ${timeTableObj.timeTableID}`
  );
  global.socket.emit("fromWeb", jsonData);
};
