import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Popover, Box } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";
import moment from "moment";
import DivExist from "components/DivExist";
import { getSchBoxColorText } from "actions/scheduleActions";
// import { updateDocLF } from "actions/dbActions";
// import DialogInputScheduleTime from "./DialogInputScheduleTime";

export default function SchBox({ schObj, selected }) {
  const [, dispatch] = useContext(SMContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { no, reportType, schedule_timeStamp, rescheduleCount } = schObj;
  const { backgroundColor, tooltipText } = getSchBoxColorText(reportType);
  const opacity =
    schedule_timeStamp > moment().tz("Asia/Hong_Kong").valueOf() ||
    !schedule_timeStamp
      ? 1
      : 0.5;
  const currentTS = moment().tz("Asia/Hong_Kong").valueOf();
  const nextMonthTS = moment().tz("Asia/Hong_Kong").add(1, "months").valueOf();

  async function handleChangeScheduleTime(newTSP) {
    const obj = {
      schedule_timeStamp: newTSP,
      schedule_timeString: moment(newTSP).format("YYYY-MM-DD,HH:mm:ss"),
    };
    // console.log(obj);
    // await updateDocLF(
    //   serverType,
    //   `SM_schedule/${scheduleID}`,
    //   "schedule",
    //   { scheduleID: scheduleID },
    //   obj
    // );
    // const newSchObj = { ...schObj, ...obj };
    // dispatch({
    //   type: "UPDATED_SINGLE_TIME_SCHEDULE",
    //   payload: newSchObj,
    // });
  }
  return (
    <>
      <Box sx={{ width: "2.6vw", margin: "0.2vw" }}>
        <div
          onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
          onMouseLeave={() => setAnchorEl(null)}
          style={{
            backgroundColor,
            padding: 2,
            opacity,
            cursor: "pointer",
            border: selected ? "3px solid red" : "",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography display="block">{no}</Typography>
        </div>
        <DivExist
          show={
            currentTS < schedule_timeStamp && schedule_timeStamp < nextMonthTS
          }
        >
          <div style={{ textAlign: "center" }}>
            <ArrowUpward color="secondary" fontSize="small" />
          </div>
        </DivExist>
      </Box>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Box
          sx={{
            padding: "5px",
            border: "1px solid lightgrey",
            backgroundColor,
          }}
        >
          <Typography sx={{ color: tooltipText }}>{no}</Typography>
          <Typography variant="caption">{reportType}</Typography>
          <Typography display="block" variant="caption">
            {moment(schedule_timeStamp).format("YYYY-MM-DD")}
          </Typography>
          <Typography display="block" variant="caption">
            {moment(schedule_timeStamp).format("HH:mm")}
          </Typography>
          {rescheduleCount > 0 && (
            <Typography display="block" variant="caption">
              Reschedule:{rescheduleCount}
            </Typography>
          )}
        </Box>
      </Popover>
    </>
  );
}
