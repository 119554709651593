/**
 * ButtonSM component represents a small button with customizable properties.
 *
 * @component
 * @param {string} [contentDirection="row"] - The direction of the button content. Can be "row" or "column".
 * @param {ReactNode} children - The content of the button.
 * @param {function} onClick - The function to be called when the button is clicked.
 * @param {string} [margin="1vw"] - The margin of the button.
 * @param {string} [mouseOverBorder="#fff"] - The border color when the mouse is over the button.
 * @param {string} [mouseOutBorder="grey"] - The border color when the mouse is not over the button.
 * @param {object} [sx] - Additional styles to be applied to the button.
 * @param {string} [tooltip] - The tooltip text to be displayed when hovering over the button.
 * @returns {JSX.Element} The rendered ButtonSM component.
 */
import { useState } from "react";
import { Tooltip } from "@mui/material";

export default function ButtonSM({
  contentDirection = "row",
  children,
  onClick,
  margin = "1vw",
  mouseOverBorder = "#fff",
  mouseOutBorder = "grey",
  sx,
  tooltip,
  disabled,
  padding,
}) {
  const [mouseEnter, setMouseEnter] = useState(false);
  const handleClick = async (e) => {
    if (!disabled) {
      await onClick(e);
    }
  };
  return (
    <Tooltip title={tooltip || ""}>
      <div
        style={{
          display: "flex",
          flexDirection: contentDirection,
          alignItems: "center",
          justifyContent: "center",
          margin,
          cursor: disabled ? "not-allowed" : "pointer",
          padding: padding || "0.5vh",
          outline: `0.5px solid ${
            mouseEnter ? mouseOverBorder : mouseOutBorder
          }`,
          borderRadius: "0.4vw",
          background: mouseEnter ? "rgba(230,230,230,0.1" : "",
          ...sx,
        }}
        onClick={handleClick}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
      >
        {children}
      </div>
    </Tooltip>
  );
}
