import { useContext } from "react";
import { SMContext } from "context/smContext";
import SpaceBetweenSwitch from "components/SpaceBetweenSwitch";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import IconButtonRefresh from "components/IconButtonRefresh";
import { queryGatewayToDeviceComAllow, updateGatewayToDeviceComAllow } from "actions/gatewayActions";
import { deviceInfoOfObj } from "actions/generalActions";
import { changeSensorOneSetting, queryOneSensorSetting, querySensorComType } from "actions/sensorActions";
import { changeOneLightSetting, queryOneLightSetting, queryLightComType } from "actions/lightActions";
import { changeDaliCtlOneSetting, queryDaliCtlOneSetting, queryDaliCtlComType } from "actions/daliCtlActions";
import DivExist from "components/DivExist";
import InterfaceMesh255Panel from "./InterfaceMesh255Panel";
import InterfaceDtkPanel from "./InterfaceDtkPanel";


export default function DeviceInterfaceTtyPanel({ deviceObj }) {
    const [state, dispatch] = useContext(SMContext);
    const { gatewayID, dtkAdd } = deviceObj || {};
    const deviceInfo = deviceInfoOfObj(deviceObj);
    const { deviceID, deviceType } = deviceInfo;
    const interfaceType = deviceObj.dtkChannel ? "DTK" : deviceObj.meshChannel >= 0 ? "Mesh255" : ""
    return (
        <>
            <SpaceBetweenDiv
                title="Query interface enable/disable"
                data={
                    <IconButtonRefresh onBtnClick={() => queryGatewayToDeviceComAllow(gatewayID, deviceObj)} />
                }
            />
            {/* <SpaceBetweenSwitch
                hideComponent={!dtkAdd}
                title="Disable interface ttyS2"
                data={deviceObj.disableTtyS2 ? true : false}
                onChange={async (e) => {
                    let val = e.target.checked ? 1 : 0;
                    await updateGatewayToDeviceComAllow(gatewayID, deviceID, deviceType, { disableTtyS2: val });
                }}
            />
            <SpaceBetweenSwitch
                hideComponent={!dtkAdd}
                title="Disable interface ttyS3"
                data={deviceObj.disableTtyS3 ? true : false}
                onChange={async (e) => {
                    let val = e.target.checked ? 1 : 0;
                    await updateGatewayToDeviceComAllow(gatewayID, deviceID, deviceType, { disableTtyS3: val });
                }}
            /> */}
            <SpaceBetweenSwitch
                hideComponent={!dtkAdd}
                title="Disable interface ttyS3 and ttyS2"
                data={deviceObj.disableTtyS2 ? true : false}
                onChange={async (e) => {
                    let val = e.target.checked ? 1 : 0;
                    await updateGatewayToDeviceComAllow(gatewayID, deviceID, deviceType, { disableTtyS3: val, disableTtyS2: val });
                }}
            />
            <SpaceBetweenSwitch
                hideComponent={!dtkAdd}
                title="Enable interface ttyS7(ESP)"
                data={deviceObj.enableTtyS7 ? true : false}
                onChange={async (e) => {
                    let val = e.target.checked ? 1 : 0;
                    await updateGatewayToDeviceComAllow(gatewayID, deviceID, deviceType, { enableTtyS7: val });
                }}
            />
            <SpaceBetweenSwitch
                hideComponent={!dtkAdd}
                title="Disable ESP com"
                data={deviceObj.disableEspNow ? true : false}
                onChange={async (e) => {
                    let val = e.target.checked ? 1 : 0;
                    const updateObj = { disableEspNow: val };
                    if (deviceType === "light") {
                        await changeOneLightSetting(deviceObj, 0x229, updateObj);
                    } else if (deviceType === "sensor") {
                        await changeSensorOneSetting(deviceObj, 0x0626, updateObj);
                    } else if (deviceType === "daliCtl") {
                        await changeDaliCtlOneSetting(deviceObj, 0x0a23, updateObj);
                    }
                }}
                handleRefresh={async () => {
                    if (deviceType === "light") {
                        await queryOneLightSetting(deviceObj, 0x316);
                    } else if (deviceType === "sensor") {
                        await queryOneSensorSetting(deviceObj, 0x071c);
                    } else if (deviceType === "daliCtl") {
                        await queryDaliCtlOneSetting(deviceObj, 0x0b11);
                    }
                }}
            />
            <SpaceBetweenSwitch
                hideComponent={!dtkAdd}
                data={deviceObj.enableEspOut ? true : false}
                title="Enable ESP out"
                onChange={async (e) => {
                    let val = e.target.checked ? 1 : 0;
                    const updateObj = { enableEspOut: val };
                    if (deviceType === "light") {
                        await changeOneLightSetting(deviceObj, 0x022d, updateObj);
                    } else if (deviceType === "sensor") {
                        await changeSensorOneSetting(deviceObj, 0x0627, updateObj);
                    } else if (deviceType === "daliCtl") {
                        await changeDaliCtlOneSetting(deviceObj, 0x0a24, updateObj);
                    }
                }}
                handleRefresh={async () => {
                    if (deviceType === "light") {
                        await queryOneLightSetting(deviceObj, 0x317);
                    } else if (deviceType === "sensor") {
                        await queryOneSensorSetting(deviceObj, 0x071e);
                    } else if (deviceType === "daliCtl") {
                        await queryDaliCtlOneSetting(deviceObj, 0x0b12);
                    }
                }}
            />

            <SpaceBetweenDiv
                title="Com type"
                data={deviceObj.dtkChannel ? "DTK" : (deviceObj.meshChannel || deviceObj.meshChannel === 0) ? "Mesh255" : ""}
                handleRefresh={async () => {
                    if (deviceType === "light") await queryLightComType(deviceObj);
                    else if (deviceType === "sensor") await querySensorComType(deviceObj);
                    else if (deviceType === "daliCtl") await queryDaliCtlComType(deviceObj);
                }}
            />
            <DivExist show={interfaceType === "Mesh255"}>
                <InterfaceMesh255Panel
                    deviceID={deviceID}
                    deviceObj={deviceObj}
                    deviceType={deviceType}
                />
            </DivExist>
            <DivExist show={interfaceType === "DTK"}>
                <InterfaceDtkPanel
                    deviceID={deviceID}
                    deviceObj={deviceObj}
                    deviceType={deviceType}
                />
            </DivExist>

        </>
    )
}