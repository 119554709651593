import { useState, useRef, useEffect } from "react";
import { Circle, Group, Text } from "react-konva";
import DivExist from "components/DivExist";
import KonvaLabel from "containers/map/device/KonvaLabel";
import MapIconLight from "./MapIconLight";
import GearChecking from "asset/mapComp/GearChecking";
import { deviceConnected, lightColorDark } from "actions/generalActions";
import WarningSign from "asset/mapComp/WarningSign";
import { konvaShrinkAndBack } from "actions/konvaActions";
import { updateDevicePos } from "actions/mapActions";
import ShiftSelectCircle from "./ShiftSelectCircle";

export default function DeviceLight({ layerProps, x, y, serial, lightObj }) {
  const {
    mapScale,
    mapObjAll,
    activeMapID,
    selectedID,
    gatewayObjAll,
    enablePan,
    dispatch,
    layerRef,
    iconSize,
    mapLayer,
    socket,
    shiftSelectedArray,
    mapSize,
    layerScale,
    layerPos,
    daliCtlObjAll
  } = layerProps;
  const activeMapObj = mapObjAll[activeMapID];
  const [showID, setShowID] = useState(false);
  const [scale, setScale] = useState(1);
  const [shadEnabled, setShadEnabled] = useState(false);
  const [toRender, setToRender] = useState(true);
  // const [showMapDeviceMsg, setShowMapDeviceMsg] = useState(false);
  const deviceRef = useRef(null);
  // const mapMsgRef = useRef(null);
  useEffect(() => {
    if (deviceRef.current) {
      const p = deviceRef.current.absolutePosition();
      if (p.x < 0 || p.x > mapSize.x || p.y < 0 || p.y > mapSize.y) setToRender(false);
      else setToRender(true);
    }
  }, [layerScale, layerPos])

  const handleDragStart = () => {
    setShadEnabled(true);
  };
  const handleDragEnd = async (e) => {
    e.evt.preventDefault();
    const unScaleX = e.target.x() / mapScale.x;
    const unScaleY = e.target.y() / mapScale.y;
    setShadEnabled(false);
    const newX = unScaleX > 1000 ? 970 : unScaleX < 0 ? 30 : unScaleX;
    const newY = unScaleY > 600 ? 570 : unScaleY < 0 ? 30 : unScaleY;
    await updateDevicePos(socket, activeMapID, "light", serial, newX, newY);
  };
  const selected = selectedID === serial ? true : false;
  const shiftSelected = (shiftSelectedArray || [])
    .map((obj) => obj.deviceID)
    .includes(serial);
  const {
    description,
    style,
    gatewayID,
    type,
    currentData,
    zigbeeConnected,
    daliCtlID,
    faultObj,
    reversePolarityI,
    timeStamp,
    result,
    progress,
  } = lightObj || {};
  const faultCode = faultObj?.faultCode;
  // const faultTSP = faultObj?.timeStamp;
  const { onoffstatusL, current, bypass } = currentData || {};
  let lightLv = currentData?.lightLv || 0;
  let onoffstatusA = currentData?.onoffstatusA;
  if (daliCtlID) {
    const daliCtlObj = daliCtlObjAll[daliCtlID];
    if (!daliCtlObj?.powerOnOffStatus) lightLv = 0;
  }
  if (lightLv === 0) onoffstatusA = 1;
  let tooltipDescription = description;
  if ((current > 0 && !reversePolarityI) || (current < 0 && reversePolarityI)) tooltipDescription += "(discharging)";
  const gatewayConnected = gatewayObjAll?.[gatewayID]?.gatewayConnected;
  const iconBackground = activeMapObj?.iconBackground || "";
  let alertText = "";
  if (!gatewayID) {
    alertText += "Not connected to any gateway!\n";
  } else {
    if (Object.keys(activeMapObj?.gatewayObj).includes(gatewayID)) {
      if (!gatewayConnected) {
        alertText += "gateway disconnected!\n";
      }
    } else {
      alertText += "gateway not in this map!\n";
    }
  }
  if (faultCode > 0) {
    alertText += `Light fault occurs-F${faultCode}`;
  }
  const connected = deviceConnected(
    zigbeeConnected,
    timeStamp,
    gatewayConnected
  );
  function handleClickDevice(e) {
    //if (!disableClick) {
    e.evt.preventDefault();
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
    if (e.evt.shiftKey) {
      if (!shiftSelected) {
        dispatch({
          type: "SHIFT_SELECTED_DEVICE",
          payload: {
            deviceID: serial,
            deviceType: "light",
            deviceObj: lightObj,
          },
        });
      } else {
        dispatch({
          type: "SHIFT_UNSELECTED_DEVICE",
          payload: {
            deviceID: serial,
          },
        });
      }
    } else {
      dispatch({
        type: "DEVICE_SELECTED",
        payload: {
          deviceID: serial,
          deviceType: "light",
          deviceObj: lightObj,
          mapRightMode: "light",
          x,
          y,
          layerScale,
        },
      });
    }
    konvaShrinkAndBack(deviceRef, 0.8, 0.15);
  }

  return (
    <>
      <Group
        x={x}
        y={y}
        ref={deviceRef}
        draggable={!enablePan}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        {toRender && (
          <>
            <DivExist show={result === "started"}>
              <GearChecking
                x={iconSize * mapScale.x * 0.2}
                y={iconSize * mapScale.y * 1.05}
                size={iconSize * 0.8}
              />
              <Text
                x={Number(iconSize * mapScale.x)}
                y={Number(iconSize * mapScale.y * 0.9)}
                fontSize={iconSize * mapScale.x * 0.4}
                text={`${progress?.toFixed(0)}%`}
                fill="darkgreen"
              />
            </DivExist>
            <DivExist show={showID && alertText}>
              <KonvaLabel
                x={iconSize * mapScale.x * 2.5}
                y={iconSize * mapScale.y * -0.8}
                text={alertText}
                textColor="red"
                fontSize={12}
                tagColor="transparent"
                scale={scale}
              />
            </DivExist>
            <DivExist show={showID}>
              <KonvaLabel
                x={0}
                y={iconSize * mapScale.y}
                text={tooltipDescription}
                fontSize={12}
                tagColor={lightColorDark}
                scale={scale}
              />
            </DivExist>
            <ShiftSelectCircle
              shiftSelected={shiftSelected}
              iconSize={iconSize}
              scale={scale}
            />
            <MapIconLight
              x={0}
              y={0}
              lightStyle={style}
              iconSize={iconSize}
              mapScale={mapScale}
              onClick={handleClickDevice}
              onMouseOver={(e) => {
                const layer = layerRef.current;
                const layerScale = layer.scaleX();
                setScale(layerScale);
                setShowID(true);
                const container = e.target.getStage().container();
                container.style.cursor = enablePan ? "pointer" : "move";
              }}
              onMouseLeave={(e) => {
                setShowID(false);
                const container = e.target.getStage().container();
                container.style.cursor = enablePan ? "grab" : "default";
              }}
              onoffstatusA={onoffstatusA}
              onoffstatusL={onoffstatusL}
              type={type}
              lightLv={lightLv}
              connectStatus={connected}
              outline={selected}
              shadEnabled={shadEnabled}
              timeStamp={timeStamp}
              scale={scale}
              iconBackground={iconBackground}
              bypass={bypass}
            />
          </>)}
        {/* {
          showMapDeviceMsg && (
            <Text
              ref={mapMsgRef}
              x={0} y={iconSize * mapScale.y * 1.5} fontSize={iconSize * mapScale.x * 0.4} text={mapDeviceMessage[serial]} fill="red" />
          )
        } */}
        {/* <DivExist show={alertText && !activeMapObj.disableAlert ? true : false}>
          <WarningSign
            x={Number(iconSize * mapScale.x)}
            y={0}
            size={iconSize * mapScale.x * 0.55}
          />
        </DivExist> */}
      </Group>
    </>
  );
}
