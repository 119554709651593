import { useState, useContext, useMemo, useRef } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Box, Tooltip, ButtonGroup } from "@mui/material";
import {
  Highlight,
  CloudDone,
  CloudOff,
  Battery80,
  Lens,
  ToggleOn,
  BlurLinear,
} from "@mui/icons-material";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import moment from "moment";
import ModalSM from "components/ModalSM";
import IconButtonBack from "components/IconButtonBack";
import LightIcon from "components/LightIcon";
import Divider10 from "components/Divider10";
import SensorIcon from "components/SensorIcon";
import { isMatch, secToMin, tspToDateTime } from "actions/generalActions";
import MapTopBtn from "containers/home/MapTopBtn";
import { ViewportList } from "react-viewport-list";
import InputField from "components/InputField";
import IconButtonDelete from "components/IconButtonDelete";
import AdminContainer from "components/AdminContainer";

const w = window.innerWidth;
const h = window.innerHeight;
export default function DeviceListModal({ btnSize }) {
  const [state, dispatch] = useContext(SMContext);
  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const [open, setOpen] = useState(false);
  const [showList, setShowList] = useState("light");
  const [searchText, setSearchText] = useState("");
  const { lightObjAll, gatewayObjAll, sensorObjAll, daliCtlObjAll } = state;
  const lightObjArr = useMemo(() => {
    let arr = Object.keys(lightObjAll)
      .map((s) => lightObjAll[s])
      .sort((a, b) => a.description?.localeCompare(b.description));
    if (searchText !== "" && showList === "light") {
      arr = arr.filter(
        (s) =>
          isMatch(s.description, searchText) ||
          isMatch(s.serial, searchText) ||
          isMatch(s.zigbeeAdd, searchText) ||
          isMatch(s.deviceVersion, searchText) ||
          isMatch(s.gatewayID, searchText) ||
          isMatch(s.daliCtlID, searchText) ||
          isMatch(s.dtkAdd, searchText) ||
          isMatch(s.style, searchText)
      );
    }
    const titleRow = {
      serial: "Serial no",
      description: "Description",
      zigbeeAdd: "Address",
      deviceVersion: "Firmware",
    };
    return [titleRow, ...arr];
  }, [lightObjAll, searchText, showList]);
  const gatewayObjArr = useMemo(() => {
    let arr = Object.keys(gatewayObjAll)
      .map((s) => gatewayObjAll[s])
      .sort((a, b) => a.description?.localeCompare(b.description));
    if (searchText !== "" && showList === "gateway") {
      arr = arr.filter(
        (s) =>
          isMatch(s.description, searchText) ||
          isMatch(s.gatewayID, searchText) ||
          isMatch(s.zigbeeAdd, searchText) ||
          isMatch(s.deviceVersion, searchText) ||
          isMatch(s.dtkAdd, searchText)
      );
    }
    const titleRow = {
      gatewayID: "ID",
      description: "Description",
      zigbeeAdd: "Address",
      gwVersion: "Firmware",
      serverID: "serverID",
      mac: "MAC",
    };
    return [titleRow, ...arr];
  }, [gatewayObjAll, searchText, showList]);
  const sensorObjArr = useMemo(() => {
    let arr = Object.keys(sensorObjAll)
      .map((s) => sensorObjAll[s])
      .sort((a, b) => a.sensorName?.localeCompare(b.sensorName));
    if (searchText !== "" && showList === "sensor") {
      arr = arr.filter(
        (s) =>
          isMatch(s.sensorName, searchText) ||
          isMatch(s.sensorID, searchText) ||
          isMatch(s.zigbeeAdd, searchText) ||
          isMatch(s.deviceVersion, searchText) ||
          isMatch(s.gatewayID, searchText) ||
          isMatch(s.daliCtlID, searchText) ||
          isMatch(s.dtkAdd, searchText) ||
          isMatch(s.type, searchText)
      );
    }
    const titleRow = {
      sensorID: "ID",
      sensorName: "Name",
      zigbeeAdd: "Address",
      deviceVersion: "Firmware",
    };
    return [titleRow, ...arr];
  }, [sensorObjAll, searchText, showList]);
  const daliCtlObjArr = useMemo(() => {
    let arr = Object.keys(daliCtlObjAll)
      .map((s) => daliCtlObjAll[s])
      .sort((a, b) => a.description?.localeCompare(b.description));
    if (searchText !== "" && showList === "daliCtl") {
      arr = arr.filter(
        (s) =>
          isMatch(s.description, searchText) ||
          isMatch(s.zigbeeAdd, searchText) ||
          isMatch(s.deviceVersion, searchText) ||
          isMatch(s.gatewayID, searchText) ||
          isMatch(s.daliCtlID, searchText) ||
          isMatch(s.dtkAdd, searchText) ||
          isMatch(s.daliMasterModel, searchText) ||
          isMatch(s.daliMasterID, searchText)
      );
    }
    const titleRow = {
      daliCtlID: "ID",
      description: "Description",
      zigbeeAdd: "Address",
      deviceVersion: "Firmware",
      daliMasterModel: "Master controller model",
      daliMasterID: "Master controller ID",
    };
    return [titleRow, ...arr];
  }, [daliCtlObjAll, searchText, showList]);
  const zigbeeArrAll = useMemo(() => {
    const arr = [
      ...sensorObjArr,
      ...gatewayObjArr,
      ...lightObjArr,
      ...daliCtlObjArr,
    ];
    const zbArr = arr.map((obj) => obj.zigbeeAdd);
    return zbArr;
  }, [sensorObjArr, gatewayObjArr, lightObjArr, daliCtlObjArr]);

  const handleClose = () => {
    setOpen(false);
  };
  const selectLightRow = (s) => {
    dispatch({
      type: "OPENED_LIGHT_DETAIL_MODAL",
      payload: s,
    });
  };
  const selectGatewayRow = (g) => {
    dispatch({
      type: "OPENED_GATEWAY_DETAIL_MODAL",
      payload: g,
    });
  };
  const selectSensorRow = (s) => {
    dispatch({
      type: "OPENED_SENSOR_DETAIL_MODAL",
      payload: s,
    });
  };
  const selectDaliCtlRow = (d) => {
    dispatch({
      type: "OPENED_DALICTL_DETAIL_MODAL",
      payload: d,
    });
  };
  const gwQtyExcludeDuplicateMac = useMemo(() => {
    const macArr = Object.keys(gatewayObjAll).map((g) => gatewayObjAll[g].mac);
    const macArrUnique = [...new Set(macArr)];
    return macArrUnique.length;
  }, [gatewayObjAll]);
  return (
    <>
      <MapTopBtn
        tooltip="Device List"
        btnSize={btnSize}
        btnClick={() => {
          setOpen(true);
        }}
      >
        <Highlight />
      </MapTopBtn>
      <ModalSM
        open={open}
        onClose={handleClose}
        width="98vw"
        height="95vh"
        disableBottomClose
      >
        <DivInline>
          <IconButtonBack onBtnClick={handleClose} />
          <Highlight />
          <Typography sx={{ marginRight: "2vw" }}>Device List</Typography>
          <ButtonGroup size="small">
            <Button
              color={showList === "light" ? "primary" : "secondary"}
              variant={showList === "light" ? "contained" : "outlined"}
              onClick={() => setShowList("light")}
            >
              Light
            </Button>
            <Button
              color={showList === "gateway" ? "primary" : "secondary"}
              variant={showList === "gateway" ? "contained" : "outlined"}
              onClick={() => setShowList("gateway")}
            >
              Gateway
            </Button>
            <Button
              color={showList === "sensor" ? "primary" : "secondary"}
              variant={showList === "sensor" ? "contained" : "outlined"}
              onClick={() => setShowList("sensor")}
            >
              Sensor
            </Button>
            <Button
              color={showList === "daliCtl" ? "primary" : "secondary"}
              variant={showList === "daliCtl" ? "contained" : "outlined"}
              onClick={() => setShowList("daliCtl")}
            >
              Dali controller
            </Button>
          </ButtonGroup>
          <InputField
            sx={{ width: "20vw" }}
            label="Search"
            value={searchText}
            onInput={(v) => setSearchText(v)}
            keyPressEnter
          />
          <IconButtonDelete onBtnClick={() => setSearchText("")} />
        </DivInline>
        <Divider10 />
        <DivExist show={showList === "light"}>
          <Typography>Light List [{lightObjArr?.length - 1}]</Typography>
          <div className="scroll-container" ref={ref}>
            <ViewportList viewportRef={ref} items={lightObjArr}>
              {(lightObj, index) => {
                // const lightObj = lightObjArr?.[index];
                const {
                  serial,
                  description,
                  zigbeeAdd,
                  gatewayID,
                  zigbeeConnected,
                  timeStamp,
                  deviceVersion,
                  type,
                  dimTransition,
                  daliCtlID,
                } = lightObj;
                const zbDuplicated =
                  zigbeeArrAll.filter((z) => z === zigbeeAdd).length > 1;
                const latestDataTimeFromNow = moment().diff(
                  moment(timeStamp),
                  "minutes"
                );
                let connectStatus = zigbeeConnected;
                if (!timeStamp) connectStatus = false;
                if (latestDataTimeFromNow >= 30) connectStatus = false;

                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: index === 0 ? "default" : "pointer",
                      borderBottom: "1px solid grey",
                      "&:hover": { background: index === 0 ? "" : "#1B2631" },
                    }}
                    onClick={() => {
                      if (index > 0) selectLightRow(serial);
                    }}
                  >
                    <Typography variant="caption" sx={{ width: 0.1 * w }}>
                      {serial}
                    </Typography>
                    <Box sx={{ width: 0.15 * w }}>
                      <Typography variant="caption">{description}</Typography>
                    </Box>

                    <ConnectStatus
                      width={0.06}
                      index={index}
                      status={connectStatus}
                    />
                    <DataTime width={0.13} tsp={timeStamp} index={index} />
                    <DataGateway
                      index={index}
                      width={0.15}
                      gatewayID={gatewayID}
                      gatewayObjAll={gatewayObjAll}
                    />
                    <Box sx={{ width: 0.05 * w }}>
                      {index === 0 ? (
                        <Typography variant="caption">Style</Typography>
                      ) : (
                        <LightIcon
                          lightStyle={lightObj.style}
                          disabledConnectStatus
                        />
                      )}
                    </Box>

                    <Box sx={{ width: 0.04 * w }}>
                      {index === 0 ? (
                        <Tooltip title="Emergency battery analysis function">
                          <Battery80 />
                        </Tooltip>
                      ) : (
                        type?.batVI && (
                          <Lens sx={{ fontSize: "0.9em", color: "#fff" }} />
                        )
                      )}
                    </Box>
                    <Box sx={{ width: 0.04 * w }}>
                      {index === 0 ? (
                        <Tooltip title="Light switch function">
                          <ToggleOn />
                        </Tooltip>
                      ) : (
                        type?.A && (
                          <Lens sx={{ fontSize: "0.9em", color: "#fff" }} />
                        )
                      )}
                    </Box>
                    <Box sx={{ width: 0.04 * w }}>
                      {index === 0 ? (
                        <Tooltip title="Light dimming function">
                          <BlurLinear />
                        </Tooltip>
                      ) : (
                        type?.pwm && (
                          <Lens sx={{ fontSize: "0.9em", color: "#fff" }} />
                        )
                      )}
                    </Box>
                    <Typography variant="caption" sx={{ width: 0.08 * w }}>
                      {index === 0
                        ? "Fade time"
                        : daliCtlID || !type?.pwm
                        ? "-"
                        : secToMin(dimTransition)}
                    </Typography>
                    <Tooltip
                      sx={{ color: "red" }}
                      title={zbDuplicated ? "Address duplicated" : ""}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          width: 0.06 * w,
                          color: index === 0 ? "" : zbDuplicated ? "red" : "",
                        }}
                      >
                        {zigbeeAdd}
                      </Typography>
                    </Tooltip>
                    <Typography variant="caption" sx={{ width: 0.06 * w }}>
                      {deviceVersion}
                    </Typography>
                  </Box>
                );
              }}
            </ViewportList>
          </div>
        </DivExist>
        <DivExist show={showList === "gateway"}>
          <DivInline>
            <Typography>Gateway List [{gatewayObjArr?.length - 1}]</Typography>
            <AdminContainer>
              <Typography variant="caption" sx={{ marginLeft: "1vw" }}>
                {`Exclude null ID & duplicated mac: ${gwQtyExcludeDuplicateMac}`}
              </Typography>
            </AdminContainer>
          </DivInline>
          <div className="scroll-container" ref={ref2}>
            <ViewportList viewportRef={ref2} items={gatewayObjArr}>
              {(gatewayObj, index) => {
                // const gatewayObj = gatewayObjArr?.[index];
                const {
                  gatewayID,
                  description,
                  zigbeeAdd,
                  gatewayConnected,
                  gatewayAliveTimeStamp,
                  gatewayAliveTimeString,
                  gwVersion,
                  serverID,
                  serialMap,
                  sensorMap,
                  mac,
                } = gatewayObj;
                const zbDuplicated =
                  zigbeeArrAll.filter((z) => z === zigbeeAdd).length > 1;
                return (
                  <Box
                    sx={{
                      display: "flex",
                      cursor: index === 0 ? "default" : "pointer",
                      borderBottom: "1px solid grey",
                      "&:hover": { background: index === 0 ? "" : "#1B2631" },
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if (index > 0) selectGatewayRow(gatewayID);
                    }}
                  >
                    <Typography variant="caption" sx={{ width: 0.08 * w }}>
                      {gatewayID}
                    </Typography>
                    <Box sx={{ width: 0.18 * w }}>
                      <Typography variant="caption">{description}</Typography>
                    </Box>

                    <ConnectStatus
                      width={0.06}
                      index={index}
                      status={gatewayConnected}
                    />
                    <DataTime
                      width={0.15}
                      tsp={gatewayAliveTimeStamp}
                      index={index}
                    />
                    <DeviceNo
                      index={index}
                      width={0.09}
                      title="Connected light"
                      data={Object.keys(serialMap || {})?.length}
                    />
                    <DeviceNo
                      index={index}
                      width={0.09}
                      title="Connected sensor"
                      data={Object.keys(sensorMap || {})?.length}
                    />
                    <Tooltip
                      sx={{ color: "red" }}
                      title={zbDuplicated ? "Address duplicated" : ""}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          width: 0.06 * w,
                          color: index === 0 ? "" : zbDuplicated ? "red" : "",
                        }}
                      >
                        {zigbeeAdd}
                      </Typography>
                    </Tooltip>
                    <Typography variant="caption" sx={{ width: 0.06 * w }}>
                      {gwVersion}
                    </Typography>
                    <Typography variant="caption" sx={{ width: 0.06 * w }}>
                      {mac}
                    </Typography>
                  </Box>
                );
              }}
            </ViewportList>
          </div>
        </DivExist>
        <DivExist show={showList === "sensor"}>
          <Typography>Sensor List [{sensorObjArr?.length - 1}]</Typography>
          <div className="scroll-container" ref={ref3}>
            <ViewportList viewportRef={ref3} items={sensorObjArr}>
              {(sensorObj, index) => {
                // const sensorObj = sensorObjArr?.[index];
                const {
                  sensorID,
                  sensorName,
                  zigbeeAdd,
                  zigbeeConnected,
                  timeStamp,
                  deviceVersion,
                  serialMap,
                  type,
                  gang,
                } = sensorObj;
                const zbDuplicated =
                  zigbeeArrAll.filter((z) => z === zigbeeAdd).length > 1;
                let connectStatus = zigbeeConnected;
                if (!timeStamp) connectStatus = false;
                const latestDataTimeFromNow = moment().diff(
                  moment(timeStamp),
                  "minutes"
                );
                if (latestDataTimeFromNow >= 30) connectStatus = false;

                return (
                  <Box
                    sx={{
                      display: "flex",
                      cursor: index === 0 ? "default" : "pointer",
                      borderBottom: "1px solid grey",
                      "&:hover": { background: index === 0 ? "" : "#1B2631" },
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if (index > 0) selectSensorRow(sensorID);
                    }}
                  >
                    <Typography variant="caption" sx={{ width: 0.12 * w }}>
                      {sensorID}
                    </Typography>
                    <Box sx={{ width: 0.22 * w }}>
                      <Typography variant="caption">{sensorName}</Typography>
                    </Box>

                    <ConnectStatus
                      width={0.06}
                      index={index}
                      status={connectStatus}
                    />
                    <DataTime width={0.15} tsp={timeStamp} index={index} />
                    <Box sx={{ width: 0.08 * w }}>
                      {index === 0 ? (
                        <Typography variant="caption">Type</Typography>
                      ) : (
                        <SensorIcon
                          type={type}
                          backgroundColor="#fff"
                          disabledConnectStatus
                          gang={gang}
                        />
                      )}
                    </Box>
                    <DeviceNo
                      index={index}
                      width={0.1}
                      title="Connected light"
                      data={Object.keys(serialMap || {})?.length}
                    />
                    <Tooltip
                      sx={{ color: "red" }}
                      title={zbDuplicated ? "Address duplicated" : ""}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          width: 0.06 * w,
                          color: index === 0 ? "" : zbDuplicated ? "red" : "",
                        }}
                      >
                        {zigbeeAdd}
                      </Typography>
                    </Tooltip>
                    <Typography variant="caption" sx={{ width: 0.06 * w }}>
                      {deviceVersion}
                    </Typography>
                  </Box>
                );
              }}
            </ViewportList>
          </div>
        </DivExist>
        <DivExist show={showList === "daliCtl"}>
          <Typography>
            Dali Control List [{daliCtlObjArr?.length - 1}]
          </Typography>
          <div className="scroll-container" ref={ref4}>
            <ViewportList viewportRef={ref4} items={daliCtlObjArr}>
              {(daliCtlObj, index) => {
                // const daliCtlObj = daliCtlObjArr?.[index];
                const {
                  daliCtlID,
                  description,
                  zigbeeAdd,
                  zigbeeConnected,
                  timeStamp,
                  deviceVersion,
                  daliType,
                  lightObj,
                  sensorObj,
                  daliMasterModel,
                  daliMasterID,
                } = daliCtlObj;
                const typeName = daliType === "dalimqtt" ? "Wired" : "Wireless";
                return (
                  <Box
                    sx={{
                      display: "flex",
                      cursor: index === 0 ? "default" : "pointer",
                      borderBottom: "1px solid grey",
                      "&:hover": { background: index === 0 ? "" : "#1B2631" },
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if (index > 0) selectDaliCtlRow(daliCtlID);
                    }}
                  >
                    <Typography variant="caption" sx={{ width: 0.08 * w }}>
                      {daliCtlID}
                    </Typography>
                    <Box sx={{ width: 0.2 * w }}>
                      <Typography variant="caption">{description}</Typography>
                    </Box>
                    <Box sx={{ width: 0.12 * w }}>
                      <Typography variant="caption">
                        {daliMasterModel}
                      </Typography>
                    </Box>

                    <Box sx={{ width: 0.12 * w }}>
                      <Typography variant="caption">{daliMasterID}</Typography>
                    </Box>
                    <Box sx={{ width: 0.07 * w }}>
                      <Typography variant="caption">
                        {index === 0 ? "Type" : typeName}
                      </Typography>
                    </Box>
                    <ConnectStatus
                      width={0.06}
                      index={index}
                      status={zigbeeConnected}
                    />
                    <DataTime width={0.11} tsp={timeStamp} index={index} />

                    <DeviceNo
                      index={index}
                      width={0.07}
                      title="Connected light"
                      data={Object.keys(lightObj || {})?.length}
                    />
                    <DeviceNo
                      index={index}
                      width={0.07}
                      title="Connected sensor"
                      data={Object.keys(sensorObj || {})?.length}
                    />
                    <Box sx={{ width: 0.06 * w }}>
                      <Typography variant="caption">{deviceVersion}</Typography>
                    </Box>
                  </Box>
                );
              }}
            </ViewportList>
          </div>
        </DivExist>
      </ModalSM>
    </>
  );
}
function DeviceNo({ index, width, title, data }) {
  return (
    <Box sx={{ width: width * w, textAlign: "center" }}>
      {index === 0 ? (
        <Typography variant="caption">{title}</Typography>
      ) : (
        <Typography variant="caption">{data}</Typography>
      )}
    </Box>
  );
}
function ConnectStatus({ index, status, width }) {
  return (
    <Box sx={{ width: width * w, textAlign: "center" }}>
      {index === 0 ? (
        <Typography variant="caption">Connect</Typography>
      ) : status ? (
        <CloudDone style={{ color: "green" }} />
      ) : (
        <CloudOff color="error" />
      )}
    </Box>
  );
}
function DataGateway({ index, width, gatewayID, gatewayObjAll }) {
  return (
    <Box sx={{ width: width * w }}>
      {index === 0 ? (
        <Typography variant="caption">Gateway</Typography>
      ) : (
        <Typography variant="caption">
          {gatewayID}
          <br />
          {gatewayObjAll[gatewayID]?.description || "-"}
        </Typography>
      )}
    </Box>
  );
}
function DataTime({ tsp, index, width }) {
  const tsr = tspToDateTime(tsp);
  return (
    <Box sx={{ width: width * w }}>
      {index === 0 ? (
        <Typography variant="caption">Data time</Typography>
      ) : (
        <Typography variant="caption">
          {tsr}
          <br />
          {moment(tsp).fromNow()}
        </Typography>
      )}
    </Box>
  );
}
