import { useState, useEffect, useRef } from "react";
import KonvaLabel from "../device/KonvaLabel";
import { Circle, Group } from "react-konva";
import { Opacity } from "@mui/icons-material";

export default function LayerMapDeviceMessage({ layerProps }) {
    const [scale, setScale] = useState(2);
    const {
        layerRef,
        activeMapID,
        mapObjAll,
        mapScale,
        iconSize,
        mapDeviceMessage,
        dispatch
    } = layerProps;
    const mapObj = mapObjAll[activeMapID];
    const mapPosObj = { ...mapObj?.lightObj || {}, ...mapObj?.sensorObj || {}, ...mapObj?.daliCtlObj || {}, ...mapObj?.gatewayObj || {}, ...mapObj?.zoneControlObj || {} };
    useEffect(() => {
        const layer = layerRef.current;
        const layerScale = layer.scaleX();
        setScale(layerScale);
    }, []);
    if (Object.keys(layerProps.mapDeviceMessage || {}).length === 0) return null;

    return (
        <>
            {
                Object.keys(mapDeviceMessage || {}).map((deviceID, key) => {
                    const msg = mapDeviceMessage[deviceID];
                    const pos = mapPosObj[deviceID];
                    // if (!pos) return null;
                    let x = pos?.x * mapScale.x || 0;
                    let y = (pos?.y + iconSize) * mapScale.y || 0;
                    return (
                        <DeviceMsg
                            key={key}
                            x={x}
                            y={y}
                            msg={msg}
                            scale={scale}
                            dispatch={dispatch}
                            deviceID={deviceID}
                        />
                    )
                })
            }
        </>
    )
}

function DeviceMsg({ x, y, msg, scale, dispatch, deviceID }) {
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        if (msg) {
            setShow(true);
            ref.current?.to({
                scaleX: 1.1,
                scaleY: 1.1,
                opacity: 1,
                onFinish: () => {
                    ref.current?.to({
                        scaleX: 0.9,
                        scaleY: 0.9,
                        opacity: 0,
                        duration: 4,
                    });
                }

            });
            setTimeout(() => {
                dispatch({
                    type: "CLEAR_MAP_DEVICE_MESSAGE",
                    payload: { deviceID }
                })
            }, 4500);
        } else {
            setShow(false);
        }
    }, [msg, ref.current]);
    if (!show) return null;
    return (

        <Group ref={ref} x={x} y={y}>
            <KonvaLabel
                text={msg}
                fontSize={12 / scale}
                tagColor={"rgba(36,113,163,0.6"}
                strokeColor="#85C1E9"
            />
        </Group>

    )
}