import { useContext } from "react";
import MapLeftToolbar from "./toolbar/MapLeftToolbar";

export default function MapContainerLeft({ vw, vh }) {
  return (
    <div
      style={{
        width: `${vw}vw`,
        height: `${vh}vh`,
        // backgroundColor: "green",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <MapLeftToolbar />
    </div>
  );
}
