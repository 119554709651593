import { useContext, useState, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Checkbox, Typography } from "@mui/material";
import { ArrowForward, Repeat } from "@mui/icons-material";
import Space10 from "components/Space10";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import SensorIcon from "components/SensorIcon";
import DivCol from "components/DivCol";
import SpaceBetweenCheckbox from "components/SpaceBetweenCheckbox";
import DivExist from "components/DivExist";
import DialogInputMinSec from "components/DialogInputMinSec";
import { updateSceneButtonDelayTime, sceneBtnConvertFromToggleToNormalSetting, sceneBtnConvertToToggleSetting } from "actions/sensorActions";
import General from "@ecoenghk/general";
import ButtonSM from "components/ButtonSM";
import LightIcon from "components/LightIcon";
import { secToMin } from "actions/generalActions";
import ModalSM from "components/ModalSM";
import { alertWindow } from "actions/screenActions";
import { deviceGatewayIsDisconnected } from "actions/gatewayActions";
import ZcGeneral from "asset/svgComp/ZcGeneral";
import LightBulb from "asset/svgComp/LightBulb";

const gs = new General();

export default function SensorDetailSceneBtnDelayPanel() {
  const [state, dispatch] = useContext(SMContext);
  const [openEdit, setOpenEdit] = useState(false);
  const [activeButtonNo, setActiveButtonNo] = useState(0);
  const { activeSensorID, sensorObjAll } = state;
  const sensorObj = sensorObjAll[activeSensorID];
  const handleSave = async (delaySec, delaySec2, buttonNo) => {
    if (deviceGatewayIsDisconnected(state, sensorObj)) {
      alertWindow(dispatch, "Gateway is not connected, not able to update delay time");
      return;
    } else {
      await updateSceneButtonDelayTime(sensorObj, delaySec, delaySec2, buttonNo);
    }


  };
  return (
    <div style={{ paddingBottom: "2vh" }}>
      <Divider10 />
      <DivInline justifyContent="center">
        <Typography variant="h6" display="block" align="center">
          Button Setting
        </Typography>
      </DivInline>
      <Divider10 space={5} />
      {gs.newArrayBetween(1, sensorObj?.gang || 1).map((n) => {
        const delaySec1 = sensorObj[`delaySec${n === 1 ? "" : 2 * n - 1}`] || 0;
        const delaySec2 = sensorObj[`delaySec${2 * n}`] || 0;
        return (
          <DivInline key={n}>
            <Typography variant="caption">Button {n}</Typography>
            <DivInline justifyContent="flex-start" hide={sensorObj[`enableToggle${n}`]}>
              <ButtonSM
                onClick={() => {
                  setOpenEdit(true);
                  setActiveButtonNo(n);
                }}
              >
                <SensorIcon
                  gang={sensorObj?.gang}
                  type="scenebutton"
                  disabledConnectStatus
                  status={n === 1 ? 1 : 0}
                  status2={n === 2 ? 1 : 0}
                  status3={n === 3 ? 1 : 0}
                  status4={n === 4 ? 1 : 0}
                  width={3}
                  sizeUnit="vw"
                />
                <ArrowForward sx={{ fontSize: "1rem" }} />
                <DivExist show={delaySec1 > 0}>
                  <LightIcon
                    tooltip="light action on press"
                    width={2.5}
                    sizeUnit="vw"
                    statusA={0}
                    lightLv={10}
                    disabledConnectStatus
                  />

                  <Typography variant="body2">
                    {secToMin(delaySec1)}
                  </Typography>
                  <ArrowForward sx={{ fontSize: "1rem" }} />

                  <LightIcon
                    tooltip="delayed light action"
                    width={2.5}
                    sizeUnit="vw"
                    statusA={0}
                    lightLv={30}
                    disabledConnectStatus
                  />
                </DivExist>
                <DivExist show={delaySec2 > 0}>
                  <Typography variant="body2">
                    {secToMin(delaySec2)}
                  </Typography>
                  <ArrowForward sx={{ fontSize: "1rem" }} />

                  <LightIcon
                    tooltip="delayed light action step 2"
                    width={2.5}
                    sizeUnit="vw"
                    statusA={0}
                    lightLv={30}
                    disabledConnectStatus
                  />
                </DivExist>
                <DivExist show={delaySec1 === 0 && delaySec2 === 0}>
                  <Typography variant="body2">No delayed light action</Typography>
                </DivExist>
              </ButtonSM>
            </DivInline>
            <DivInline justifyContent="flex-start" hide={!sensorObj[`enableToggle${n}`]} sx={{ outline: "1px solid grey", marginRight: "0.5vw" }}>
              <ZcGeneral width={2.5} sizeUnit={"vw"} />
              <LightBulb onoff="on" width={2.5} sizeUnit={"vw"} />
              <Repeat sx={{ fontSize: "2rem", margin: "0 2vw" }} />
              <LightBulb onoff="off" width={2.5} sizeUnit={"vw"} />
            </DivInline>
            <DivInline>
              <Typography align="right" variant="body2">Convert to toggle button</Typography>
              <Checkbox
                checked={sensorObj[`enableToggle${n}`] ? true : false}
                onChange={(e) => {
                  if (e.target.checked) {
                    sceneBtnConvertToToggleSetting(sensorObj, n);
                  } else {
                    sceneBtnConvertFromToggleToNormalSetting(sensorObj, n);
                  }
                }} />
            </DivInline>
          </DivInline>
        );
      })}
      <EditModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        sensorObj={sensorObj}
        handleSave={handleSave}
        buttonNo={activeButtonNo}
      />
    </div>
  );
}

function EditModal({ open, onClose, sensorObj, handleSave, buttonNo }) {
  const [state, dispatch] = useContext(SMContext);
  const [delayT1, setDelayT1] = useState(0);
  const [delayT2, setDelayT2] = useState(0);
  useEffect(() => {
    const delayNoI = buttonNo === 1 ? "" : buttonNo * 2 - 1;
    const delayNoII = buttonNo * 2;
    setDelayT1(sensorObj?.[`delaySec${delayNoI}`] || 0);
    setDelayT2(sensorObj?.[`delaySec${delayNoII}`] || 0);
  }, [open, buttonNo]);
  return (
    <ModalSM
      open={open}
      onClose={onClose}
      width="55vw"
      height="50vh"
      onSave={() => {
        handleSave(delayT1, delayT2, buttonNo);
        onClose();
      }}
      modalTitle={`Button ${buttonNo} delay setting`}
    >
      <SpaceBetweenCheckbox
        title="Delay action"
        data={delayT1 > 0 ? true : false}
        onCheck={async (e) => {
          const val = e.target.checked ? 1 : 0;
          if (val === 0) {
            setDelayT1(0);
            setDelayT2(0);
          }
          if (val === 1 && !delayT1) setDelayT1(10);
        }}
      />
      <SpaceBetweenCheckbox
        title="2-step delay action"
        data={delayT2 > 0 ? true : false}
        onCheck={async (e) => {
          const val = e.target.checked ? 1 : 0;
          if (val === 0) setDelayT2(0);
          if (val === 1 && !delayT2) setDelayT2(10);
        }}
      />
      <Divider10 />
      <DivInline justifyContent="space-around">
        <Space10 space="2vw" />

        <SensorIcon
          gang={sensorObj?.gang}
          type="scenebutton"
          disabledConnectStatus
          status={buttonNo === 1 ? 1 : 0}
          status2={buttonNo === 2 ? 1 : 0}
          status3={buttonNo === 3 ? 1 : 0}
          status4={buttonNo === 4 ? 1 : 0}
          width={5}
          sizeUnit="vw"
        />
        <ArrowForward sx={{ fontSize: "1.2rem" }} />
        <DivCol>
          <LightIcon
            width={4}
            sizeUnit="vw"
            statusA={0}
            lightLv={10}
            disabledConnectStatus
          />
          <Typography variant="caption">light action on press</Typography>
        </DivCol>
        <ArrowForward sx={{ fontSize: "1.2rem" }} />

        <DivExist show={delayT1 > 0 ? true : false}>
          <DivInline
            justifyContent="flex-end"
            style={{
              border: "1px solid #fff",
              padding: 3,
              borderRadius: 6,
              width: "10vw",
            }}
          >
            <DialogInputMinSec
              title="delay time 1"
              initialSec={delayT1}
              iconSize={30}
              showValue
              showValueVariant="body1"
              onInput={async (val) => setDelayT1(val)}
              minSec={5}
              dispatch={dispatch}
            />
          </DivInline>
          <ArrowForward sx={{ fontSize: "1.2rem" }} />
          <DivCol>
            <LightIcon
              width={4}
              sizeUnit="vw"
              statusA={0}
              lightLv={10}
              disabledConnectStatus
            />
            <Typography variant="caption">delay light action</Typography>
          </DivCol>
          <DivExist show={delayT2 > 0 ? true : false}>
            <ArrowForward sx={{ fontSize: "1.2rem" }} />
            <DivInline
              style={{
                border: "1px solid #fff",
                padding: 5,
                borderRadius: 6,
                width: "10vw",
              }}
              justifyContent="flex-end"
            >
              <DialogInputMinSec
                title="delay time 2"
                initialSec={delayT2}
                iconSize={30}
                showValue
                showValueVariant="body1"
                onInput={async (val) => setDelayT2(val)}
                dispatch={dispatch}
              />
            </DivInline>
            <ArrowForward sx={{ fontSize: "1.2rem" }} />
            <DivCol>
              <LightIcon
                width={4}
                sizeUnit="vw"
                statusA={0}
                lightLv={10}
                disabledConnectStatus
              />
              <Typography variant="caption">
                delay light action step 2
              </Typography>
            </DivCol>
          </DivExist>
        </DivExist>
        <DivExist show={!delayT1}>
          <DivInline>
            <Typography>No delayed light action</Typography>
          </DivInline>
        </DivExist>
      </DivInline>
    </ModalSM>
  );
}
