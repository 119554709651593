import { deviceInfoOfObj } from "./generalActions";

// export const deviceRestart = async (deviceID, deviceObj) => {
//   const { gatewayID, zigbeeAdd } = deviceObj;
//   const payload = {
//     type: "control_general_device",
//     gatewayID,
//     controlMode: "EE",
//     commandMode: 2,
//     zigbeeAdd,
//   };
//   console.log(
//     `[COMMAND OUT] control_general_device - deviceRestart ${deviceID}`
//   );
//   global.socket.emit("fromWeb", payload);
// };

// export const deviceWifiOnOff = async (deviceID, deviceObj, wifiOnOff) => {
//   const payload = {
//     type: "control_general_device",
//     gatewayID: deviceObj.gatewayID,
//     controlMode: "EE",
//     zigbeeAdd: deviceObj.zigbeeAdd,
//     commandMode: 3,
//     wifiOnOff,
//   };
//   console.log(`[COMMAND OUT] deviceWifiOnOff ${deviceID}  wifi ${wifiOnOff}`);
//   global.socket.emit("fromWeb", payload);
// };
// export const changeDeviceZbChannel = async (
//   deviceID,
//   deviceObj,
//   hexStrChannel
// ) => {
//   const zbChannel = parseInt(hexStrChannel, 16);
//   const payload = {
//     type: "control_general_device",
//     gatewayID: deviceObj.gatewayID,
//     controlMode: "EE",
//     zigbeeAdd: deviceObj.zigbeeAdd,
//     commandMode: 5,
//     zbChannel,
//   };
//   console.log(
//     `[COMMAND OUT] changeDeviceZbChannel ${deviceID} new channel 0x${hexStrChannel} / ${zbChannel}`
//   );
//   global.socket.emit("fromWeb", payload);
// };

// export const changeDeviceZbAddress = async (
//   deviceID,
//   deviceType,
//   zigbeeAdd,
//   gatewayID,
//   gatewayObj,
//   newZbAdd,
//   zbToDevice
// ) => {
//   const payload = {
//     type: "control_general_device",
//     gatewayID,
//     controlMode: "EE",
//     deviceID,
//     deviceType,
//     zigbeeAdd,
//     commandMode: 6,
//     newZbAdd,
//     zbToDevice,
//     gatewayObj,
//   };
//   console.log(
//     `[COMMAND OUT] changeDeviceZbChannel ${deviceID} new ZB address ${newZbAdd}`
//   );
//   console.log(payload);
//   global.socket.emit("fromWeb", payload);
// };
// export const updateDeviceFirmware_EE = async (gatewayID, zigbeeAdd) => {
//   const payload = {
//     type: "control_general_device",
//     controlMode: "EE",
//     commandMode: 8,
//     gatewayID,
//     zigbeeAdd,
//   };
//   console.log(`[COMMAND OUT] updateDeviceFirmware_EE ${zigbeeAdd}`);
//   global.socket.emit("fromWeb", payload);
// };
// export const updateOtaSsid_EE = async (gatewayID, zigbeeAdd, otaSsid) => {
//   const payload = {
//     type: "control_general_device",
//     controlMode: "EE",
//     commandMode: 0x0d,
//     gatewayID,
//     zigbeeAdd,
//     otaSsid,
//   };
//   console.log(`[COMMAND OUT] updateOtaSsid_EE ${zigbeeAdd} SSID ${otaSsid}`);
//   global.socket.emit("fromWeb", payload);
// };
// export const updateOtaPassword_EE = async (
//   gatewayID,
//   zigbeeAdd,
//   otaPassword
// ) => {
//   const payload = {
//     type: "control_general_device",
//     controlMode: "EE",
//     commandMode: 0x0e,
//     gatewayID,
//     zigbeeAdd,
//     otaPassword,
//   };
//   console.log(
//     `[COMMAND OUT] updateOtaPassword_EE ${zigbeeAdd} wifi password ${otaPassword}`
//   );
//   global.socket.emit("fromWeb", payload);
// };
// export const queryOtaSsid = async (gatewayID, zigbeeAdd) => {
//   const payload = {
//     type: "control_general_device",
//     controlMode: "EE",
//     commandMode: 0x0f,
//     gatewayID,
//     zigbeeAdd,
//   };
//   console.log(`[COMMAND OUT] queryOtaSsid ${zigbeeAdd}`);
//   global.socket.emit("fromWeb", payload);
// };
// export const queryOtaPassword = async (gatewayID, zigbeeAdd) => {
//   const payload = {
//     type: "control_general_device",
//     controlMode: "EE",
//     commandMode: 0x10,
//     gatewayID,
//     zigbeeAdd,
//   };
//   console.log(`[COMMAND OUT] queryOtaPassword ${zigbeeAdd}`);
//   global.socket.emit("fromWeb", payload);
// };
// export const queryCurrentGateway_EE = async (gatewayID, zigbeeAdd) => {
//   const payload = {
//     type: "control_general_device",
//     controlMode: "EE",
//     commandMode: 0x0a,
//     gatewayID,
//     zigbeeAdd,
//   };
//   console.log(`[COMMAND OUT] queryCurrentGateway_EE ${zigbeeAdd}`);
//   global.socket.emit("fromWeb", payload);
// };
// export const handleReceiveGeneralDeviceReply = async (dispatch, payload) => {
//   const {
//     gatewayID,
//     zigbeeAdd,
//     commandMode,
//     commandName,
//     deviceType,
//     deviceID,
//     deviceIDField,
//   } = payload;
//   console.log(
//     `[FROM GW] C0 General device - ${deviceType} ${deviceID} | ${commandName} | ${commandMode} `
//   );
//   switch (commandMode) {
//     case 0x07:
//       const { otaSsid } = payload;
//       console.log(` | otaSsid ${otaSsid}`);
//       openSnackbar(
//         dispatch,
//         `${payload.deviceType} ${payload.deviceID}- update firmware SSID: ${otaSsid}`
//       );
//       dispatch({
//         type: "UPDATED_LIGHT_PROPERTY",
//         payload: { serial: deviceID, updateObj: { otaSsid } },
//       });
//       break;
//     case 0x08:
//       const { otaPassword } = payload;
//       console.log(` | otaPassword ${otaPassword}`);
//       openSnackbar(
//         dispatch,
//         `${payload.deviceType} ${payload.deviceID}- update firmware password: ${otaPassword}`
//       );
//       dispatch({
//         type: "UPDATED_LIGHT_PROPERTY",
//         payload: { serial: deviceID, updateObj: { otaPassword } },
//       });
//       break;
//     default:
//       break;
//   }
// };
export const deviceUpdateFirmwareFromIP = async (deviceObj) => {
  const deviceInfo = deviceInfoOfObj(deviceObj);
  const { deviceType, deviceID } = deviceInfo || {};
  const serverIpStr = deviceObj.updateFirmwareServerIP;
  let payload = {
    gatewayID: deviceObj.gatewayID,
    dtkAdd: deviceObj.dtkAdd,
    updateSpiffs: 0,
    serverIP: serverIpStr.split(".").map((str) => parseInt(str)),
  }
  if (deviceType === "light") {
    payload.serial = deviceID;
    payload.commandCode = 0x0230;
    payload.type = "control_single_light";
  } else if (deviceType === "sensor") {
    payload.sensorID = deviceID;
    payload.commandCode = 0x062f;
    payload.type = "control_single_sensor";
  } else if (deviceType === "daliCtl") {
    payload.daliCtlID = deviceID;
    payload.commandCode = 0x0a2c;
    payload.type = "control_dali_ctl";
  } else {
    alert("Invalid device type");
    return;
  }
  console.log(`[COMMAND OUT] deviceUpdateFirmwareFromIP ${deviceType} ${deviceID} server IP ${serverIpStr}`);
  global.socket.emit("fromWeb", payload);
}
export const queryDeviceCurrentFirmwareVersion = async (deviceObj) => {
  const deviceInfo = deviceInfoOfObj(deviceObj);
  const { deviceType, deviceID } = deviceInfo || {};
  let payload = {
    gatewayID: deviceObj.gatewayID,
    dtkAdd: deviceObj.dtkAdd,
  }
  if (deviceType === "light") {
    payload.serial = deviceID;
    payload.commandCode = 0x0314;
    payload.type = "control_single_light";
  } else if (deviceType === "sensor") {
    payload.sensorID = deviceID;
    payload.commandCode = 0x0711;
    payload.type = "control_single_sensor";
  } else if (deviceType === "daliCtl") {
    payload.daliCtlID = deviceID;
    payload.commandCode = 0x0b1c;
    payload.type = "control_dali_ctl";
  } else {
    alert("Invalid device type");
    return;
  }
  console.log(`[COMMAND OUT] queryDeviceCurrentFirmwareVersion ${deviceType} ${deviceID}`);
  global.socket.emit("fromWeb", payload);
}
