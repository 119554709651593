import React, { useContext, useState } from "react";
import { SMContext } from "context/smContext";
import { Typography, Tooltip, Box, IconButton } from "@mui/material";
import { ArrowForward, Lock, LockOpen, QuestionMark } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import Space from "components/Space";
import DivInline from "components/DivInline";
import LightIcon from "components/LightIcon";
import DivCol from "components/DivCol";
import DivSpaceBetween from "components/DivSpaceBetween";
import ChangeLockLvTimerModal from "./ChangeLockLvTimerModal";
import AdminContainer from "components/AdminContainer";
import { updateZcProperty, zoneControlDisconnectLight } from "actions/zcActions";
import DeviceIdAddGwLine from "components/DeviceIdAddGwLine";
import DeviceActionLine from "components/DeviceActionLine";
import IconButtonDelete from "components/IconButtonDelete";
import General from "@ecoenghk/general";
import { mgFindOne } from "actions/mongoApiActions";
import { confirmWindow } from "actions/screenActions";
import GroupActionConfirmOneDevice from "components/GroupActionConfirmOneDevice";
import GroupActionConfirmOneGroup from "components/GroupActionConfirmOneGroup";
const gs = new General();
export default function ControlDevicesZcTimerPanel() {
  const [state, dispatch] = useContext(SMContext);
  const {
    activeZoneControlID,
    mapObjAll,
    lightObjAll,
    sensorObjAll,
    activeMapID,
    zoneControlObjAll,
  } = state;
  const zoneControlObj = zoneControlObjAll[activeZoneControlID];
  const activeMapObj = mapObjAll[activeMapID];
  const { setting1, setting2, type, serialMap, sensorMap, GAS_setting1, GAS_setting2 } = zoneControlObj || {};
  const lightArrayAll = Object.values(serialMap || {});
  const sensorArrayAll = Object.values(sensorMap || {});
  const lightArrayInMap = lightArrayAll.filter((s) =>
    Object.keys(activeMapObj.lightObj || {}).includes(s)
  );
  const sensorArrayInMap = sensorArrayAll.filter((s) =>
    Object.keys(activeMapObj.sensorObj || {}).includes(s)
  );
  const lightArrayNotInMap = lightArrayAll.filter(
    (s) => !Object.keys(activeMapObj.lightObj || {}).includes(s)
  );

  return (
    <>
      <DivInline justifyContent="center" alignItems="center">
        <Typography variant="h6">Control devices</Typography>
        <GroupActionConfirmOneGroup groupDeviceObj={zoneControlObj} settingNumArray={[1, 2]} />
      </DivInline>
      <Divider10 />
      <DivSpaceBetween>
        <Typography>
          <Lock />
          Priority Level
        </Typography>
        <ChangeLockLvTimerModal zoneControlObj={zoneControlObj} />
      </DivSpaceBetween>

      <Divider10 />
      <DivCol style={{ width: "100%" }} alignItems="flex-start">
        {lightArrayInMap.map((s, key) => {
          const lightObj = lightObjAll[s];
          return (
            <LightRowTimer
              key={key}
              show={type === "timer"}
              zoneControlObj={zoneControlObj}
              serial={s}
              lightObj={lightObj}
              set1={setting1?.[s]}
              set2={setting2?.[s]}
              GAS_set1={GAS_setting1?.[s.substring(0, 8)]}
              GAS_set2={GAS_setting2?.[s.substring(0, 8)]}
            />
          );
        })}
      </DivCol>
      <Divider10 />
      <DivCol style={{ width: "100%" }} alignItems="flex-start">
        {sensorArrayInMap.map((s, key) => {
          const sensorObj = sensorObjAll[s];
          return (

            <SensorRowTimer
              key={key}
              zoneControlObj={zoneControlObj}
              sensorID={s}
              sensorObj={sensorObj}
              set1={setting1?.[s]}
              set2={setting2?.[s]}
            />
          );
        })}
      </DivCol>
      <Divider10 />
      <DivExist show={lightArrayNotInMap.length > 0}>
        <DivCol style={{ width: "100%" }} alignItems="flex-start">
          <Typography>Lights not in this map</Typography>
          {
            lightArrayNotInMap.map((s, key) => {
              return (
                <NotInMapLightRow key={key} serial={s} dispatch={dispatch} zoneControlObj={zoneControlObj} />
              );
            })
          }
        </DivCol>
      </DivExist>
    </>
  );
}
function SensorRowTimer({ zoneControlObj, sensorID, sensorObj, set1, set2 }) {
  return (
    <Box>
      <Typography>{sensorObj.sensorName}</Typography>
      <DeviceIdAddGwLine deviceID={sensorID} address={sensorObj.dtkAdd || sensorObj.zigbeeAdd} gatewayID={set1?.gatewayID} />
      <DeviceActionLine
        deviceSetting={set1}
        deviceSetting2={set2}
        mainDeviceObj={zoneControlObj}
        deviceObj={sensorObj}
      />
    </Box>
  )
}
function LightRowTimer({ show, zoneControlObj, serial, lightObj, set1, set2 }) {
  const [state] = useContext(SMContext);
  const { zoneControlID } = zoneControlObj;
  const lightStyle = lightObj?.style;
  const { description, dtkAdd } = lightObj || {};
  let gatewayMsg = "";
  if (lightObj.gatewayID !== set1?.gatewayID) {
    gatewayMsg += ` (command gateway:${set1?.gatewayID} and light gateway:${lightObj.gatewayID}, not match!)`;
  }
  const isDim = lightObj?.type?.pwm;
  const serialSetting = { off: set1, on: set2 }
  return (
    <DivExist show={show}>
      <DivInline>
        <Typography sx={{ marginRight: "1vw" }} onClick={() => {
          if (state.userObj.level > 0) return;
          alert(JSON.stringify(serialSetting));
        }}>{description}</Typography>
        <GroupActionConfirmOneDevice deviceObj={zoneControlObj} controlledDeviceObj={lightObj} settingNumArray={[1, 2]} />
      </DivInline>
      <DeviceIdAddGwLine deviceID={serial} address={dtkAdd} gatewayID={set1?.gatewayID} />
      <AdminContainer>
        <DivInline hide={!gatewayMsg}>
          <Typography variant="caption">{gatewayMsg}</Typography>
          <DivExist show={lightObj.gatewayID !== set1?.gatewayID}>
            <Typography
              variant="caption"
              sx={{
                color: "cyan",
                cursor: "pointer",
                outline: "1px solid cyan",
                marginLeft: "1vw",
                padding: "0 0.6vw",
              }}
              onClick={() => {
                updateZcProperty(zoneControlID, {
                  [`setting1.${serial}.gatewayID`]: lightObj.gatewayID,
                  [`setting2.${serial}.gatewayID`]: lightObj.gatewayID,
                });
              }}
            >
              fix
            </Typography>
          </DivExist>
        </DivInline>
      </AdminContainer>
      <DivInline style={{ width: "100%" }} justifyContent="space-around">
        <Typography>Start</Typography>
        <LightIcon
          statusA={set2?.onoffcontrolA}
          lightStyle={lightStyle}
          width={2.5}
          sizeUnit="vw"
          lightLv={set2?.pwm}
          disabledConnectStatus
        />
        <DivExist show={isDim && set2?.onoffcontrolA === 0}>
          <Typography style={{ width: "3vw" }}>{set2?.pwm}%</Typography>
        </DivExist>
        <Lock fontSize="small" />
        <Typography>{set2?.lockLevel}</Typography>
        <Space width={10} />
        <ArrowForward />
        <Space width={10} />
        <Tooltip
          title={
            set1?.lockLevel === 0
              ? "Set to lowest priority on timer end"
              : `Priority level ${set1?.lockLevel}`
          }
        >
          <Box sx={{ display: "flex" }}>
            <Typography>End</Typography>
            <LightIcon
              statusA={set1?.onoffcontrolA}
              lightStyle={lightStyle}
              width={2.5}
              lightLv={set1?.pwm}
              sizeUnit="vw"
              disabledConnectStatus
            />
            <DivExist show={isDim && set1?.onoffcontrolA === 0}>
              <Typography style={{ width: "3vw" }}>{set1?.pwm}%</Typography>
            </DivExist>
            {set1?.releaseOnDelayEnd === 1 ? (
              <LockOpen fontSize="small" />
            ) : (
              <>
                <Lock fontSize="small" />
                <Typography>{set1?.lockLevel}</Typography>
              </>
            )}
          </Box>
        </Tooltip>
      </DivInline>
      <Divider10 space={3} light />
    </DivExist>
  );
}

function NotInMapLightRow({ serial, dispatch, zoneControlObj }) {
  const [lightObj, setLightObj] = useState({});
  let mapIDStr = (lightObj?.mapID || [])?.join(",");
  if (mapIDStr.length === 0) mapIDStr = "no map";
  return (
    <>
      <DivInline >
        <Typography variant="body2">{serial}</Typography>
        <IconButton onClick={async () => {
          const obj = await mgFindOne("serial", "serial", serial);
          console.log(obj)
          setLightObj(obj);
        }}>
          <QuestionMark />
        </IconButton>
        <DivExist show={!gs.isEmptyJson(lightObj)}>
          <IconButtonDelete tooltip="disconnect" onBtnClick={() => {
            confirmWindow(dispatch, "Disconnect this light from zone control?", () => {
              zoneControlDisconnectLight(zoneControlObj, serial, lightObj);
              setLightObj({});
            });
          }} />
        </DivExist>
      </DivInline>
      <DivExist show={!gs.isEmptyJson(lightObj)}>
        <DeviceIdAddGwLine deviceID={serial} address={lightObj?.dtkAdd || lightObj?.zigbeeAdd} gatewayID={lightObj?.gatewayID} />
        <Typography variant="caption">{lightObj?.description}</Typography>
        <Typography variant="caption">mapID:{mapIDStr}</Typography>
      </DivExist>
    </>
  )
}