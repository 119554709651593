import { useState } from "react";
import { Typography, Button, Checkbox } from "@mui/material";
import { Lock, ArrowForward, ArrowBack } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import ModalSM from "components/ModalSM";
import DivCol from "components/DivCol";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import { saveSensorLockLevel } from "actions/sensorActions";
import General from "@ecoenghk/general";
const gs = new General();

export default function SensorChangeLockLvModal({
  btnContent,
  sensorObj,
  buttonNo,
  existingLv,
  existingRelease,
}) {
  const [open, setOpen] = useState(false);
  const [lockLevel, setLockLevel] = useState(5);
  const [releaseOnDelayEnd, setReleaseOnDelayEnd] = useState(0);
  const handleOpen = () => {
    setOpen(true);
    setLockLevel(existingLv);
    setReleaseOnDelayEnd(existingRelease);
  };
  const handleClose = () => {
    setOpen(false);
    setLockLevel(5);
    setReleaseOnDelayEnd(0);
  };
  const handleSave = async () => {
    if (sensorObj.type === "daylight") {
      const noArr = gs.newArrayBetween(1, 11);
      const lvArr = noArr.map((n) => lockLevel);
      const releaseArr = noArr.map((n) => releaseOnDelayEnd);
      await saveSensorLockLevel(
        sensorObj.sensorID,
        sensorObj,
        noArr,
        lvArr,
        releaseArr
      );
    } else if (sensorObj.type === "daylightmotion") {
    } else {
      await saveSensorLockLevel(
        sensorObj.sensorID,
        sensorObj,
        [buttonNo],
        [lockLevel],
        [releaseOnDelayEnd]
      );
    }
    handleClose();
  };

  return (
    <>
      <Button size="small" onClick={handleOpen} variant="outlined">
        {btnContent}
        <Lock sx={{ fontSize: "1.2em" }} />
        {existingLv > 5 ? 5 : existingLv}
      </Button>
      <ModalSM
        open={open}
        onClose={handleClose}
        modalTitle="Change priority level"
        onSave={handleSave}
      >
        <DivInline justifyContent="space-between">
          {[5, 4, 3, 2, 1].map((n) => {
            let variant = n === lockLevel ? "contained" : "outlined";
            return (
              <Button key={n} variant={variant} onClick={() => setLockLevel(n)}>
                <Lock />
                {n}
              </Button>
            );
          })}
        </DivInline>
        <DivInline sx={{ marginTop: "1vh" }} justifyContent="space-around">
          <DivCol>
            <ArrowBack />
            <Typography>Low priority</Typography>
          </DivCol>
          <DivCol>
            <ArrowForward />
            <Typography>High priority</Typography>
          </DivCol>
        </DivInline>
        <Divider10 />
        <SpaceBetweenDiv
          title="Release light to lowest priority on action end"
          data={
            <Checkbox
              checked={releaseOnDelayEnd === 1 ? true : false}
              onChange={(e) => setReleaseOnDelayEnd(e.target.checked ? 1 : 0)}
            />
          }
        />
      </ModalSM>
    </>
  );
}
