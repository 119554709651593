import { useContext, useRef } from "react";
import { SMContext } from "context/smContext";
import MapTopToolbar from "./toolbar/MapTopToolbar";
import { Stage, Layer, Image, Rect, Text } from "react-konva";
import useImage from "use-image";
import { useListenMapKeyPress, useListenWindowResize } from "hooks/mapHooks";
import LayerAll from "./layer/LayerAll";
import { useNavigate } from "react-router-dom";

export default function MapContainer({ vw, vhMap, vhToolbar }) {
  const [state, dispatch] = useContext(SMContext);
  const navigate = useNavigate();
  const { activeMapID, mapObjAll, mapSize, enablePan, enableZoom, mapScale } = state;
  const stageRef = useRef(null);
  const layerRef = useRef(null);
  const mapObj = mapObjAll[activeMapID] || {};
  const localMapUrl = `${global.ip}/img/${mapObj?.mapFileName}`;
  useListenMapKeyPress(layerRef);
  useListenWindowResize(vw, vhMap);
  const handleClickZoom = (e) => {
    e.evt.preventDefault();
    const scaleBy = 0.2;
    if (
      stageRef.current !== null &&
      layerRef.current !== null &&
      enableZoom !== ""
    ) {
      const stage = stageRef.current;
      const layer = layerRef.current;
      const oldScale = layer.scaleX();
      const newScale =
        enableZoom === "in" ? oldScale + scaleBy : oldScale - scaleBy;

      const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - layer.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - layer.y() / oldScale,
      };
      layer.scale({ x: newScale, y: newScale });
      const newPos = {
        x:
          -(mousePointTo.x - stage.getPointerPosition().x / newScale) *
          newScale,
        y:
          -(mousePointTo.y - stage.getPointerPosition().y / newScale) *
          newScale,
      };
      layer.position(newPos);
      layer.batchDraw();
      stage.batchDraw();
      dispatch({
        type: "SET_LAYER_SCALE",
        payload: {
          layerScale: newScale,
          layerPos: newPos
        },
      });
    }
  };
  const handleWheel = (e) => {
    e.evt.preventDefault();
    const scaleBy = 1.03;
    if (stageRef.current !== null && layerRef.current !== null) {
      const stage = stageRef.current;
      const layer = layerRef.current;
      const oldScale = layer.scaleX();
      const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - layer.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - layer.y() / oldScale,
      };
      const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      layer.scale({ x: newScale, y: newScale });
      const newPos = {
        x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
        y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
      };
      layer.position(newPos);
      layer.batchDraw();
      stage.batchDraw();
      dispatch({
        type: "SET_LAYER_SCALE",
        payload: {
          layerScale: newScale,
          layerPos: newPos
        },
      });
    }
  };
  const handleDragLayer = (e) => {
    if (enablePan) {
      const layer = layerRef.current;
      const newPos = { x: e.target.x(), y: e.target.y() };
      layer.position(newPos);
      dispatch({
        type: "SET_LAYER_SCALE",
        payload: {
          layerScale: layer.scaleX(),
          layerPos: newPos
        },
      });

    }
  };
  const cursorOnLayer = () => {
    let c =
      enableZoom === "in"
        ? "zoom-in"
        : enableZoom === "out"
          ? "zoom-out"
          : enablePan
            ? "grab"
            : "default";
    return c;
  };
  const layerProps = {
    ...state,
    dispatch,
    stageRef,
    layerRef,
    navigate,
    iconSize: mapObjAll[activeMapID]?.iconSize,
    buttonSize: mapObjAll[activeMapID]?.buttonSize,
  };
  return (
    <div>
      <MapTopToolbar vw={vw} vh={vhToolbar} layerRef={layerRef} />
      <div
        style={{
          width: `${vw}vw`,
          height: `${vhMap}vh`,
          backgroundColor: "lightgrey",
          border: "1px solid lightgrey",
        }}
      >
        <Stage
          ref={stageRef}
          width={mapSize?.x || 300}
          height={mapSize?.y || 200}
          onContextMenu={(e) => e.evt.preventDefault()}
        >
          <Layer
            ref={layerRef}
            draggable={enablePan}
            onDragEnd={handleDragLayer}
            onWheel={handleWheel}
            onMouseLeave={(e) => {
              const container = e.target.getStage().container();
              container.style.cursor = "default";
            }}
            onMouseEnter={(e) => {
              const container = e.target.getStage().container();
              container.style.cursor = cursorOnLayer();
            }}
          >
            <BackgroundImage
              width={mapSize?.x || 300}
              height={mapSize?.y || 200}
              mapUrl={mapObj?.mapFileName ? localMapUrl : mapObj.mapUrl}
              clickFct={(e) => {
                if (e.evt.which === 3) {
                  console.log("RIGHT Click", e.evt.x, e.evt.y);
                  return;
                }
                dispatch({
                  type: "ALL_UNSELECTED",
                });

                handleClickZoom(e);
              }}
            />
            <LayerAll layerProps={layerProps} />
            {/* <Rect ref={refRect} width={100} height={100} x={800} y={0} fill="red"/> */}
            {/* <Text text={`mapSizeX: ${mapSize?.x}, mapScaleX:${mapScale.x?.toFixed(2)} scale:${layerProps.layerScale}`} x={400} y={300} onClick={() => alert("Clicked X")} /> */}
            {/* <Text text={`mapSizeY: ${mapSize?.y}, mapScaleY:${mapScale.y?.toFixed(2)} `} x={400} y={330} onClick={() => alert("Clicked Y")} listening={false} /> */}
          </Layer>
        </Stage>
      </div>
    </div>
  );
}

const BackgroundImage = ({ width, height, mapUrl, clickFct }) => {
  const [image] = useImage(mapUrl);

  return (
    <Image
      image={image}
      width={width}
      height={height}
      onClick={(e) => clickFct(e)}
      onTap={(e) => clickFct(e)}
    />
  );
};
