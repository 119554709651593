import { useContext, useState } from "react";
import { SMContext } from "context/smContext";
import { getLatestDeviceFirmwareVer } from "actions/serverActions"
import ModalSM from "components/ModalSM"
import SpaceBetweenDiv from "components/SpaceBetweenDiv"
import { Typography } from "@mui/material";
import { ArrowForwardIos, SystemUpdate } from "@mui/icons-material"
import ButtonSM from "components/ButtonSM"
import DivInline from "components/DivInline"
import { alertIfDisconnected, deviceInfoOfObj } from "actions/generalActions";
import IconButtonBack from "components/IconButtonBack";
import DeviceIcon from "components/DeviceIcon";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import { alertWindow, confirmWindow } from "actions/screenActions";
import { lightUpdateFirmware, updateSerialProperty } from "actions/lightActions";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import { queryOneSensorSetting, sensorChangeOtaPassword, sensorChangeOtaSsid, sensorUpdateFirmware, updateSensorProperty } from "actions/sensorActions";
import { daliCtlChangeOtaPassword, daliCtlChangeOtaSsid, daliCtlUpdateFirmware, queryDaliCtlOneSetting, updateDaliCtlProperty } from "actions/daliCtlActions";
import AdminContainer from "components/AdminContainer";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
import { lightChangeOtaSsid, lightChangeOtaPassword, queryOneLightSetting } from "actions/lightActions";
import { deviceUpdateFirmwareFromIP, queryDeviceCurrentFirmwareVersion } from "actions/generalDeviceActions";

const programOfType = { light: "SM_light_DTK", sensor: "SM_sensor_DTK", daliCtl: "SM_light_DTK" }

export default function DeviceUpdateFirmwareModal({ deviceObj }) {
    const [state, dispatch] = useContext(SMContext);
    const { latestDeviceFirmwareVerObj } = state;
    const [open, setOpen] = useState(false);
    const deviceInfo = deviceInfoOfObj(deviceObj);
    const type = deviceInfo.deviceType;
    const deviceID = deviceInfo.deviceID;
    const program = programOfType[deviceInfo.deviceType];
    const latestVer = latestDeviceFirmwareVerObj[program]?.replace("_", ".");
    const handleOpen = () => {
        getLatestDeviceFirmwareVer(deviceObj);
        setOpen(true);
    }

    return (
        <>
            <SpaceBetweenDiv
                title="Firmware version"
                data={
                    <DivInline>
                        <Typography variant="body1">{deviceObj?.deviceVersion}</Typography>
                        <ButtonSM
                            onClick={handleOpen}
                            tooltip="Check and update firmware">
                            <SystemUpdate />
                        </ButtonSM>
                    </DivInline>
                }
            />
            <ModalSM
                open={open}
                onClose={() => setOpen(false)}
                width="50vw"
                height="70vh"
            >
                <DivInline>
                    <IconButtonBack onBtnClick={() => setOpen(false)} />
                    <Typography>Update firmware -</Typography>
                    <DeviceIcon deviceType={type} deviceStyle={deviceObj?.style || deviceObj?.type} gang={deviceObj?.gang} />
                    <Typography>{deviceID}</Typography>
                </DivInline>
                <Divider10 />
                <SpaceBetweenDiv
                    title="Current version"
                    data={deviceObj?.deviceVersion}
                    handleRefresh={() => queryDeviceCurrentFirmwareVersion(deviceObj)}
                />
                <SpaceBetweenDiv
                    title="Latest version"
                    data={latestVer}
                    handleRefresh={() => getLatestDeviceFirmwareVer(deviceObj)}
                />
                <DivExist show={Number(deviceObj?.deviceVersion) < Number(latestVer)}>
                    <SpaceBetweenButton
                        title="Update firmware"
                        btnContent={<ArrowForwardIos />}
                        onBtnClick={() => {
                            if (alertIfDisconnected(deviceObj, state, dispatch)) return;
                            confirmWindow(dispatch, `Confirm update ${type} firmware to ${latestVer} for ${deviceID}`, () => {
                                if (type === "light") lightUpdateFirmware(deviceObj);
                                else if (type === "sensor") sensorUpdateFirmware(deviceObj);
                                else if (type === "daliCtl") daliCtlUpdateFirmware(deviceObj);
                            })
                        }}

                    />
                </DivExist>
                <AdminContainer>
                    <SpaceBetweenDialogInput
                        title="Update firmware network SSID"
                        data={deviceObj?.otaSsid}
                        handleSave={async (val) => {
                            if (alertIfDisconnected(deviceObj, state, dispatch)) return;
                            if (type === "light") lightChangeOtaSsid(deviceObj, val);
                            else if (type === "sensor") sensorChangeOtaSsid(deviceObj, val);
                            else if (type === "daliCtl") daliCtlChangeOtaSsid(deviceObj, val);
                        }}
                        handleRefresh={() => {
                            if (type === "light") queryOneLightSetting(deviceObj, 0x0312);
                            else if (type === "sensor") queryOneSensorSetting(deviceObj, 0x0716);
                            else if (type === "daliCtl") queryDaliCtlOneSetting(deviceObj, 0x0b19);
                        }}
                    />
                    <SpaceBetweenDialogInput
                        title="Update firmware network password"
                        data={deviceObj?.otaPassword}
                        handleSave={async (val) => {
                            if (alertIfDisconnected(deviceObj, state, dispatch)) return;
                            if (type === "light") lightChangeOtaPassword(deviceObj, val);
                            else if (type === "sensor") sensorChangeOtaPassword(deviceObj, val);
                            else if (type === "daliCtl") daliCtlChangeOtaPassword(deviceObj, val);
                        }}
                        handleRefresh={() => {
                            if (type === "light") queryOneLightSetting(deviceObj, 0x0313);
                            else if (type === "sensor") queryOneSensorSetting(deviceObj, 0x0717);
                            else if (type === "daliCtl") queryDaliCtlOneSetting(deviceObj, 0x0b1a);
                        }}
                    />
                    <Divider10 />
                    <SpaceBetweenDialogInput
                        title="Update firmware server IP [xxx.xxx.xxx.xxx]"
                        data={deviceObj?.updateFirmwareServerIP}
                        handleSave={async (val) => {
                            const ipArray = val.split(".");
                            if (ipArray.length !== 4) {
                                alertWindow(dispatch, "Invalid IP format");
                                return;
                            }
                            if (type === "light") updateSerialProperty(deviceID, deviceObj, { updateFirmwareServerIP: val });
                            else if (type === 'sensor') updateSensorProperty(deviceID, deviceObj, deviceObj?.gatewayID, { updateFirmwareServerIP: val });
                            else if (type === "daliCtl") updateDaliCtlProperty(deviceID, { updateFirmwareServerIP: val }, true);
                        }}
                    />
                    <Divider10 />
                    <SpaceBetweenButton
                        title={`Update firmware from server IP ${deviceObj?.updateFirmwareServerIP}`}
                        hideComponent={!deviceObj?.updateFirmwareServerIP || Number(deviceObj?.deviceVersion) >= Number(latestVer)}
                        btnContent={<ArrowForwardIos />}
                        onBtnClick={() => {
                            if (alertIfDisconnected(deviceObj, state, dispatch)) return;
                            confirmWindow(dispatch, `Confirm update ${type} firmware to ${latestVer} for ${deviceID}`, () => {
                                deviceUpdateFirmwareFromIP(deviceObj);
                            })
                        }}
                    />
                </AdminContainer>
            </ModalSM>
        </>
    )
}