import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Tooltip, IconButton, Box } from "@mui/material";
import DivInline from "components/DivInline";
import LightIcon from "components/LightIcon";
import ModalSM from "components/ModalSM";
import { FolderCopy } from "@mui/icons-material";
import { confirmWindow, openSnackbar } from "actions/screenActions";
import { copyMultiSchedules } from "actions/scheduleActions";
export default function CopyScheduleModal({ serial, schObjArr, handleUpdate }) {
  const [state, dispatch] = useContext(SMContext);
  const { lightObjAll, serverObj } = state;
  const [open, setOpen] = useState(false);
  const lightArray = Object.keys(lightObjAll || {});

  const handleClose = async () => {
    setOpen(false);
    await handleUpdate();
  };
  const handleCopy = async (targetSerial) => {
    confirmWindow(
      dispatch,
      `Copy schedules to light ${targetSerial}`,
      async () => {
        await copyMultiSchedules(serverObj, targetSerial, schObjArr);
        openSnackbar(dispatch, "Copy finished");
        await handleClose();
      }
    );
  };
  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        size="small"
        disabled={schObjArr?.length === 0}
      >
        <Tooltip title="Copy schedules to other light">
          <FolderCopy />
        </Tooltip>
      </IconButton>
      <ModalSM
        modalTitle="Copy schedules to other light"
        open={open}
        onClose={handleClose}
      >
        <Typography>Choose target light:</Typography>
        {lightArray
          .filter((s) => s !== serial)
          .sort((a, b) =>
            lightObjAll[a]?.description?.localeCompare(
              lightObjAll[b]?.description
            )
          )
          .map((s) => (
            <Box
              sx={{
                margin: "2vh",
                cursor: "pointer",
                "&:hover": {
                  outline: "2px solid lightgrey",
                },
              }}
              key={s}
              onClick={() => handleCopy(s)}
            >
              <DivInline>
                <LightIcon
                  lightStyle={lightObjAll[s]?.style}
                  disabledStatus
                  disabledConnectStatus
                  width={20}
                />
                <Typography>{`${lightObjAll[s]?.description} [${s}]`}</Typography>
              </DivInline>
            </Box>
          ))}
      </ModalSM>
    </>
  );
}
