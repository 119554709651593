import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { ArrowBack, ArrowForward, Lock } from "@mui/icons-material";
import { Button, Typography, Checkbox } from "@mui/material";
import DivCol from "components/DivCol";
import ModalSM from "components/ModalSM";
import DivExist from "components/DivExist";
import { saveZcLockLevel } from "actions/zcActions";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";

export default function ChangeLockLvTimerModal({ zoneControlObj }) {
  const [open, setOpen] = useState(false);
  const [lockLevel1, setLockLevel1] = useState(5); //timer END
  const [lockLevel2, setLockLevel2] = useState(5); //timer START
  const [releaseOnDelayEnd1, setReleaseOnDelayEnd1] = useState(0); //timer END release
  const handleOpen = () => {
    setOpen(true);
    setLockLevel1(zoneControlObj.lockLevelSet1);
    setLockLevel2(zoneControlObj.lockLevelSet2);
    setReleaseOnDelayEnd1(zoneControlObj.releaseOnDelayEnd1);
  };
  const handleClose = () => {
    setOpen(false);
    setLockLevel1(5);
    setLockLevel2(5);
    setReleaseOnDelayEnd1(0);
  };
  const handleSave = async () => {
    await saveZcLockLevel(
      zoneControlObj.zoneControlID,
      zoneControlObj,
      [1, 2],
      [lockLevel1, lockLevel2],
      [releaseOnDelayEnd1, 0]
    );
    handleClose();
  };

  return (
    <>
      <Button onClick={handleOpen} variant="outlined" size="small">
        <Lock sx={{ fontSize: "1.2em" }} />
        {zoneControlObj.lockLevelSet2 > 5 ? 5 : zoneControlObj.lockLevelSet2}
      </Button>
      <ModalSM
        open={open}
        onClose={handleClose}
        modalTitle="Change priority level"
        onSave={handleSave}
      >
        <DivInline justifyContent="space-between">
          {[5, 4, 3, 2, 1].map((n) => {
            let variant = n === lockLevel2 ? "contained" : "outlined";
            return (
              <Button
                key={n}
                variant={variant}
                onClick={() => {
                  setLockLevel2(n);
                  setLockLevel1(n);
                }}
              >
                <Lock />
                {n}
              </Button>
            );
          })}
        </DivInline>
        <DivInline sx={{ marginTop: "1vh" }} justifyContent="space-around">
          <DivCol>
            <ArrowBack />
            <Typography>Low priority</Typography>
          </DivCol>
          <DivCol>
            <ArrowForward />
            <Typography>High priority</Typography>
          </DivCol>
        </DivInline>
        <Divider10 />
        <SpaceBetweenDiv
          title="Release light to lowest priority on action end"
          data={
            <Checkbox
              checked={releaseOnDelayEnd1 === 1 ? true : false}
              onChange={(e) => setReleaseOnDelayEnd1(e.target.checked ? 1 : 0)}
            />
          }
        />
      </ModalSM>
    </>
  );
}
