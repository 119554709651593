import { useContext } from "react";
import { SMContext } from "context/smContext";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";

export default function InterfaceEspPanel({ ttyS_no, ttyObj }) {
    const [state, dispatch] = useContext(SMContext);
    const { activeGatewayID, gatewayObjAll } = state;
    const gatewayObj = gatewayObjAll[activeGatewayID];
    return (
        <>
            <SpaceBetweenDiv title="Type" data="ESP" />
            <SpaceBetweenDiv title="WifiApMacAddress" data={ttyObj?.macAddress} />
            <SpaceBetweenDiv title="Version" data={ttyObj?.version} />


        </>

    )
}
