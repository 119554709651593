import DeviceIcon from "components/DeviceIcon";
import MapModal from "../device/MapModal";
import Divider10 from "components/Divider10";
import { useState } from "react";
import { Typography } from "@mui/material";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
import SensorIcon from "components/SensorIcon";
import General from "@ecoenghk/general";
import { confirmWindow } from "actions/screenActions";
const gs = new General();

export default function ChooseGangModal({
    open,
    onClose,
    x,
    y,
    connectDeviceID,
    connectDeviceObj,
    sensorObj,
    layerProps,
    onConnect
}) {
    const [hoverSw, setHoverSw] = useState(0);
    const { dispatch } = layerProps;
    const { gang } = sensorObj || {};
    const connectDeviceType = connectDeviceObj?.type;
    const handleConnect = async (swNo) => {
        confirmWindow(dispatch, `Connect ${connectDeviceType} ${connectDeviceID} to switch no ${swNo}?`, async () => {
            onConnect(swNo);
            onClose();
        });

    }
    const modalWidth = window.innerWidth * 0.28;
    const modalHeight = window.innerHeight * 0.3;
    return (
        <>
            <MapModal
                x={x}
                y={y}
                open={open}
                handleCloseModal={onClose}
                width={modalWidth}
                height={modalHeight}
                layerProps={layerProps}
            >
                <DivInline>
                    <DeviceIcon deviceType="sensor" deviceStyle={connectDeviceType} width={3}
                        sizeUnit="vw"
                        gang={gang} />
                    <Typography>{` [${connectDeviceID}] connect to button no:`}</Typography>

                </DivInline>
                <Divider10 />
                <DivInline justifyContent="space-around">
                    {gs.newArrayBetween(1, gang || 2).map((n) => (
                        <DivCol
                            key={n}
                            onClick={() => handleConnect(n)}
                            style={{
                                cursor: "pointer",
                                outline: "1px solid #fff",
                                padding: "0.5vw",
                                borderRadius: "0.3vw",
                                backgroundColor: hoverSw === n ? "darkgrey" : "",
                            }}
                            onMouseEnter={() => setHoverSw(n)}
                            onMouseLeave={() => setHoverSw(0)}
                        >
                            <SensorIcon
                                type={sensorObj?.type}
                                width={4}
                                sizeUnit="vw"
                                disabledConnectStatus
                                status={n === 1 ? 1 : 0}
                                status2={n === 2 ? 1 : 0}
                                status3={n === 3 ? 1 : 0}
                                status4={n === 4 ? 1 : 0}
                                gang={gang}
                            />
                            <Typography>No.{n}</Typography>
                        </DivCol>
                    ))}
                </DivInline>
            </MapModal>
        </>
    )
}