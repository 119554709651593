import moment from "moment";

export const smReducer = (state, action) => {
  // console.log("Finished dispatched", action);
  switch (action.type) {
    case "FETCHED_USER": {
      const userObj = action.payload;
      let newState = {
        ...state,
        userObj,
        storedEmail: userObj.email,
      };
      if (userObj.enableFS) {
        newState = { ...newState, enableFS: userObj.enableFS, fs: userObj.fs };
      }
      return newState;
    }
    case "LOGIN_APPROVED": {
      return { ...state, userObj: action.payload };
    }
    case "SET_SOCKET": {
      return { ...state, socket: action.payload };
    }
    case "SHOW_BIG_PROGRESS": {
      return { ...state, showBigProgress: true };
    }
    case "HIDE_BIG_PROGRESS": {
      return { ...state, showBigProgress: false };
    }
    case "OPENED_SNACKBAR": {
      const { title, severity, time } = action.payload;
      return { ...state, snackbarMsg: { title, severity, time, status: true } };
    }
    case "CLOSED_SNACKBAR": {
      return {
        ...state,
        snackbarMsg: { title: "", severity: "", status: false },
      };
    }
    case "WINDOW_CONFIRM": {
      const { status, title, onConfirm, onCancel, callback } = action.payload;
      return {
        ...state,
        windowConfirm: status,
        windowConfirmTitle: title,
        onConfirm,
        onCancel,
        callback,
      };
    }
    case "WINDOW_ALERT": {
      const { status, windowAlertTitle } = action.payload;
      return { ...state, windowAlert: status, windowAlertTitle };
    }
    case "WINDOW_PROMPT": {
      const { status, title, onConfirm, defaultValue } = action.payload;
      return {
        ...state,
        windowPrompt: status,
        windowPromptTitle: title,
        onConfirm,
        windowPromptDefaultValue: defaultValue,
      };
    }
    case "ADD_MAP_DEVICE_MESSAGE": {
      const { deviceIDArr, messageArr } = action.payload;
      return addMapDeviceMessage(state, deviceIDArr, messageArr);
    }
    case "CLEAR_MAP_DEVICE_MESSAGE": {
      const { deviceID } = action.payload;
      if (deviceID) delete state.mapDeviceMessage[deviceID];
      return { ...state };
    }
    case "FETCHED_SERVER_OBJ": {
      const serverObj = action.payload;
      return { ...state, serverObj, serverID: serverObj?.serverID };
    }
    case "FETCHED_MAP_OBJ_ALL": {
      return { ...state, mapObjAll: action.payload };
    }
    case "FETCHED_LIGHT_OBJ_ALL": {
      return { ...state, lightObjAll: action.payload };
    }
    case "FETCHED_GATEWAY_OBJ_ALL": {
      return { ...state, gatewayObjAll: action.payload };
    }
    case "FETCHED_SENSOR_OBJ_ALL": {
      return { ...state, sensorObjAll: action.payload };
    }
    case "FETCHED_DALICTL_OBJ_ALL": {
      return { ...state, daliCtlObjAll: action.payload };
    }
    case "FETCHED_ZONECONTROL_OBJ_ALL": {
      return { ...state, zoneControlObjAll: action.payload };
    }
    case "FETCHED_TIMETABLE_OBJ_ALL": {
      const { objAll, arrAll } = action.payload;
      return { ...state, timeTableObjAll: objAll, timeTableArrAll: arrAll };
    }

    case "SET_ACTIVE_MAP": {
      return {
        ...state,
        activeMapID: action.payload,
      };
    }
    case "SET_ACTIVE_SERIAL": {
      return { ...state, activeSerial: action.payload };
    }
    case "SET_ACTIVE_GATEWAY": {
      return { ...state, activeGatewayID: action.payload };
    }
    case "SET_ACTIVE_SENSOR": {
      return { ...state, activeSensorID: action.payload };
    }
    case "SET_ACTIVE_ZONE_CONTROL": {
      return { ...state, activeZoneControlID: action.payload };
    }
    case "SET_ACTIVE_REPORT": {
      const reportObj = action.payload;
      return {
        ...state,
        activeReportID: reportObj?.reportID || "",
        activeReportObj: reportObj,
      };
    }
    case "MAP_ADDED": {
      const { newMapObj, newUserObj } = action.payload;
      const mapID = newMapObj.mapID;
      state.mapObjAll[mapID] = newMapObj;
      return { ...state, userObj: newUserObj, activeMapID: mapID };
    }
    case "MAP_REMOVED": {
      const { mapID, newUserObj } = action.payload;
      delete state.mapObjAll[mapID];
      return { ...state, userObj: newUserObj, activeMapID: "" };
    }
    case "UPDATED_USER_OBJ": {
      return { ...state, userObj: action.payload };
    }
    case "UPDATED_MAP_OBJ": {
      const mapObj = action.payload;
      const mapID = mapObj.mapID;
      state.mapObjAll[mapID] = mapObj;
      return { ...state };
    }
    case "UPDATE_LIGHT_OBJ": {
      const lightObj = action.payload;
      const serial = lightObj.serial;
      state.lightObjAll[serial] = lightObj;
      return { ...state };
    }
    case "UPDATE_GATEWAY_OBJ": {
      const gatewayObj = action.payload;
      const gatewayID = gatewayObj?.gatewayID;
      state.gatewayObjAll[gatewayID] = gatewayObj;
      return { ...state };
    }
    case "UPDATE_SENSOR_OBJ": {
      const obj = action.payload;
      if (obj) {
        const sensorID = obj.sensorID;
        state.sensorObjAll[sensorID] = obj;
      }
      return { ...state };
    }
    case "UPDATED_SERVER_OBJ": {
      return { ...state, serverObj: action.payload };
    }
    case "LIGHT_DELETE_PERMANENTLY": {
      const serial = action.payload;
      delete state.lightObjAll[serial];
      return { ...state };
    }
    case "UPDATED_ZONE_CONTROL_OBJ": {
      const obj = action.payload;
      if (obj) {
        const zoneControlID = obj.zoneControlID;
        state.zoneControlObjAll[zoneControlID] = obj;
      }
      return { ...state };
    }
    case "UPDATE_LIGHT_CURRENT_DATA": {
      const { serial, serialProps } = action.payload;
      let modifySerialProps = {};
      Object.keys(serialProps).forEach((k) => {
        modifySerialProps[k] = serialProps[k] || "";
      });
      const lightArr = Object.keys(state.lightObjAll || {});
      if (lightArr.includes(serial)) {
        state.lightObjAll[serial] = {
          ...state.lightObjAll[serial],
          ...modifySerialProps,
        };
      }
      return { ...state };
    }
    case "UPDATED_MAP_PROPERTY": {
      const { mapID, updateObj } = action.payload;
      // state.mapObjAll[mapID][field] = value;
      state.mapObjAll[mapID] = { ...state.mapObjAll[mapID], ...updateObj };
      return { ...state };
    }
    case "UPDATED_LIGHT_PROPERTY": {
      const { serial, updateObj } = action.payload;
      state.lightObjAll[serial] = {
        ...state.lightObjAll[serial],
        ...updateObj,
      };
      return { ...state };
    }
    case "UPDATED_LIGHT_TYPE": {
      const { serial, typeField, value } = action.payload;
      if (state.lightObjAll[serial]) {
        if (state.lightObjAll[serial].type) {
          state.lightObjAll[serial].type[typeField] = value;
        }
      };
      return { ...state };
    }
    case "UPDATE_MULTI_SERIAL_PROPERTY": {
      const updateObjBySerial = action.payload;
      // console.log("updateObjBySerial", updateObjBySerial);
      Object.keys(updateObjBySerial || {}).forEach((s) => {
        const updateObj = updateObjBySerial[s];
        state.lightObjAll[s] = {
          ...state.lightObjAll[s],
          ...updateObj,
        };
      });
      // console.log("state.lightObjAll", state.lightObjAll['1e127db1_0_LG']);
      return { ...state };
    }
    case "UPDATED_SENSOR_PROPERTY": {
      const { sensorID, updateObj } = action.payload;
      state.sensorObjAll[sensorID] = {
        ...state.sensorObjAll[sensorID],
        ...updateObj,
      };
      return { ...state };
    }
    case "UPDATED_GATEWAY_PROPERTY": {
      const { gatewayID, updateObj } = action.payload;
      state.gatewayObjAll[gatewayID] = {
        ...state.gatewayObjAll[gatewayID],
        ...updateObj,
      };
      return { ...state };
    }
    case "UPDATED_ZC_PROPERTY": {
      const { zoneControlID, updateObj } = action.payload;
      state.zoneControlObjAll[zoneControlID] = {
        ...state.zoneControlObjAll[zoneControlID],
        ...updateObj,
      };
      return { ...state };
    }
    case "UPDATED_DALICTL_PROPERTY": {
      const { daliCtlID, updateObj } = action.payload;
      state.daliCtlObjAll[daliCtlID] = {
        ...state.daliCtlObjAll[daliCtlID],
        ...updateObj,
      };
      return { ...state };
    }
    case "UPDATED_SERVER_PROPERTY": {
      const updateObj = action.payload;
      state.serverObj = { ...state.serverObj, ...updateObj };
      return { ...state };
    }
    case "TEST_TERMINATED": {
      const { serial, reportID } = action.payload;
      state.lightObjAll[serial].reportObj.result = "FAIL";
      return { ...state };
    }
    case "DELETED_ZONE_CONTROL": {
      const zoneControlID = action.payload;
      delete state.zoneControlObjAll[zoneControlID];
      return { ...state };
    }
    case "ADD_ZC_TIME_SETTING": {
      const { zoneControlID, timeTableObj1, timeTableObj2 } = action.payload;
      const tid1 = timeTableObj1?.timeTableID;
      const tid2 = timeTableObj2?.timeTableID;
      let zoneControlObj = state.zoneControlObjAll[zoneControlID] || {};
      let timeTableID1 = [...(zoneControlObj?.timeTableID1 || [])];
      if (!timeTableID1.includes(tid1)) timeTableID1 = [...timeTableID1, tid1];
      let timeTableID2 = [...(zoneControlObj?.timeTableID2 || [])];
      if (!timeTableID2.includes(tid2)) timeTableID2 = [...timeTableID2, tid2];
      zoneControlObj = {
        ...zoneControlObj,
        timeTableID1,

        timeTableID2,
      };
      state.zoneControlObjAll[zoneControlID] = zoneControlObj;
      state.timeTableObjAll = {
        ...state.timeTableObjAll,
        [tid1]: timeTableObj1,
        [tid2]: timeTableObj2,
      };
      return { ...state };
    }
    case "UPDATED_TIMETABLE_OBJ": {
      let updateObj = action.payload;
      let newTimeTableObjAll = { ...(state.timeTableObjAll || {}) };
      let newTimeTableArrAll = [...(state.timeTableArrAll || [])];
      const tid = updateObj.timeTableID;
      const newObj = { ...newTimeTableObjAll[tid], ...updateObj };
      const index = newTimeTableArrAll.findIndex(
        (obj) => obj.timeTableID === tid
      );
      newTimeTableArrAll[index] = newObj;
      return {
        ...state,
        timeTableArrAll: newTimeTableArrAll,
        timeTableObjAll: newTimeTableObjAll,
      };
    }
    case "CREATE_SLAVE_LIGHT": {
      const slaveLightObj = action.payload;
      const slaveSerial = slaveLightObj.serial;
      const masterSerial = slaveLightObj.masterSerial;
      let masterLightObj = state.lightObjAll[masterSerial];
      let slaveLightArray = masterLightObj.slaveLightArray || [];
      if (!slaveLightArray.includes(slaveSerial)) slaveLightArray.push(slaveLightObj.serial);
      masterLightObj.slaveLightArray = slaveLightArray;
      state.lightObjAll = { ...state.lightObjAll, [masterSerial]: masterLightObj, [slaveSerial]: slaveLightObj }
      return state;
    }
    case "DELETE_SLAVE_LIGHT": {
      const slaveLightObj = action.payload;
      const slaveSerial = slaveLightObj.serial;
      const masterSerial = slaveLightObj.masterSerial;
      let masterLightObj = state.lightObjAll[masterSerial];
      let slaveLightArray = masterLightObj.slaveLightArray || [];
      slaveLightArray = slaveLightArray.filter(s => s !== slaveSerial);
      masterLightObj.slaveLightArray = slaveLightArray;
      state.lightObjAll = { ...state.lightObjAll, [masterSerial]: masterLightObj }
      delete state.lightObjAll[slaveSerial];
      return state;
    }

    ///////////Map Use //////////////////Map Use ///////
    case "SET_MAP_SIZE_SCALE": {
      const { size, scale } = action.payload;
      return { ...state, mapScale: scale, mapSize: size };
    }
    case "SET_ENABLE_PAN": {
      return { ...state, enablePan: action.payload };
    }
    case "SET_ENABLE_ZOOM": {
      return { ...state, enableZoom: action.payload };
    }
    case "SET_MAP_RIGHT_MODE": {
      return { ...state, mapRightMode: action.payload };
    }
    case "SET_MAP_LAYER": {
      const obj = { ...state.mapLayer, ...action.payload };
      return { ...state, mapLayer: obj };
    }
    case "SET_LAYER_SCALE": {
      const { layerScale, layerPos } = action.payload;
      return { ...state, layerScale, layerPos };
    }
    // case "SET_RSSIWEB": {
    //   return { ...state, rssiWeb: action.payload };
    // }
    // case "UPDATE_RSSI_OBJ": {
    //   const rssiObj = action.payload;
    //   const updateDocID = rssiObj.docID;
    //   let webArr = [...state.rssiWeb];
    //   webArr = webArr.filter((obj) => obj.docID !== updateDocID);
    //   webArr = [...webArr, rssiObj];
    //   state.rssiWeb = webArr;
    //   return { ...state };
    // }
    case "MAP_ADD_LIGHT": {
      const { serial, lightObj, mapID, x, y } = action.payload;
      const newMapIDArr = [...(lightObj.mapID || []), mapID];
      const newLightObj = { ...lightObj, mapID: newMapIDArr };
      state.lightObjAll[serial] = newLightObj;
      const newMapLightObj = {
        ...state.mapObjAll[mapID]?.lightObj,
        [serial]: { x, y },
      };
      state.mapObjAll[mapID].lightObj = newMapLightObj;
      return { ...state };
    }
    case "MAP_REMOVE_LIGHT": {
      const { serial, mapID } = action.payload;
      let lightObjAll = state.lightObjAll;
      delete lightObjAll[serial];
      state.lightObjAll = lightObjAll;
      let newMapLightObj = state.mapObjAll[mapID]?.lightObj;
      newMapLightObj = { ...newMapLightObj };
      delete newMapLightObj[serial];
      state.mapObjAll[mapID].lightObj = newMapLightObj;
      return { ...state };
    }
    case "MAP_ADD_SENSOR": {
      const { sensorID, sensorObj, mapID, x, y } = action.payload;
      const newMapIDArr = [...(sensorObj.mapID || []), mapID];
      const newSensorObj = { ...sensorObj, mapID: newMapIDArr };
      state.sensorObjAll[sensorID] = newSensorObj;
      let newMapSensorObj = state.mapObjAll[mapID]?.sensorObj;
      newMapSensorObj = { ...newMapSensorObj, [sensorID]: { x, y } };
      state.mapObjAll[mapID].sensorObj = newMapSensorObj;
      return { ...state };
    }
    case "MAP_REMOVE_SENSOR": {
      const { sensorID, mapID } = action.payload;
      delete state.sensorObjAll[sensorID];
      let newMapSensorObj = state.mapObjAll[mapID]?.sensorObj;
      delete newMapSensorObj[sensorID];
      state.mapObjAll[mapID].sensorObj = newMapSensorObj;
      return { ...state };
    }
    case "MAP_ADD_GATEWAY": {
      const { gatewayID, gatewayObj, mapID, x, y } = action.payload;
      const newMapIDArr = [...gatewayObj.mapID, mapID];
      const newGatewayObj = { ...gatewayObj, mapID: newMapIDArr };
      state.gatewayObjAll[gatewayID] = newGatewayObj;
      let newMapGwObj = state.mapObjAll[mapID]?.gatewayObj;
      newMapGwObj = { ...newMapGwObj, [gatewayID]: { x, y } };
      if (state.mapObjAll[mapID]) {
        state.mapObjAll[mapID].gatewayObj = newMapGwObj;
      }
      return { ...state };
    }
    case "MAP_REMOVE_GATEWAY": {
      const { gatewayID, mapID } = action.payload;
      // let newGwObj = state.gatewayObjAll[gatewayID] || {};
      // const newMapIDArr = [...(newGwObj.mapID || [])].filter(
      //   (mid) => mid !== mapID
      // );
      // newGwObj = { ...newGwObj, mapID: newMapIDArr };
      // state.gatewayObjAll[gatewayID] = newGwObj;
      delete state.gatewayObjAll[gatewayID];
      let newMapGwObj = state.mapObjAll[mapID]?.gatewayObj;
      delete newMapGwObj[gatewayID];
      state.mapObjAll[mapID].gatewayObj = newMapGwObj;
      return { ...state };
    }
    case "MAP_ADDED_ZONE_CONTROL": {
      const { zoneControlID, zoneControlObj, mapID, x, y } = action.payload;
      state.zoneControlObjAll[zoneControlID] = zoneControlObj;
      let newZcMapObj = { ...state.mapObjAll[mapID].zoneControlObj };
      newZcMapObj = { ...newZcMapObj, [zoneControlID]: { x, y } };
      state.mapObjAll[mapID].zoneControlObj = newZcMapObj;
      // let newZcArr = state.serverObj.zoneControlArray || [];
      // newZcArr = [...newZcArr, zoneControlID];
      // state.serverObj.zoneControlArray = newZcArr;
      return { ...state };
    }
    case "MAP_DELETED_ZONE_CONTROL": {
      const { zoneControlID, mapID } = action.payload;
      let existingZcObj = state.zoneControlObjAll[zoneControlID];
      let serialMap = existingZcObj?.serial || {};
      delete state.zoneControlObjAll[zoneControlID];
      if (mapID) {
        let newZcMapObj = { ...state.mapObjAll[mapID].zoneControlObj };
        delete newZcMapObj[zoneControlID];
        state.mapObjAll[mapID].zoneControlObj = newZcMapObj;
      }
      Object.values(serialMap || {}).forEach((s) => {
        let lightObj = state.lightObjAll[s];
        let zcArr = lightObj?.zoneControlArray || [];
        zcArr = zcArr.filter((z) => z !== zoneControlID);
        state.lightObjAll[s].zoneControlArray = zcArr;
      });
      if (existingZcObj?.type === "timer") {
        delete state.timeTableObjAll[existingZcObj.timeTableID1];
        delete state.timeTableObjAll[existingZcObj.timeTableID2];
      }
      return { ...state };
    }
    case "MAP_ADD_DALICTL": {
      const { daliCtlID, daliCtlObj, mapID, x, y } = action.payload;
      let newMapIDArr = [...(daliCtlObj?.mapID || [])];
      newMapIDArr = [...newMapIDArr, mapID];
      const newDaliCtlObj = { ...daliCtlObj, mapID: newMapIDArr };
      state.daliCtlObjAll[daliCtlID] = newDaliCtlObj;
      let newMapDaliObj = state.mapObjAll[mapID]?.daliCtlObj;
      newMapDaliObj = { ...newMapDaliObj, [daliCtlID]: { x, y } };
      state.mapObjAll[mapID].daliCtlObj = newMapDaliObj;
      return { ...state };
    }
    case "MAP_REMOVED_DALICTL": {
      const { daliCtlID, mapID } = action.payload;
      // let newDaliObj = { ...state.daliCtlObjAll[daliCtlID] };
      // let newMapIDArr = [...(newDaliObj?.mapID || [])];
      // newMapIDArr = newMapIDArr.filter((mid) => mid !== mapID);
      // state.daliCtlObjAll[daliCtlID] = { ...newDaliObj, mapID: newMapIDArr };
      delete state.daliCtlObjAll[daliCtlID];
      let newMapDaliObj = state.mapObjAll[mapID]?.daliCtlObj;
      delete newMapDaliObj[daliCtlID];
      state.mapObjAll[mapID].daliCtlObj = newMapDaliObj;
      return { ...state };
    }
    case "DEVICE_SELECTED": {
      const {
        deviceID,
        deviceType,
        deviceObj,
        mapRightMode,
        x,
        y,
        layerScale,
      } = action.payload;
      let obj = {
        selectedID: deviceID,
        selectedDeviceType: deviceType,
        selectedDeviceObj: deviceObj,
        selectedDeviceX: x,
        selectedDeviceY: y,
      };
      if (mapRightMode) obj = { ...obj, mapRightMode };
      if (layerScale) obj = { ...obj, layerScale };
      return {
        ...state,
        ...obj,
      };
    }
    case "DEVICE_MOVED": {
      const { mapID, deviceType, deviceID, x, y } = action.payload;
      let mapDeviceObj =
        { ...state.mapObjAll[mapID][`${deviceType}Obj`] } || {};
      mapDeviceObj = { ...mapDeviceObj, [deviceID]: { x: x, y: y } };
      state.mapObjAll[mapID][`${deviceType}Obj`] = mapDeviceObj;
      return { ...state };
    }
    case "SET_SELECTED_GATEWAY": {
      return { ...state, selectedGatewayID: action.payload };
    }
    case "SET_SELECTED_SENSOR": {
      return { ...state, selectedSensorID: action.payload };
    }
    case "SELECTED_MULTI": {
      const newObj = action.payload;
      const deviceID = newObj.deviceID;
      const idArray = state.selectMultiArray.map((obj) => obj.deviceID);
      let newArray = [...state.selectMultiArray];
      if (!idArray?.includes(deviceID)) {
        newArray = [...newArray, newObj];
      } else {
        newArray = newArray.filter((obj) => obj.deviceID !== deviceID);
      }
      return { ...state, selectMultiArray: newArray };
    }
    case "SELECT_MULTI_CLEAR": {
      return { ...state, selectMultiArray: [] };
    }
    case "OPENED_LIGHT_DETAIL_MODAL": {
      return {
        ...state,
        activeSerial: action.payload,
        openLightDetailModal: true,
      };
    }
    case "CLOSED_LIGHT_DETAIL_MODAL": {
      return { ...state, activeSerial: "", openLightDetailModal: false };
    }
    case "OPENED_SENSOR_DETAIL_MODAL": {
      return {
        ...state,
        activeSensorID: action.payload,
        openSensorDetailModal: true,
      };
    }
    case "CLOSED_SENSOR_DETAIL_MODAL": {
      return { ...state, activeSensorID: "", openSensorDetailModal: false };
    }
    case "OPENED_GATEWAY_DETAIL_MODAL": {
      return {
        ...state,
        activeGatewayID: action.payload,
        openGatewayDetailModal: true,
      };
    }
    case "CLOSED_GATEWAY_DETAIL_MODAL": {
      return { ...state, activeGatewayID: "", openGatewayDetailModal: false };
    }
    case "OPENED_GATEWAY_ADVANCE_HISTORY_MODAL": {
      return {
        ...state,
        activeGatewayID: action.payload,
        openGatewayAdvanceHistoryMordal: true,
      };
    }
    case "CLOSED_GATEWAY_ADVANCE_HISTORY_MODAL": {
      return { ...state, openGatewayAdvanceHistoryMordal: false };
    }
    case "OPENED_ZC_DETAIL_MODAL": {
      return {
        ...state,
        activeZoneControlID: action.payload,
        openZoneControlDetailModal: true,
      };
    }
    case "CLOSED_ZC_DETAIL_MODAL": {
      return {
        ...state,
        activeZoneControlID: "",
        openZoneControlDetailModal: false,
      };
    }
    case "OPENED_DALICTL_DETAIL_MODAL": {
      return {
        ...state,
        activeDaliCtlID: action.payload,
        openDaliCtlDetailModal: true,
      };
    }
    case "CLOSED_DALICTL_DETAIL_MODAL": {
      return { ...state, activeDaliCtlID: "", openDaliCtlDetailModal: false };
    }
    case "IMAGE_UPLOADED": {
      return { ...state, uploadedImgUrl: action.payload };
    }
    case "CLEARED_UPLOADED_IMAGE": {
      return { ...state, uploadedImgUrl: "" };
    }
    case "DALI_REPLIED_GENERAL_QUERY": {
      const { daliCtlID, daliCmd, shortAdd, reply } = action.payload;
      if (state.daliCtlObjAll[daliCtlID]) {
        state.daliCtlObjAll[daliCtlID].daliReply = { daliCmd, shortAdd, reply };
      }
      return { ...state };
    }
    case "CLEARED_DALI_REPLY": {
      const daliCtlID = action.payload;
      state.daliCtlObjAll[daliCtlID].daliReply = {};
      return { ...state };
    }
    case "GOT_LATEST_FIRMWARE_VERSION": {
      const ver = action.payload;
      let serverObj = { ...state.serverObj, latestFirmwareVer: ver };
      return { ...state, serverObj };
    }
    case "REPLY_LATEST_DEVICE_FIRMWARE_VER": {
      const latestVerObj = action.payload;
      return { ...state, latestDeviceFirmwareVerObj: latestVerObj };

    }
    case "GATEWAY_REPLIED_LATEST_FIRMWARE_VER": {
      return { ...state, updateGwFirmwareObj: action.payload };
    }
    case "DALI_UPDATE_SENSOR": {
      const { daliCtlID, sensorID, sensorType } = action.payload;
      if (state.sensorObjAll[sensorID])
        state.sensorObjAll[sensorID].type = sensorType;
      if (state.daliCtlObjAll[daliCtlID])
        state.daliCtlObjAll[daliCtlID].sensorObj[sensorID].type = sensorType;
      return { ...state };
    }
    case "GATEWAY_CONNECT_LIGHT": {
      const { newLightObj, newGatewayObj, oldGatewayObj, sensorUpdateObjArr } =
        action.payload;
      const { serial } = newLightObj;
      if (state.gatewayObjAll) {
        state.gatewayObjAll[newGatewayObj.gatewayID] = newGatewayObj;
        if (oldGatewayObj.gatewayID)
          state.gatewayObjAll[oldGatewayObj.gatewayID] = oldGatewayObj;
      }
      state.lightObjAll[serial] = newLightObj;
      (sensorUpdateObjArr || []).forEach((obj) => {
        const sensorID = obj.sensorID;
        state.sensorObjAll[sensorID] = {
          ...state.sensorObjAll[sensorID],
          ...obj,
        };
      });

      state = addMapDeviceMessage(state, [newGatewayObj.gatewayID, serial], [`Connected light ${serial}`, `Connected gateway ${newGatewayObj.gatewayID}`]);
      return { ...state };
    }
    case "GATEWAY_DISCONNECT_LIGHT": {
      const { gatewayObj, newLightObj, sensorUpdateObjArr } = action.payload;
      const { serial } = newLightObj;
      if (state.gatewayObjAll) {
        state.gatewayObjAll[gatewayObj.gatewayID] = gatewayObj;
      }
      state.lightObjAll[serial] = newLightObj;
      (sensorUpdateObjArr || []).forEach((obj) => {
        const sensorID = obj.sensorID;
        state.sensorObjAll[sensorID] = {
          ...state.sensorObjAll[sensorID],
          ...obj,
        };
      });
      return { ...state };
    }
    case "GATEWAY_CONNECT_SENSOR": {
      const { newSensorObj, newGatewayObj, oldGatewayObj, sensorUpdateObjArr } =
        action.payload;
      const { sensorID } = newSensorObj;
      const newGatewayID = newGatewayObj.gatewayID;
      const oldGatewayID = oldGatewayObj.gatewayID;
      if (state.gatewayObjAll) {
        if (newGatewayID) state.gatewayObjAll[newGatewayID] = newGatewayObj;
        if (oldGatewayID) state.gatewayObjAll[oldGatewayID] = oldGatewayObj;
      }
      if (sensorID) state.sensorObjAll[sensorID] = newSensorObj;
      (sensorUpdateObjArr || []).forEach((obj) => {
        const sensorID = obj.sensorID;
        state.sensorObjAll[sensorID] = {
          ...state.sensorObjAll[sensorID],
          ...obj,
        };
      });
      return { ...state };
    }
    case "GATEWAY_DISCONNECT_SENSOR": {
      const { gatewayObj, newSensorObj, sensorUpdateObjArr } = action.payload;
      const { sensorID } = newSensorObj;
      const gatewayID = gatewayObj.gatewayID;
      if (state.gatewayObjAll) {
        if (gatewayID) state.gatewayObjAll[gatewayID] = gatewayObj;
      }
      if (sensorID) state.sensorObjAll[sensorID] = newSensorObj;
      (sensorUpdateObjArr || []).forEach((obj) => {
        const sensorID = obj.sensorID;
        state.sensorObjAll[sensorID] = {
          ...state.sensorObjAll[sensorID],
          ...obj,
        };
      });
      return { ...state };
    }
    case "GATEWAY_CONNECT_DALICTL": {
      const {
        newGwUpdateObj,
        oldGwUpdateObj,
        daliCtlUpdateObj,
        sensorUpdateObjArr,
      } = action.payload;
      const newGwID = newGwUpdateObj?.gatewayID;
      const oldGwID = oldGwUpdateObj?.gatewayID;
      const daliCtlID = daliCtlUpdateObj?.daliCtlID;
      if (newGwID)
        state.gatewayObjAll[newGwID] = {
          ...state.gatewayObjAll[newGwID],
          ...newGwUpdateObj,
        };
      if (oldGwID)
        state.gatewayObjAll[oldGwID] = {
          ...state.gatewayObjAll[oldGwID],
          ...oldGwUpdateObj,
        };
      if (daliCtlID) {
        let daliCtlObj = state.daliCtlObjAll[daliCtlID] || {};
        daliCtlObj = { ...daliCtlObj, ...daliCtlUpdateObj };
        state.daliCtlObjAll[daliCtlID] = daliCtlObj;
        const serialArray = Object.keys(daliCtlObj.lightObj || {});
        serialArray.forEach((s) => {
          state.lightObjAll[s] = {
            ...state.lightObjAll[s],
            gatewayID: newGwID,
          };
        });
      }
      (sensorUpdateObjArr || []).forEach((obj) => {
        const sensorID = obj.sensorID;
        state.sensorObjAll[sensorID] = {
          ...state.sensorObjAll[sensorID],
          ...obj,
        };
      });
      return { ...state };
    }
    case "GATEWAY_DISCONNECT_DALICTL": {
      const { oldGatewayUpdateObj, daliCtlUpdateObj, sensorUpdateObjArr } =
        action.payload;
      const gatewayID = oldGatewayUpdateObj?.gatewayID;
      const daliCtlID = daliCtlUpdateObj?.daliCtlID;
      let daliCtlObj = state.daliCtlObjAll[daliCtlID] || {};
      daliCtlObj = { ...daliCtlObj, ...daliCtlUpdateObj };
      state.daliCtlObjAll[daliCtlID] = daliCtlObj;
      const serialArray = Object.keys(daliCtlObj.lightObj || {});
      serialArray.forEach((s) => {
        state.lightObjAll[s] = {
          ...state.lightObjAll[s],
          gatewayID: "",
        };
      });
      if (gatewayID)
        state.gatewayObjAll[gatewayID] = {
          ...state.gatewayObjAll[gatewayID],
          ...oldGatewayUpdateObj,
        };
      (sensorUpdateObjArr || []).forEach((obj) => {
        const sensorID = obj.sensorID;
        state.sensorObjAll[sensorID] = {
          ...state.sensorObjAll[sensorID],
          ...obj,
        };
      });
      return { ...state };
    }
    case "GATEWAY_REPORT_SURVIVAL": {
      const {
        gwUpdateObj,
        serialUpdateObjArr,
        sensorUpdateObjArr,
        daliCtlUpdateObjArr,
      } = action.payload;
      const gatewayID = gwUpdateObj?.gatewayID;
      let gatewayObj = state.gatewayObjAll[gatewayID]
      if (gatewayObj) {
        gatewayObj = { ...gatewayObj, ...gwUpdateObj };
        state.gatewayObjAll[gatewayID] = gatewayObj;
      }
      serialUpdateObjArr.forEach((obj) => {
        const serial = obj.serial;
        let lightObj = state.lightObjAll[serial];
        if (lightObj) {
          lightObj = { ...lightObj, ...obj };
          state.lightObjAll[serial] = lightObj;
        }
      });
      sensorUpdateObjArr.forEach((obj) => {
        const sensorID = obj.sensorID;
        let sensorObj = state.sensorObjAll[sensorID];
        if (sensorObj) {
          sensorObj = { ...sensorObj, ...obj };
          state.sensorObjAll[sensorID] = sensorObj;
        }
      });
      daliCtlUpdateObjArr.forEach((obj) => {
        const daliCtlID = obj.daliCtlID;
        let daliCtlObj = state.daliCtlObjAll[daliCtlID];
        if (daliCtlObj) {
          daliCtlObj = { ...daliCtlObj, ...obj };
          state.daliCtlObjAll[daliCtlID] = daliCtlObj;
        }
      });
      return { ...state };
    }
    case "DALI_ADD_LIGHT": {
      const { daliCtlID, serial, shortAdd } = action.payload;
      state.daliCtlObjAll[daliCtlID].lightObj[serial] = {
        connect: false,
        serial,
        shortAdd,
      };
      return { ...state };
    }
    case "DALI_DELETE_LIGHT": {
      const { daliCtlID, serial, shortAdd } = action.payload;
      let lightObj = state.daliCtlObjAll[daliCtlID].lightObj;
      delete lightObj[serial];
      state.daliCtlObjAll[daliCtlID].lightObj = lightObj;
      return { ...state };
    }
    case "DALI_ADD_SENSOR": {
      const { daliCtlID, sensorID, shortAdd, instance, sensorType } =
        action.payload;
      state.daliCtlObjAll[daliCtlID].sensorObj[sensorID] = {
        connect: false,
        sensorID,
        shortAdd,
        instance,
        type: sensorType,
      };
      return { ...state };
    }
    case "DALI_DELETE_SENSOR": {
      const { daliCtlID, sensorID, shortAdd, instance } = action.payload;
      let sensorObj = state.daliCtlObjAll[daliCtlID].sensorObj;
      delete sensorObj[sensorID];
      state.daliCtlObjAll[daliCtlID].sensorObj = sensorObj;
      return { ...state };
    }
    case "DALI_DELETE_ALL_LIGHTS": {
      const { daliCtlID } = action.payload;
      state.daliCtlObjAll[daliCtlID].lightObj = {};
      return { ...state };
    }
    case "DALICTL_CONVERT_TO_LIGHT": {
      const { lightObj, daliCtlID } = action.payload;
      const serial = lightObj.serial;
      state.lightObjAll[serial] = lightObj;
      delete state.daliCtlObjAll[daliCtlID];
      state.activeDaliCtlID = "";
      state.openDaliCtlDetailModal = false;
      return { ...state };
    }
    case "LIGHT_CONVERT_TO_DALICTL": {
      const { serial, daliCtlObj } = action.payload;
      const daliCtlID = daliCtlObj.daliCtlID;
      state.daliCtlObjAll[daliCtlID] = daliCtlObj;
      delete state.lightObjAll[serial];
      state.activeSerial = "";
      state.openLightDetailModal = false;
      return { ...state };
    }
    case "UPDATE_MANY_TIMETABLES": {
      const { updateObjArray } = action.payload;
      updateObjArray.forEach((obj) => {
        let ttObj = state.timeTableObjAll[obj.timeTableID] || {};
        ttObj = { ...ttObj, ...obj };
        state.timeTableObjAll[obj.timeTableID] = ttObj;
      });
      return { ...state };
    }
    case "DELETE_ZC_TIME_SETTING": {
      const { zoneControlID, zcUpdateObj, timeTableID1, timeTableID2 } =
        action.payload;
      state.zoneControlObjAll[zoneControlID] = {
        ...state.zoneControlObjAll[zoneControlID],
        ...zcUpdateObj,
      };
      delete state.timeTableObjAll[timeTableID1];
      delete state.timeTableObjAll[timeTableID2];
      return { ...state };
    }
    case "FETCHED_MANY_TIMETABLE_OBJ": {
      const objAll = action.payload;
      Object.keys(objAll || {}).forEach((tid) => {
        state.timeTableObjAll[tid] = objAll[tid];
      });
      return { ...state };
    }
    case "ZC_CONNECT_LIGHT": {
      const { zoneControlID, newZoneControlObj, serial } = action.payload;
      state.zoneControlObjAll[zoneControlID] = newZoneControlObj;
      let zcArr = state.lightObjAll[serial]?.zoneControlArray || [];
      if (!zcArr.includes(zoneControlID)) zcArr = [...zcArr, zoneControlID];
      if (state.lightObjAll[serial])
        state.lightObjAll[serial].zoneControlArray = zcArr;
      state = addMapDeviceMessage(state, [zoneControlID, serial], [`Connected light ${serial}`, `Connected zone control ${zoneControlID}`]);
      return { ...state };
    }
    case "ZC_DISCONNECT_LIGHT": {
      const { zoneControlID, newZoneControlObj, serial } = action.payload;
      state.zoneControlObjAll[zoneControlID] = newZoneControlObj;
      let zcArr = state.lightObjAll[serial]?.zoneControlArray || [];
      zcArr = zcArr.filter((z) => z !== zoneControlID);
      if (state.lightObjAll) {
        if (state.lightObjAll[serial]) {
          state.lightObjAll[serial].zoneControlArray = zcArr;
        }
      }
      state = addMapDeviceMessage(state, [zoneControlID, serial], [`Disconnected light ${serial}`, `Disconnected zone control ${zoneControlID}`]);
      return { ...state };
    }
    case "ZC_DISCONNECT_ALL_LIGHTS": {
      const { zoneControlID, newZoneControlObj, serialArray } = action.payload;
      state.zoneControlObjAll[zoneControlID] = newZoneControlObj;
      serialArray.forEach((s) => {
        let arr = state.lightObjAll[s]?.zoneControlArray || [];
        arr = arr.filter((z) => z !== zoneControlID);
        if (state.lightObjAll[s]) state.lightObjAll[s].zoneControlArray = arr;
      });
      return { ...state };
    }
    case "ZC_CONNECT_SENSOR": {
      const { zoneControlID, newZoneControlObj, sensorID } = action.payload;
      state.zoneControlObjAll[zoneControlID] = newZoneControlObj;
      let zcArr = state.sensorObjAll[sensorID]?.zoneControlArray || [];
      if (!zcArr.includes(zoneControlID)) zcArr = [...zcArr, zoneControlID];
      if (state.sensorObjAll[sensorID])
        state.sensorObjAll[sensorID].zoneControlArray = zcArr;
      return { ...state };
    }
    case "SENSOR_DISCONNECT_LIGHT": {
      const { sensorID, newSensorObj, serial } = action.payload;
      state.sensorObjAll[sensorID] = newSensorObj;
      let arr = state.lightObjAll[serial]?.sensorArray || [];
      arr = arr.filter((sen) => sen !== sensorID);
      if (state.lightObjAll[serial])
        state.lightObjAll[serial].sensorArray = arr;
      return { ...state };
    }
    case "SENSOR_DISCONNECT_ALL_LIGHTS": {
      const { sensorID, newSensorObj, serialArray } = action.payload;
      state.sensorObjAll[sensorID] = newSensorObj;
      (serialArray || []).forEach((s) => {
        let arr = state.lightObjAll[s]?.sensorArray || [];
        arr = arr.filter((sen) => sen !== sensorID);
        if (state.lightObjAll[s]) state.lightObjAll[s].sensorArray = arr;
      });
      return { ...state };
    }
    case "SENSOR_DISCONNECT_SENSOR": {
      const { mainSensorID, connectSensorID, newSensorObj } = action.payload;
      state.sensorObjAll[mainSensorID] = newSensorObj;
      let connectSensorObj = state.sensorObjAll[connectSensorID];
      let arr = connectSensorObj?.sensorArray || [];
      arr = arr.filter((sen) => sen !== mainSensorID);
      if (connectSensorObj) {
        connectSensorObj.sensorArray = arr;
      }
      state.sensorObjAll[connectSensorID] = connectSensorObj;
      return { ...state };
    }
    case "SET_COPIED_DEVICE_OBJ": {
      return { ...state, copiedDeviceObj: action.payload };
    }
    // case "DEVICE_CHANGED_ZBADD": {
    //   const { deviceType, deviceID, gatewayID, zigbeeAdd, newzbAdd } =
    //     action.payload;
    //   if (deviceType === "serial") {
    //     state.lightObjAll[deviceID].zigbeeAdd = newzbAdd;
    //     if (gatewayID) {
    //       let serialMap = state.gatewayObjAll[gatewayID].serialMap;
    //       serialMap[newzbAdd] = deviceID;
    //       delete serialMap[zigbeeAdd];
    //       state.gatewayObjAll[gatewayID].serialMap = serialMap;
    //     }
    //   } else if (deviceType === "sensor") {
    //     state.sensorObjAll[deviceID].zigbeeAdd = newzbAdd;
    //     if (gatewayID) {
    //       let sensorMap = state.gatewayObjAll[gatewayID].sensorMap;
    //       sensorMap[newzbAdd] = deviceID;
    //       delete sensorMap[zigbeeAdd];
    //       state.gatewayObjAll[gatewayID].sensorMap = sensorMap;
    //     }
    //   } else if (deviceType === "daliCtl") {
    //     state.daliCtlObjAll[deviceID].zigbeeAdd = newzbAdd;
    //     if (gatewayID) {
    //       let daliCtlMap = state.gatewayObjAll[gatewayID].daliCtlMap;
    //       daliCtlMap[newzbAdd] = deviceID;
    //       delete daliCtlMap[zigbeeAdd];
    //       state.gatewayObjAll[gatewayID].daliCtlMap = daliCtlMap;
    //     }
    //   }
    //   return { ...state };
    // }
    case "SHIFT_SELECTED_DEVICE": {
      const { deviceID, deviceType, deviceObj } = action.payload;
      let shiftSelectedArray = [...state.shiftSelectedArray];
      let obj = { deviceID, deviceType, deviceObj };
      if (!shiftSelectedArray.find((o) => o.deviceID === deviceID)) {
        shiftSelectedArray = [...shiftSelectedArray, obj];
      }
      return { ...state, shiftSelectedArray };
    }
    case "CLEARED_SHIFT_SELECT": {
      return { ...state, shiftSelectedArray: [] };
    }
    case "SHIFT_UNSELECTED_DEVICE": {
      const { deviceID } = action.payload;
      let shiftSelectedArray = [...state.shiftSelectedArray];
      shiftSelectedArray = shiftSelectedArray.filter(
        (obj) => obj.deviceID !== deviceID
      );
      return { ...state, shiftSelectedArray };
    }
    case "DELETE_GAS_SETTINGS": {
      const { groupID, groupDeviceType } = action.payload;
      if (groupDeviceType === "sensor") {
        let newSensorObj = state.sensorObjAll[groupID];
        for (let i = 1; i <= 12; i++) {
          delete newSensorObj[`GAS_setting${i}`];
        }
        state.sensorObjAll[groupID] = newSensorObj;
      }
      if (groupDeviceType === "zoneControl") {
        const zoneControlID = "zc_" + groupID;
        let newZcObj = state.zoneControlObjAll[zoneControlID];
        for (let i = 1; i <= 12; i++) {
          delete newZcObj[`GAS_setting${i}`];
        }
        state.zoneControlObjAll[zoneControlID] = newZcObj;
      }
      return { ...state };
    }
    case "SET_GENERAL_STATE": {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    case "SET_GENERAL_MANY_STATE": {
      const obj = action.payload;
      return { ...state, ...obj };
    }
    case "ALL_UNSELECTED": {
      return {
        ...state,
        activeSerial: "",
        activeGatewayID: "",
        activeReportID: "",
        activeSensorID: "",
        activeZoneControlID: "",
        selectedID: "",
        selectedDeviceType: "",
        selectedDeviceX: 0,
        selectedDeviceY: 0,
        selectedDeviceObj: {},
        selectedGatewayID: "",
        selectedSensorID: "",
        mapLayer: {
          normal: true,
          gatewaySelect: false,
          sensorSelect: false,
          lockLevel: false,
          light: false,
          sensor: false,
          gateway: false,
          luxProfile: false,
          serialNo: false,
          deviceName: false,
          zigbeeAdd: false,
          rssi255Web: false,
          espWeb: false,
        },
        shiftSelectedArray: [],
        espWebMapMode: "route",
        espWebGatewayID: "",
        espWebDeviceID: "",
        espCheckUnicastSender: {},
        espCheckUnicastReceiver: {},
        espWebPathTargetDevice: {},
        espWebPathArray: [],

      };
    }
    case "RESET_REDUCER":
      const email = state.userObj.email;
      return { ...initialState, storedEmail: email };
    default:
      return state;
  }
};

export const initialState = {
  //screen
  snackbarMsg: {
    title: "",
    severity: "info",
    status: false,
  },

  showBigProgress: false,
  windowConfirm: false,
  windowConfirmTitle: "",
  onConfirm: () => { },
  onCancel: () => { },
  callback: () => { },
  windowAlert: false,
  windowAlertTitle: "",
  windowPrompt: false,
  windowPromptDefaultValue: "",
  windowPromptTitle: "",
  mapDeviceMessage: {},
  mapDeviceMessageArray: [],

  //user
  storedEmail: "",
  userObj: {},
  serverObj: {},
  serverID: "",
  enableFS: false,
  fs: null,

  //obj and ID
  mapObjAll: {},
  lightObjAll: {},
  gatewayObjAll: {},
  sensorObjAll: {},
  daliCtlObjAll: {},
  zoneControlObjAll: {},
  timeTableObjAll: {},
  timeTableArrAll: [],
  windowPromptTitle: "",
  activeMapID: "",
  activeSerial: "",
  activeSensorID: "",
  activeGatewayID: "",
  activeZoneControlID: "",
  activeDaliCtlID: "",
  activeReportID: "",
  activeReportObj: {},

  latestDeviceFirmwareVerObj: {},

  //Map use
  uploadedImgUrl: "",
  mapScale: { x: 1, y: 1 },
  mapSize: { x: 1000, y: 600 },
  layerScale: 1,
  layerPos: { x: 0, y: 0 },
  mapMode: "normal",
  mapRightMode: "normal",
  mapLayer: {
    normal: true,
    gatewaySelect: false,
    sensorSelect: false,
    lockLevel: false,
    light: false,
    sensor: false,
    gateway: false,
    daliCtl: false,
    zoneControl: false,
    luxProfile: false,
    serialNo: false,
    deviceName: false,
    zigbeeAdd: false,
    rssi255Web: false,
  },
  enablePan: true,
  enableZoom: "",
  selectedID: "",
  selectedDeviceType: "",
  selectedDeviceObj: {},
  selectedDeviceX: 0,
  selectedDeviceY: 0,
  selectMultiArray: [],
  // rssiWeb: [],
  updateGwFirmwareObj: {},
  copiedDeviceObj: {},
  shiftSelectedArray: [],
  espWebMapMode: "route", //"web":route table line, "check":unicast check, "setPath":set manual path, 
  espWebGatewayID: "",
  espWebDeviceID: "",
  espCheckUnicastSender: {},
  espCheckUnicastReceiver: {},
  espWebPathTargetDevice: {},
  espWebPathArray: [],


  openDaliCtlDetailModal: false,
  openLightDetailModal: false,
  openGatewayDetailModal: false,
  openSensorDetailModal: false,
  openGatewayAdvanceHistoryMordal: false,
  openZoneControlDetailModal: false,
};

const addMapDeviceMessage = (state, deviceIDArr, messageArr) => {
  deviceIDArr.forEach((deviceID, key) => {
    const obj = { deviceID, message: messageArr[key], timeStamp: moment().valueOf() };
    state.mapDeviceMessage[deviceID] = messageArr[key];
    state.mapDeviceMessageArray.unshift(obj);
    if (state.mapDeviceMessageArray.length > 100) state.mapDeviceMessageArray.pop();
  });
  return state;
}