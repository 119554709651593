import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography } from "@mui/material";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import { ArrowForwardIos } from "@mui/icons-material";
import { alertWindow, confirmWindow, hideBigProgress, showBigProgress } from "actions/screenActions";
import AdminContainer from "components/AdminContainer";
import DivExist from "components/DivExist";
import General from "@ecoenghk/general";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import SpaceBetweenSwitch from "components/SpaceBetweenSwitch";
import {
  updateDaliCtlProperty,
  daliReset,
  changeDaliCtlOneSetting,
  queryDaliCtlOneSetting,
  daliCtlConvertToLight,
} from "actions/daliCtlActions";
import { sendStartSettingToDevice } from "actions/gatewayActions";
import SpaceBetweenSelect from "components/SpaceBetweenSelect";
import ServerConsoleLogFilter from "containers/server/ServerConsoleLogFilter";
import DeviceInterfaceTtyPanel from "containers/gateway/DeviceInterfaceTtyPanel";
const gs = new General();
export default function DaliCtlSuperAdminPanel({ handleClose }) {
  const [state, dispatch] = useContext(SMContext);
  const { daliCtlObjAll, activeDaliCtlID } = state;
  const daliCtlObj = daliCtlObjAll[activeDaliCtlID];
  const {
    daliMasterModel,
    zigbeeAdd,
    gatewayID,
    serverID,
    dtkAdd,
  } = daliCtlObj || {};
  const handleConvertDaliCtlToLight = async () => {
    if (daliCtlObj?.addList?.length > 0) {
      alertWindow(dispatch, "Please remove all lights from dali controller before convert to light controller");
    } else if (Object.keys(daliCtlObj?.lightObj || {})?.length > 0) {
      alertWindow(dispatch, "Please remove all lights from dali controller before convert to light controller");
    } else if (Object.keys(daliCtlObj?.sensorObj || {})?.length > 0) {
      alertWindow(dispatch, "Please remove all sensors from dali controller before convert to light controller");
    }
    confirmWindow(
      dispatch,
      "Confirm convert dali controller to light controller?",
      async () => {
        await showBigProgress(dispatch);
        await daliCtlConvertToLight(daliCtlObj);
        await gs.waitFor(3500);
        await queryDaliCtlOneSetting(daliCtlObj, 0x0b15);
        await hideBigProgress(dispatch);
        handleClose();
        dispatch({
          type: "ALL_UNSELECTED",
        });
      }
    );
  }


  return (
    <AdminContainer>
      <Typography variant="h6" align="center">
        Super Admin Setting
      </Typography>
      <Divider10 />
      <DivInline justifyContent="space-between" hide={!daliCtlObj?.serverID}>
        <Typography>Server ID</Typography>
        <Typography>{serverID}</Typography>
      </DivInline>
      <DivExist show={daliMasterModel?.includes("Skymon")}>
        <SpaceBetweenDialogInput
          title="Regular upload time interval"
          data={daliCtlObj?.regular_time_interval}
          dialogTitle="Regular upload time interval"
          showValueUnit="min"
          handleSave={async (val) => {
            const valueObj = { regular_time_interval: Number(val) };
            if (zigbeeAdd) updateDaliCtlProperty(activeDaliCtlID, valueObj, true);
            if (dtkAdd) await changeDaliCtlOneSetting(daliCtlObj, 0x0a25, valueObj);
          }}
          handleRefresh={() => queryDaliCtlOneSetting(daliCtlObj, 0x0b13)}
        />
        <SpaceBetweenDialogInput
          title="Survival upload time interval"
          data={daliCtlObj?.survival_time_interval}
          dialogTitle="Survival upload time interval"
          showValueUnit="sec"
          handleSave={async (val) => {
            const valueObj = { survival_time_interval: Number(val) };
            if (zigbeeAdd) updateDaliCtlProperty(activeDaliCtlID, valueObj, true);
            if (dtkAdd) await changeDaliCtlOneSetting(daliCtlObj, 0x0a26, valueObj);
          }}
          handleRefresh={() => queryDaliCtlOneSetting(daliCtlObj, 0x0b14)}
        />
        <SpaceBetweenButton
          title="Send start setting"
          onBtnClick={async () => {
            await sendStartSettingToDevice("daliCtl", activeDaliCtlID, gatewayID)
            // const gatewayAdd = gatewayObjAll[gatewayID]?.zigbeeAdd;
            // daliSendStartSetting(daliCtlObj, gatewayAdd);
          }}
          btnContent={<ArrowForwardIos />}
        />
        <SpaceBetweenButton
          title="Reset all lights dali setting"
          btnContent={<ArrowForwardIos />}
          onBtnClick={async () => {
            confirmWindow(
              dispatch,
              "Confirm reset all lights dali setting?",
              () => {
                daliReset(daliCtlObj, 100);
              }
            );
          }}
          color="secondary"
        />
        <SpaceBetweenSelect
          title="All lights to 100% on controller disconnect"
          data={daliCtlObj?.enableTurnOnOnDisconnect?1:0}
          onChange={async (e) => {
            const val = Number(e.target.value);
            await changeDaliCtlOneSetting(daliCtlObj, 0xa28, { enableTurnOnOnDisconnect: val })
          }}
          menuObj={{ 0: "Disabled", 1: "Enabled" }}
          handleRefresh={() => queryDaliCtlOneSetting(daliCtlObj, 0x0b17)}
        />

        <SpaceBetweenSelect
          title="Switching sequence"
          data={daliCtlObj?.switchSequence || 0}
          onChange={async (e) => {
            const val = Number(e.target.value);
            await changeDaliCtlOneSetting(daliCtlObj, 0xa30, { switchSequence: val })
          }}
          menuObj={{ 0: "0:b^->d^->a^->bv->dv", 1: "a^->b^->d^->bv", 2: "a^" }}
          handleRefresh={() => queryDaliCtlOneSetting(daliCtlObj, 0x0b1b)}
        />
        <SpaceBetweenSwitch
          title="Enable acknowledge and resend"
          data={daliCtlObj?.enableAcknowledge || false}
          onChange={async (e) => changeDaliCtlOneSetting(daliCtlObj, 0x0a3c, { enableAcknowledge: e.target.checked ? 1 : 0 })}
          handleRefresh={() => queryDaliCtlOneSetting(daliCtlObj, 0x0b20)}
        />
        {/* <SpaceBetweenDiv
          title="Saved group action"
          handleRefresh={() => queryDaliCtlOneSetting(daliCtlObj, 0xb21)}
          data={(daliCtlObj?.savedGroupAction || []).map(str => <div>{str}</div>)}
        /> */}
        <Divider10 />
        <Typography>Communication</Typography>
        <Divider10 />
        <SpaceBetweenDiv
          title="Wifi AP mac address"
          data={daliCtlObj?.wifiApMacAddress}
          handleRefresh={() => queryDaliCtlOneSetting(daliCtlObj, 0x0b16)}
        />
        <DeviceInterfaceTtyPanel deviceObj={daliCtlObj} />
        <Divider10 />
        <SpaceBetweenDialogInput
          title="PCB version"
          data={daliCtlObj?.pcbVersion}
          dialogTitle="Change PCB version"
          handleSave={async (val) => {
            updateDaliCtlProperty(activeDaliCtlID, {
              pcbVersion: val,
            });
          }}
        />
        <Divider10 />
        <ServerConsoleLogFilter filterString={activeDaliCtlID} />
        <Divider10 />
        <SpaceBetweenButton
          title="Convert dali controller to light controller"
          onBtnClick={() => handleConvertDaliCtlToLight()}
          btnContent={<ArrowForwardIos />}
          color="secondary"
          handleRefresh={() => queryDaliCtlOneSetting(daliCtlObj, 0x0b15)}
        />
        <Divider10 />
      </DivExist>

    </AdminContainer>
  );
}
