import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import DivExist from "components/DivExist";
import Divider10 from "components/Divider10";
import {
  Typography,
  Box,
  Slider,
  Tooltip,
  IconButton,
} from "@mui/material";
import DivInline from "components/DivInline";
import {
  CopyAll,
  ArrowDownward,
  Repeat,
} from "@mui/icons-material";
import LightBulb from "asset/svgComp/LightBulb";
import {
  copyLightSettingInOneSensor,
  sensorConnectLight,
  sensorDisconnectLight,
  updateSensorProperty,
  sensorRemoveDeviceSetting,
  getSensorDeviceNearFieldObj,
  sensorDeleteNearFieldSetting,
} from "actions/sensorActions";
import NearFieldIcon from "asset/svgComp/NearFieldIcon";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import {
  alertWindow,
  confirmWindow,
  hideBigProgress,
  showBigProgress,
} from "actions/screenActions";
import General from "@ecoenghk/general";
import IconButtonDone from "components/IconButtonDone";
import SensorIcon from "components/SensorIcon";
import { secToMin } from "actions/generalActions";
import IconButtonAdd from "components/IconButtonAdd";
import ZcGeneral from "asset/svgComp/ZcGeneral";
import SenLightSettingCheckModal from "./SenLightSettingCheckModal";
import SenNearFieldModal from "./SenNearFieldModal";
import { deviceGatewayIsDisconnected } from "actions/gatewayActions";
import SensorMapModalHeader from "./SensorMapModalHeader";
const gs = new General();
const sxArrow = {
  outline: "1px solid #fff",
  margin: "1vh",
  padding: "0 0.5vw 0 0.5vw ",
};
export default function SenSceneButtonLightModal({
  x,
  y,
  modalLocation,
  open,
  handleClose,
  layerProps,
  selectedLight,
  sensorID,
  sensorObj,
  scale,
}) {
  const [settingObj, setSettingObj] = useState({});
  const [activeNearFieldButton, setActiveNearFieldButton] = useState(0);
  const { delaySec2, gang } = sensorObj || {};
  useEffect(() => {
    let obj = { setting1: sensorObj.setting1 };
    if (gang > 1) {
      obj = { ...obj, setting2: sensorObj.setting2 };
      if (gang > 2) {
        obj = { ...obj, setting3: sensorObj.setting3 };
        if (gang > 3) {
          obj = { ...obj, setting4: sensorObj.setting4 };
        }
      }
    }
    setSettingObj(obj);
  }, [selectedLight, open, sensorObj]);
  const { iconSize, lightObjAll, dispatch, daliCtlObjAll } = layerProps;
  const lightObj = lightObjAll[selectedLight];

  const updateSetting = async (settingNum, updateObj) => {
    let serialSetting = settingObj[`setting${settingNum}`]?.[selectedLight];
    serialSetting = { ...serialSetting, ...updateObj };
    const newSettingObj = { ...settingObj };
    newSettingObj[`setting${settingNum}`][selectedLight] = serialSetting;
    setSettingObj(newSettingObj);
  };
  const handleSave = async () => {
    console.log(settingObj);
    const did = sensorObj.daliCtlID;
    const gid = did ? daliCtlObjAll[did]?.gatewayID : sensorObj.gatewayID;
    let updateObj = {};
    Object.keys(settingObj || {}).forEach((settingN) => {
      if (settingObj[settingN])
        updateObj = { ...updateObj, [settingN]: settingObj[settingN] };
    });
    await updateSensorProperty(sensorID, sensorObj, gid, updateObj);
    handleClose();
  };
  const handleDisconnect = async () => {
    confirmWindow(
      dispatch,
      `Confirm disconnect light [${selectedLight}]?`,
      async () => {
        showBigProgress(dispatch);
        const did = sensorObj.daliCtlID;
        const gid = did ? daliCtlObjAll[did]?.gatewayID : sensorObj.gatewayID;
        await sensorDisconnectLight(sensorObj, gid, lightObj);
        hideBigProgress(dispatch);
        handleClose();
      }
    );
  };

  const handleCopyLightSetting = async () => {
    confirmWindow(
      dispatch,
      "Confirm copy setting to other lights?",
      async () => {
        await copyLightSettingInOneSensor(sensorObj, selectedLight);
      }
    );
  };
  const modalWidth = window.innerWidth * 0.32;
  const modalHeight =
    window.innerHeight * (delaySec2 > 0 ? 0.45 : 0.35);
  const modalX = modalLocation.includes("right")
    ? x - modalWidth / scale - 2 * iconSize
    : x;
  const modalY = modalLocation.includes("bottom")
    ? y - modalHeight / scale - iconSize
    : y;
  return (
    <>
      <MapModal
        x={modalX}
        y={modalY}
        open={open}
        handleCloseModal={handleClose}
        disableCloseButton
        width={modalWidth}
        height={modalHeight}
        layerProps={layerProps}
      >
        <SensorMapModalHeader sensorObj={sensorObj} deviceObj={lightObj} handleClose={handleClose} layerProps={layerProps} />
        <Divider10 />
        <ButtonGroup
          settingNum={1}
          updateSetting={updateSetting}
          settingObj={settingObj}
          sensorObj={sensorObj}
          lightObj={lightObj}
          layerProps={layerProps}
          onNfClick={() => setActiveNearFieldButton(1)}
        />
        <DivExist show={gang > 1}>
          <ButtonGroup
            settingNum={2}
            updateSetting={updateSetting}
            settingObj={settingObj}
            sensorObj={sensorObj}
            lightObj={lightObj}
            layerProps={layerProps}
            onNfClick={() => setActiveNearFieldButton(2)}
          />
          <DivExist show={gang > 2}>
            <ButtonGroup
              settingNum={3}
              updateSetting={updateSetting}
              settingObj={settingObj}
              sensorObj={sensorObj}
              lightObj={lightObj}
              layerProps={layerProps}
              onNfClick={() => setActiveNearFieldButton(3)}
            />
            <DivExist show={gang > 3}>
              <ButtonGroup
                settingNum={4}
                updateSetting={updateSetting}
                settingObj={settingObj}
                sensorObj={sensorObj}
                lightObj={lightObj}
                layerProps={layerProps}
                onNfClick={() => setActiveNearFieldButton(4)}
              />
            </DivExist>
          </DivExist>
        </DivExist>
        <IconButtonClose
          tooltip="Close dialog without saving"
          onBtnClick={handleClose}
        />
        <IconButtonDone
          tooltip="Save settings and close dialog"
          onBtnClick={handleSave}
        />
        <IconButtonDelete
          tooltip="Disconnect light from sensor"
          onBtnClick={handleDisconnect}
        />
        <IconButton onClick={handleCopyLightSetting} size="small">
          <Tooltip title="Copy setting to other lights">
            <CopyAll />
          </Tooltip>
        </IconButton>
      </MapModal>
      <SenNearFieldModal
        x={modalX}
        y={modalY}
        width={modalWidth}
        height={modalHeight}
        open={activeNearFieldButton > 0 ? true : false}
        handleClose={() => setActiveNearFieldButton(0)}
        layerProps={layerProps}
        sensorObj={sensorObj}
        deviceObj={lightObj}
        buttonNo={activeNearFieldButton}
      />
    </>
  );
}

function ButtonGroup({
  settingNum,
  updateSetting,
  settingObj,
  sensorObj,
  lightObj,
  layerProps,
  onNfClick,
}) {
  const { dispatch, userObj } = layerProps;
  const serial = lightObj?.serial;
  const { sensorID, gang } = sensorObj || {};
  const serialSetting = settingObj?.[`setting${settingNum}`]?.[serial];
  const { delaySec, delaySec2 } = serialSetting || {};
  const handleSensorRemoveDevice = async () => {
    if (deviceGatewayIsDisconnected(layerProps, sensorObj)) {
      await gs.waitFor(500);
      alertWindow(dispatch, "Gateway is disconnected, no action made");
      return;
    }
    const nearFieldObj = getSensorDeviceNearFieldObj(sensorObj, lightObj, settingNum);
    if (nearFieldObj) {
      console.log("Near field found, delete near field setting first");
      await sensorDeleteNearFieldSetting(sensorObj, nearFieldObj.macArray, lightObj, settingNum);
    }
    let connectToButtonQty = 0;
    Object.keys(settingObj).forEach((settingN) => {
      if (settingObj[settingN]?.[serial]) connectToButtonQty++;
    });
    if (connectToButtonQty === 1) {
      console.log(`last button ${settingNum}, disconnect from sensor`);
      sensorDisconnectLight(sensorObj, sensorObj.gatewayID, lightObj);
    } else {
      console.log(`now connecting to ${connectToButtonQty} buttons, remove from button${settingNum}`);
      await sensorRemoveDeviceSetting(sensorObj, lightObj, [settingNum]);
    }
  }
  let enableToggle = false;
  if (serialSetting?.daliCtlID) {
    if (serialSetting?.commandCode === 0x0a36) enableToggle = true;
  } else {
    if (serialSetting?.commandCode === 0x205) enableToggle = true;
  }
  // const updateToggle = (newStatus) => {
  //   let newCommandCode;
  //   if (serialSetting?.daliCtlID) {
  //     if (newStatus) newCommandCode = newStatus ? 0x0a36 : 0x0a09;
  //   } else {
  //     if (newStatus) newCommandCode = 0x205;
  //     else if (delaySec2 > 0) newCommandCode = 0x204;
  //     else if (delaySec > 0) newCommandCode = 0x203;
  //     else newCommandCode = 0x202;
  //   }
  //   updateSetting(settingNum, { commandCode: newCommandCode });
  // }
  return (
    <div>
      <DivInline >
        <SensorIcon
          gang={gang}
          type="scenebutton"
          disabledConnectStatus
          status={settingNum === 1 ? 1 : 0}
          status2={settingNum === 2 ? 1 : 0}
          status3={settingNum === 3 ? 1 : 0}
          status4={settingNum === 4 ? 1 : 0}
          width={3}
          sizeUnit="vw"
        />
        <SenLightSettingCheckModal sensorObj={sensorObj} lightObj={lightObj} settingNumArray={[settingNum]} layerProps={layerProps}>
          <Typography display="block" align="center">
            Button {settingNum}
          </Typography>
        </SenLightSettingCheckModal>
        {serialSetting ? (
          <>
            <IconButtonDelete
              tooltip={`Remove from button ${settingNum}`}
              onBtnClick={async () => {
                confirmWindow(
                  dispatch,
                  `Remove ${serial} from button no ${settingNum}?`,
                  async () => handleSensorRemoveDevice()
                );
              }}
            />
            {/* <Typography sx={{ marginLeft: "2vw" }} variant="caption">
              Toggle on/off on push
            </Typography>
            <Checkbox
              checked={enableToggle}
              onChange={(e) => updateToggle(e.target.checked)}
            /> */}
            {userObj.level === 0 && (

              <Tooltip title="Save near field action to sensor">
                <IconButton onClick={onNfClick}>
                  <NearFieldIcon width={2} sizeUnit="vw" color={sensorObj?.nearFieldSetting?.[`${serial}_${settingNum}_${lightObj.shortAdd || 0}`] ? "red" : "#fff"} />
                </IconButton>
              </Tooltip>

            )}

          </>
        ) : (
          <IconButtonAdd
            tooltip={`Connect to button ${settingNum}`}
            onBtnClick={async () => {
              confirmWindow(
                dispatch,
                `Connect ${serial} to button no ${settingNum}?`,
                async () => {
                  await sensorConnectLight(sensorObj, lightObj, settingNum);
                }
              );
            }}
          />
        )}

      </DivInline>
      <Divider10 />
      <DivExist show={serialSetting ? true : false}>
        {serialSetting?.commandCode === 0x0205 || serialSetting?.commandCode === 0x0a36 ? (
          <DivInline>
            <ZcGeneral width={4} sizeUnit="vw" />
            <LightBulb onoff="on" width={4} sizeUnit="vw" />
            <Repeat sx={{ fontSize: "2rem", margin: "0 2vw" }} />
            <LightBulb onoff="off" width={4} sizeUnit="vw" />
          </DivInline>
        ) : (
          <>
            <LightSettingRow
              settingNum={settingNum}
              updateSetting={updateSetting}
              serialSetting={serialSetting}
              onoffField="onoffcontrolA"
              pwmField="pwm"
              enablePwm={lightObj?.type?.pwm}
            />
            <DivExist show={delaySec > 0}>
              <DivInline justifyContent="center" alignItems="center">
                <Box sx={sxArrow}>
                  <ArrowDownward sx={{ fontSize: "0.8rem" }} />
                  <Typography variant="caption">
                    {secToMin(serialSetting?.delaySec || serialSetting?.delayed_action)}
                  </Typography>
                </Box>
              </DivInline>
              <LightSettingRow
                settingNum={settingNum}
                updateSetting={updateSetting}
                serialSetting={serialSetting}
                onoffField="delayedOnOff"
                pwmField="delayedPwm"
                enablePwm={lightObj?.type?.pwm}
              />
            </DivExist>
            <DivExist show={delaySec2 > 0}>
              <DivInline justifyContent="center" alignItems="center">
                <Box sx={sxArrow}>
                  <ArrowDownward sx={{ fontSize: "0.8rem" }} />
                  <Typography variant="caption">
                    {secToMin(serialSetting?.delaySec2 || serialSetting?.delayed_action2)}
                  </Typography>
                </Box>
              </DivInline>
              <LightSettingRow
                settingNum={settingNum}
                updateSetting={updateSetting}
                serialSetting={serialSetting}
                onoffField="delayedOnOff2"
                pwmField="delayedPwm2"
                enablePwm={lightObj?.type?.pwm}
              />
            </DivExist>
          </>
        )}

        <Divider10 />
      </DivExist>
    </div>
  );
}

function LightSettingRow({
  settingNum,
  updateSetting,
  serialSetting,
  onoffField,
  pwmField,
  enablePwm,
}) {
  const [latestPwm, setLatestPwm] = useState(10);
  let pwmMarks = [];
  for (let i = 0; i <= 100; i += 20) {
    const obj = { value: i, label: `${i}` };
    pwmMarks = [...pwmMarks, obj];
  }
  return (
    <>
      <DivInline style={{ width: "95%" }} justifyContent="space-around">
        <Box
          sx={{
            outline: serialSetting[onoffField] === 0 ? "1px solid red" : "",
          }}
          onClick={() => {
            let newPwm =
              serialSetting[onoffField] === 1
                ? latestPwm
                : serialSetting[pwmField];
            const updateObj = {
              [onoffField]: 0,
              [pwmField]: newPwm,
            };
            updateSetting(settingNum, updateObj);
          }}
        >
          <LightBulb onoff="on" width={2.5} sizeUnit="vw" />
        </Box>
        <Box
          sx={{
            outline: serialSetting[onoffField] === 1 ? "1px solid red" : "",
          }}
          onClick={() => {
            setLatestPwm(serialSetting[pwmField]);
            updateSetting(settingNum, { [onoffField]: 1, [pwmField]: 0 });
          }}
        >
          <LightBulb onoff="off" width={2.5} sizeUnit="vw" />
        </Box>
        <DivExist show={enablePwm}>
          <Slider
            sx={{ width: "50%" }}
            size="small"
            value={serialSetting[pwmField] || 0}
            onChangeCommitted={(e, val) => {
              const newA = Number(val) > 0 ? 0 : 1;
              setLatestPwm(serialSetting[pwmField]);
              updateSetting(settingNum, {
                [onoffField]: newA,
                [pwmField]: Number(val),
              });
            }}
            marks={pwmMarks}
            max={100}
            min={0}
            step={10}
          />
        </DivExist>
      </DivInline>
    </>
  );
}
