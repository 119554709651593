import React, { useState, useContext, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Typography, IconButton } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import moment from "moment";
import Timeline from "@mui/lab/Timeline";
import SensorDetailHistoryModal from "./SensorDetailHistoryModal";
import SensorHistoryItem from "./SensorHistoryItem";
import { fetchSensorHistory } from "actions/sensorActions";

export default function SensorDataLogPanel() {
  const [state] = useContext(SMContext);
  const [sensorDataLog, setSensorDataLog] = useState([]);
  const { activeSensorID, sensorObjAll } = state;
  const sensorObj = sensorObjAll[activeSensorID];
  const { type } = sensorObj || {};

  useEffect(() => {
    if (activeSensorID) fetchLogs();
  }, [activeSensorID]);

  const fetchLogs = async () => {
    let arr = await fetchSensorHistory(activeSensorID, moment().subtract(1, "hours").valueOf(), moment().valueOf());
    arr = arr
      .filter((obj) => obj.uploadProtocol !== "gw_zigbeeConnected")
      .sort((a, b) => b.timeStamp - a.timeStamp)
      .filter((obj, key) => key < 1000);
    if (type === "scenebutton") {
      arr = arr.filter((obj) => {
        if (
          obj.uploadProtocol === "8282" &&
          obj.currentStatus === 0 &&
          obj.currentStatus2 === 0 &&
          obj.currentStatus3 === 0 &&
          obj.currentStatus4 === 0
        )
          return false;
        else return true;
      });
    }
    console.log(arr);
    setSensorDataLog(arr);
  };

  return (
    <div>
      <Divider10 />
      <DivInline justifyContent="center">
        <Typography variant="h6">History</Typography>
        <IconButton onClick={fetchLogs}>
          <Refresh />
        </IconButton>
        <SensorDetailHistoryModal />
      </DivInline>
      <Divider10 />
      <Timeline>
        {sensorDataLog.map((obj, key) => {
          return (
            <SensorHistoryItem key={key} data={obj} sensorObj={sensorObj} />
          );
        })}
      </Timeline>
    </div>
  );
}
