import { useEffect, useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Radio, Button } from "@mui/material";
import { Edit, Add, Remove } from "@mui/icons-material";
import DivExist from "components/DivExist";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import DivInline from "components/DivInline";
import DialogInput from "components/DialogInput";
import DialogSelect from "components/DialogSelect";
import ModalSM from "components/ModalSM";
import Divider10 from "components/Divider10";
import { batteryTypeMenu } from "asset/string/string";
import BatteryCharge from "asset/svgComp/BatteryCharge";
import { updateSerialProperty } from "actions/lightActions";

export default function LightInfoBatPanel() {
  const [state, dispatch] = useContext(SMContext);
  const { userObj, activeSerial, lightObjAll, serverObj, socket } = state;
  const { level } = userObj;
  const lightObj = lightObjAll[activeSerial];
  const {
    type,
    reversePolarityI,
    batteryHour,
    lightBatteryCapacity,
    lightBatteryType,
    nomBatV,
    nomBatI,
    maxVRequired,
    minVRequired,
    minIRequired,
    warningV,
    warningI,
    lightPower,
  } = lightObj || {};

  const handleUpdateLightProps = async (updateObj) => {
    await updateSerialProperty(activeSerial, lightObj, updateObj);
  };
  async function updateFieldOfAllSchedule(schField, val) {
    // openDataFetchProgress();
    // const schArr = await getDocsWhereLF(
    //   serverType,
    //   `SM_schedule`,
    //   "schedule",
    //   "serial",
    //   activeSerial
    // );
    // await asyncForEach(schArr, async (schObj) => {
    //   const scheduleID = schObj.scheduleID;
    //   await updateDocLF(
    //     serverType,
    //     `SM_schedule/${schObj.scheduleID}`,
    //     "schedule",
    //     { scheduleID: scheduleID },
    //     { [schField]: val }
    //   );
    //   await waitFor(300);
    // });
    // closeDataFetchProgress();
  }
  return (
    <DivExist show={type?.batVI}>
      <Divider10 />
      <Typography variant="h6" align="center">
        Battery test parameters
      </Typography>
      <Divider10 />
      <DivExist show={level < 1}>
        <SpaceBetweenDiv
          title="Battery hour"
          data={
            <DivInline>
              <span>
                {batteryHour}
                {batteryHour > 1 ? " hours" : " hour"}
              </span>
              <DialogSelect
                title="Battery hour"
                initialVal={batteryHour || 2}
                handleSave={async (val) =>
                  await handleUpdateLightProps({ batteryHour: Number(val) })
                }
                menuObj={{ 1: "1 hour", 2: "2 hours", 3: "3 hours" }}
              />
            </DivInline>
          }
        />
        <SpaceBetweenDiv
          title="Battery type"
          data={
            <DivInline>
              <Typography>{batteryTypeMenu[lightBatteryType]}</Typography>
              <DialogSelect
                title="Light battery type?"
                initialVal={lightBatteryType || ""}
                handleSave={async (val) =>
                  await handleUpdateLightProps({ lightBatteryType: val })
                }
                menuObj={batteryTypeMenu}
                hideValInMenu
              />
            </DivInline>
          }
        />
        <SpaceBetweenDiv
          title="Rated battery capacity"
          data={
            <DivInline>
              <Typography>{lightBatteryCapacity}mAh</Typography>
              <DivExist
                show={lightBatteryCapacity / nomBatI / 1000 < batteryHour}
              >
                <Typography>Battery capacity seems not sufficient</Typography>
              </DivExist>
              <DialogInput
                title="Light rated battery capacity (mAh)?"
                initialVal={lightBatteryCapacity || 0}
                handleSave={async (val) =>
                  await handleUpdateLightProps({
                    lightBatteryCapacity: Number(val),
                  })
                }
              />
            </DivInline>
          }
        />
        <SpaceBetweenDiv
          title="Nominal battery voltage"
          data={
            <DivInline>
              <Typography>{nomBatV}V</Typography>
              <DialogInput
                title="Light nominal battery voltage (V)?"
                initialVal={nomBatV || 0}
                handleSave={async (val) => {
                  const updateObj = {
                    nomBatV: Number(val),
                    nomBatI: (lightPower / val || 12) * 0.4,
                    minVRequired: (val * 11 || 12) / 12,
                    warningV: ((val * 11 || 12) / 12) * 1.05,
                    maxVRequired: val * 1.5,
                    minIRequired: (lightPower / val || 12) * 0.3,
                    warningI: (lightPower / val || 12) * 0.3 * 1.05,
                  };
                  await handleUpdateLightProps(updateObj);
                }}
              />
            </DivInline>
          }
        />
        <SpaceBetweenDiv
          title="Nominal battery discharge current"
          data={
            <DivInline>
              <Typography>{Number(nomBatI).toFixed(2)}A</Typography>
              <DialogInput
                title="Light nominal battery discharge current (A)?"
                initialVal={nomBatI || 0}
                handleSave={async (val) => {
                  const updateObj = {
                    nomBatI: Number(val),
                    minIRequired: val * 0.3,
                    warningI: val * 0.3 * 1.05,
                  };
                  await handleUpdateLightProps(updateObj);
                }}
              />
            </DivInline>
          }
        />
        <SpaceBetweenDiv
          title="Battery pass voltage V"
          dataColor="white"
          data={
            <DivInline>
              <Typography>{Number(minVRequired)?.toFixed(1)}V</Typography>
              <DialogInput
                title="Battery pass voltage V (~1V less than nominal voltage)"
                initialVal={minVRequired || 0}
                dataType="number"
                handleSave={async (val) => {
                  await handleUpdateLightProps({ minVRequired: Number(val) });
                  await updateFieldOfAllSchedule("minV", Number(val));
                }}
              />
            </DivInline>
          }
        />
        <SpaceBetweenDiv
          title="Discharge pass current A"
          dataColor="white"
          data={
            <DivInline>
              <Typography>{Number(minIRequired)?.toFixed(2)}A</Typography>
              <DialogInput
                title="Discharge pass current A (~70% of nominal load current)"
                initialVal={minIRequired || 0}
                dataType="number"
                handleSave={async (val) => {
                  await handleUpdateLightProps({ minIRequired: Number(val) });
                  await updateFieldOfAllSchedule("minI", Number(val));
                }}
              />
            </DivInline>
          }
        />

        <SpaceBetweenDiv
          title="Maximum voltage (~1.5x of nominal battery voltage)"
          dataColor="white"
          data={
            <DivInline>
              <Typography>{Number(maxVRequired)?.toFixed(1)}V</Typography>
              <DialogInput
                title="Maximum voltage (~1.5x of nominal battery voltage)"
                initialVal={maxVRequired || 0}
                dataType="number"
                handleSave={async (val) => {
                  await handleUpdateLightProps({ maxVRequired: Number(val) });
                  await updateFieldOfAllSchedule("maxV", Number(val));
                }}
              />
            </DivInline>
          }
        />

        <SpaceBetweenDiv
          title="Test discharge voltage warning level"
          dataColor="white"
          data={
            <DivInline>
              <Typography>{Number(warningV)?.toFixed(1)}V</Typography>
              <DialogInput
                title="Test discharge voltage warning level"
                initialVal={warningV || 0}
                dataType="number"
                handleSave={async (val) => {
                  await handleUpdateLightProps({ warningV: Number(val) });
                  await updateFieldOfAllSchedule("warningV", Number(val));
                }}
              />
            </DivInline>
          }
        />
        <SpaceBetweenDiv
          title="Test discharge current warning level"
          dataColor="white"
          data={
            <DivInline>
              <Typography>{Number(warningI)?.toFixed(2)}A</Typography>
              <DialogInput
                title="Test discharge current warning level"
                initialVal={warningI || 0}
                dataType="number"
                handleSave={async (val) => {
                  await handleUpdateLightProps({ warningI: Number(val) });
                  await updateFieldOfAllSchedule("warningI", Number(val));
                }}
              />
            </DivInline>
          }
        />
        <SpaceBetweenDiv
          title="Battery current polarity setting"
          data={
            <BatteryPolarityModal
              initialVal={reversePolarityI}
              savePolarity={async (val) => {
                console.log("new rev polarity", val);
                await handleUpdateLightProps({
                  reversePolarityI: Boolean(val),
                });
              }}
            />
          }
        />
      </DivExist>
      <DivExist show={level > 0}>
        <SpaceBetweenDiv title="Battery hour" data={batteryHour} />
        <SpaceBetweenDiv title="Battery type" data={lightBatteryType} />
        <SpaceBetweenDiv
          title="Rated battery capacity"
          data={lightBatteryCapacity + "mAh"}
        />
        <SpaceBetweenDiv title="Nominal battery voltage" data={nomBatV} />
        <SpaceBetweenDiv
          title="Nominal battery discharge current"
          data={nomBatI}
        />
        <SpaceBetweenDiv title="Battery pass voltage V" data={minVRequired} />
        <SpaceBetweenDiv title="Discharge pass current A" data={minIRequired} />
      </DivExist>
    </DivExist>
  );
}

function BatteryPolarityModal({ savePolarity, initialVal }) {
  const [revPolarity, setRevPolarity] = useState(false);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  useEffect(() => {
    setRevPolarity(initialVal);
  }, [initialVal]);
  return (
    <>
      <DivInline>
        <BatteryCharge darkLight="light" status="charge" width={30} />
        {revPolarity ? <Add /> : <Remove />}
        <div style={{ marginLeft: "10px" }} />
        <BatteryCharge darkLight="light" status="discharge" width={30} />
        {revPolarity ? <Remove /> : <Add />}
        <Button onClick={() => setOpen(true)}>
          <Edit
            style={{
              cursor: "pointer",
              color: "grey",
              fontSize: "1.2em",
            }}
          />
        </Button>
      </DivInline>
      <ModalSM
        open={open}
        onClose={closeModal}
        onSave={async () => {
          await savePolarity(revPolarity);
          closeModal();
        }}
        backgroundColor="darkslategrey"
      >
        <Typography variant="h5">Battery current polarity setting</Typography>
        <Divider10 />

        <DivInline>
          <Radio
            checked={revPolarity}
            value={revPolarity}
            onChange={async (evt) => setRevPolarity(true)}
          />
          <BatteryCharge darkLight="light" status="charge" width={35} />
          <Typography>Charge</Typography>
          <Add sx={{ marginRight: "20px" }} />
          <BatteryCharge darkLight="light" status="discharge" width={35} />
          <Typography>Discharge</Typography>
          <Remove />
        </DivInline>

        <DivInline>
          <Radio
            checked={!revPolarity}
            value={!revPolarity}
            onChange={async (evt) => setRevPolarity(false)}
          />
          <BatteryCharge darkLight="light" status="discharge" width={35} />
          <Typography>Discharge</Typography>{" "}
          <Add sx={{ marginRight: "20px" }} />
          <BatteryCharge darkLight="light" status="charge" width={35} />
          <Typography>Charge</Typography>
          <Remove />
        </DivInline>
      </ModalSM>
    </>
  );
}
