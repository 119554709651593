import { useContext, useState, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Typography, Tooltip } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import Space10 from "components/Space10";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import SensorIcon from "components/SensorIcon";
import SpaceBetweenCheckbox from "components/SpaceBetweenCheckbox";
import DivExist from "components/DivExist";
import DialogInputMinSec from "components/DialogInputMinSec";
import Intermediate from "asset/svgComp/Intermediate";
import ModalSM from "components/ModalSM";
import {
  queryOneSensorSetting,
  updateMotionDelayTime,
} from "actions/sensorActions";
import { secToMin } from "actions/generalActions";
import General from "@ecoenghk/general";
import ButtonSM from "components/ButtonSM";
import IconButtonBack from "components/IconButtonBack";
import IconButtonRefresh from "components/IconButtonRefresh";
import { alertWindow } from "actions/screenActions";
const gs = new General();
export default function SensorDetailMotionDelayPanel() {
  const [state] = useContext(SMContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { activeSensorID, sensorObjAll } = state;
  const sensorObj = sensorObjAll[activeSensorID];

  return (
    <div style={{ paddingBottom: "2vh" }}>
      <Divider10 />
      <DivInline justifyContent="center">
        <Typography variant="h6" display="block" align="center">
          Motion Delay Setting
        </Typography>
      </DivInline>
      <Divider10 space={5} />
      <ButtonSM onClick={() => setOpenEdit(true)}>
        <Tooltip title="Action when motion detected">
          <div>
            <SensorIcon
              type="motion"
              status={1}
              width={4}
              sizeUnit="vw"
              disabledConnectStatus
            />
          </div>
        </Tooltip>
        <Space10 />
        <ArrowForward sx={{ fontSize: "1.2rem" }} />
        <Space10 />
        <Typography>{secToMin(sensorObj?.motion_off_delay)}</Typography>
        <Space10 />
        <ArrowForward sx={{ fontSize: "1.2rem" }} />
        <Space10 />
        <DivExist show={sensorObj?.enable_delayed_action2 ? true : false}>
          <Tooltip title="Intermediate action">
            <Intermediate width={4.5} sizeUnit="vw" background="transparent" />
          </Tooltip>
          <Space10 />
          <ArrowForward sx={{ fontSize: "1.2rem" }} />
          <Space10 />
          <Typography>{secToMin(sensorObj?.motion_off_delay2)}</Typography>
          <Space10 />
          <ArrowForward sx={{ fontSize: "1.5vw" }} /> <Space10 />
        </DivExist>
        <Tooltip title="No motion action">
          <div>
            <SensorIcon
              type="motion"
              status={0}
              width={4}
              sizeUnit="vw"
              disabledConnectStatus
            />
          </div>
        </Tooltip>
      </ButtonSM>
      <EditModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        sensorObj={sensorObj}
      />
    </div>
  );
}

function EditModal({ open, onClose, sensorObj }) {
  const [state, dispatch] = useContext(SMContext);
  const [delayT, setDelayT] = useState(0);
  const [delayT2, setDelayT2] = useState(0);
  const [en2, setEn2] = useState(0);
  useEffect(() => {
    setDelayT(sensorObj?.delaySec ?? sensorObj?.motion_off_delay ?? 0);
    setDelayT2(sensorObj?.delaySec2 ?? sensorObj?.motion_off_delay2 ?? 0);
    setEn2(sensorObj?.enable_delayed_action2 || false);
  }, [
    open,
    sensorObj.delaySec,
    sensorObj.delaySec2,
    sensorObj.motion_off_delay,
    sensorObj.motion_off_delay2,
    sensorObj.enable_delayed_action2,
  ]);
  const handleSave = async (delayT, delayT2, en2) => {
    console.log(delayT, delayT2, en2);
    await updateMotionDelayTime(sensorObj, delayT, delayT2, en2);
    onClose();
  };
  return (
    <ModalSM
      open={open}
      onClose={onClose}
      width="75vw"
      height="50vh"
      onSave={() => handleSave(delayT, delayT2, en2)}
    >
      <DivInline>
        <IconButtonBack onBtnClick={onClose} />
        <Typography>Motion delay setting</Typography>
        <IconButtonRefresh
          onBtnClick={async () => {
            await queryOneSensorSetting(sensorObj, 0x070c);
            await gs.waitFor(2500);
            await queryOneSensorSetting(sensorObj, 0x070d);
          }}
        />
      </DivInline>
      <DivInline justifyContent="flex-start">
        <Space10 />
        <SpaceBetweenCheckbox
          title="2-step action"
          data={en2 === 1 ? true : false}
          onCheck={async (e) => {
            const val = e.target.checked ? 1 : 0;
            if (val === 0) setDelayT2(0);
            setEn2(val);
          }}
        />
        <Space10 space="2vw" />
        <Tooltip title="Action when motion detected">
          <div>
            <SensorIcon
              type="motion"
              status={1}
              width={4}
              sizeUnit="vw"
              disabledConnectStatus
            />
          </div>
        </Tooltip>
        <Space10 />
        <ArrowForward sx={{ fontSize: "1.2rem" }} />
        <Space10 />
        <DivInline
          justifyContent="flex-end"
          style={{
            border: "1px solid #fff",
            padding: 3,
            borderRadius: 6,
            width: "10vw",
          }}
        >
          <DialogInputMinSec
            title="delay time 1"
            initialSec={delayT}
            iconSize={30}
            showValue
            showValueVariant="body1"
            onInput={async (val) => {
              if (val > 28800) {
                alertWindow(dispatch, "Maximum delay time is 8 hours");
                return;
              }
              setDelayT(val);
            }}
            minSec={5}
            dispatch={dispatch}
          />
        </DivInline>
        <Space10 />
        <ArrowForward sx={{ fontSize: "1.2rem" }} />
        <Space10 />
        <DivExist show={en2 ? true : false}>
          <Tooltip title="Intermediate action">
            <Intermediate width={4} sizeUnit="vw" background="transparent" />
          </Tooltip>
          <Space10 />
          <ArrowForward sx={{ fontSize: "1.2rem" }} />
          <Space10 />
          <DivInline
            style={{
              border: "1px solid #fff",
              padding: 5,
              borderRadius: 6,
              width: "10vw",
            }}
            justifyContent="flex-end"
          >
            <DialogInputMinSec
              title="delay time 2"
              initialSec={delayT2}
              iconSize={30}
              showValue
              showValueVariant="body1"
              onInput={async (val) => {
                if (val > 28800) {
                  alertWindow(dispatch, "Maximum delay time is 8 hours");
                  return;
                }
                setDelayT2(val);
              }}
              dispatch={dispatch}
            />
          </DivInline>
          <Space10 />
          <ArrowForward sx={{ fontSize: "1.5vw" }} /> <Space10 />
        </DivExist>
        <Tooltip title="No motion action">
          <div>
            <SensorIcon
              type="motion"
              status={0}
              width={4}
              sizeUnit="vw"
              disabledConnectStatus
            />
          </div>
        </Tooltip>
      </DivInline>
    </ModalSM>
  );
}
