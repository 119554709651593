import ChargeIcon from "./ChargeIcon";

export default function Owl({
  width,
  height,
  sizeUnit,
  disabledStatus,
  statusL,
  connectStatus,
  disabledConnectStatus,
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  const lightColor = disabledStatus || statusL === 0 ? "lightgrey" : "yellow";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <ellipse
        cx="31.093"
        cy="25.514"
        fill={lightColor}
        fillOpacity="0.996"
        stroke="#f60"
        strokeLinecap="round"
        strokeMiterlimit="7.8"
        strokeWidth="2.869"
        rx="11.739"
        ry="12.1"
      ></ellipse>
      <ellipse
        cx="27.344"
        cy="66.71"
        fill="#0a4"
        fillOpacity="0.996"
        strokeWidth="0.89"
        rx="1.354"
        ry="1.456"
      ></ellipse>
      <ellipse
        cx="38.855"
        cy="66.71"
        fill="red"
        fillOpacity="0.996"
        strokeWidth="0.89"
        rx="1.354"
        ry="1.456"
      ></ellipse>
      <ellipse
        cx="33.041"
        cy="66.71"
        fill="#0a4"
        fillOpacity="0.996"
        strokeWidth="0.89"
        rx="1.354"
        ry="1.456"
      ></ellipse>
      <ellipse
        cx="63.914"
        cy="25.693"
        fill={lightColor}
        fillOpacity="0.996"
        stroke="#f60"
        strokeLinecap="round"
        strokeMiterlimit="7.8"
        strokeWidth="2.869"
        rx="11.739"
        ry="12.1"
      ></ellipse>
      <path
        fill="none"
        stroke="#f60"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="3.294"
        d="M17.51 45.087c-.003-2.86.756-5.123 4.83-4.786l51.763.087c2.282-.159 3.538.916 3.294 3.796l.097 24.473c.084 2.85-1.342 3.997-3.876 3.896l-52.134-.464c-2.16-.312-3.873-1.249-3.904-4.54z"
      ></path>
      {!disabledConnectStatus && (
        <circle r="6" cx="10" cy="12" fill={connectStatus ? "green" : "red"} />
      )}
      {!disabledStatus && <ChargeIcon y={28} x={0} statusL={statusL} />}
    </svg>
  );
}
