import { Typography } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export default function ChartVI2({
  chartTitle = "",
  chartDataArray,
  chartLabel,
  backgroundColor,
  chartWidth,
  chartHeight,
  voltageCurveColor,
  voltageAxesColor,
  currentCurveColor,
  currentAxesColor,
  titleTextColor,
  legendTextColor,
  hAxisColor,
}) {
  voltageAxesColor = voltageAxesColor ? voltageAxesColor : "lawngreen";
  currentAxesColor = currentAxesColor ? currentAxesColor : "red";
  hAxisColor = hAxisColor ? hAxisColor : "white";
  titleTextColor = titleTextColor ? titleTextColor : "white";
  const options = {
    responsive: true,
    stacked: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: legendTextColor ? legendTextColor : "#000",
        },
      },
      title: {
        display: true,
        text: chartTitle,
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const data = {
    labels: chartLabel,
    datasets: [
      {
        label: "Voltage(V)",
        data: chartDataArray[0],
        borderColor: "rgb(255, 99, 132)",
        yAxisID: "y",
      },
      {
        label: "Current(A)",
        data: chartDataArray[1],
        borderColor: "rgb(53, 162, 235)",
        yAxisID: "y1",
      },
      {
        label: "min Voltage(V)",
        data: chartDataArray[2],
        borderColor: "rgb(245, 183, 177)",
        yAxisID: "y",
      },
      {
        label: "min discharge current(A)",
        data: chartDataArray[3],
        borderColor: "rgb(133, 193, 233)",
        yAxisID: "y1",
      },
    ],
  };
  return (
    <>
      {chartDataArray.length > 1 ? (
        <Line data={data} options={options} />
      ) : (
        <Typography variant="h4">No chart data</Typography>
      )}
    </>
  );
}
