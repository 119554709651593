import { useState, useEffect } from "react";
import KonvaFaButton from "containers/map/device/KonvaFaButton";
import { Group } from "react-konva";
import {
  confirmWindow,
  hideBigProgress,
  openSnackbar,
  showBigProgress,
} from "actions/screenActions";
import { zoneControlColorDark } from "actions/generalActions";
import RubbishBin from "asset/mapComp/RubbishBin";
import Refresh from "asset/mapComp/Refresh";
import Document from "asset/mapComp/Document";
import {
  deleteZoneControl,
  transferSettingToZoneControl,
} from "actions/zcActions";
import CopyIcon from "asset/mapComp/CopyIcon";
import PasteIcon from "asset/mapComp/PasteIcon";
export default function ToolbarZoneControl({ layerProps }) {
  const {
    layerRef,
    mapObjAll,
    activeMapID,
    mapScale,
    iconSize,
    selectedID,
    selectedDeviceObj,
    dispatch,
    enablePan,
    mapLayer,
    copiedDeviceObj,
    zoneControlObjAll,
    userObj
  } = layerProps;
  const [scale, setScale] = useState(1);
  useEffect(() => {
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
  }, []);
  const activeMapObj = mapObjAll[activeMapID];
  const { x, y } = activeMapObj?.zoneControlObj[selectedID] || {};
  const btnSize = activeMapObj.buttonSize / scale;
  const btnSpace = btnSize * 1.2;
  const { zoneControlName, type } = selectedDeviceObj || {};

  const groupX = x < 500 ? x * mapScale.x : x * mapScale.x - 2 * btnSpace;
  const groupY =
    y < 300 ? y * mapScale.y + iconSize * 1.1 : y * mapScale.y - btnSpace;
  const enablePasteSetting =
    copiedDeviceObj.zoneControlID &&
      copiedDeviceObj.type === type &&
      copiedDeviceObj.zoneControlID !== selectedID
      ? true
      : false;

  const handleRemove = async () => {
    confirmWindow(
      dispatch,
      `Confirm to deletev Zone Control ${zoneControlName} [${selectedID}]?`,
      async () => {
        showBigProgress(dispatch);
        await deleteZoneControl(activeMapID, selectedID, selectedDeviceObj);
        dispatch({
          type: "ALL_UNSELECTED",
        });
        hideBigProgress(dispatch);
      }
    );
  };
  const handleCopySetting = () => {
    dispatch({
      type: "SET_COPIED_DEVICE_OBJ",
      payload: selectedDeviceObj,
    });
    openSnackbar(dispatch, "Zone control settings copied");
  };
  const handlePasteSetting = async () => {
    confirmWindow(
      dispatch,
      `Confirm paste settings from zone control ID ${copiedDeviceObj.zoneControlID}?`,
      async () => {
        await transferSettingToZoneControl(
          copiedDeviceObj.zoneControlID,
          selectedID,
          zoneControlObjAll
        );
        openSnackbar(dispatch, "Setting pasted");
      }
    );
  };
  const handleOpenDetail = () => {
    dispatch({
      type: "OPENED_ZC_DETAIL_MODAL",
      payload: selectedID,
    });
  };

  let btnContent = [
    {
      onClick: handleOpenDetail,
      icon: <Document x={0} y={0} size={btnSize} />,
      tooltip: "Zone control detail",
    },
    {
      onClick: handleRemove,
      icon: <RubbishBin x={0} y={0} size={btnSize} />,
      tooltip: "Remove from map",
    }
  ];
  if (userObj.level < 1) {
    btnContent = [
      ...btnContent,
      {
        onClick: handleCopySetting,
        icon: <CopyIcon x={0} y={0} size={btnSize} />,
        tooltip: "Copy setting",
      },
    ];
  }
  if (enablePasteSetting) {
    btnContent = [
      ...btnContent,
      {
        onClick: handlePasteSetting,
        icon: <PasteIcon x={0} y={0} size={btnSize} />,
        tooltip: "Paste setting",
      },
    ];
  }
  return (
    <Group x={groupX} y={groupY}>
      {btnContent.map((obj, key) => (
        <KonvaFaButton
          key={key}
          x={btnSpace * key}
          y={0}
          mapScale={mapScale}
          enablePan={enablePan}
          stageScale={scale}
          btnSize={btnSize}
          onBtnClick={obj.onClick}
          iconSvg={obj.icon}
          tooltip={obj.tooltip}
          tagColor={zoneControlColorDark}
        />
      ))}
      {/* <KonvaFaButton
        x={0}
        y={0}
        mapScale={mapScale}
        enablePan={enablePan}
        stageScale={scale}
        btnSize={btnSize}
        onBtnClick={handleOpenDetail}
        iconSvg={<Document x={0} y={0} size={btnSize} />}
        tooltip="Zone control detail"
        tagColor={zoneControlColorDark}
      />
      <KonvaFaButton
        x={btnSpace * 1}
        y={0}
        mapScale={mapScale}
        enablePan={enablePan}
        stageScale={scale}
        btnSize={btnSize}
        onBtnClick={handleRemove}
        iconSvg={<RubbishBin x={0} y={0} size={btnSize} />}
        tooltip="Remove from map"
        tagColor={zoneControlColorDark}
      />
      <KonvaFaButton
        x={btnSpace * 2}
        y={0}
        mapScale={mapScale}
        enablePan={enablePan}
        stageScale={scale}
        btnSize={btnSize}
        onBtnClick={handleCopySetting}
        iconSvg={<CopyIcon x={0} y={0} size={btnSize} />}
        tooltip="Copy setting"
        tagColor={zoneControlColorDark}
      />
      {enablePasteSetting && (
      <KonvaFaButton
        x={btnSpace * 3}
        y={0}
        mapScale={mapScale}
        enablePan={enablePan}
        stageScale={scale}
        btnSize={btnSize}
        onBtnClick={handlePasteSetting}
        iconSvg={<PasteIcon x={0} y={0} size={btnSize} />}
        tooltip="Paste setting"
        tagColor={zoneControlColorDark}
      />
       )}  */}
    </Group>
  );
}
