import { useState, useEffect } from "react";
import { TextField, InputAdornment } from "@mui/material";

/**
 *
 * @param {object} props
 * @param {string|number} props.value
 * @param {string} props.label
 * @param {function(string)} props.onInput
 * @param {boolean} props.multiline
 * @param {number} props.maxRows
 * @param {boolean} props.autoFocus
 * @param {object} props.sx
 * @param {function} props.onFocus
 * @param {function} props.onBlur
 * @param {""|"date"} props.type
 * @param {boolean} props.keyPressEnter
 * @param {string} props.startAdornment
 * @param {string} props.endAdornment
 * @returns
 */
export default function InputField({
  value,
  label,
  onInput,
  multiline,
  maxRows,
  autoFocus,
  sx,
  onFocus,
  onBlur,
  type,
  keyPressEnter,
  startAdornment,
  endAdornment,
}) {
  const [data, setData] = useState("");
  useEffect(() => {
    setData(value);
  }, [value]);
  let adorObj = {};
  if (endAdornment)
    adorObj.endAdornment = (
      <InputAdornment position="end">{endAdornment}</InputAdornment>
    );
  if (startAdornment)
    adorObj.startAdornment = (
      <InputAdornment position="start">{startAdornment}</InputAdornment>
    );
  return (
    <TextField
      type={type || ""}
      value={data}
      label={label}
      onChange={(e) => setData(e.target.value)}
      onFocus={(e) => {
        e.currentTarget.select();
        if (onFocus) onFocus();
      }}
      onBlur={() => {
        if (data !== value) {
          onInput(data);
        }
        if (onBlur) onBlur();
      }}
      onKeyUp={(e) => {
        if (keyPressEnter && e.key === "Enter") {
          onInput(data);
        }
      }}
      fullWidth
      sx={sx}
      multiline={multiline}
      maxRows={maxRows || 1}
      size="small"
      autoFocus={autoFocus}
      InputProps={adorObj}
    />
  );
}
