import DaliCtlIcon from "./DaliCtlIcon";
import GatewayIcon from "./GatewayIcon";
import LightIcon from "./LightIcon";
import SensorIcon from "./SensorIcon";
import ZoneControlIcon from "./ZoneControlIcon";

/**
 * Renders an icon for a device of a specific type.
 *
 * @param {Object} props - The properties for the DeviceIcon component.
 * @param {light|sensor|gateway|daliCtl|zoneControl} props.deviceType - The type of the device. Can be "light", "sensor", "gateway", or "daliCtl".
 * @param {number} [props.width=3] - The width of the icon.
 * @param {string} [props.sizeUnit="vw"] - The unit of measurement for the width of the icon.
 * @param {string} [props.deviceStyle=""] - The style of the device.
 * @param {number} [props.gang=3] - The number of gangs for the device.
 * @returns {React.Element|null} A React element representing the icon for the device, or null if the device type is not recognized.
 *
 * @example
 * <DeviceIcon deviceType="light" width={5} sizeUnit="em" deviceStyle="modern" gang={2} />
 */
export default function DeviceIcon({ deviceType, width = 3, sizeUnit = "vw", deviceStyle = "", gang = 3 }) {
    if (deviceType === "light") {
        return <LightIcon width={width} sizeUnit={sizeUnit}
            disabledConnectStatus
            disableStatus
            lightStyle={deviceStyle}

        />
    }
    if (deviceType === "sensor") {
        return <SensorIcon width={width} sizeUnit={sizeUnit} disabledConnectStatus type={deviceStyle} gang={gang} />
    }
    if (deviceType === "gateway") {
        return <GatewayIcon width={width} sizeUnit={sizeUnit} disabledConnectStatus />
    }
    if (deviceType === "daliCtl") {
        return <DaliCtlIcon width={width} sizeUnit={sizeUnit} disabledConnectStatus />
    }
    if (deviceType === "zoneControl") {
        return <ZoneControlIcon type={deviceStyle} width={width} sizeUnit={sizeUnit} disabledConnectStatus />
    }
    return null;
}