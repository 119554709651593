import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import DivExist from "components/DivExist";
import Divider10 from "components/Divider10";
import {
  Typography,
  Box,
  Tooltip,
  Slider,
  IconButton,
  Checkbox
} from "@mui/material";
import DivInline from "components/DivInline";
import { CopyAll, FastForward, ArrowDownward } from "@mui/icons-material";
import Intermediate from "asset/svgComp/Intermediate";
import LightBulb from "asset/svgComp/LightBulb";
import SensorIcon from "components/SensorIcon";
import {
  sensorDisconnectLight,
  updateSensorProperty,
  copyLightSettingInOneSensor,
} from "actions/sensorActions";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import IconButtonDone from "components/IconButtonDone";
import {
  confirmWindow,
  hideBigProgress,
  showBigProgress,
} from "actions/screenActions";
import SensorMapModalHeader from "./SensorMapModalHeader";
import NearFieldIcon from "asset/svgComp/NearFieldIcon";
import General from "@ecoenghk/general";
import SenNearFieldModal from "./SenNearFieldModal";
const gs = new General();

const sxArrow = {
  outline: "1px solid #fff",
  margin: "1vh",
  padding: "0 0.5vw 0 0.5vw ",
};
export default function SenMotionLightModal({
  x,
  y,
  modalLocation,
  open,
  handleClose,
  layerProps,
  selectedLight,
  sensorID,
  sensorObj,
  scale,
}) {
  const [serialSetting, setSerialSetting] = useState({});
  const [activeNearFieldDeviceObj, setActiveNearFieldDeviceObj] = useState({});
  useEffect(() => {
    const setting1 = sensorObj?.setting1 || {};
    setSerialSetting(setting1[selectedLight]);
  }, [selectedLight, open, sensorObj]);

  const { iconSize, lightObjAll, dispatch, daliCtlObjAll, userObj } = layerProps;
  const lightObj = lightObjAll[selectedLight];
  const { type, gatewayID } = sensorObj || {};
  const delaySec = sensorObj.delaySec || sensorObj.motion_off_delay || 0;
  const delaySec2 = sensorObj.delaySec2 || sensorObj.motion_off_delay2 || 0;
  const hasNearField = sensorObj?.nearFieldSetting?.[`${selectedLight}_1_0`] ? true : false;
  const updateSetting = async (updateObj) => {
    const newSerialSetting = { ...serialSetting, ...updateObj };
    setSerialSetting(newSerialSetting);
  };
  const handleDisconnect = async () => {
    confirmWindow(
      dispatch,
      `Confirm disconnect light [${selectedLight}]?`,
      async () => {
        showBigProgress(dispatch);
        const did = sensorObj.daliCtlID;
        const gid = did ? daliCtlObjAll[did]?.gatewayID : sensorObj.gatewayID;
        await sensorDisconnectLight(sensorObj, gid, lightObj);
        hideBigProgress(dispatch);
        handleClose();
      }
    );
  };
  const handleSave = async () => {
    let setting1 = { ...sensorObj.setting1 || {} };
    setting1[selectedLight] = serialSetting;
    let newSettingObj = { setting1 };
    await updateSensorProperty(sensorID, sensorObj, gatewayID, newSettingObj);
    handleClose();
  };
  const handleCopyLightSetting = async () => {
    confirmWindow(
      dispatch,
      "Confirm copy setting to other lights?",
      async () => {
        await copyLightSettingInOneSensor(sensorObj, selectedLight);
      }
    );
  };
  const modalWidth = window.innerWidth * 0.38;
  const modalHeight =
    window.innerHeight * (delaySec2 > 0 ? 0.48 : 0.38);
  const modalX = modalLocation.includes("right") ? x - modalWidth / scale : x;
  const modalY = modalLocation.includes("bottom")
    ? y - modalHeight / scale - iconSize
    : y;
  return (
    <>
      <MapModal
        x={modalX}
        y={modalY}
        open={open}
        handleCloseModal={handleClose}
        disableCloseButton
        width={modalWidth}
        height={modalHeight}
        layerProps={layerProps}
      >
        <SensorMapModalHeader
          sensorObj={sensorObj}
          deviceObj={lightObj}
          handleClose={handleClose}
          layerProps={layerProps}
        />

        <Divider10 />
        <LightSettingRow
          sensorIcon={
            <SensorIcon type={type} status={1} width={3} sizeUnit="vw" disabledConnectStatus />
          }
          serialSetting={serialSetting}
          updateSetting={updateSetting}
          onoffField="onoffcontrolA"
          pwmField="pwm"
          enablePwm={lightObj?.type?.pwm}
        />
        <DivInline justifyContent="center" alignItems="center">
          <Box sx={sxArrow}>
            <ArrowDownward sx={{ fontSize: "0.8rem" }} />
            <Typography variant="caption">{delaySec}s</Typography>
          </Box>
        </DivInline>
        <LightSettingRow
          sensorIcon={
            delaySec2 > 0 ? (
              <Intermediate width={3} sizeUnit="vw" background="transparent" />
            ) : (
              <SensorIcon type={type} status={0} width={3} sizeUnit="vw" disabledConnectStatus />
            )
          }
          serialSetting={serialSetting}
          updateSetting={updateSetting}
          onoffField="delayedOnOff"
          pwmField="delayedPwm"
          enablePwm={lightObj?.type?.pwm}
        />
        <DivExist show={delaySec2 > 0}>
          <DivInline justifyContent="center" alignItems="center">
            <Box sx={sxArrow}>
              <ArrowDownward sx={{ fontSize: "0.8rem" }} />
              <Typography variant="caption">{delaySec2}s</Typography>
            </Box>
          </DivInline>
          <LightSettingRow
            sensorIcon={
              <SensorIcon type={type} status={0} width={3} sizeUnit="vw" disabledConnectStatus />
            }
            serialSetting={serialSetting}
            updateSetting={updateSetting}
            onoffField="delayedOnOff2"
            pwmField="delayedPwm2"
            enablePwm={lightObj?.type?.pwm}
          />
        </DivExist>
        <Divider10 />
        <DivInline alignItems="center">
          <IconButtonClose
            tooltip="Close dialog without saving"
            onBtnClick={handleClose}
          />
          <IconButtonDone
            tooltip="Save settings and close dialog"
            onBtnClick={handleSave}
          />
          <IconButtonDelete
            tooltip="Disconnect light from sensor"
            onBtnClick={handleDisconnect}
          />
          <IconButton onClick={handleCopyLightSetting} size="small">
            <Tooltip title="Copy setting to other lights">
              <CopyAll />
            </Tooltip>
          </IconButton>

          {
            userObj?.level === 0 &&
            <Tooltip title="Save near field action to sensor">
              <IconButton
                onClick={() => {
                  setActiveNearFieldDeviceObj(lightObj)
                }}
              >
                <NearFieldIcon width={2} sizeUnit="vw" color={hasNearField ? "red" : "#fff"} />
              </IconButton>
            </Tooltip>
          }


        </DivInline>

      </MapModal>
      <SenNearFieldModal
        x={modalX}
        y={modalY}
        width={modalWidth}
        height={modalHeight}
        open={!gs.isEmptyJson(activeNearFieldDeviceObj) ? true : false}
        handleClose={() => setActiveNearFieldDeviceObj({})}
        layerProps={layerProps}
        sensorObj={sensorObj}
        deviceObj={activeNearFieldDeviceObj}
        buttonNo={1}
      />
    </>
  );
}

function LightSettingRow({
  sensorIcon,
  updateSetting,
  serialSetting,
  onoffField,
  pwmField,
  enablePwm,
}) {
  const [latestPwm, setLatestPwm] = useState(10);
  let pwmMarks = [];
  for (let i = 0; i <= 100; i += 20) {
    const obj = { value: i, label: `${i}` };
    pwmMarks = [...pwmMarks, obj];
  }
  const pwmValue = serialSetting?.[pwmField];
  const onoffValue = serialSetting?.[onoffField];
  return (
    <>
      <DivInline style={{ width: "98%" }} justifyContent="space-between">
        {sensorIcon}
        <FastForward style={{ fontSize: "1em" }} />
        {serialSetting?.[pwmField] === 250 ? (
          <Typography sx={{ width: "60%", outline: "1px solid grey" }}>
            no light action
          </Typography>
        ) : (
          <>
            <Box
              sx={{ outline: onoffValue === 0 || pwmValue > 0 ? "1px solid red" : "" }}
              onClick={() => {
                let newPwm = serialSetting[onoffField] === 1 ? latestPwm : serialSetting[pwmField];
                if (!enablePwm) newPwm = 100;
                let updateObj = { [onoffField]: 0, [pwmField]: newPwm };
                updateSetting(updateObj);
              }}
            >
              <LightBulb onoff="on" width={2.5} sizeUnit="vw" />
            </Box>
            <Box
              sx={{ outline: onoffValue === 1 || !pwmValue ? "1px solid red" : "" }}
              onClick={() => {
                if (!enablePwm) {
                  setLatestPwm(0);
                } else {
                  setLatestPwm(serialSetting[pwmField]);
                }
                updateSetting({ [onoffField]: 1, [pwmField]: 0 });
              }}
            >
              <LightBulb onoff="off" width={2.5} sizeUnit="vw" />
            </Box>
            <DivExist show={enablePwm}>
              <Slider
                sx={{ width: "40%" }}
                size="small"
                value={serialSetting?.[pwmField] || 0}
                onChangeCommitted={(e, val) => {
                  const newA = Number(val) > 0 ? 0 : 1;
                  setLatestPwm(serialSetting?.[pwmField]);
                  updateSetting({
                    [onoffField]: newA,
                    [pwmField]: Number(val),
                  });
                }}
                marks={pwmMarks}
                max={100}
                min={0}
                step={10}
              />
            </DivExist>
          </>
        )}

        <DivInline>
          <Checkbox
            size="small"
            checked={serialSetting?.[pwmField] === 250}
            onChange={(e) => {
              if (e.target.checked) {
                setLatestPwm(serialSetting?.[pwmField]);
                updateSetting({ [pwmField]: 250 });
              } else {
                updateSetting({ [pwmField]: latestPwm });
              }
            }}
          />
          <Typography variant="caption">no action</Typography>
        </DivInline>
      </DivInline>
    </>
  );
}

