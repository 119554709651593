import MapIconLight from "../device/MapIconLight";
import MapIconSensor from "../device/MapIconSensor";
import DaliCtl from "asset/mapComp/DaliCtl";
import Gateway from "asset/mapComp/Gateway";

export default function MapDeviceIcon({ deviceObj, deviceType, layerProps, onMouseOver, onMouseLeave }) {
    const { iconSize, mapScale, scale } = layerProps;
    if (deviceType === "light")
        return (
            <MapIconLight
                x={0}
                y={0}
                lightStyle={deviceObj?.style}
                iconSize={iconSize}
                mapScale={mapScale}
                scale={scale}
                disabledConnectStatus
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                shadEnabled={false}
                onoffstatusL={0}

            />
        );
    if (deviceType === "sensor")
        return (
            <MapIconSensor
                x={0}
                y={0}
                layerProps={layerProps}
                type={deviceObj?.type}
                scale={scale}
                disabledConnectStatus
                gang={deviceObj?.gang}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                shadEnabled={false}

            />
        );
    if (deviceType === "daliCtl")
        return (
            <DaliCtl disabledConnectStatus size={iconSize * mapScale.x} />
        );
    if (deviceType === "gateway")
        return (
            <Gateway disabledConnectStatus size={iconSize * mapScale.x} />
        );
    return null;
}
