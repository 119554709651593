import { useContext, useRef, useState, useMemo } from "react";
import {
  Button,
  Tooltip,
  Typography,
  ButtonGroup,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Box,
} from "@mui/material";
import DivInline from "components/DivInline";
import { Storage } from "@mui/icons-material";
import { SMContext } from "context/smContext";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import ModalSM from "components/ModalSM";
import InputField from "components/InputField";
import Divider10 from "components/Divider10";
import { mgFindOne } from "actions/mongoApiActions";
import SpaceBetweenSwitch from "components/SpaceBetweenSwitch";
import { changeServerUploadFirestore, editServerProps } from "actions/serverActions";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
import moment from "moment";
import {
  fetchAllMap,
  updateMapsFsToLs,
  updateSingleMapFsToLs,
} from "actions/mapActions";
import { ViewportList } from "react-viewport-list";
import {
  fetchAllUsers,
  updateUsersFsToLs,
  updateSingleUserFsToLs,
} from "actions/userActions";
import {
  uploadGatewayFS,
  uploadMapFS,
  uploadSensorFS,
  uploadSerialFS,
  uploadZcFS,
  uploadUserFS,
  uploadDaliCtlFS,
} from "actions/uploadFsActions";
import {
  fetchAllGateways,
  updateGatewaysFsToLs,
  updateSingleGatewayFsToLs,
} from "actions/gatewayActions";
import {
  fetchAllLights,
  updateLightsFsToLs,
  updateSingleLightFsToLs,
} from "actions/lightActions";
import {
  fetchAllSensors,
  updateSensorsFsToLs,
  updateSingleSensorFsToLs,
} from "actions/sensorActions";
import {
  fetchAllZcs,
  updateZcsFsToLs,
  updateSingleZcFsToLs,
} from "actions/zcActions";
import {
  fetchAllDaliCtls,
  updateDaliCtlsFsToLs,
  updateSingleDaliCtlFsToLs,
} from "actions/daliCtlActions";
import { mgPost } from "actions/mongoApiActions";
import General from "@ecoenghk/general";
import { alertWindow, confirmWindow } from "actions/screenActions";
import IconButtonBack from "components/IconButtonBack";
import DivExist from "components/DivExist";
import { mgUpdateOneUpsert } from "actions/mongoApiActions";
import ServerLog from "./ServerLog";
import ServerConsoleLog from "./ServerConsoleLog";
import IconButtonDelete from "components/IconButtonDelete";
import { isMatch } from "actions/generalActions";
import SpaceBetweenButton from "components/SpaceBetweenButton";
const gs = new General();

export default function ServerSettingModal() {
  const [state, dispatch] = useContext(SMContext);
  const [open, setOpen] = useState(false);
  const [display, setDisplay] = useState("server");
  const [allUsers, setAllUsers] = useState({});
  const [allMaps, setAllMaps] = useState({});
  const [allGateways, setAllGateways] = useState({});
  const [allLights, setAllLights] = useState({});
  const [allSensors, setAllSensors] = useState({});
  const [allZcs, setAllZcs] = useState({});
  const [allDaliCtls, setAllDaliCtls] = useState({});
  const [importID, setImportID] = useState("");
  const [searchText, setSearchText] = useState("");
  const { userObj, serverID, serverObj, socket } = state;
  const gatewayRef = useRef(null);
  const lightRef = useRef(null);
  const sensorRef = useRef(null);
  const daliRef = useRef(null);
  const {
    description,
    enableUploadFS,
    serPublicIp,
    serVersion,
    serverStartTimeStamp,
  } = serverObj || {};
  const upTime = moment(serverStartTimeStamp).fromNow();
  const handleOpen = async () => {
    setOpen(true);
    handleFetchAllUsers();
    handleFetchAllMaps();
    handleFetchAllGateway();
    handleFetchAllLights();
    handleFetchAllSensors();
    handleFetchAllZcs();
    handleFetchAllDaliCtls();
  };
  const handleEditProps = async (updateObj) => {
    editServerProps(dispatch, serverID, enableUploadFS, updateObj);
  };

  const handleFetchAllUsers = async () => {
    const objAll = await fetchAllUsers();
    setAllUsers(objAll);
  };

  const handleFetchAllMaps = async () => {
    const objAll = await fetchAllMap();
    setAllMaps(objAll);
  };
  const handleFetchAllGateway = async () => {
    const objAll = await fetchAllGateways();
    setAllGateways(objAll);
  };
  const handleFetchAllLights = async () => {
    const objAll = await fetchAllLights();
    setAllLights(objAll);
  };
  const handleFetchAllSensors = async () => {
    const objAll = await fetchAllSensors();
    setAllSensors(objAll);
  };
  const allMapsArr = useMemo(() => {
    let arr = Object.keys(allMaps || {})
      .sort((a, b) => a.localeCompare(b))
      .map((mid) => allMaps[mid]);
    if (searchText !== "" && arr.length > 0 && display === "map") {
      arr = arr.filter(
        (obj) =>
          isMatch(obj.mapID, searchText) || isMatch(obj.mapName, searchText)
      );
    }
    return arr;
  }, [allMaps, searchText]);
  const allLightsArr = useMemo(() => {
    let arr = Object.keys(allLights || {})
      .sort((a, b) => a.localeCompare(b))
      .map((s) => allLights[s]);
    if (searchText !== "" && arr.length > 0 && display === "light") {
      arr = arr.filter(
        (obj) =>
          isMatch(obj.serial, searchText) ||
          isMatch(obj.description, searchText) ||
          isMatch(obj.dtkAdd, searchText) ||
          isMatch(obj.zigbeeAdd, searchText)
      );
    }
    return arr;
  }, [allLights, searchText]);
  const allGatewayArr = useMemo(() => {
    let arr = Object.keys(allGateways || {})
      .sort((a, b) => a.localeCompare(b))
      .map((gid) => allGateways[gid]);
    if (searchText !== "" && arr.length > 0 && display === "gateway") {
      arr = arr.filter(
        (obj) =>
          isMatch(obj.gatewayID, searchText) ||
          isMatch(obj.description, searchText) ||
          isMatch(obj.dtkAdd, searchText) ||
          isMatch(obj.zigbeeAdd, searchText)
      );
    }
    return arr;
  }, [allGateways, searchText]);
  const allSensorsArr = useMemo(() => {
    let arr = Object.keys(allSensors || {})
      .sort((a, b) => a.localeCompare(b))
      .map((s) => allSensors[s]);
    if (searchText !== "" && arr.length > 0 && display === "sensor") {
      arr = arr.filter(
        (obj) =>
          isMatch(obj.sensorID, searchText) ||
          isMatch(obj.sensorName, searchText) ||
          isMatch(obj.dtkAdd, searchText) ||
          isMatch(obj.zigbeeAdd, searchText)
      );
    }
    return arr;
  }, [allSensors, searchText]);
  const handleFetchAllZcs = async () => {
    const objAll = await fetchAllZcs();
    console.log("Fetched all Zone control", objAll);
    setAllZcs(objAll);
  };
  const handleFetchAllDaliCtls = async () => {
    const objAll = await fetchAllDaliCtls();
    console.log("Fetched all daliCtl", objAll);
    setAllDaliCtls(objAll);
  };
  const handleUpdateSingleUserFsToLs = async (uid) => {
    await updateSingleUserFsToLs(socket, uid);
    await gs.waitFor(1200);
    const obj = await mgFindOne("user", "uid", uid);
    let newAllUsers = { ...allUsers, [uid]: obj };
    setAllUsers(newAllUsers);
  };
  const handleUpdateSingleMapFsToLs = async (mid) => {
    await updateSingleMapFsToLs(socket, mid);
    await gs.waitFor(1200);
    const obj = await mgFindOne("map", "mapID", mid);
    console.log("updated map from FS", obj);
    let newAllMaps = { ...allMaps, [mid]: obj };
    setAllMaps(newAllMaps);
  };
  const handleUpdateSingleGatewayFsToLs = async (g) => {
    await updateSingleGatewayFsToLs(socket, g);
    await gs.waitFor(1200);
    const obj = await mgFindOne("gateway", "gatewayID", g);
    console.log("updated gateway from FS", obj);
    let newAllGateway = { ...allGateways, [g]: obj };
    setAllGateways(newAllGateway);
  };
  const handleUpdateSingleLightFsToLs = async (s) => {
    await updateSingleLightFsToLs(socket, s);
    await gs.waitFor(1200);
    const obj = await mgFindOne("serial", "serial", s);
    console.log("updated light from FS", obj);
    let newAllLight = { ...allLights, [s]: obj };
    setAllLights(newAllLight);
  };
  const handleUpdateSingleSensorFsToLs = async (s) => {
    await updateSingleSensorFsToLs(socket, s);
    await gs.waitFor(1200);
    const obj = await mgFindOne("sensor", "sensorID", s);
    console.log("updated sensor from FS", obj);
    let newAllSensor = { ...allSensors, [s]: obj };
    setAllSensors(newAllSensor);
  };
  const handleUpdateSingleZcFsToLs = async (s) => {
    await updateSingleZcFsToLs(s);
    await gs.waitFor(1200);
    const obj = await mgFindOne("zoneControl", "zoneControlID", s);
    console.log("updated zone control from FS", obj);
    let newAllZc = { ...allZcs, [s]: obj };
    setAllZcs(newAllZc);
  };
  const handleUpdateSingleDaliCtlFsToLs = async (s) => {
    await updateSingleDaliCtlFsToLs(socket, s);
    await gs.waitFor(1200);
    const obj = await mgFindOne("daliCtl", "daliCtlID", s);
    console.log("updated daliCtl from FS", obj);
    let newAllDaliCtl = { ...allDaliCtls, [s]: obj };
    setAllDaliCtls(newAllDaliCtl);
  };
  const deviceObj = {
    server: "Server",
    user: "User",
    map: "Map",
    gateway: "Gateway",
    light: "Light",
    sensor: "Sensor",
    zoneControl: "Zone Ctl",
    daliCtl: "DaliCtl",
    serverLog: "Server Log",
    serverConsoleLog: "Console Log",
  };

  return (
    <>
      <Tooltip
        title={<Typography>Server Settings</Typography>}
        placement="bottom"
      >
        <Button
          size="small"
          variant="outlined"
          color="inherit"
          onClick={handleOpen}
        >
          <Storage />
        </Button>
      </Tooltip>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        modalIcon={<Storage />}
        width="98vw"
        height="98vh"
        disableBottomClose
      >
        <DivInline>
          <IconButtonBack onBtnClick={() => setOpen(false)} />
          <Typography
            sx={{ marginRight: "1vw" }}
          >{`Server Settings [ID: ${serverID}]`}</Typography>
          <ButtonGroup size="small">
            {Object.keys(deviceObj).map((d, key) => {
              return (
                <Button
                  key={key}
                  variant={display === d ? "contained" : "outlined"}
                  onClick={() => {
                    setImportID("");
                    setDisplay(d);
                  }}
                >
                  {deviceObj[d]}
                </Button>
              );
            })}
          </ButtonGroup>
          <InputField
            sx={{ width: "15vw", marginLeft: "1vw" }}
            label="Search"
            value={searchText}
            onInput={(v) => setSearchText(v)}
            keyPressEnter
          />
          <IconButtonDelete onBtnClick={() => setSearchText("")} />
        </DivInline>
        <Divider10 />
        <DivExist show={display === "server"}>
          <SpaceBetweenDialogInput
            title="Description"
            data={description}
            handleSave={(val) => handleEditProps({ description: val })}
          />
          <Divider10 />
          <SpaceBetweenDiv title="Public IP" data={serPublicIp} />
          <Divider10 />
          <SpaceBetweenSwitch
            title="Upload changes to cloud"
            data={enableUploadFS}
            onChange={async (e) => {
              const newStatus = e.target.checked;
              confirmWindow(
                dispatch,
                `${newStatus ? "Start" : "Stop"} synchronize changes to cloud`,
                async () => {
                  await changeServerUploadFirestore(serverID, newStatus);
                  alertWindow(
                    dispatch,
                    "Going to restart server, please refresh this webapp"
                  );
                }
              );
            }}
          />
          <Divider10 />
          <SpaceBetweenButton title="Check server connect internet" btnContent="Check" onBtnClick={async () => {
            const res = await fetch(`${global.ip}/api/serverHasInternet`);
            const hasInternetRes = await res.json();
            const hasInternet = hasInternetRes.result;
            alertWindow(dispatch, hasInternet ? "Server HAS internet" : "Server HAS NO internet");
          }} />;
          <Divider10 />
          <SpaceBetweenDiv title="Up time" data={upTime} />
          <Divider10 />
          <SpaceBetweenDiv title="Version" data={serVersion} />
        </DivExist>
        <DivExist show={display === "user"}>
          <DivInline justifyContent="space-between">
            <Typography variant="h6">Users</Typography>
            <Button
              variant="outlined"
              onClick={async () => {
                await updateUsersFsToLs(socket);
                await gs.gs.waitFor(1000);
                await handleFetchAllUsers();
              }}
            >
              All users FS ▶︎ Server
            </Button>
            <DivInline>
              <TextField
                label="import one user from FS (UID)"
                value={importID}
                onChange={(e) => setImportID(e.target.value)}
                size="small"
                sx={{ minWidth: "30vw" }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  const fsObj = await mgPost("getDocFS", {
                    docPath: `SM_user/${importID}`,
                  });
                  console.log("fsObj", fsObj);
                  if (fsObj.result !== "fail") {
                    await mgUpdateOneUpsert("user", { uid: importID }, fsObj);
                  } else {
                    alertWindow(dispatch, "No such user in FS");
                  }
                }}
              >
                import
              </Button>
            </DivInline>
          </DivInline>
          <Divider10 />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>uid</TableCell>
                <TableCell>email</TableCell>
                <TableCell>Server▶︎FS</TableCell>
                <TableCell>FS▶︎Server</TableCell>
              </TableRow>
            </TableBody>
            {Object.keys(allUsers || {}).map((uid) => {
              return (
                <TableRow key={uid}>
                  <TableCell>{uid}</TableCell>
                  <TableCell>{allUsers[uid].email}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => uploadUserFS(socket, uid)}
                    >
                      Server▶︎FS
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={async () => handleUpdateSingleUserFsToLs(uid)}
                    >
                      FS▶︎Server
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </DivExist>
        <DivExist show={display === "map"}>
          <DivInline justifyContent="space-between">
            <Typography variant="h6">Maps</Typography>
            <Button
              variant="outlined"
              onClick={async () => {
                await updateMapsFsToLs(socket);
                await gs.waitFor(1200);
                await handleFetchAllMaps();
              }}
            >
              All maps FS ▶︎ Server
            </Button>
            <DivInline>
              <TextField
                label="import one map from FS (mapID)"
                value={importID}
                onChange={(e) => setImportID(e.target.value)}
                size="small"
                sx={{ minWidth: "30vw" }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  let fsObj = await mgPost("getDocFS", {
                    docPath: `SM_map/${importID}`,
                  });
                  console.log("fsObj", fsObj);
                  if (fsObj.result !== "fail") {
                    fsObj = { ...fsObj, serverID: serverID };
                    await mgUpdateOneUpsert("map", { mapID: importID }, fsObj);
                    await mgPost("updateDocFS", {
                      docPath: `SM_map/${importID}`,
                      updateObj: fsObj,
                    });
                  } else {
                    alertWindow(dispatch, "No such map in FS");
                  }
                }}
              >
                import
              </Button>
            </DivInline>
          </DivInline>
          <Divider10 />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>mapID</TableCell>
                <TableCell>name</TableCell>
                <TableCell>mapUrl</TableCell>
                <TableCell>Server▶︎FS</TableCell>
                <TableCell>FS▶︎Server</TableCell>
              </TableRow>

              {(allMapsArr || []).map((mapObj, key) => {
                const bgUrl = mapObj.mapFileName
                  ? `${global.ip}/img/${mapObj.mapFileName}`
                  : mapObj.mapUrl;
                const { mapName, mapID } = mapObj;
                return (
                  <TableRow key={key}>
                    <TableCell>{mapID}</TableCell>
                    <TableCell>{mapName}</TableCell>
                    <TableCell>
                      <img
                        src={bgUrl}
                        style={{ width: "8vw", height: "7vh" }}
                        alt=""
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => uploadMapFS(socket, mapID)}
                      >
                        Server▶︎FS
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={async () => handleUpdateSingleMapFsToLs(mapID)}
                      >
                        FS▶︎Server
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DivExist>
        <DivExist show={display === "gateway"}>
          <DivInline justifyContent="space-between">
            <Typography variant="h6">Gateway</Typography>
            <Button
              variant="outlined"
              onClick={async () => {
                await updateGatewaysFsToLs(socket);
                await gs.waitFor(1200);
                await handleFetchAllGateway();
              }}
            >
              All gateways FS ▶︎ Server
            </Button>
            <DivInline>
              <TextField
                label="import one gateway from FS (gatewayID)"
                value={importID}
                onChange={(e) => setImportID(e.target.value)}
                size="small"
                sx={{ minWidth: "30vw" }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  let fsObj = await mgPost("getDocFS", {
                    docPath: `SM_gateway/${importID}`,
                  });
                  console.log("fsObj", fsObj);
                  if (fsObj.result !== "fail") {
                    fsObj = { ...fsObj, serverID: serverID };
                    await mgUpdateOneUpsert(
                      "gateway",
                      { gatewayID: importID },
                      fsObj
                    );
                    await mgPost("updateDocFS", {
                      docPath: `SM_gateway/${importID}`,
                      updateObj: fsObj,
                    });
                  } else {
                    alertWindow(dispatch, "No such gateway in FS");
                  }
                }}
              >
                import
              </Button>
            </DivInline>
          </DivInline>
          <Divider10 />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>gatewayID</TableCell>
                <TableCell>description</TableCell>
                <TableCell>add</TableCell>
                <TableCell>Server▶︎FS</TableCell>
                <TableCell>FS▶︎Server</TableCell>
              </TableRow>
              {(allGatewayArr || []).map((gatewayObj, key) => {
                // const gatewayObj = allGateways[gid];
                const { gatewayID, description, zigbeeAdd, dtkAdd } =
                  gatewayObj;
                const commType = dtkAdd ? "dtk" : zigbeeAdd ? "zigbee" : "";
                return (
                  <TableRow key={key}>
                    <TableCell>{gatewayID}</TableCell>
                    <TableCell>{description}</TableCell>
                    <TableCell>{`${commType} ${dtkAdd || zigbeeAdd || ""
                      }`}</TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => uploadGatewayFS(socket, gatewayID)}
                      >
                        Server▶︎FS
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={async () =>
                          handleUpdateSingleGatewayFsToLs(gatewayID)
                        }
                      >
                        FS▶︎Server
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DivExist>
        <DivExist show={display === "light"}>
          <DivInline justifyContent="space-between">
            <Typography variant="h6">Light</Typography>
            <Button
              variant="outlined"
              onClick={async () => {
                await updateLightsFsToLs(socket);
                await gs.waitFor(1200);
                await handleFetchAllLights();
              }}
            >
              All lights FS ▶︎ Server
            </Button>
            <DivInline>
              <TextField
                label="import one light from FS (serial)"
                value={importID}
                onChange={(e) => setImportID(e.target.value)}
                size="small"
                sx={{ minWidth: "30vw" }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  let fsObj = await mgPost("getDocFS", {
                    docPath: `SM_serial/${importID}`,
                  });
                  console.log("fsObj", fsObj);
                  if (fsObj.result !== "fail") {
                    fsObj = { ...fsObj, serverID };
                    await mgUpdateOneUpsert(
                      "serial",
                      { serial: importID },
                      fsObj
                    );
                    await mgPost("updateDocFS", {
                      docPath: `SM_serial/${importID}`,
                      updateObj: fsObj,
                    });
                  } else {
                    alertWindow(dispatch, "No such light in FS");
                  }
                }}
              >
                import
              </Button>
            </DivInline>
          </DivInline>
          <Divider10 />
          <DivInline>
            <Box sx={{ width: "10vw" }}>serial</Box>
            <Box sx={{ width: "30vw" }}>description</Box>
            <Box sx={{ width: "15vw" }}>address</Box>
            <Box sx={{ width: "15vw" }}>Server▶︎FS</Box>
            <Box sx={{ width: "15vw" }}>FS▶︎Server</Box>
          </DivInline>
          <div className="scroll-container" ref={lightRef}>
            <ViewportList viewportRef={lightRef} items={allLightsArr}>
              {(lightObj, index) => {
                const { serial, description, dtkAdd, zigbeeAdd } =
                  lightObj || {};
                const commType = dtkAdd ? "dtk" : zigbeeAdd ? "zigbee" : "";
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      borderBottom: "1px solid grey",
                      "&:hover": { background: "#1B2631" },
                    }}
                  >
                    <Box sx={{ width: "10vw" }}>{serial}</Box>
                    <Box sx={{ width: "30vw" }}>{description}</Box>
                    <Box sx={{ width: "15vw" }}>{`${commType} ${dtkAdd || zigbeeAdd || ""
                      }`}</Box>
                    <Box sx={{ width: "15vw" }}>
                      <button onClick={() => uploadSerialFS(socket, serial)}>
                        Server▶︎FS
                      </button>
                    </Box>
                    <Box sx={{ width: "15vw" }}>
                      <button
                        onClick={async () =>
                          handleUpdateSingleLightFsToLs(serial)
                        }
                      >
                        FS▶︎Server
                      </button>
                    </Box>
                  </Box>
                );
              }}
            </ViewportList>
          </div>
        </DivExist>
        <DivExist show={display === "sensor"}>
          <DivInline justifyContent="space-between">
            <Typography variant="h6">Sensor</Typography>
            <Button
              variant="outlined"
              onClick={async () => {
                await updateSensorsFsToLs(socket);
                await gs.waitFor(1200);
                await handleFetchAllSensors();
              }}
            >
              All sensors FS ▶︎ Server
            </Button>
            <DivInline>
              <TextField
                label="import one sensor from FS (sensorID)"
                value={importID}
                onChange={(e) => setImportID(e.target.value)}
                size="small"
                sx={{ minWidth: "30vw" }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  let fsObj = await mgPost("getDocFS", {
                    docPath: `SM_sensor/${importID}`,
                  });
                  console.log("fsObj", fsObj);
                  if (fsObj.result !== "fail") {
                    fsObj = { ...fsObj, serverID };
                    await mgUpdateOneUpsert(
                      "sensor",
                      { sensorID: importID },
                      fsObj
                    );
                    await mgPost("updateDocFS", {
                      docPath: `SM_sensor/${importID}`,
                      updateObj: fsObj,
                    });
                  } else {
                    alertWindow(dispatch, "No such sensor in FS");
                  }
                }}
              >
                import
              </Button>
            </DivInline>
          </DivInline>
          <Divider10 />
          <DivInline>
            <Box sx={{ width: "10vw" }}>sensorID</Box>
            <Box sx={{ width: "30vw" }}>sensorName</Box>
            <Box sx={{ width: "15vw" }}>address</Box>
            <Box sx={{ width: "15vw" }}>Server▶︎FS</Box>
            <Box sx={{ width: "15vw" }}>FS▶︎Server</Box>
          </DivInline>
          <div className="scroll-container" ref={sensorRef}>
            <ViewportList viewportRef={sensorRef} items={allSensorsArr}>
              {(sensorObj, index) => {
                const { sensorID, sensorName, dtkAdd, zigbeeAdd } =
                  sensorObj || {};
                const commType = dtkAdd ? "dtk" : zigbeeAdd ? "zigbee" : "";
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      borderBottom: "1px solid grey",
                      "&:hover": { background: "#1B2631" },
                    }}
                  >
                    <Box sx={{ width: "10vw" }}>{sensorID}</Box>
                    <Box sx={{ width: "30vw" }}>{sensorName}</Box>
                    <Box sx={{ width: "15vw" }}>{`${commType} ${dtkAdd || zigbeeAdd || ""
                      }`}</Box>
                    <Box sx={{ width: "15vw" }}>
                      <button onClick={() => uploadSensorFS(socket, sensorID)}>
                        Server▶︎FS
                      </button>
                    </Box>
                    <Box sx={{ width: "15vw" }}>
                      <button
                        onClick={async () =>
                          handleUpdateSingleSensorFsToLs(sensorID)
                        }
                      >
                        FS▶︎Server
                      </button>
                    </Box>
                  </Box>
                );
              }}
            </ViewportList>
          </div>
        </DivExist>
        <DivExist show={display === "zoneControl"}>
          <DivInline justifyContent="space-between">
            <Typography variant="h6">Zone Control</Typography>
            <Button
              variant="outlined"
              onClick={async () => {
                await updateZcsFsToLs();
                await gs.waitFor(1200);
                await handleFetchAllZcs();
              }}
            >
              All zone controls FS ▶︎ Server
            </Button>
            <DivInline>
              <TextField
                label="import one zone control from FS (zoneControlID)"
                value={importID}
                onChange={(e) => setImportID(e.target.value)}
                size="small"
                sx={{ minWidth: "30vw" }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  let fsObj = await mgPost("getDocFS", {
                    docPath: `SM_zoneControl/${importID}`,
                  });
                  console.log("fsObj", fsObj);
                  if (fsObj.result !== "fail") {
                    fsObj = { ...fsObj, serverID };
                    await mgUpdateOneUpsert(
                      "zoneControl",
                      { zoneControlID: importID },
                      fsObj
                    );
                    await mgPost("updateDocFS", {
                      docPath: `SM_zoneControl/${importID}`,
                      updateObj: fsObj,
                    });
                  } else {
                    alertWindow(dispatch, "No such zone control in FS");
                  }
                }}
              >
                import
              </Button>
            </DivInline>
          </DivInline>
          <Divider10 />

          <Table>
            <TableBody>
              <TableRow>
                <TableCell>zoneControlID</TableCell>
                <TableCell>description</TableCell>
                <TableCell>Server▶︎FS</TableCell>
                <TableCell>FS▶︎Server</TableCell>
              </TableRow>

              {Object.keys(allZcs || {}).map((zid, key) => {
                const zcObj = allZcs[zid];
                return (
                  <TableRow key={key}>
                    <TableCell>{zid}</TableCell>
                    <TableCell>{zcObj.zoneControlName}</TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => uploadZcFS(socket, zid)}
                      >
                        Server▶︎FS
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={async () => handleUpdateSingleZcFsToLs(zid)}
                      >
                        FS▶︎Server
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DivExist>
        <DivExist show={display === "daliCtl"}>
          <DivInline justifyContent="space-between">
            <Typography variant="h6">Dali Control</Typography>
            <Button
              variant="outlined"
              onClick={async () => {
                await updateDaliCtlsFsToLs(socket);
                await gs.waitFor(1200);
                await handleFetchAllDaliCtls();
              }}
            >
              All dali controls FS ▶︎ Server
            </Button>
            <DivInline>
              <TextField
                label="import one dali control from FS (daliCtlID)"
                value={importID}
                onChange={(e) => setImportID(e.target.value)}
                size="small"
                sx={{ minWidth: "30vw" }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  let fsObj = await mgPost("getDocFS", {
                    docPath: `SM_daliCtl/${importID}`,
                  });
                  console.log("fsObj", fsObj);

                  if (fsObj.result !== "fail") {
                    fsObj = { ...fsObj, serverID };
                    await mgUpdateOneUpsert(
                      "daliCtl",
                      { daliCtlID: importID },
                      fsObj
                    );
                    await mgPost("updateDocFS", {
                      docPath: `SM_daliCtl/${importID}`,
                      updateObj: fsObj,
                    });
                  } else {
                    alertWindow(dispatch, "No such dali control in FS");
                  }
                }}
              >
                import
              </Button>
            </DivInline>
          </DivInline>
          <Divider10 />

          <Table>
            <TableBody>
              <TableRow>
                <TableCell>daliCtlID</TableCell>
                <TableCell>description</TableCell>
                <TableCell>Server▶︎FS</TableCell>
                <TableCell>FS▶︎Server</TableCell>
              </TableRow>

              {Object.keys(allDaliCtls || {}).map((zid, key) => {
                const zcObj = allDaliCtls[zid];
                return (
                  <TableRow key={key}>
                    <TableCell>{zid}</TableCell>
                    <TableCell>{zcObj.description}</TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => uploadDaliCtlFS(socket, zid)}
                      >
                        Server▶︎FS
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={async () =>
                          handleUpdateSingleDaliCtlFsToLs(zid)
                        }
                      >
                        FS▶︎Server
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DivExist>
        <DivExist show={display === "serverLog"}>
          <ServerLog />
        </DivExist>
        <DivExist show={display === "serverConsoleLog"}>
          <ServerConsoleLog />
        </DivExist>
      </ModalSM>
    </>
  );
}
