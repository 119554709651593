import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, TextField, MenuItem } from "@mui/material";
import DivInline from "components/DivInline";
import {
  addSingleSchedule,
  getReportTypeAndTestTime,
} from "actions/scheduleActions";
import DivSpaceBetween from "components/DivSpaceBetween";
import moment from "moment-timezone";
import { hourMenu, minuteMenu } from "asset/string/string";
import Divider10 from "components/Divider10";
import ModalSM from "components/ModalSM";
import General from "@ecoenghk/general";
const gs = new General();

export default function AddSingleScheduleModal({ open, handleClose, serial }) {
  const [state] = useContext(SMContext);
  const { lightObjAll, serverObj } = state;
  const [testNo, setTestNo] = useState(0);
  const [testDate, setTestDate] = useState(moment().format("YYYY-MM-DD"));
  const [testHour, setTestHour] = useState(0);
  const [testMin, setTestMin] = useState(0);
  const [rescheduleCount, setRescheduleCount] = useState(0);
  const [scheduleYear, setScheduleYear] = useState(moment().year());
  const [scheduleMonth, setScheduleMonth] = useState(1);
  const { batteryHour } = lightObjAll[serial] || {};
  const {
    reportType,
    time_interval,
    time_pretest,
    time_cutOff,
    time_recharge,
  } = getReportTypeAndTestTime("ed5", batteryHour, Number(testNo));
  const handleAdd = async () => {
    await addSingleSchedule(
      serverObj,
      serial,
      lightObjAll,
      testNo,
      testDate,
      testHour,
      testMin,
      rescheduleCount,
      scheduleYear,
      scheduleMonth
    );
    handleClose();
  };

  return (
    <ModalSM
      open={open}
      onClose={handleClose}
      minWidth="60vw"
      minHeight="88vh"
      maxHeight="88vh"
      modalTitle={`Add schedule for [${serial}]`}
      onAdd={handleAdd}
    >
      <DivSpaceBetween>
        <Typography>Test No</Typography>
        <TextField
          select
          value={testNo}
          onChange={(e) => setTestNo(e.target.value)}
          size="small"
        >
          {[...Array(12).keys()].map((n) => (
            <MenuItem key={n} value={n}>
              {n}
            </MenuItem>
          ))}
        </TextField>
      </DivSpaceBetween>

      <Divider10 />
      <DivSpaceBetween>
        <Typography>Test Date</Typography>
        <TextField
          size="small"
          type="date"
          value={testDate}
          onChange={(e) => setTestDate(e.target.value)}
        />
      </DivSpaceBetween>
      <Divider10 />
      <DivSpaceBetween>
        <Typography>Test Time</Typography>
        <DivInline>
          <TextField
            size="small"
            select
            value={testHour}
            onChange={(e) => setTestHour(e.target.value)}
          >
            {hourMenu.map((h) => (
              <MenuItem key={h} value={h}>
                {h < 10 ? "0" + h : h}
              </MenuItem>
            ))}
          </TextField>
          <Typography sx={{ margin: "1vw" }} variant="h5">
            :
          </Typography>
          <TextField
            size="small"
            select
            value={testMin}
            onChange={(e) => setTestMin(e.target.value)}
          >
            {Object.keys(minuteMenu).map((m) => (
              <MenuItem key={m} value={m}>
                {m < 10 ? "0" + m : m}
              </MenuItem>
            ))}
          </TextField>
        </DivInline>
      </DivSpaceBetween>

      <Divider10 />
      <DivSpaceBetween>
        <Typography>Reschedule count</Typography>
        <TextField
          size="small"
          select
          value={rescheduleCount}
          onChange={(e) => setRescheduleCount(e.target.value)}
        >
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
        </TextField>
      </DivSpaceBetween>
      <Divider10 />

      <DivSpaceBetween>
        <Typography>ScheduleYear</Typography>
        <TextField
          size="small"
          select
          value={scheduleYear}
          onChange={(e) => setScheduleYear(e.target.value)}
        >
          {gs
            .newArrayBetween(
              moment().subtract(1, "years").year(),
              moment().add(5, "years").year()
            )
            .map((y) => (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            ))}
        </TextField>
      </DivSpaceBetween>
      <Divider10 />
      <DivSpaceBetween>
        <Typography>ScheduleMonth</Typography>
        <TextField
          size="small"
          select
          value={scheduleMonth}
          onChange={(e) => setScheduleMonth(e.target.value)}
        >
          {gs.newArrayBetween(1, 12).map((m) => (
            <MenuItem key={m} value={m}>
              {m}
            </MenuItem>
          ))}
        </TextField>
      </DivSpaceBetween>
      <Divider10 />
      <Typography display="block" variant="caption">
        Battery hour: {batteryHour + " hours"}
      </Typography>
      <Typography display="block" variant="caption">
        Report type: {reportType}
      </Typography>
      <Typography display="block" variant="caption">
        Time interval: {time_interval}second
      </Typography>
      <Typography display="block" variant="caption">
        Time pretest: {time_pretest}min
      </Typography>
      <Typography display="block" variant="caption">
        Time cut off: {time_cutOff}min
      </Typography>
      <Typography display="block" variant="caption">
        Time recharge: {time_recharge}min
      </Typography>
    </ModalSM>
  );
}
