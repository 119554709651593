import { useState, useEffect, useContext } from "react";
import { SMContext } from "context/smContext";
import { Workspaces } from "@mui/icons-material";
import { Typography, Tooltip } from "@mui/material";
import Hover from "./Hover";
import { gatewayGroupActionCheck } from "actions/gatewayActions";
import General from "@ecoenghk/general";
import { dtkCmdIncludeDaliLight } from "actions/daliCtlActions";
const gs = new General();
export default function GroupActionConfirmOneDevice({ deviceObj, controlledDeviceObj, settingNumArray, size = "1rem" }) {
    const [state, dispatch] = useContext(SMContext);
    const { daliCtlObjAll } = state;
    const [confirmed, setConfirmed] = useState(true);
    const [msg, setMsg] = useState("");
    const { enableGroupAction } = deviceObj || {};
    let groupID, groupDeviceType;
    if (deviceObj.zoneControlID) {
        groupID = deviceObj.zoneControlID.replace("zc_", "");
        groupDeviceType = "zoneControl";
    } else {
        groupID = deviceObj.sensorID;
        groupDeviceType = "sensor";
    }
    const controlledDeviceID = controlledDeviceObj?.serial || controlledDeviceObj?.sensorID;
    const controlledDeviceDaliCtlID = controlledDeviceObj?.daliCtlID;
    useEffect(() => {
        let allConfirmed = true;
        let tempMsg = `Setting- `;
        settingNumArray.forEach((settingNum, key) => {
            let setConfirm;
            const GAS_set = deviceObj[`GAS_setting${settingNum}`];
            if (controlledDeviceDaliCtlID) {
                const daliCtlObj = daliCtlObjAll[controlledDeviceDaliCtlID];
                const gasSettingObj = GAS_set?.[controlledDeviceDaliCtlID];
                let commandHasDaliLight = dtkCmdIncludeDaliLight(gasSettingObj?.command, daliCtlObj, controlledDeviceObj);
                setConfirm = gasSettingObj?.confirmed && commandHasDaliLight;
            } else {
                setConfirm = GAS_set?.[controlledDeviceID]?.confirmed || false;
            }
            tempMsg += `${settingNum}:${setConfirm?.toString()}, `;
            if (setConfirm === false || setConfirm === undefined) allConfirmed = false;
        });
        setConfirmed(allConfirmed);
        setMsg(tempMsg);
    }, [settingNumArray, deviceObj, controlledDeviceObj]);
    const setConfirmToFalse = async () => {
        let newDeviceObj = { ...deviceObj };
        settingNumArray.forEach(settingNum => {
            let GAS_set = newDeviceObj[`GAS_setting${settingNum}`];
            if (GAS_set) {
                if (controlledDeviceDaliCtlID) {
                    if (GAS_set[controlledDeviceDaliCtlID]) GAS_set[controlledDeviceDaliCtlID].confirmed = false;
                } else {
                    if (GAS_set[controlledDeviceID]) GAS_set[controlledDeviceID].confirmed = false;
                }
                newDeviceObj[`GAS_setting${settingNum}`] = GAS_set;
            }
        });
        if (groupDeviceType === "zoneControl") dispatch({ type: "UPDATED_ZONE_CONTROL_OBJ", payload: newDeviceObj });
        else dispatch({ type: "UPDATE_SENSOR_OBJ", payload: newDeviceObj });
    };
    const testSettingNum = settingNumArray.length > 1 ? 99 : settingNumArray[0];
    if (!enableGroupAction) return null;
    return (
        <>

            <Hover>
                <Tooltip title={msg}>
                    <Workspaces
                        color={confirmed ? "success" : "error"}
                        sx={{ fontSize: size }}
                        onClick={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            await gs.waitFor(500);
                            setConfirmToFalse();
                            await gatewayGroupActionCheck(controlledDeviceObj.gatewayID, groupID, controlledDeviceID, testSettingNum);
                        }}
                    />
                </Tooltip>
            </Hover>

        </>
    )
}