import { useContext } from "react";
import { SMContext } from "context/smContext";
// import { Typography, IconButton, Paper } from "@mui/material";
import SensorIcon from "components/SensorIcon";
import SensorInfoPanel from "./SensorInfoPanel";
import SensorDataLogPanel from "./SensorDataLogPanel";
import ControlDevicesMotionPanel from "./ControlDevicesMotionPanel";
import SensorStatusPanel from "./SensorStatusPanel";
import SensorDetailMotionDelayPanel from "./SensorDetailMotionDelayPanel";
import ControlDevicesLightSwPanel from "./ControlDevicesLightSwPanel";
import ControlDevicesDaylightPanel from "containers/sensor/ControlDevicesDaylightPanel";
import DivInline from "components/DivInline";
import ControlDevicesSceneButton from "./ControlDevicesSceneButton";
import ControlDevicesDaylightMotionPanel from "./ControlDevicesDaylightMotionPanel";
import ModalSM from "components/ModalSM";
import DivExist from "components/DivExist";
import SensorDetailSceneBtnDelayPanel from "./SensorDetailSceneBtnDelayPanel";
import DeviceMongoModal from "components/DeviceMongoModal";
import ManualControlPanelLightSwitch from "./ManualControlPanelLightSwitch";
import ManualControlPanelSceneButton from "./ManualControlPanelSceneButton";
import Divider10 from "components/Divider10";
import ButtonSM from "components/ButtonSM";
import NearFieldIcon from "asset/svgComp/NearFieldIcon";
import { Delete } from "@mui/icons-material";
import { sensorDeleteAllNearFieldSetting } from "actions/sensorActions";
import { confirmWindow } from "actions/screenActions";
import ControlDevicesDimControllerPanel from "./ControlDevicesDimControllerPanel";
import SensorChangeDimStepPanel from "./SensorChangeDimStepPanel";

const boxSx = {
  padding: "0.5vw",
  border: "0.5px solid grey",
  borderRadius: "1vw",
};

export default function SensorDetailModal() {
  const [state, dispatch] = useContext(SMContext);
  const { activeSensorID, sensorObjAll, gatewayObjAll, openSensorDetailModal } =
    state;
  const sensorObj = sensorObjAll[activeSensorID];
  const { sensorName, type, gatewayID } = sensorObj || {};
  const handleClose = () => {
    dispatch({ type: "CLOSED_SENSOR_DETAIL_MODAL" });
  };
  return (
    <ModalSM
      open={openSensorDetailModal}
      onClose={handleClose}
      width="97vw"
      height="96vh"
      disableBottomClose
      modalTitle={`${sensorName} [${activeSensorID}]`}
      modalIcon={
        <DeviceMongoModal deviceObj={sensorObj}>
          <SensorIcon
            sensorObj={sensorObj}
            width={3.5}
            sizeUnit="vw"
            margin={0}
            gatewayConnected={gatewayObjAll[gatewayID]?.gatewayConnected}
            backgroundColor="white"
          />
        </DeviceMongoModal>
      }
    >
      <DivInline>
        <div style={{ width: "32vw", height: "82vh", overflowY: "auto", ...boxSx }}>
          <SensorInfoPanel />
        </div>
        <div style={{ display: "flex", flexDirection: "column", height: "82vh", width: "32vw", overflowY: "auto", ...boxSx }}>
          <div >
            <SensorStatusPanel />
          </div>
          <DivExist show={type === "motion" || type === "daylightmotion"}>
            <SensorDetailMotionDelayPanel />
          </DivExist>
          <DivExist show={type === "scenebutton"}>
            <SensorDetailSceneBtnDelayPanel />
            <ManualControlPanelSceneButton />
          </DivExist>
          <DivExist show={type === "lightswitch"}>
            <ManualControlPanelLightSwitch />
          </DivExist>
          <DivExist show={type === 'dimcontroller'}>
            <SensorChangeDimStepPanel />
          </DivExist>
          <SensorDataLogPanel />
        </div>
        <div
          style={{
            width: "30vw",
            height: "82vh",
            overflowY: "auto",
            ...boxSx,
          }}
        >
          <ControlDevicesMotionPanel show={type === "motion"} />
          <ControlDevicesDaylightPanel show={type === "daylight"} />
          <ControlDevicesDaylightMotionPanel show={type === "daylightmotion"} />
          <ControlDevicesLightSwPanel
            show={type === "lightswitch" || type === "fire"}
          />
          <ControlDevicesSceneButton show={type === "scenebutton"} />
          <ControlDevicesDimControllerPanel show={type === 'dimcontroller'} />
          <Divider10 />
          <ButtonSM
            tooltip="Delete all near field settings"
            onClick={() => {
              confirmWindow(dispatch, "Delete all near field settings?", () => {
                sensorDeleteAllNearFieldSetting(sensorObj);
              });
            }} >
            <NearFieldIcon width={2} sizeUnit={'vw'} />
            <Delete />
          </ButtonSM>
        </div>
      </DivInline>
    </ModalSM>
  );
}
