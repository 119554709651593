import { mgPost } from "./mongoApiActions";
import General from "@ecoenghk/general";
const gs = new General();
export const uploadServerFS = async (serverID) => {
  const serverBody = {
    col: "server",
    query: { serverID: serverID },
    fsCol: "SM_server",
    fsDocKey: "serverID",
  };
  const res = await mgPost("mgUploadFs", serverBody);
  return res;
};

export const uploadFsMapAndDevices = async (serverID, mapID, mapObj) => {
  const { gatewayObj, lightObj, sensorObj } = mapObj;
  const gatewayArr = Object.keys(gatewayObj || {});
  const lightArr = Object.keys(lightObj || {});
  const sensorArr = Object.keys(sensorObj || {});
  const mapBody = {
    col: "map",
    query: { mapID: mapID },
    fsCol: "SM_map",
    fsDocKey: "mapID",
  };
  await mgPost("mgUploadFs", mapBody);
  if (gatewayArr.length > 0) {
    await gs.waitFor(200);
    const gwBody = {
      col: "gateway",
      query: { gatewayID: { $in: gatewayArr } },
      fsCol: "SM_gateway",
      fsDocKey: "gatewayID",
    };
    await mgPost("mgUploadFs", gwBody);
  }
  if (lightArr.length > 0) {
    await gs.waitFor(200);
    const lightBody = {
      col: "serial",
      query: { serial: { $in: lightArr } },
      fsCol: "SM_serial",
      fsDocKey: "serial",
    };
    await mgPost("mgUploadFs", lightBody);
  }
  if (sensorArr.length > 0) {
    await gs.waitFor(200);
    const sensorBody = {
      col: "sensor",
      query: { sensorID: { $in: sensorArr } },
      fsCol: "SM_sensor",
      fsDocKey: "sensorID",
    };
    await mgPost("mgUploadFs", sensorBody);
  }
  await gs.waitFor(200);
  await uploadServerFS(serverID);
};

export const uploadMapFS = async (mapID) => {
  const serverBody = {
    col: "map",
    query: { mapID: mapID },
    fsCol: "SM_map",
    fsDocKey: "mapID",
  };
  const res = await mgPost("mgUploadFs", serverBody);
  return res;
};

export const uploadUserFS = async (socket, uid) => {
  const jsonData = {
    type: "uploadMgToFs",
    col: "user",
    query: { uid },
    fsDocPath: `SM_user/${uid}`,
  };
  await socket.emit("fromWeb", jsonData);
};
export const uploadSerialArrayFS = async (serialArr) => {
  const serverBody = {
    col: "serial",
    query: { serial: { $in: serialArr } },
    fsCol: "SM_serial",
    fsDocKey: "serial",
  };
  const res = await mgPost("mgUploadFs", serverBody);
  return res;
};
export const uploadSerialFS = async (socket, serial) => {
  const jsonData = {
    type: "uploadMgToFs",
    col: "serial",
    query: { serial },
    fsDocPath: `SM_serial/${serial}`,
  };
  await socket.emit("fromWeb", jsonData);
};
export const uploadGatewayFS = async (socket, gatewayID) => {
  const jsonData = {
    type: "uploadMgToFs",
    col: "gateway",
    query: { gatewayID },
    fsDocPath: `SM_gateway/${gatewayID}`,
  };
  await socket.emit("fromWeb", jsonData);
};
export const uploadSensorFS = async (socket, sensorID) => {
  const jsonData = {
    type: "uploadMgToFs",
    col: "sensor",
    query: { sensorID },
    fsDocPath: `SM_sensor/${sensorID}`,
  };
  await socket.emit("fromWeb", jsonData);
};
export const uploadZcFS = async (socket, zoneControlID) => {
  const jsonData = {
    type: "uploadMgToFs",
    col: "zoneControl",
    query: { zoneControlID },
    fsDocPath: `SM_zoneControl/${zoneControlID}`,
  };
  await socket.emit("fromWeb", jsonData);
};
export const uploadDaliCtlFS = async (socket, daliCtlID) => {
  const jsonData = {
    type: "uploadMgToFs",
    col: "daliCtl",
    query: { daliCtlID },
    fsDocPath: `SM_daliCtl/${daliCtlID}`,
  };
  await socket.emit("fromWeb", jsonData);
};
export const uploadTimeTableBySerialFS = async (serial) => {
  const serverBody = {
    col: "timeTable",
    query: { serial },
    fsCol: "SM_timeTable",
    fsDocKey: "scheduleID",
  };
  const res = await mgPost("mgUploadFs", serverBody);
  return res;
};
export const uploadTimeTableFS = async (timeTableID) => {
  const serverBody = {
    col: "timeTable",
    query: { timeTableID },
    fsCol: "SM_timeTable",
    fsDocKey: "timeTableID",
  };
  const res = await mgPost("mgUploadFs", serverBody);
  return res;
};
export const uploadTimeTableByZoneControlFS = async (timeTableIDArr) => {
  const serverBody = {
    col: "timeTable",
    query: { scheduleID: { $in: timeTableIDArr } },
    fsCol: "SM_timeTable",
    fsDocKey: "scheduleID",
  };
  console.log("uploadTimeTableByZoneControlFS", serverBody);
  const res = await mgPost("mgUploadFs", serverBody);
  return res;
};
