import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button } from "@mui/material";
import DivExist from "components/DivExist";
import { titleText, dataText } from "asset/string/color";
import DivInline from "components/DivInline";
import CurrentWithPolarity from "components/CurrentWithPolarity";
import LightIcon from "components/LightIcon";
import ThemeContainer from "components/ThemeContainer";
import { tspToDateTime } from "actions/generalActions";
import General from "@ecoenghk/general";
import { mapAddLight, mapRemoveLight } from "actions/mapActions";
import { mgFindOne } from "actions/mongoApiActions";
import { getDocFS } from "actions/firebaseMgActions";
import { alertWindow } from "actions/screenActions";
import DeviceMongoModal from "components/DeviceMongoModal";
const gs = new General();

export default function SelectedLightInfo() {
  const [state, dispatch] = useContext(SMContext);
  const {
    selectedID,
    lightObjAll,
    gatewayObjAll,
    activeMapID,
    timeTableArrAll,
    serverObj,
    mapObjAll,
    daliCtlObjAll,
  } = state;
  const lightObj = lightObjAll[selectedID];
  const {
    serial,
    description,
    gatewayID,
    currentData,
    type,
    daliCtlID,
    timeStamp,
    serverID,
    lockLevel,
    dtkAdd,
    masterSerial,
    shortAdd,
  } = lightObj || {};
  const { voltage, onoffstatusL } = currentData || {};
  let lightLv = currentData?.lightLv || 0;
  let onoffstatusA = currentData?.onoffstatusA;
  const daliCtlObj = daliCtlObjAll[daliCtlID];
  if (daliCtlID) {
    if (!daliCtlObj?.powerOnOffStatus) lightLv = 0;
  }
  if (lightLv === 0) onoffstatusA = 1;
  const gatewayName = gatewayObjAll?.[gatewayID]?.description;
  const unknownDevice = Object.keys(lightObj || {}).length === 0 ? true : false;
  const handleAddToMap = async () => {
    let lightObj = await mgFindOne("serial", "serial", selectedID);
    if (!lightObj && serverObj.enableUploadFS)
      lightObj = await getDocFS(`SM_serial/${selectedID}`);
    if (!lightObj) {
      alertWindow(dispatch, `Light ${selectedID} not found in local db and fs`);
      return;
    }
    const { x, y } = mapObjAll[activeMapID]?.lightObj || {};
    await mapAddLight(activeMapID, selectedID, lightObj, x, y);
  };

  let monthTestTimeTableObj = {};
  timeTableArrAll.forEach((obj) => {
    if (obj.serial === selectedID && obj.reportType === "check_1month") {
      const { date, hour, minute } = obj || {};
      const monthTestTime = `Day${date} each month, ${hour}:${minute}`;
      const newObj = { ...obj, monthTestTime };
      monthTestTimeTableObj = newObj;
    }
  });

  let yearTestTimeTableObj = {};
  timeTableArrAll.forEach((obj) => {
    if (obj.serial === selectedID && obj.reportType === "check_12month") {
      const { month, date, hour, minute } = obj || {};
      const yearTestTime = `${date}/${month} , ${hour}:${minute}`;
      const newObj = { ...obj, yearTestTime };
      yearTestTimeTableObj = newObj;
    }
  });

  return (
    <>
      <DivInline justifyContent="center">
        <DeviceMongoModal deviceObj={lightObj}>
          <LightIcon
            width={8}
            sizeUnit="vw"
            lightObj={lightObj}
            daliCtlObj={daliCtlObj}
            gatewayConnected={gatewayObjAll?.[gatewayID]?.gatewayConnected}
            disabledConnectStatus={masterSerial ? true : false}
          />
        </DeviceMongoModal>
      </DivInline>
      <DataSet title="Light ID" data={serial} />
      <DivExist show={!unknownDevice}>
        <Typography style={{ color: dataText }} align="center">
          {description}
        </Typography>
        <InfoDivider />
        <DivExist show={!masterSerial}>
          <DataSet
            title="Connected to gateway:"
            data={
              gatewayID
                ? `${gatewayID}\n${gatewayName}`
                : "Not connected to any gateway"
            }
          />
          <DataSet title="Server ID:" data={serverID} hide={!serverID} />
          {
            lightObj.dtkChannel > 0 ?
              <DataSet title="Dtk channel" data={lightObj.dtkChannel} />
              :
              <DataSet title="Mesh channel" data={lightObj.meshChannel} />
          }
          <DataSet title="Lock level" data={lockLevel === 100 ? 5 : lockLevel} />
          <DataSet title="Address" data={dtkAdd} hide={daliCtlID} />
          <DataSet title="Dali controller ID" data={daliCtlID} hide={!daliCtlID} />
          <DataSet title="Dali address" data={shortAdd} hide={!daliCtlID} />
          <DataSet title="On off status" data={onoffstatusA ? "Off" : "On"} hide={!type?.A || daliCtlID} />
          <DataSet title="Light level" data={`${lightLv%10==9?lightLv+1:lightLv}%`} hide={!type?.pwm} />
          {
            daliCtlID && !daliCtlObj?.powerOnOffStatus &&
            <>
              <Typography
                variant="caption"
                display="block"
                align="center"
                style={{ color: titleText }}
              >
                Dali master cut off light power
              </Typography>
              <hr color="lightgrey" size={1} width="90%" />
            </>
          }
          <DivExist show={type?.batVI}>
            <Typography
              variant="caption"
              display="block"
              align="center"
              style={{ color: titleText }}
            >
              Emergency battery status
            </Typography>
            <DivInline justifyContent="space-around" style={{ width: "100%" }}>
              <Typography
                style={{ color: dataText }}
                display="block"
                align="center"
                variant="caption"
              >
                {`${voltage}V`}
              </Typography>
              <CurrentWithPolarity
                iconSize={20}
                darkLight="dark"
                variant="caption"
                style={{
                  color: dataText,
                }}
                serial={selectedID}
              />
            </DivInline>

            <hr color="lightgrey" size={1} width="90%" />
            <DataSet
              title="Month test time"
              data={monthTestTimeTableObj?.monthTestTime}
            />
            <DataSet
              title="Annual test time"
              data={yearTestTimeTableObj?.yearTestTime}
            />
          </DivExist>
          <DataSet title="Latest data time" data={tspToDateTime(timeStamp)} hide={masterSerial} />
          <DataSet title="Version" data={lightObj?.deviceVersion} hide={masterSerial || daliCtlID} />
        </DivExist>
      </DivExist>
      <DivExist show={unknownDevice}>
        <Typography sx={{ color: dataText }} align="center">
          Unknown light
        </Typography>
        <Typography sx={{ color: dataText }} align="center">
          {selectedID}
        </Typography>
        <InfoDivider />
        <Button
          sx={{ margin: "0.5vh" }}
          size="small"
          variant="outlined"
          onClick={handleAddToMap}
        >
          Add to map
        </Button>
        <InfoDivider />
        <Button
          sx={{ margin: "0.5vh" }}
          size="small"
          variant="outlined"
          onClick={async () => mapRemoveLight(activeMapID, selectedID)}
        >
          Remove from map
        </Button>
      </DivExist>
    </>
  );
}

function DataSet({ title, data, hide }) {
  if (hide) return null;
  return (
    <>
      <Typography
        variant="caption"
        display="block"
        align="center"
        style={{ color: titleText }}
      >
        {title}
      </Typography>
      <Typography
        style={{ color: dataText }}
        display="block"
        align="center"
        variant="caption"
      >
        {data}
      </Typography>
      <hr color="lightgrey" size={1} width="90%" />
    </>
  );
}

function InfoDivider() {
  return <hr color="lightgrey" size={1} width="90%" />;
}
