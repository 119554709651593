import { useState, useContext, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Typography, TextField, MenuItem } from "@mui/material";
import DivInline from "components/DivInline";
import {
  deleteSingleSchedule,
  editSingleSchedule,
  getReportTypeAndTestTime,
} from "actions/scheduleActions";
import DivSpaceBetween from "components/DivSpaceBetween";
import moment from "moment-timezone";
import { hourMenu, minuteMenu } from "asset/string/string";
import Divider10 from "components/Divider10";
import ModalSM from "components/ModalSM";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import IconButtonClose from "components/IconButtonClose";
import IconButtonDelete from "components/IconButtonDelete";
import JsonModal from "components/JsonModal";
import AdminContainer from "components/AdminContainer";
import { confirmWindow, openSnackbar } from "actions/screenActions";
import IconButtonSave from "components/IconButtonSave";
export default function EditSingleScheduleModal({
  open,
  handleClose,
  serial,
  schObj,
}) {
  const [state, dispatch] = useContext(SMContext);
  const { lightObjAll, serverObj } = state;
  const [testDay, setTestDay] = useState(1);
  const [testHour, setTestHour] = useState(0);
  const [testMin, setTestMin] = useState(0);
  const { batteryHour } = lightObjAll[serial] || {};
  useEffect(() => {
    const m = moment(schObj.schedule_timeStamp);
    setTestHour(m.hour());
    setTestMin(m.minute());
    setTestDay(m.date());
  }, [open, schObj]);
  const {
    scheduleID,
    no,
    reportType,
    time_interval,
    time_pretest,
    time_cutOff,
    time_recharge,
    scheduleMonth,
    scheduleYear,
    rescheduleCount,
  } = schObj;
  const handleEdit = async () => {
    const newTestM = moment(schObj.schedule_timeStamp)
      .date(testDay)
      .hour(testHour)
      .minute(testMin);
    await editSingleSchedule(serverObj, scheduleID, newTestM);
    handleClose();
  };
  const testM = moment(schObj.schedule_timeStamp);
  const handleDelete = async () => {
    confirmWindow(dispatch, "Confirm delete this schedule?", async () => {
      // const query = { serial: serial, scheduleID: schObj.scheduleID };
      // await mgDeleteMany("schedule_oneTime", query);
      await deleteSingleSchedule(serverObj, scheduleID);
      handleClose();
      openSnackbar(dispatch, "Schedule deleted");
    });
  };
  return (
    <ModalSM
      open={open}
      onClose={handleClose}
      minWidth="60vw"
      minHeight="88vh"
      maxHeight="88vh"
      modalTitle={`Edit single schedule for [${serial}]`}
      disableBottomClose
    >
      <SpaceBetweenDiv title="Test No" data={no} />
      <SpaceBetweenDiv title="Report type" data={reportType} />

      <DivSpaceBetween>
        <Typography>Test Date</Typography>
        <DivInline>
          <TextField
            select
            size="small"
            value={testDay}
            onChange={(e) => setTestDay(e.target.value)}
          >
            {[...Array(testM.daysInMonth()).keys()].map((n) => (
              <MenuItem key={n} value={n + 1}>
                {n + 1}
              </MenuItem>
            ))}
          </TextField>
          <Typography>
            -{testM.month() + 1}-{testM.year()}
          </Typography>
        </DivInline>
      </DivSpaceBetween>
      <Divider10 />
      <DivSpaceBetween>
        <Typography>Test Time</Typography>
        <DivInline>
          <TextField
            size="small"
            select
            value={testHour}
            onChange={(e) => setTestHour(e.target.value)}
          >
            {hourMenu.map((h) => (
              <MenuItem key={h} value={h}>
                {h < 10 ? "0" + h : h}
              </MenuItem>
            ))}
          </TextField>
          <Typography sx={{ margin: "1vw" }} variant="h5">
            :
          </Typography>
          <TextField
            size="small"
            select
            value={testMin}
            onChange={(e) => setTestMin(e.target.value)}
          >
            {Object.keys(minuteMenu).map((m) => (
              <MenuItem key={m} value={m}>
                {m < 10 ? "0" + m : m}
              </MenuItem>
            ))}
          </TextField>
        </DivInline>
      </DivSpaceBetween>
      <Divider10 />
      <SpaceBetweenDiv title="Reschedule count" data={rescheduleCount} />
      <SpaceBetweenDiv title="Schedule Year" data={scheduleYear} />
      <SpaceBetweenDiv title="Schedule Month" data={scheduleMonth} />
      <Divider10 />
      <SpaceBetweenDiv title="Battery hour" data={batteryHour + " hours"} />
      <SpaceBetweenDiv
        title="Data upload interval"
        data={time_interval + "second"}
      />
      <SpaceBetweenDiv title="Time pretest" data={time_pretest + "min"} />
      <SpaceBetweenDiv title="Time cut off" data={time_cutOff + "min"} />
      <SpaceBetweenDiv title="Time recharge" data={time_recharge + "min"} />
      <Divider10 />
      <SpaceBetweenDiv title="Schedule ID" data={scheduleID} />

      <Divider10 />
      <DivInline justifyContent="flex-end">
        <AdminContainer>
          <JsonModal jsonData={schObj} show={true} btnContent="Open json" />
        </AdminContainer>
        <IconButtonClose onBtnClick={handleClose} />
        <IconButtonSave onBtnClick={handleEdit} />
        <IconButtonDelete onBtnClick={handleDelete} color="error" />
      </DivInline>
    </ModalSM>
  );
}
