import { sensorTypeOf } from "asset/string/string";
import { getDocsWhereFS } from "./firebaseMgActions";
import {
  mgFindOne,
  mgUpdateOneUpsert,
  mgFindAll,
  mgPost,
  mgFindByMatchAndRange,
} from "./mongoApiActions";
import General from "@ecoenghk/general";
import { type } from "@testing-library/user-event/dist/type";
const gs = new General();

export const fetchOneSensor = async (dispatch, sensorID) => {
  const obj = await mgFindOne("sensor", "sensorID", sensorID);
  dispatch({
    type: "UPDATE_SENSOR_OBJ",
    payload: obj,
  });
  return obj;
};
export const fetchAllSensors = async () => {
  const data = await mgFindAll("sensor");
  console.log(data);
  let objAll = {};
  data.forEach((obj) => {
    const s = obj.sensorID;
    objAll[s] = obj;
  });
  return objAll;
};
export const fetchSensorHistory = async (sensorID, startTsp, endTsp) => {
  const arr = await mgFindByMatchAndRange(
    "deviceLogs",
    "deviceID",
    sensorID,
    "timeStamp",
    endTsp,
    startTsp
  );
  return arr;
};
export const updateSensorsFsToLs = async (socket) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "sensor",
  };
  await socket.emit("fromWeb", payload);
};
export const updateSingleSensorFsToLs = async (socket, sensorID) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "singleSensor",
    sensorID,
  };
  await socket.emit("fromWeb", payload);
};
export const updateSensorProperty = async (
  sensorID,
  sensorObj,
  gatewayID,
  updateObj
) => {
  const jsonData = {
    sensorID,
    gatewayID,
    updateObj,
    sensorObj,
    updateSetting: true,
    type: "updateSensorProperty",
  };
  console.log("[SOCKET OUT] updateSensorProperty", jsonData);
  global.socket.emit("fromWeb", jsonData);
};
// export const updateSensorType = async (
//   sensorID,
//   sensorObj,
//   sensorType,
//   sensorTypeName
// ) => {
//   //zigbee use only, dtk use changeSensorOneSetting
//   const payload = {
//     type: "updateSensorType",
//     sensorID,
//     sensorObj,
//     sensorType,
//     sensorTypeName,
//   };
//   console.log(
//     `[SOCKET OUT] updateSensorType ${sensorID} type [${sensorType}] ${sensorTypeName}`
//   );
//   global.socket.emit("fromWeb", payload);
// };
/**
 *
 * @param {object} sensorObj
 * @param {number} commandCode
 * @param {object} valueObj
 */
export const changeSensorOneSetting = async (
  sensorObj,
  commandCode,
  valueObj
) => {
  const { sensorID, gatewayID, dtkAdd } = sensorObj || {};
  let payload = {
    type: "control_single_sensor",
    sensorID,
    gatewayID,
    dtkAdd,
    commandCode,
    ...valueObj
  };
  console.log(
    `[COMMAND OUT] changeSensorOneSetting ${sensorID} commandCode:0x${commandCode.toString(16)} valueObj:${JSON.stringify(valueObj)}`
  );
  global.socket.emit("fromWeb", payload);
};
export const transferSettingToSensor = async (
  sourceID,
  targetID,
  sensorObjAll
) => {
  const sourceObj = sensorObjAll[sourceID];
  const targetObj = sensorObjAll[targetID];
  const payload = {
    type: "transferSettingToSensor",
    sourceObj,
    targetObj,
  };
  console.log(
    `[COMMAND OUT] transferSettingToSensor ${sourceID} to ${targetID}`
  );
  global.socket.emit("fromWeb", payload);
};
export const sensorDisconnectLight = async (sensorObj, gatewayID, lightObj) => {
  const jsonData = {
    gatewayID,
    sensorID: sensorObj.sensorID,
    sensorObj,
    serial: lightObj.serial,
    lightObj,
    type: "sensorDisconnectLight",
  };
  console.log(`[COMMAND OUT] sensor ${sensorObj.sensorID} disconnectLight ${lightObj.serial} `);
  global.socket.emit("fromWeb", jsonData);
};
export const sensorConnectLight = async (sensorObj, lightObj, switchNo) => {
  const payload = {
    sensorObj,
    lightObj,
    switchNo,
    type: "sensorConnectLight",
  };
  console.log(`[COMMAND OUT] Light ${lightObj.serial} connect to sensor ${sensorObj.sensorID}`);
  global.socket.emit("fromWeb", payload);
};
export const sensorDisconnectAllLights = async (sensorObj) => {
  const emitObj = {
    type: "sensorDisconnectAllLights",
    sensorID: sensorObj.sensorID,
    sensorObj,
  };
  console.log(`[SOCKET OUT] sensor ${sensorObj.sensorID} DisconnectAllLights `);
  global.socket.emit("fromWeb", emitObj);
};
export const sensorConnectSensor = async (mainSensorObj, sensorObjConnect, mainButtonNo) => {
  const payload = {
    type: "sensorConnectSensor",
    mainSensorObj,
    sensorObjConnect,
    mainButtonNo
  };
  let logMsg = `[COMMAND OUT] Sensor ${sensorObjConnect.sensorID} connect to sensor ${mainSensorObj.sensorID}`;
  if (mainButtonNo) logMsg += ` button ${mainButtonNo}`;
  console.log(logMsg);
  global.socket.emit("fromWeb", payload);
};
export const sensorDisconnectSensor = async (
  mainSensorObj,
  sensorObjConnect
) => {
  const payload = {
    type: "sensorDisconnectSensor",
    mainSensorObj,
    sensorObjConnect,
  };
  console.log(
    `[COMMAND OUT] Sensor ${sensorObjConnect.sensorID} disconnect from sensor ${mainSensorObj.sensorID}`
  );
  global.socket.emit("fromWeb", payload);
};
export const updateMotionDelayTime = async (
  sensorObj,
  delayT,
  delayT2,
  enable_delayed_action2
) => {
  const sensorID = sensorObj.sensorID;
  if (sensorObj.dtkAdd) {
    let payload = {
      type: "updateSensorDelayTime",
      sensorObj,
      delaySec: delayT,
      delaySec2: delayT2,
      buttonNo: 1
    }
    console.log(`[COMMAND OUT] updateSensorDelayTime motion ${sensorID} delaySec:${delayT}s, delaySec2:${delayT2}s `);
    global.socket.emit("fromWeb", payload);
  }

};
export const updateSceneButtonDelayTime = async (sensorObj, delaySec, delaySec2, buttonNo) => {
  let payload = {
    type: "updateSensorDelayTime",
    sensorObj,
    delaySec,
    delaySec2,
    buttonNo
  }
  console.log(`[COMMAND OUT] updateSceneButtonDelayTime ${sensorObj.sensorID} buttonNo ${buttonNo} delaySec:${delaySec}s, delaySec2:${delaySec2}s `);
  global.socket.emit("fromWeb", payload);
}
export const checkSensorConnection = async (
  dispatch,
  socket,
  sensorID,
  sensorObj
) => {
  dispatch({
    type: "UPDATED_SENSOR_PROPERTY",
    payload: {
      sensorID,
      updateObj: { zigbeeConnected: false },
    },
  });
  await gs.waitFor(1000);
  await requestSensorCurrentData(socket, sensorID, sensorObj);
};
export const requestSensorCurrentData = async (socket, sensorID, sensorObj) => {
  const payload = {
    type: "control_single_sensor",
    gatewayID: sensorObj.gatewayID,
    sensorID,
    serverID: sensorObj.serverID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x0601,
    protocol: "0601"
  };
  console.log(`[COMMAND OUT] requestSensorCurrentData ${sensorID}`);
  socket.emit("fromWeb", payload);
};
export const restartSensor = async (sensorObj) => {
  let payload = {
    type: "control_single_sensor",
    sensorID: sensorObj.sensorID,
    gatewayID: sensorObj.gatewayID,
    serverID: sensorObj.serverID,
    commandCode: 0x0608,
    dtkAdd: sensorObj.dtkAdd,
    protocol: "0608",
  };
  console.log(`[COMMAND OUT] restartSensor ${sensorObj.sensorID} `);
  global.socket.emit("fromWeb", payload);
};
export const saveSensorLockLevel = async (
  sensorID,
  sensorObj,
  lockLevelNoArray,
  lockLevelArray,
  releaseOnDelayEndArray
) => {
  const jsonData = {
    type: "sensorChangeLockLevel",
    sensorID,
    sensorObj,
    lockLevelNoArray,
    lockLevelArray,
    releaseOnDelayEndArray,
  };
  console.log(`[COMMAND OUT] sensorChangeLockLevel ${sensorID} `);
  global.socket.emit("fromWeb", jsonData);
};
export const sensorManualExecuteSetting = async (sensorObj, settingNum) => {
  const type = "sensorExecuteSetting";
  const { sensorID, gatewayID } = sensorObj;
  const jsonData = {
    type,
    sensorID,
    gatewayID,
    settingNum,
  };
  console.log(
    `[SOCKET OUT] ${type} - gateway ${gatewayID} sensor ${sensorID} execute ${settingNum}`
  );
  global.socket.emit("fromWeb", jsonData);
};
export const queryOneSensorSetting = async (sensorObj, commandCode) => {
  let payload = {
    type: "control_single_sensor",
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode
  };
  console.log(
    `[COMMAND OUT] queryOneSensorSetting ${sensorObj.sensorID} ${commandCode.toString(16)}`
  );
  global.socket.emit("fromWeb", payload);
};
export const copyLightSettingInOneSensor = async (sensorObj, serial) => {
  const payload = {
    type: "copyLightSettingInOneSensor",
    sensorObj,
    serial,
  };
  console.log(
    `[COMMAND OUT] copyLightSettingInOneSensor ${sensorObj.sensorID} light being copied ${serial}`
  );
  global.socket.emit("fromWeb", payload);
  // let updateObj = {};
  // for (let i = 1; i <= 12; i++) {
  //   const setting = sensorObj[`setting${i}`];
  //   if (setting) {
  //     const sourceSerialSetting = setting[serial];
  //     let newSetting = {};
  //     Object.keys(setting || {}).forEach((s) => {
  //       newSetting[s] = sourceSerialSetting;
  //     });
  //     updateObj[`setting${i}`] = newSetting;
  //   }
  // }
  // console.log(updateObj);
  // await updateSensorProperty(
  //   sensorObj.sensorID,
  //   sensorObj,
  //   sensorObj.gatewayID,
  //   updateObj
  // );
};
export const checkSensorUnderGateway = async (sensorObj) => {
  const { sensorID } = sensorObj;
  const gatewayArrWithSensor = [];
  const gatewayArrWithSensorFS = [];
  const gatewayObjArr = await mgFindAll("gateway");
  const gatewayObjArrFs = await getDocsWhereFS("SM_gateway", "array");
  // console.log(gatewayObjArr);
  // console.log(gatewayObjArrFs);
  gatewayObjArr.forEach((gatewayObj) => {
    const { sensorMap } = gatewayObj || {};
    if (Object.values(sensorMap || {}).includes(sensorID)) {
      gatewayArrWithSensor.push(gatewayObj.gatewayID);
    }
  });
  gatewayObjArrFs.forEach((gatewayObj) => {
    const { sensorMap } = gatewayObj || {};
    if (Object.values(sensorMap || {}).includes(sensorID)) {
      gatewayArrWithSensorFS.push(gatewayObj.gatewayID);
    }
  });
  return { mongo: gatewayArrWithSensor, firebase: gatewayArrWithSensorFS };
};
export const sensorDeletePermanently = async (sensorObj) => {
  const payload = {
    type: "sensorDeletePermanently",
    sensorObj,
  };
  console.log(`[COMMAND OUT] sensorDeletePermanently ${sensorObj.sensorID}`);
  global.socket.emit("fromWeb", payload);
};
export const sensorCheckLatestFirmware = async (sensorObj) => {
  const apiName = "getDevice32FirmwareVer";
  const url = `${global.ip}/api/${apiName}?program=SM_sensor_DTK`;
  try {
    const res = await fetch(url);
    const response = await res.json();
    const latestVer = response.result;
    console.log(`[API OUT] sensorCheckLatestFirmware [${url}]: result:${latestVer}`);
    return latestVer;
  } catch (e) {
    return null;
  }
};
export const sensorChangeDtkChannel = async (sensorObj, hexStrChannel) => {
  const dtkChannel = parseInt(hexStrChannel, 16);
  const payload = {
    type: "lightChangeDtkChannel",
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
    dtkChannel,
  };
  console.log(
    `[COMMAND OUT] sensorChangeDtkChannel ${sensorObj.serial} [${sensorObj.dtkAdd}] new channel 0x${hexStrChannel}`
  );
  global.socket.emit("fromWeb", payload);
};
export const sensorQueryDtkInfo = async (sensorObj) => {
  const payload = {
    type: "control_single_sensor",
    commandCode: 0x0780,
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
  };
  console.log(
    `[COMMAND OUT] sensorQueryDtkInfo ${sensorObj.sensorID} [${sensorObj.dtkAdd}]`,
    payload
  );
  global.socket.emit("fromWeb", payload);
};
export const sensorChangeDtkInfo = async (sensorObj, dtkInfo) => {
  const payload = {
    type: "control_single_sensor",
    commandCode: 0x0680,
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
    dtkType: dtkInfo.dtkType,
    dtkPanID: dtkInfo.dtkPanID,
    dtkChannel: dtkInfo.dtkChannel,
    dtkTransferMode: dtkInfo.dtkTransferMode,
    definedAdd: dtkInfo.definedAdd,
    loraParameter: dtkInfo.loraParameter,
  };
  console.log(
    `[COMMAND OUT] sensorChangeDtkInfo ${sensorObj.sensorID} [${sensorObj.dtkAdd
    }] ${JSON.stringify(payload)}`
  );
  global.socket.emit("fromWeb", payload);
};
export const sensorUpdateFirmware = async (sensorObj) => {
  const payload = {
    type: "control_single_sensor",
    sensorObj,
    sensorID: sensorObj.sensorID,
    gatewayID: sensorObj.gatewayID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x060a,
  };
  console.log(
    `[COMMAND OUT] sensorUpdateFirmware ${sensorObj.sensorID
    } commandCode 0x${payload.commandCode.toString(16)}`
  );
  global.socket.emit("fromWeb", payload);
};
export const flashSensorHeadLED = async (sensorObj, flashSec) => {
  const payload = {
    type: "control_single_sensor",
    sensorObj,
    sensorID: sensorObj.sensorID,
    gatewayID: sensorObj.gatewayID,
    dtkAdd: sensorObj.dtkAdd,
    flashSec: Number(flashSec),
    commandCode: 0x0621,
  };
  console.log(
    `[COMMAND OUT] flashSensorHeadLED ${sensorObj.sensorID} commandCode 0x0621 for ${flashSec} seconds `
  );
  global.socket.emit("fromWeb", payload);
};
export const querySensorFirmwareVer = async (sensorObj) => {
  const payload = {
    type: "control_single_sensor",
    sensorObj,
    sensorID: sensorObj.sensorID,
    gatewayID: sensorObj.gatewayID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x0711,
  };
  console.log(
    `[COMMAND OUT] querySensorFirmwareVer ${sensorObj.sensorID} commandCode 0x0711`
  );
  global.socket.emit("fromWeb", payload);
};
export const sensorQueryMacAddress = async (sensorObj) => {
  const commandCode = 0x0718;
  const payload = {
    type: "control_single_sensor",
    sensorObj,
    sensorID: sensorObj.sensorID,
    gatewayID: sensorObj.gatewayID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode,
  };
  console.log(
    `[COMMAND OUT] sensor ${sensorObj.sensorID} Query MacAddress commandCode ${commandCode}`
  );
  global.socket.emit("fromWeb", payload);
};
export const sensorChangeDisableEspNow = async (sensorObj, disableEspNow) => {
  let payload = {
    type: "control_single_sensor",
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x0626,
    disableEspNow,
  };
  console.log(
    `[COMMAND OUT] sensorChangeDisableEspNow ${sensorObj.sensorID} [${sensorObj.dtkAdd
    }] to ${disableEspNow.toString()}`
  );
  global.socket.emit("fromWeb", payload);
};
export const sensorChangeEnableEspOut = async (sensorObj, enableEspOut) => {
  let payload = {
    type: "control_single_sensor",
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x0627,
    enableEspOut,
  };
  console.log(
    `[COMMAND OUT] sensorChangeEnableEspOut ${sensorObj.sensorID} [${sensorObj.dtkAdd
    }] to ${enableEspOut.toString()}`
  );
  global.socket.emit("fromWeb", payload);
};
export const sensorTestSettingReadWrite = async (sensorObj) => {
  let payload = {
    type: "control_single_sensor",
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x0625,
  };
  console.log(
    `[COMMAND OUT] sensorTestSettingReadWrite 0x0625 ${sensorObj.sensorID}`
  );
  global.socket.emit("fromWeb", payload);
};
export const sensorChangeOtaSsid = async (sensorObj, otaSsid) => {
  let payload = {
    type: "control_single_sensor",
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x060b,
    otaSsid,
  };
  console.log(
    `[COMMAND OUT] sensor  ${sensorObj.sensorID} ChangeOtaSsid 0x060b to ${otaSsid}`
  );
  global.socket.emit("fromWeb", payload);
};
export const sensorChangeOtaPassword = async (sensorObj, otaPassword) => {
  let payload = {
    type: "control_single_sensor",
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x060c,
    otaPassword,
  };
  console.log(
    `[COMMAND OUT] sensorChangeOtaPassword 0x060c ${sensorObj.sensorID}`
  );
  global.socket.emit("fromWeb", payload);
};
export const sensorDeleteDtk = async (sensorObj) => {
  const payload = {
    type: "sensorDeleteDtk",
    sensorID: sensorObj.sensorID,
  };
  console.log(
    `[COMMAND OUT] sensorDeleteDtk ${sensorObj.sensorID} [${sensorObj.dtkAdd}]`
  );
  global.socket.emit("fromWeb", payload);
};
export const sensorRemoveDeviceSetting = async (
  sensorObj,
  deviceObj,
  settingNumArray
) => {
  const payload = {
    type: "sensorRemoveDeviceSetting",
    sensorObj,
    deviceObj,
    settingNumArray,
  };
  console.log(
    `[COMMAND OUT] sensor ${sensorObj.sensorID} Remove device ${deviceObj.serial || deviceObj.sensorID} Setting num: ${settingNumArray.join(",")}`
  );
  global.socket.emit("fromWeb", payload);
};
export const querySensorComType = async (sensorObj) => {
  const payload = {
    type: "querySensorComType",
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
  };
  console.log(
    `[COMMAND OUT] querySensorComType ${sensorObj.sensorID} [${sensorObj.dtkAdd}]`
  );
  global.socket.emit("fromWeb", payload);
}
export const sensorSaveBatchNearFieldSetting = async (sensorObj, wifiApMacAddressArray, deviceObj, settingNumArray) => {
  const deviceID = deviceObj.serial || deviceObj.sensorID || deviceObj.daliCtlID || "";
  let lightLvArray = settingNumArray.map(n => {
    const deviceSetting = sensorObj[`setting${n}`]?.[deviceID];
    if (!deviceSetting) return null;
    return deviceSetting.pwm;
  });
  const payload = {
    type: "control_single_sensor",
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x0639,
    deviceID,
    macAddressArray: wifiApMacAddressArray,
    shortAdd: deviceObj.shortAdd || 0,
    lightLvArray
  }
  let msg = `[COMMAND OUT] [0639] sensor ${sensorObj.sensorID} save batch near field setting for ${deviceID} - light lv: ${lightLvArray.join(",")}`;
  console.log(msg);
  global.socket.emit("fromWeb", payload);
}
export const sensorSaveNearFieldSetting = async (sensorObj, wifiApMacAddressArray, deviceObj, settingNum) => {
  const deviceID = deviceObj.serial || deviceObj.sensorID || deviceObj.daliCtlID || "";
  const deviceSetting = sensorObj[`setting${settingNum}`]?.[deviceID];
  const dtkByteCmd = gs.jsonCommandToDtk(deviceSetting || {});
  // console.log(dtkByteCmd);
  let msgStr;
  if (deviceSetting && dtkByteCmd) {
    msgStr = dtkByteCmd.substring(8, dtkByteCmd.length - 4);
    let msgArray = [];
    for (let i = 0; i < msgStr.length; i += 2) {
      msgArray.push(parseInt(msgStr.substring(i, i + 2), 16));
    }
    const payload = {
      type: "control_single_sensor",
      gatewayID: sensorObj.gatewayID,
      sensorID: sensorObj.sensorID,
      dtkAdd: sensorObj.dtkAdd,
      commandCode: 0x0628,
      deviceID,
      macAddressArray: wifiApMacAddressArray,
      settingNum,
      shortAdd: deviceObj.shortAdd || 0,
      msgArray
    }
    let msg = `[COMMAND OUT] [0628] sensor ${sensorObj.sensorID} save device ${deviceID} near field setting - settingNum: ${settingNum}`;
    if ("shortAdd" in deviceObj) msg += ` shortAdd:${deviceObj.shortAdd}`;
    msg += `msg:${msgStr}`;
    console.log(msg);
    global.socket.emit("fromWeb", payload);
    return;
  } else {
    alert(`Invalid setting${settingNum} for device ${deviceID}`);
  }
}
export const sensorDeleteNearFieldSetting = async (sensorObj, wifiApMacAddressArray, deviceObj, settingNum) => {
  const deviceID = deviceObj.serial || deviceObj.sensorID || deviceObj.daliCtlID || "";
  const payload = {
    type: "control_single_sensor",
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x0629,
    deviceID,
    macAddressArray: wifiApMacAddressArray,
    settingNum,
    shortAdd: deviceObj.shortAdd || 0
  }
  let msg = `[COMMAND OUT] [0629] sensor ${sensorObj.sensorID} delete device ${deviceID} near field setting - settingNum: ${settingNum}`;
  if ("shortAdd" in deviceObj) msg += ` shortAdd:${deviceObj.shortAdd}`;
  console.log(msg);
  global.socket.emit("fromWeb", payload);
}
export const sensorQueryNearFieldSetting = async (sensorObj, wifiApMacAddressArray, deviceObj, settingNum) => {
  const deviceID = deviceObj.serial || deviceObj.sensorID || deviceObj.daliCtlID;
  const payload = {
    type: "control_single_sensor",
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x071f,
    deviceID,
    macAddressArray: wifiApMacAddressArray,
    settingNum,
    shortAdd: deviceObj.shortAdd || 0
  };
  let msg = `[COMMAND OUT] [071f] sensor ${sensorObj.sensorID} Query NearFieldSetting deviceID:${deviceID} settingNum:${settingNum}`
  if ("shortAdd" in deviceObj) msg += ` shortAdd:${deviceObj.shortAdd}`;
  console.log(msg);
  global.socket.emit("fromWeb", payload);
}
export const sensorExecuteNearFieldLightSetting = async (sensorObj, deviceObj, settingNum) => {
  const deviceID = deviceObj.serial || deviceObj.sensorID || deviceObj.daliCtlID || "";
  const payload = {
    type: "control_single_sensor",
    sensorID: sensorObj.sensorID,
    gatewayID: sensorObj.gatewayID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x062a,
    deviceID,
    macAddressArray: deviceObj.wifiApMacAddressArray,
    settingNum,
    shortAdd: deviceObj.shortAdd || 0
  };
  let msg = `[COMMAND OUT] [062a] sensor ${sensorObj.sensorID} execute NearFieldLightSetting device ${deviceID} settingNum:${settingNum}`;
  if ("shortAdd" in deviceObj) msg += ` shortAdd:${deviceObj.shortAdd}`;
  console.log(msg);
  global.socket.emit("fromWeb", payload);
}
export const sensorDeleteAllNearFieldSetting = async (sensorObj) => {
  let payload = {
    type: "control_single_sensor",
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x0630,
  };
  console.log(`[COMMAND OUT] [0x0630] sensor ${sensorObj.sensorID} delete all near field settings`);
  global.socket.emit("fromWeb", payload);
}
export const getSensorDeviceNearFieldObj = async (sensorObj, deviceObj, settingNum) => {
  const deviceID = deviceObj.serial || deviceObj.sensorID || deviceObj.daliCtlID || "";
  const nfKey = `${deviceID}_${settingNum}_${deviceObj.shortAdd || 0}`;
  const nearFieldSetting = sensorObj.nearFieldSetting || {};
  const nfObj = nearFieldSetting[nfKey];
  return nfObj;
}
export const sensorChangeDimStepSize = async (sensorObj, dimStepSize) => {
  const payload = {
    type: "sensorChangeDimStepSize",
    sensorObj,
    dimStepSize
  };
  let msg = `[COMMAND OUT]  sensor  ${sensorObj.sensorID} sensorChangeDimStepSize to ${dimStepSize}`;
  console.log(msg);
  global.socket.emit("fromWeb", payload);
}
export const flashTouchPanelLed = async (sensorObj, buttonNo = 0) => {
  const payload = {
    type: "control_single_sensor",
    sensorObj,
    sensorID: sensorObj.sensorID,
    gatewayID: sensorObj.gatewayID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x0635,
    buttonNo: Number(buttonNo)
  };
  let msg = `[COMMAND OUT] flashTouchPanelLed ${sensorObj.sensorID} commandCode 0x062b `;
  if (buttonNo === 0) msg += "all buttons";
  else msg += `buttonNo ${buttonNo}`;
  console.log(msg);
  global.socket.emit("fromWeb", payload);
}
export const sensorChangeDelayCountdown = async (sensorObj, delayCountDown) => {
  let payload = {
    type: "control_single_sensor",
    gatewayID: sensorObj.gatewayID,
    sensorID: sensorObj.sensorID,
    dtkAdd: sensorObj.dtkAdd,
    commandCode: 0x061a,
    delayCountDown,
  };
  console.log(
    `[COMMAND OUT] sensor ${sensorObj.sensorID} change delay countdown 0x061a to ${delayCountDown}s`
  );
  global.socket.emit("fromWeb", payload);
}
export const sceneBtnConvertToToggleSetting = async (sensorObj, settingNum) => {
  const payload = {
    type: "sceneBtnConvertToToggleSetting",
    sensorObj,
    settingNum
  };
  console.log(`[COMMAND OUT] scenBtnConvertToToggleSetting ${sensorObj.sensorID} settingNum ${settingNum}`);
  global.socket.emit("fromWeb", payload);
}
export const sceneBtnConvertFromToggleToNormalSetting = async (sensorObj, settingNum) => {
  const payload = {
    type: "sceneBtnConvertFromToggleToNormalSetting",
    sensorObj,
    settingNum
  };
  console.log(`[COMMAND OUT] sceneBtnConvertFromToggleToNormalSetting ${sensorObj.sensorID} settingNum ${settingNum}`);
  global.socket.emit("fromWeb", payload);
}
export const updateSensorOneDeviceSetting = async (sensorObj, deviceObj, newSettingObj) => {
  const payload = {
    type: "updateSensorOneDeviceSetting",
    sensorObj,
    deviceObj,
    newSettingObj
  }
  console.log(`[COMMAND OUT] updateSensorOneDeviceSetting sensor:${sensorObj.sensorID} device:${deviceObj.serial || deviceObj.sensorID} -  ${JSON.stringify(newSettingObj)}`);
  global.socket.emit("fromWeb", payload);
}

