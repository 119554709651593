import { useContext, useEffect, useMemo, useState } from "react";
import { SMContext } from "context/smContext";
import { Switch, Typography, Box } from "@mui/material";
import { ArrowForwardIos, List } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import SensorIcon from "components/SensorIcon";
import SensorSuperAdminPanel from "./SensorSuperAdminPanel";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import SpaceBetweenSelect from "components/SpaceBetweenSelect";
import DialogSelect from "components/DialogSelect";
import DialogInput from "components/DialogInput";
import DivInline from "components/DivInline";
import { sensorStyleMenu, sensorTypeOf } from "asset/string/string";
import { confirmWindow } from "actions/screenActions";
import DivCol from "components/DivCol";
import AdminContainer from "components/AdminContainer";
import {
  updateSensorProperty,
  changeSensorOneSetting,
  restartSensor,
  sensorDisconnectAllLights,
  queryOneSensorSetting,
  sensorDeletePermanently,
  flashSensorHeadLED,
  sensorQueryMacAddress,
  sensorChangeDelayCountdown,
} from "actions/sensorActions";
import General from "@ecoenghk/general";
import { sensorStringsOfType } from "actions/generalActions";
import ModalSM from "components/ModalSM";
import DivSpaceBetween from "components/DivSpaceBetween";
import IconButtonRefresh from "components/IconButtonRefresh";
import IconButtonBack from "components/IconButtonBack";
import ButtonSM from "components/ButtonSM";
import CopyDeviceToMapModal from "containers/map/modal/CopyDeviceToMapModal";
import ModalRemarks from "components/ModalRemarks";
import { mapRemoveSensor } from "actions/mapActions";
import DeviceUpdateFirmwareModal from "containers/server/DeviceUpdateFirmwareModal";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
import SensorControlledBySensorModal from "./SensorControlledBySensorModal";
const gs = new General();

export default function SensorInfoPanel() {
  const [state, dispatch] = useContext(SMContext);
  const {
    userObj,
    activeSensorID,
    sensorObjAll,
    mapObjAll,
    gatewayObjAll,
    activeMapID,
    daliCtlObjAll,
  } = state;
  const sensorObj = sensorObjAll[activeSensorID];
  const {
    sensorName,
    zigbeeAdd,
    type,
    gatewayID,
    deviceVersion,
    mapID,
    ipArray,
    hasIR,
    disabledSensor,
    gang,
    check_lux_interval,
    serialMap,
    sensorMap,
    dtkAdd,
  } = sensorObj || {};

  const inMapArray = mapID?.filter((id) => Object.keys(mapObjAll).includes(id));
  const editSensorProperty = async (updateObj) => {
    const did = sensorObj.daliCtlID;
    const gid = did ? daliCtlObjAll[did]?.gatewayID : sensorObj.gatewayID;
    await updateSensorProperty(activeSensorID, sensorObj, gid, updateObj);
  };

  const handleRemoveFromMap = async () => {
    confirmWindow(
      dispatch,
      `Confirm removing sensor ${activeSensorID} from map ${activeMapID}?`,
      async () => {
        await mapRemoveSensor(activeMapID, activeSensorID);
      }
    );
  };
  const handleRemoveAllLights = async () => {
    confirmWindow(
      dispatch,
      "Confirm disconnect all lights from this sensor?",
      async () => {
        await sensorDisconnectAllLights(sensorObj);
      }
    );
  }
  let sensorDistMenu = {};
  gs.newArrayBetween(0, 15).forEach((n) => {
    if (n === 0) sensorDistMenu[0] = "0 : high sensitivity";
    else if (n === 15) sensorDistMenu[15] = "15 : low sensitivity";
    else sensorDistMenu[n] = n < 10 ? "0" + String(n) : String(n);
  });
  const sensorMacArray = useMemo(() => {
    let arr = [];
    (sensorObj.macArray || []).forEach(n => {
      if (n) arr.push(n);
      else arr.push(0);
    });
    return arr;
  }, [sensorObj.macArray]);
  return (
    <div>
      <Typography variant="h6" align="center">
        General
      </Typography>
      <Divider10 />
      <SpaceBetweenDiv title="Sensor ID" data={activeSensorID} />
      <Divider10 />
      <SpaceBetweenDiv
        title="Description"
        data={
          <DivInline>
            <Typography>{sensorName}</Typography>
            <DialogInput
              title="Sensor name?"
              initialVal={sensorName || ""}
              handleSave={async (newVal) => {
                await editSensorProperty({ sensorName: newVal });
              }}
            />
          </DivInline>
        }
      />
      <Divider10 />
      <DivSpaceBetween>
        <DivInline>
          <Typography>Sensor type</Typography>
          <IconButtonRefresh
            onBtnClick={() => queryOneSensorSetting(sensorObj, 0x0701)}
          />
        </DivInline>
        <DivInline>
          <Typography>{sensorStringsOfType(type).sensorTypeName}</Typography>
          <AdminContainer>
            <SelectSensorTypeModal
              initialType={type || ""}
              handleSave={async (newVal) => {
                if (!sensorObj.dtkAdd) return;
                const typeCode = sensorTypeOf[newVal];
                console.log("***sensor type: ", newVal, typeCode);
                await changeSensorOneSetting(sensorObj, 0x060d, { sensorType: typeCode });

              }}
            />
          </AdminContainer>
        </DivInline>
      </DivSpaceBetween>

      <DivExist show={type === "lightswitch" || type === "scenebutton"}>
        <SpaceBetweenDiv
          title="No of gang"
          data={
            <DivInline>
              <Typography>{gang}</Typography>
              <ChangeGangModal sensorObj={sensorObj} />
              {/* <AdminContainer>
                <DialogSelect
                  title="No of gang"
                  initialVal={gang || ""}
                  menuObj={{ 1: 1, 2: 2, 3: 3, 4: 4 }}
                  handleSave={async (newVal) =>
                    await changeSensorOneSetting(sensorObj, 0x0614, { gang: Number(newVal) })
                  }
                />
              </AdminContainer> */}
            </DivInline>
          }
        />
      </DivExist>
      <Divider10 />
      <SpaceBetweenDiv
        title="Gateway"
        data={
          <DivCol alignItems="flex-end">
            <Typography>{gatewayObjAll[gatewayID]?.description}</Typography>
            <Typography variant="caption">[{gatewayID}]</Typography>
          </DivCol>
        }
      />
      <Divider10 />
      <SpaceBetweenDiv title="Address" data={dtkAdd || ""} />
      <Divider10 />
      <SpaceBetweenDiv
        title="In Map"
        data={
          <div>
            {inMapArray?.map((id, key) => (
              <Typography
                align="right"
                display="block"
                variant="caption"
                key={key}
              >
                {mapObjAll[id].mapName} [{id}]
              </Typography>
            ))}
          </div>
        }
      />

      <DivExist show={(type === "motion" || type === "daylightmotion")} >
        <Divider10 />
        <DivSpaceBetween>
          <DivInline>
            <Typography>Sensor Mode</Typography>
            <IconButtonRefresh
              onBtnClick={() => queryOneSensorSetting(sensorObj, 0x0703)}
            />
          </DivInline>
          <EditSensorModeModal sensorObj={sensorObj} />
        </DivSpaceBetween>
        <DivSpaceBetween>
          <DivInline>
            <Typography>Sensor Distance</Typography>
            <IconButtonRefresh
              onBtnClick={() => queryOneSensorSetting(sensorObj, 0x0702)}
            />
          </DivInline>
          <EditSensorDistanceModal sensorObj={sensorObj} />
        </DivSpaceBetween>
        <SpaceBetweenButton
          size="small"
          title="Check remain countdown"
          btnContent={<ArrowForwardIos />}
          onBtnClick={() => queryOneSensorSetting(sensorObj, 0x070e)}
        />
        <SpaceBetweenButton
          size="small"
          title="Reset delay countdown"
          btnContent={<ArrowForwardIos />}
          onBtnClick={() => sensorChangeDelayCountdown(sensorObj, 2)}
        />
        <SpaceBetweenButton
          size="small"
          title="Flash sensor head LED 10seconds"
          btnContent={<ArrowForwardIos />}
          onBtnClick={() => flashSensorHeadLED(sensorObj, 10)}
          hideComponent={!dtkAdd ? true : false}
        />
      </DivExist>
      <Divider10 />
      <DivSpaceBetween>
        <Typography>Controlled by Sensor</Typography>
        <SensorControlledBySensorModal sensorObj={sensorObj} />
      </DivSpaceBetween>

      {/* ///////////////Network//////////////////////////////Network/////////////////////////////Network/////////////////////		   */}
      <Divider10 />
      <Typography variant="h6" align="center">
        Network
      </Typography>
      <Divider10 />
      <DivSpaceBetween>
        <DivInline>
          <Typography>Mac address</Typography>
          <IconButtonRefresh
            hide={!sensorObj.dtkAdd}
            onBtnClick={() => sensorQueryMacAddress(sensorObj)}
          />
        </DivInline>
        <Typography>{gs.macStringOf(sensorMacArray)}</Typography>

      </DivSpaceBetween>

      <SpaceBetweenDiv
        title="Local access point IP:"
        data={gs.ipStringOf(ipArray)}
        hideComponent={!zigbeeAdd}
      />
      <Divider10 />
      <Typography variant="h6" align="center">
        Other
      </Typography>
      <Divider10 />
      <DivExist show={type === "daylight" || type === "daylightmotion"}>
        <SpaceBetweenDiv
          title="Check lux level interval"
          dataColor="white"
          data={
            <DivInline>
              <Typography>{check_lux_interval} sec</Typography>
              <DialogInput
                title="Check lux level interval (second)?"
                initialVal={check_lux_interval || 10}
                handleSave={async (val) =>
                  await changeSensorOneSetting(sensorObj, 0x0615, { check_lux_interval: Number(val) })
                }
              />
            </DivInline>
          }
        />
      </DivExist>
      <DivExist show={userObj.level < 2}>
        <DeviceUpdateFirmwareModal deviceObj={sensorObj} />
      </DivExist>
      <DivExist show={userObj.level < 2}>
        <SpaceBetweenButton
          btnSize="small"
          title="Disconnect all lights"
          btnContent="disconnect"
          onBtnClick={handleRemoveAllLights}
          marginBottom="1vh"
        />
      </DivExist>
      <SpaceBetweenDiv
        title="Copy to other map"
        variant="outlined"
        data={
          <CopyDeviceToMapModal
            deviceID={activeSensorID}
            deviceType="sensor"
            deviceName={sensorName}
          />
        }
        marginBottom="1vh"
      />
      <DivExist show={userObj.level < 2}>
        <SpaceBetweenButton
          btnSize="small"
          title="Remove from map"
          btnContent="Remove"
          color="error"
          marginBottom="1vh"
          onBtnClick={handleRemoveFromMap}
        />
      </DivExist>
      <DivSpaceBetween>
        <DivInline>
          <Typography>Disabled sensor</Typography>
          <IconButtonRefresh
            onBtnClick={() => queryOneSensorSetting(sensorObj, 0x0705)}
          />
        </DivInline>
        <Switch
          checked={disabledSensor === 1 ? true : false}
          onChange={async (e) => {
            let val = e.target.checked ? 1 : 0;
            if (dtkAdd) val = e.target.checked ? 0x33 : 0x66;
            await changeSensorOneSetting(sensorObj, 0x0611, { disabledSensor: val });
          }}
        />
      </DivSpaceBetween>
      <DivSpaceBetween style={{ marginBottom: "1vh" }}>
        <Typography>Remarks</Typography>
        <ModalRemarks
          currentRemarks={sensorObj.remarks}
          handleSave={async (r) => {
            await editSensorProperty({ remarks: r });
          }}
          closeOnSave
        />
      </DivSpaceBetween>
      <SpaceBetweenButton
        title="Restart sensor"
        btnContent="Restart"
        variant="contained"
        color="secondary"
        btnSize="small"
        marginBottom="1vh"
        onBtnClick={async () => {
          confirmWindow(dispatch, "Confirm restart sensor?", async () => {
            await restartSensor(sensorObj);
          });
        }}
      />
      <SpaceBetweenButton
        title="Delete permanently"
        hideComponent={
          gatewayID ||
            Object.keys(serialMap || {})?.length > 0 ||
            Object.keys(sensorMap || {})?.length > 0
            ? true
            : false
        }
        btnContent="Delete"
        variant="outlined"
        marginBottom="1vh"
        onBtnClick={async () => {
          confirmWindow(
            dispatch,
            `Confirm to delete sensor ${activeSensorID} permanently?`,
            async () => {
              await sensorDeletePermanently(sensorObj);
              dispatch({ type: "CLOSED_LIGHT_DETAIL_MODAL" });
            }
          );
        }}
      />
      <Divider10 />
      <AdminContainer>
        <SensorSuperAdminPanel />
      </AdminContainer>
    </div>
  );
}

function EditSensorDistanceModal({ sensorObj }) {
  const [open, setOpen] = useState(false);
  const [distance, setDistance] = useState(0);
  useEffect(() => {
    setDistance(sensorObj.sensorDist);
  }, [open, sensorObj]);
  let sensorDistMenu = {};
  gs.newArrayBetween(0, 16).forEach((n) => {
    if (n === 0) sensorDistMenu[0] = "0 : high sensitivity";
    else if (n === 15) sensorDistMenu[15] = "15 : low sensitivity";
    else if (n === 16) sensorDistMenu[99] = "No setting";
    else sensorDistMenu[n] = n < 10 ? "0" + String(n) : String(n);
  });
  return (
    <>
      <ButtonSM
        onClick={() => setOpen(true)}
        margin="0.3vw"
        sx={{ padding: "0.5vw", minWidth: "8vw", maxWidth: "14vw" }}
      >
        <Typography>{sensorDistMenu[sensorObj.sensorDist || 0]}</Typography>
      </ButtonSM>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        onSave={async () =>
          await changeSensorOneSetting(sensorObj, 0x060e, { sensorDist: distance })
        }
      >
        <DivInline>
          <IconButtonBack onBtnClick={() => setOpen(false)} />
          <Typography>Change Sensor Distance</Typography>
          <AdminContainer>
            <IconButtonRefresh
              onBtnClick={() => queryOneSensorSetting(sensorObj, 0x0702)}
            />
          </AdminContainer>
        </DivInline>
        <Divider10 />
        <SpaceBetweenSelect
          title="Microwave sensor distance"
          data={distance || 0}
          menuObj={sensorDistMenu}
          onChange={async (e) => {
            setDistance(Number(e.target.value));
          }}
        />
      </ModalSM>
    </>
  );
}

function EditSensorModeModal({ sensorObj }) {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(0);
  useEffect(() => {
    setMode(sensorObj.sensorMode);
  }, [open, sensorObj]);
  const menuObj = {
    0: "microwave only[0]",
    1: "mircowave AND infra-red[1]",
    2: "microwave OR infra-red[2]",
    3: "infra-red only[3]",
  };
  return (
    <>
      <ButtonSM
        onClick={() => setOpen(true)}
        margin="0.3vw"
        sx={{ padding: "0.5vw", maxWidth: "15vw" }}
      >
        <Typography>{menuObj[sensorObj.sensorMode]}</Typography>
      </ButtonSM>

      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        onSave={async () => {
          await changeSensorOneSetting(sensorObj, 0x060f, { sensorMode: Number(mode) });
          setOpen(false);
        }}
      >
        <DivInline>
          <IconButtonBack onBtnClick={() => setOpen(false)} />
          <Typography>Change Sensor Mode</Typography>
          <AdminContainer>
            <IconButtonRefresh
              onBtnClick={() => queryOneSensorSetting(sensorObj, 0x0703)}
            />
          </AdminContainer>
        </DivInline>

        <Divider10 />
        <SpaceBetweenSelect
          title="Sensor Mode"
          data={mode || 0}
          menuObj={menuObj}
          onChange={async (e) => {
            setMode(e.target.value);
          }}
        />
      </ModalSM>
    </>
  );
}

function SelectSensorTypeModal({ initialType, handleSave }) {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(0);
  return (
    <>
      <List
        onClick={() => {
          setOpen(true);
          setType(initialType);
        }}
        sx={{ cursor: "pointer" }}
      />
      <ModalSM
        modalTitle="Change sensor type"
        open={open}
        onClose={() => setOpen(false)}
        onSave={async () => {
          handleSave(type);
          setOpen(false);
        }}
        width="80vw"
      >
        <DivInline>
          {Object.keys(sensorStyleMenu).map((t, key) => {
            return (
              <ButtonSM
                key={key}
                onClick={() => setType(t)}
                margin="1vw"
                sx={{
                  padding: "0.5vw",
                  width: "16vw",
                  height: "15vh",
                  outline:
                    t === type ? "3px solid yellow" : "1px solid lightgrey",
                }}
              >
                <DivCol alignItems="center">
                  <SensorIcon
                    width={4}
                    sizeUnit="vw"
                    margin={0}
                    disableStatus
                    type={t}
                    gang={1}
                    disabledConnectStatus
                  />
                  <Typography fullWidth align="center">
                    {sensorStyleMenu[t]}
                  </Typography>
                </DivCol>
              </ButtonSM>
            );
          })}
        </DivInline>
      </ModalSM>
    </>
  );
}
function ChangeGangModal({ sensorObj }) {
  const [open, setOpen] = useState(false);
  const [, dispatch] = useContext(SMContext);
  const handleChange = (newGang) => {
    confirmWindow(dispatch, `Confirm change gang to ${newGang}?`, async () => {
      changeSensorOneSetting(sensorObj, 0x0614, { gang: Number(newGang) });
      setOpen(false);
    });
  }
  return (
    <>
      <List
        onClick={() => {
          setOpen(true);
          // setType(sensorObj.gang);
        }}
        sx={{ cursor: "pointer" }}
      />
      <ModalSM
        modalTitle="Change gang"
        open={open}
        onClose={() => setOpen(false)}

        width="80vw"
      >
        <Divider10 />
        <DivInline justifyContent="space-around">
          {
            [1, 2, 3, 4].map((t, key) => {
              const outline = sensorObj.gang === t ? "3px solid yellow" : "1px solid lightgrey";
              return (
                <ButtonSM key={key} sx={{ outline, padding: "1vw" }} onClick={() => handleChange(t)}>
                  <SensorIcon type={sensorObj.type} disabledConnectStatus gang={t} width={4} sizeUnit="vw" />
                  <Typography sx={{ marginLeft: "0.5vw" }}>{t} gang</Typography>
                </ButtonSM>
              )
            })
          }
        </DivInline>
      </ModalSM>
    </>)
}