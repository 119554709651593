import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Switch, Button, ButtonGroup } from "@mui/material";
import { titleText, dataText } from "asset/string/color";
import Server from "asset/svgComp/Server";
import DivInline from "components/DivInline";
import {
  rebootServer,
  updateServerDataFromFB,
  updateServerFirmware,
  getLatestServerFirmwareVer,
  updateSeverWebapp,
  restartServerProgram,
  checkServerSocket,
} from "actions/serverActions";
import { confirmWindow } from "actions/screenActions";
import AdminContainer from "components/AdminContainer";
import DivCol from "components/DivCol";
import DivExist from "components/DivExist";
import packagejson from "../../../../package.json";
import moment from "moment";

export default function SelectedServerInfo() {
  const [state, dispatch] = useContext(SMContext);
  const { selectedID, serverObj, socket } = state;
  const {
    description,
    serPublicIp,
    serVersion,
    serverStartTimeStamp,
    serverStartTimeString,
    serInternalIp,
    enableUploadFS,
    latestFirmwareVer,
  } = serverObj || {};
  // const handleEditServerProp = async (field, val) => {
  //   await mgUpdateOneUpsert(
  //     "server",
  //     { serverID: selectedID },
  //     { [field]: val }
  //   );
  // };
  const handleDownloadFmFS = async (updateType) => {
    await updateServerDataFromFB(socket, updateType);
  };
  const currentVer = (serVersion.split(".") || []).map((n) => Number(n));
  const latestVer = (latestFirmwareVer?.split(".") || []).map((n) => Number(n));
  let verOutdated = false;
  if (serVersion && latestFirmwareVer) {
    if (latestVer[0] > currentVer[0]) verOutdated = true;
    if (latestVer[0] === currentVer[0] && latestVer[1] > currentVer[1])
      verOutdated = true;
    if (
      latestVer[0] === currentVer[0] &&
      latestVer[1] === currentVer[1] &&
      latestVer[2] > currentVer[2]
    )
      verOutdated = true;
  }
  return (
    <>
      <DivInline justifyContent="center">
        <Server width={4} sizeUnit="vw" />
      </DivInline>
      <Typography align="center" style={{ color: titleText }}>
        Server
      </Typography>
      <Typography
        align="center"
        display="block"
        variant="caption"
        style={{ color: dataText }}
      >
        {selectedID}
      </Typography>
      <InfoDivider />
      <DataSet title="Description" data={description} />
      <DataSet title="Public IP" data={serPublicIp} />
      <DivExist show={serInternalIp !== "NA" && serInternalIp}>
        <DataSet title="Internal IP" data={serInternalIp} />
      </DivExist>
      <DataSet title="Start" data={serverStartTimeString} />
      <DataSet
        title="Up time"
        data={moment(serverStartTimeStamp).fromNow(true)}
      />
      <AdminContainer>
        {/* <DivCol alignItems="center">
          <Typography
            variant="caption"
            display="block"
            align="center"
            style={{ color: titleText }}
          >
            Download from Firestore
          </Typography>
          <ButtonGroup orientation="vertical" variant="outlined" size="small">
            <Button onClick={() => handleDownloadFmFS("serial")}>Lights</Button>
            <Button onClick={() => handleDownloadFmFS("sensor")}>
              Sensors
            </Button>
            <Button onClick={() => handleDownloadFmFS("gateway")}>
              Gateways
            </Button>
            <Button onClick={() => handleDownloadFmFS("schedule")}>
              Schedules
            </Button>
            <Button onClick={() => handleDownloadFmFS("map")}>Maps</Button>
          </ButtonGroup>
        </DivCol> 
        <InfoDivider />*/}

        <DivCol justifyContent="center">
          <DataSet
            title="Synchronize cloud upload"
            data={enableUploadFS ? "Enabled" : "Disabled"}
          />
          {/* <Typography
            variant="caption"
            display="block"
            align="center"
            style={{ color: titleText }}
          >
            {`${
              enableUploadFS ? "Enabled" : "Disabled"
            } synchronize cloud upload`}
          </Typography> */}
          <InfoDivider />
          <Button
            onClick={async () => {
              confirmWindow(dispatch, "Confirm reboot server?", async () => {
                await rebootServer();
              });
            }}
            color="warning"
            variant="outlined"
            size="small"
          >
            Reboot
          </Button>
          <Button
            onClick={async () => {
              confirmWindow(
                dispatch,
                "Confirm restart server program?",
                async () => {
                  await restartServerProgram();
                }
              );
            }}
            color="warning"
            variant="outlined"
            size="small"
          >
            Restart
          </Button>
          <InfoDivider />
          <Button
            onClick={async () => await getLatestServerFirmwareVer(socket)}
            color="warning"
            variant="outlined"
            size="small"
          >
            Check latest firmware
          </Button>
        </DivCol>
        <InfoDivider />
        <DivExist show={verOutdated}>
          <Typography sx={{ color: "red" }} variant="caption">
            New version {latestFirmwareVer} found
          </Typography>
          <Button
            size="small"
            onClick={async () => {
              confirmWindow(
                dispatch,
                "Confirm update server firmware?",
                async () => {
                  await updateServerFirmware();
                }
              );
            }}
            variant="outlined"
          >
            Update firmware
          </Button>
        </DivExist>
      </AdminContainer>

      <DataSet title="Firmware version" data={serVersion} />
      <DataSet title="Webapp version" data={packagejson.version} />
      <AdminContainer>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          onClick={async () => {
            confirmWindow(
              dispatch,
              "Confirm update server web app?",
              async () => {
                await updateSeverWebapp(socket);
              }
            );
          }}
        >
          Update web app
        </Button>
        <InfoDivider />
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          onClick={() => checkServerSocket()}
        >
          Check socket
        </Button>
      </AdminContainer>
      {/* <Typography variant="caption" style={{ color: titleText }}>
        Connect gateways:
      </Typography>
      {(gatewayArray || []).map((s) => (
        <Typography variant="caption" key={s} style={{ color: dataText }}>
          {s}
        </Typography>
      ))}
      <InfoDivider />
      <Typography variant="caption" style={{ color: titleText }}>
        Connect lights:
      </Typography>
      {(serialArray || []).map((s) => (
        <Typography variant="caption" key={s} style={{ color: dataText }}>
          {s}
        </Typography>
      ))}
      <InfoDivider />
      <Typography variant="caption" style={{ color: titleText }}>
        Connect sensors:
      </Typography>
      {(sensorArray || []).map((s) => (
        <Typography variant="caption" key={s} style={{ color: dataText }}>
          {s}
        </Typography>
      ))} */}
    </>
  );
}

function InfoDivider() {
  return <hr color="lightgrey" size={1} width="90%" />;
}

function DataSet({ title, data }) {
  return (
    <>
      <Typography
        variant="caption"
        display="block"
        align="center"
        style={{ color: titleText }}
      >
        {title}
      </Typography>
      <Typography
        display="block"
        align="center"
        style={{ color: dataText }}
        variant="caption"
      >
        {data}
      </Typography>
      <hr color="lightgrey" size={1} width="90%" />
    </>
  );
}
