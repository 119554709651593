import { useContext, useState } from "react";
import { SMContext } from "context/smContext";
import { Typography, Box, Button } from "@mui/material";
import DivInline from "components/DivInline";
import DivExist from "components/DivExist";
import { Workspaces } from "@mui/icons-material";
import SensorIcon from "components/SensorIcon";
import ModalSM from "components/ModalSM";
import General from "@ecoenghk/general";
import IconButtonDelete from "components/IconButtonDelete";
import { deleteOneDeviceGroupAction, upsertOneDeviceGroupAction } from "actions/gatewayActions";
import { confirmWindow } from "actions/screenActions";
const gs = new General();
const w = ["6vw", "6vw", "8vw", "5vw", "14vw", "8vw", "40vw"];
export default function SensorControlledBySensorModal({ sensorObj }) {
    const [state, dispatch] = useContext(SMContext);
    const [open, setOpen] = useState(false);
    const { sensorObjAll } = state;
    const { sensorID, sensorArray, type } = sensorObj;
    let modalTitle = `Sensor ${sensorID} controlled by other sensors`;
    const sensorArrayAll = Object.keys(sensorObjAll).filter(s => {
        const controlSensorObj = sensorObjAll[s];
        if (Object.values(controlSensorObj.sensorMap || {}).includes(sensorID)) return true;
        for (let i = 0; i < 13; i++) {
            const setting = controlSensorObj?.[`setting${i}`]?.sensorID;
            if (setting && !gs.isEmptyJson(setting)) return true;
            const gasSetting = controlSensorObj?.[`GAS_setting${i}`]?.sensorID;
            if (gasSetting && !gs.isEmptyJson(gasSetting)) return true;
        }
    });
    const handleOpen = () => {
        setOpen(true);
    };
    const handleDeleteGroupAction = async (controlSensorID, settingNum, command) => {
        const controlSensorObj = sensorObjAll[controlSensorID];
        const controlSensorType = controlSensorObj?.type;
        confirmWindow(dispatch, `Delete group action of ${controlSensorType} sensor ${controlSensorID} setting ${settingNum} inside ${type} sensor ${sensorID} command ${command}`, async () => {
            await deleteOneDeviceGroupAction(sensorObj.gatewayID, controlSensorID, sensorID, "sensor", sensorObj.dtkAdd, [settingNum]);
        });
    }
    return (
        <>
            {(sensorArray || []).length === 0 ?
                <Button onClick={handleOpen} variant="outlined" size="small">No sensor</Button>
                :
                <DivInline onClick={handleOpen}>
                    {
                        sensorArray.map((s, key) => {
                            const controlSensorObj = sensorObjAll[s];
                            return (
                                <SensorIcon key={key}
                                    width={2}
                                    sizeUnit="vw"
                                    type={controlSensorObj?.type}
                                    tooltip={s}
                                    disabledConnectStatus
                                    backgroundColor="#fff"
                                    luxLv={10}
                                    gang={controlSensorObj?.gang}
                                />
                            )
                        })
                    }
                </DivInline>
            }


            <ModalSM modalTitle={modalTitle}
                open={open}
                onClose={() => setOpen(false)}
                height="80vh"
                width="90vw"
                disableBottomClose
            >
                <Box sx={{ display: 'flex', borderBottom: "1px solid lightgrey" }}>
                    <Typography variant="caption" sx={{ width: w[0] }}>SensorID</Typography>
                    <Typography variant="caption" sx={{ width: w[1] }}>Type</Typography>
                    <Typography variant="caption" sx={{ width: w[2] }}>Under sensor sensorArray</Typography>
                    <Typography variant="caption" sx={{ width: w[3] }}>In other sensorMap</Typography>
                    <Typography variant="caption" sx={{ width: w[4] }}>In sensor settings</Typography>
                    <Typography variant="caption" sx={{ width: w[5] }}>Group action</Typography>
                    <Typography variant="caption" sx={{ width: w[6] }}>GAS command</Typography>
                </Box>
                {
                    sensorArrayAll.map((s, key) => {
                        const controlSensorObj = sensorObjAll[s];
                        let presentSetting = [], gasCmdArray = [], gasConfirmedArray = [];
                        for (let i = 1; i < 13; i++) {
                            const setting = controlSensorObj?.[`setting${i}`]?.[sensorID];
                            if (setting && !gs.isEmptyJson(setting)) presentSetting.push(i);
                            const gasSetting = controlSensorObj?.[`GAS_setting${i}`]?.[sensorID];
                            if (gasSetting && !gs.isEmptyJson(gasSetting)) {
                                const confirmed = gasSetting?.confirmed;
                                const cmdJson = gs.dtKStrToJson(gasSetting?.command);
                                const cmdMsg = gs.dtkActionCmdToMsg(cmdJson);
                                const cmdObj = { settingNum: i, cmdMsg, command: gasSetting?.command, commandCode: cmdJson?.commandCode };
                                gasCmdArray.push(cmdObj);
                                gasConfirmedArray.push(confirmed);
                            }
                        }
                        return (
                            <Box key={key} sx={{ display: 'flex', borderBottom: "1px solid lightgrey" }}>
                                <Typography variant="caption" sx={{ width: w[0] }}> {s} </Typography>
                                <Typography variant="caption" sx={{ width: w[1] }}>{controlSensorObj?.type}</Typography>
                                <Typography sx={{ width: w[2] }}>{(sensorObj.sensorArray || []).includes(s) ? `\u{2705}` : `\u{274c}`}</Typography>
                                <Typography sx={{ width: w[3] }}>{Object.values(controlSensorObj.sensorMap || {}).includes(sensorID) ? `\u{2705}` : `\u{274c}`}</Typography>
                                <Typography variant="caption" sx={{ width: w[4] }}>{presentSetting.join(",")}</Typography>
                                <Typography variant="caption" sx={{ width: w[5] }}>{controlSensorObj.enableGroupAction ? `Enabled` : `Disabled`}</Typography>
                                <Box sx={{ width: w[6] }}>
                                    {
                                        gasCmdArray.map((cmdObj, k) => {
                                            let msg = `Setting${cmdObj.settingNum}-Code:${cmdObj.commandCode.toString(16).padStart(4, "0")}-`;
                                            msg += `${cmdObj.cmdMsg} - ${cmdObj.command}`;
                                            return (
                                                <DivInline key={k} sx={{ borderBottom: "1px solid grey" }}>
                                                    <Typography variant="caption" >{msg}</Typography>
                                                    <Workspaces color={gasConfirmedArray[k] ? "success" : "error"} sx={{ fontSize: "1.5vw" }} />
                                                    <IconButtonDelete onBtnClick={async () => await handleDeleteGroupAction(s, cmdObj.settingNum, cmdObj.command)} />
                                                </DivInline>
                                            )
                                        })
                                    }
                                </Box>
                            </Box>
                        )
                    })

                }
            </ModalSM>
        </>
    )
}
