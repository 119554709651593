import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Tooltip, Box } from "@mui/material";
import { AddToPhotos } from "@mui/icons-material";
import DivExist from "components/DivExist";
import Gateway from "asset/svgComp/Gateway";
import Tube from "asset/svgComp/Tube";
import SensorGeneral from "asset/svgComp/SensorGeneral";
import { cursor } from "asset/string/string";
import AddGatewayToMapPanel from "./AddGatewayToMapPanel";
import AddLightToMapPanel from "./AddLightToMapPanel";
import AddSensorToMapPanel from "./AddSensorToMapPanel";
import ModalSM from "components/ModalSM";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import ZcGeneral from "asset/svgComp/ZcGeneral";
import AddZcToMapPanel from "./AddZcToMapPanel";
import ZcSceneButton from "asset/svgComp/ZcSceneButton";
import Clock from "asset/svgComp/Clock";
import SceneButton from "asset/svgComp/SceneButton";
import DivCol from "components/DivCol";
import AdminContainer from "components/AdminContainer";
import DaliCtl from "asset/svgComp/DaliCtl";
import AddDaliCtlToMapPanel from "./AddDaliCtlToMapPanel";
import MapTopBtn from "containers/home/MapTopBtn";
import Lightswitch from "asset/svgComp/Lightswitch";
import ZcSwitch from "asset/svgComp/ZcSwitch";
import DimController from "asset/svgComp/DimController";
const iconW = 7;
export default function AddDeviceToMapModal({ disabled, btnSize }) {
  const [state, dispatch] = useContext(SMContext);
  const [deviceType, setDeviceType] = useState("");
  const [open, setOpen] = useState(false);
  const { activeMapID, mapObjAll, socket } = state;
  const mapName = mapObjAll[activeMapID]?.mapName;
  const handleClose = () => {
    setOpen(false);
    setDeviceType("");
  };
  const nameOfDevice = {
    sensor: "Sensor",
    scenebutton: "Physical Button",
    lightswitch: "Physical Light Switch",
    dimcontroller: "Physical Dim Controller",
    zcSceneButton: "Vitural Scene Button",
    zcSwitch: "Vitural Light Switch",
    timer: "Vitural Timer Switch",

  };
  return (
    <>
      <MapTopBtn
        tooltip="Add device to map"
        btnSize={btnSize}
        btnClick={() => {
          if (!disabled) setOpen(true);
        }}
        disabled={disabled}
      >
        <AddToPhotos />
      </MapTopBtn>
      <ModalSM
        modalIcon={<AddToPhotos />}
        modalTitle={`Add device to map`}
        open={open}
        onClose={handleClose}
        width="96vw"
        height="95vh"
        disableBottomClose
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DivInline justifyContent="center" sx={{ marginBottom: "2vh" }}>
            <DeviceBtn
              tooltip="Add Light to map"
              selected={deviceType === "light"}
              onClick={() => setDeviceType("light")}
              title="Light"
              icon={
                <Tube
                  width={iconW}
                  sizeUnit="vw"
                  disabledConnectStatus
                  disabledStatus
                />
              }
            />
            <DeviceBtn
              tooltip="Add Dali Controller to map"
              selected={deviceType === "daliCtl"}
              onClick={() => setDeviceType("daliCtl")}
              title="Dali controller"
              icon={
                <DaliCtl width={iconW} sizeUnit="vw" disabledConnectStatus />
              }
            />
            <DeviceBtn
              tooltip="Add Sensor to map"
              selected={deviceType === "sensor"}
              onClick={() => setDeviceType("sensor")}
              title={nameOfDevice.sensor}
              icon={<SensorGeneral width={6} sizeUnit="vw" />}
            />
            <DeviceBtn
              tooltip="Add Gateway to map"
              selected={deviceType === "gateway"}
              onClick={() => setDeviceType("gateway")}
              title="Gateway"
              icon={
                <Gateway width={iconW} sizeUnit="vw" disabledConnectStatus />
              }
            />
          </DivInline>
          <DivInline justifyContent="center" sx={{ marginBottom: "2vh" }}>
            <DeviceBtn
              tooltip="Add Physical Scene Button to map"
              selected={deviceType === "scenebutton"}
              onClick={() => setDeviceType("scenebutton")}
              title={nameOfDevice.scenebutton}
              icon={
                <SceneButton
                  width={iconW}
                  sizeUnit="vw"
                  gang={3}
                  disabledConnectStatus
                />
              }
            />
            <DeviceBtn
              tooltip="Add Physical Light Switch to map"
              selected={deviceType === "lightswitch"}
              onClick={() => setDeviceType("lightswitch")}
              title={nameOfDevice.lightswitch}
              icon={
                <Lightswitch
                  width={iconW}
                  sizeUnit="vw"
                  gang={3}
                  disabledConnectStatus
                />
              }
            />
            <DeviceBtn
              tooltip="Add Physical Dim Controller to map"
              selected={deviceType === "dimcontroller"}
              onClick={() => setDeviceType("dimcontroller")}
              title={nameOfDevice.dimcontroller}
              icon={
                <DimController
                  width={iconW}
                  sizeUnit="vw"
                  disabledConnectStatus
                />
              }
            />
          </DivInline>
          <DivInline justifyContent="center" sx={{ marginBottom: "2vh" }}>
            <DeviceBtn
              tooltip="Add Vitural Scene Button to map"
              selected={deviceType === "zcSceneButton"}
              onClick={() => setDeviceType("zcSceneButton")}
              title={nameOfDevice.zcSceneButton}
              icon={
                <ZcSceneButton
                  width={iconW}
                  sizeUnit="vw"
                  disabledConnectStatus
                />
              }
            />

            <DeviceBtn
              tooltip="Add Vitural Light Switch to map"
              selected={deviceType === "zcSwitch"}
              onClick={() => setDeviceType("zcSwitch")}
              title={nameOfDevice.zcSwitch}
              icon={
                <ZcSwitch width={iconW} sizeUnit="vw" disabledConnectStatus />
              }
            />
            <DeviceBtn
              tooltip="Add Vitural Timer Switch to map"
              selected={deviceType === "timer"}
              onClick={() => setDeviceType("timer")}
              title={nameOfDevice.timer}
              icon={<Clock width={iconW} sizeUnit="vw" />}
            />



          </DivInline>
          <Divider10 />
          <ModalSM open={deviceType ? true : false} onClose={() => setDeviceType("")} width={'70vw'} height={'60vh'}>
            <DivExist
              show={
                deviceType === "sensor" ||
                deviceType === "scenebutton" ||
                deviceType === "lightswitch" ||
                deviceType === "dimcontroller"
              }
            >
              <AddSensorToMapPanel
                handleClose={handleClose}
                deviceType={deviceType}
                nameOfDevice={nameOfDevice[deviceType]}
              />
            </DivExist>
            <DivExist show={deviceType === "gateway"}>
              <AddGatewayToMapPanel handleClose={handleClose} />
            </DivExist>
            <DivExist show={deviceType === "light"}>
              <AddLightToMapPanel handleClose={handleClose} />
            </DivExist>
            <DivExist
              show={
                deviceType === "zcSceneButton" ||
                deviceType === "timer" ||
                deviceType === "zcSwitch"
              }
            >
              <AddZcToMapPanel
                handleClose={handleClose}
                deviceType={deviceType}
                nameOfDevice={nameOfDevice[deviceType]}
              />
            </DivExist>
            <DivExist show={deviceType === "daliCtl"}>
              <AddDaliCtlToMapPanel handleClose={handleClose} />
            </DivExist>
          </ModalSM>
        </div>
      </ModalSM>
    </>
  );
}

function DeviceBtn({ tooltip, selected, onClick, title, icon }) {
  return (
    <Tooltip title={<Typography>{tooltip}</Typography>}>
      <Box sx={{ padding: "0.3vw", outline: selected ? "3px solid red" : "" }}>
        <Button
          variant="outlined"
          color="secondary"
          sx={{
            margin: "0.5vw",
            width: "14vw",
            height: "12vw",
          }}
          onClick={onClick}
        >
          <DivCol sx={{ cursor: "pointer" }}>
            {icon}
            <Typography variant="caption">{title}</Typography>
          </DivCol>
        </Button>
      </Box>
    </Tooltip>
  );
}
