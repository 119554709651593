import { useContext, useEffect } from "react";
import { SMContext } from "context/smContext";
// import { useNavigate } from "react-router-dom";
import { useAutoBackToRoot } from "hooks/generalHooks";
import MapContainerLeft from "./MapContainerLeft";
import MapContainerBottom from "./MapContainerBottom";
import MapContainer from "./MapContainer";
import MapContainerRight from "./MapContainerRight";
import {
  useFetchDaliCtlInMap,
  useFetchGatewayInMap,
  useFetchSensorInMap,
  useFetchSerialInMap,
  useFetchTimeTableInMap,
  useFetchZoneControlInMap,
} from "hooks/mapHooks";
import { useSocketListenToWeb } from "hooks/socketHooks";
import DaliCtlDetailModal from "containers/daliCtl/DaliCtlDetailModal";
import GatewayDetailModal from "containers/gateway/GatewayDetailModal";
import SensorDetailModal from "containers/sensor/SensorDetailModal";
import ZoneControlDetailModal from "containers/zoneCtl/ZoneControlDetailModal";
import LightDetailModal from "containers/serial/LightDetailModal";
import { useNavigate } from "react-router-dom";

export default function MapPage() {
  const [state, dispatch] = useContext(SMContext);
  const { activeMapID } = state;
  let navigate = useNavigate();
  useAutoBackToRoot();
  useFetchSerialInMap(activeMapID);
  useFetchGatewayInMap(activeMapID);
  useFetchSensorInMap(activeMapID);
  useFetchDaliCtlInMap(activeMapID);
  useFetchZoneControlInMap(activeMapID);
  useFetchTimeTableInMap(activeMapID);
  useSocketListenToWeb(navigate);

  return (
    <>
      <div
        style={{
          backgroundColor: "whitesmoke",
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          <MapContainerLeft vw={5} vh={85} />
          <MapContainer vw={80} vhMap={80} vhToolbar={5} />
          <MapContainerRight vw={14} vh={85} />
        </div>
        <MapContainerBottom vw={100} vh={15} />
      </div>
      <LightDetailModal />
      <DaliCtlDetailModal />
      <GatewayDetailModal />
      <SensorDetailModal />
      <ZoneControlDetailModal />
    </>
  );
}
