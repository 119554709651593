import { useState, useRef } from "react";
import { Group, Rect, Circle } from "react-konva";
import { executeZoneControl } from "actions/zcActions";
import { konvaEnlargeOut, konvaShrinkAndBack } from "actions/konvaActions";
import moment from "moment";

export default function ZcDeviceSceneButton({
  layerProps,
  zoneControlObj,
  handleSelectDevice,
  handleShiftSelectDevice,
  selected,
  onMouseOver,
  onMouseLeave,
}) {
  const [touchTime, setTouchTime] = useState(0);
  const { iconSize, mapScale } = layerProps;
  const circleRef = useRef(null);

  const executeSwitching = async () => {
    await executeZoneControl(zoneControlObj, 1);
  };

  return (
    <Group>
      <Circle
        ref={circleRef}
        radius={0.5 * iconSize * mapScale.x}
        x={0.5 * iconSize}
        y={0.5 * iconSize}
        stroke="red"
        opacity={0}
        strokeWidth={1}
      />
      <Rect
        width={iconSize * mapScale.x * 1.2}
        height={iconSize * mapScale.x * 1.2}
        onClick={async (e) => {
          if (e.evt.shiftKey) {
            handleShiftSelectDevice(e);
          } else if (e.evt.button === 0) {
            await executeSwitching();
            konvaEnlargeOut(circleRef, 1);
          } else if (e.evt.button === 2) {
            handleSelectDevice(e);
          }
        }}
        onTouchStart={() => setTouchTime(moment().valueOf())}
        onTouchEnd={(e) => {
          const newT = moment().valueOf();
          const duration = newT - touchTime;
          if (duration < 1000) {
            executeSwitching();
          } else {
            handleSelectDevice(e);
          }
        }}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      />
    </Group>
  );
}
