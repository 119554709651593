import { useContext, useState } from "react";
import { SMContext } from "context/smContext";
import { Typography, Box, Button } from "@mui/material";
import DivInline from "components/DivInline";
import { Workspaces } from "@mui/icons-material";
import SensorIcon from "components/SensorIcon";
import ModalSM from "components/ModalSM";
import General from "@ecoenghk/general";
import IconButtonDelete from "components/IconButtonDelete";
import { deleteOneDeviceGroupAction, upsertOneDeviceGroupAction } from "actions/gatewayActions";
import { confirmWindow } from "actions/screenActions";
import { dtkCmdIncludeDaliLight } from "actions/daliCtlActions";
import AdminContainer from "components/AdminContainer";
const gs = new General();
const w = ["6vw", "6vw", "8vw", "5vw", "14vw", "8vw", "40vw"];
export default function LightControlledSensorModal({ lightObj }) {
    const [state, dispatch] = useContext(SMContext);
    const [open, setOpen] = useState(false);
    const { sensorObjAll, daliCtlObjAll, userObj } = state;
    const { serial, sensorArray } = lightObj;
    const lightDaliCtlID = lightObj?.daliCtlID;
    const lightDaliShortAdd = lightObj?.shortAdd;
    const daliCtlObj = daliCtlObjAll[lightDaliCtlID];
    const lightDaliAddIndex = (daliCtlObj?.addList || []).findIndex(a => a === lightDaliShortAdd);
    let modalTitle = `Light ${serial} controlled by sensor`;
    if (lightDaliCtlID) modalTitle += ` (DALI control ID: ${lightDaliCtlID}, shortAdd:${lightDaliShortAdd})`;
    const sensorArrayAll = Object.keys(sensorObjAll).filter(s => {
        const sensorObj = sensorObjAll[s];
        if (Object.values(sensorObj.serialMap || {}).includes(serial)) return true;
        for (let i = 1; i < 13; i++) {
            if (Object.keys(sensorObj?.[`setting${i}`] || {}).includes(serial)) return true;
            if (Object.keys(sensorObj?.[`GAS_setting${i}`] || {}).includes(lightDaliCtlID || serial)) {
                if (lightDaliCtlID) {
                    const GAS_setting = sensorObj?.[`GAS_setting${i}`]?.[lightDaliCtlID];
                    const command = GAS_setting?.command;
                    const commandIncludeDaliLight = dtkCmdIncludeDaliLight(command, daliCtlObj, lightObj);
                    return commandIncludeDaliLight;
                    // const cmdJson = gs.dtKStrToJson(command);
                    // const lightLv = cmdJson?.lightLvAry?.[lightDaliAddIndex];
                    // if (lightLv >= 0 && lightLv <= 100) return true;
                    // const D1LightLv = cmdJson?.D1LightLvAry?.[lightDaliAddIndex];
                    // if (D1LightLv >= 0 && D1LightLv <= 100) return true;
                    // const D2LightLv = cmdJson?.D2LightLvAry?.[lightDaliAddIndex];
                    // if (D2LightLv >= 0 && D2LightLv <= 100) return true;
                }
                else {
                    return true;
                }

            }
        }
        return false;
    })
    const handleOpen = () => {
        if (userObj.level > 0) return;
        setOpen(true);
    };
    const handleDeleteGroupAction = async (sensorID, settingNum, command) => {
        const gatewayID = lightDaliCtlID ? daliCtlObj.gatewayID : lightObj.gatewayID;
        const deviceID = lightDaliCtlID ? lightDaliCtlID : serial;
        const deviceType = lightDaliCtlID ? "daliCtl" : "light";
        const dtkAdd = lightDaliCtlID ? daliCtlObj.dtkAdd : lightObj.dtkAdd;
        let isLastDaliLight = false;
        const json = gs.dtKStrToJson(command);
        if (lightDaliCtlID) {
            let lightLvAry = json?.lightLvAry;
            lightLvAry = lightLvAry.filter(lv => lv >= 0 && lv <= 100);
            if (lightLvAry.length === 1) isLastDaliLight = true;
        }

        confirmWindow(dispatch, `Delete group action of sensor ${sensorID} setting ${settingNum} inside ${deviceType} ${deviceID} - ${command} ?`, async () => {
            if (isLastDaliLight || !lightDaliCtlID) {
                console.log("delete group action");
                deleteOneDeviceGroupAction(gatewayID, sensorID, deviceID, deviceType, dtkAdd, [settingNum]);
            } else {
                let newJson = { ...json };
                if ("lightLvAry" in newJson) newJson.lightLvAry[lightDaliAddIndex] = 101;
                if ("D1LightLvAry" in newJson) newJson.D1LightLvAry[lightDaliAddIndex] = 101;
                if ("D2LightLvAry" in newJson) newJson.D2LightLvAry[lightDaliAddIndex] = 101;
                const newCommand = gs.jsonCommandToDtk(newJson);
                // console.log("currentCommand", command, gs.dtKStrToJson(command));
                // console.log("newCommand", newCommand, newJson);
                upsertOneDeviceGroupAction(gatewayID, sensorID, deviceID, deviceType, dtkAdd, [settingNum], [newCommand]);
            }
        });
    }
    return (
        <>
            {sensorArray.length === 0 ?

                <Button onClick={handleOpen} variant="outlined" size="small">No sensor</Button>
                :
                <DivInline onClick={handleOpen}>
                    {
                        sensorArray.map((s, key) => {
                            const sensorObj = sensorObjAll[s];
                            return (
                                <SensorIcon key={key}
                                    width={2}
                                    sizeUnit="vw"
                                    type={sensorObj?.type}
                                    tooltip={s}
                                    disabledConnectStatus
                                    backgroundColor="#fff"
                                    luxLv={10}
                                    gang={sensorObj?.gang}
                                />
                            )
                        })
                    }

                </DivInline>
            }
            <AdminContainer>
                <ModalSM modalTitle={modalTitle}
                    open={open}
                    onClose={() => setOpen(false)}
                    height="80vh"
                    width="90vw"
                    disableBottomClose
                >
                    <Box sx={{ display: 'flex', borderBottom: "1px solid lightgrey" }}>
                        <Typography variant="caption" sx={{ width: w[0] }}>SensorID</Typography>
                        <Typography variant="caption" sx={{ width: w[1] }}>Type</Typography>
                        <Typography variant="caption" sx={{ width: w[2] }}>Under light sensorArray</Typography>
                        <Typography variant="caption" sx={{ width: w[3] }}>In serialMap</Typography>
                        <Typography variant="caption" sx={{ width: w[4] }}>In sensor settings</Typography>
                        <Typography variant="caption" sx={{ width: w[5] }}>Group action</Typography>
                        <Typography variant="caption" sx={{ width: w[6] }}>GAS command</Typography>
                    </Box>
                    {
                        sensorArrayAll.map((s, key) => {
                            const sensorObj = sensorObjAll[s];
                            let presentSetting = [], gasCmdArray = [], gasConfirmedArray = [], isLastDaliLightArray = [];
                            for (let i = 1; i < 13; i++) {
                                const setting = sensorObj?.[`setting${i}`];
                                if (setting) {
                                    const serialSetting = setting[serial];
                                    if (serialSetting && !gs.isEmptyJson(serialSetting))
                                        if (Object.keys(setting || {}).includes(serial)) {
                                            presentSetting.push(i);
                                        }
                                }
                                const GAS_setting = sensorObj?.[`GAS_setting${i}`]?.[lightDaliCtlID || serial];
                                if (GAS_setting && !gs.isEmptyJson(GAS_setting)) {
                                    let command = GAS_setting?.command;
                                    let confirmed = false;
                                    const cmdJson = gs.dtKStrToJson(command);
                                    let cmdObj;
                                    let isLastDaliLight = false;
                                    if (lightDaliCtlID) {
                                        const cmdIncludeDaliLight = dtkCmdIncludeDaliLight(command, daliCtlObj, lightObj);
                                        const lightLv = cmdJson?.lightLvAry?.[lightDaliAddIndex];
                                        const D1LightLv = cmdJson?.D1LightLvAry?.[lightDaliAddIndex] ?? "-";
                                        const D2LightLv = cmdJson?.D2LightLvAry?.[lightDaliAddIndex] ?? "-";
                                        const commandCode = cmdJson?.commandCode?.toString(16).padStart(4, "0") ?? "-";
                                        cmdObj = { settingNum: i, commandCode, lightLv, D1LightLv, D2LightLv, command };
                                        if ((cmdJson?.lightLvAry || []).length === 1) isLastDaliLight = true;
                                        confirmed = GAS_setting?.confirmed && cmdIncludeDaliLight;
                                    } else {
                                        confirmed = GAS_setting?.confirmed;
                                        let cmdMsg = gs.dtkActionCmdToMsg(cmdJson);
                                        cmdObj = { settingNum: i, cmdMsg, command, commandCode: cmdJson?.commandCode };
                                    }
                                    gasCmdArray.push(cmdObj);
                                    gasConfirmedArray.push(confirmed);
                                    isLastDaliLightArray.push(isLastDaliLight);
                                }
                            }

                            return (
                                <Box key={key} sx={{ display: 'flex', borderBottom: "1px solid lightgrey" }}>
                                    <Typography variant="caption" sx={{ width: w[0] }}> {s} </Typography>
                                    <Typography variant="caption" sx={{ width: w[1] }}>{sensorObj?.type}</Typography>
                                    <Typography sx={{ width: w[2] }}>{(lightObj.sensorArray || []).includes(s) ? `\u{2705}` : `\u{274c}`}</Typography>
                                    <Typography sx={{ width: w[3] }}>{Object.values(sensorObj.serialMap || {}).includes(serial) ? `\u{2705}` : `\u{274c}`}</Typography>
                                    <Typography variant="caption" sx={{ width: w[4] }}>{presentSetting.join(",")}</Typography>
                                    <Typography variant="caption" sx={{ width: w[5] }}>{sensorObj.enableGroupAction ? `Enabled` : `Disabled`}</Typography>
                                    <Box sx={{ width: w[6] }}>
                                        {
                                            gasCmdArray.map((cmdObj, k) => {
                                                let msg = `Setting${cmdObj.settingNum}-Code:${cmdObj.commandCode}`;
                                                if (lightDaliCtlID) {
                                                    msg += ` lightLv${cmdObj.lightLv}`;
                                                    if (cmdObj.D1LightLv >= 0) msg += ` D1LightLv${cmdObj.D1LightLv}`;
                                                    if (cmdObj.D2LightLv >= 0) msg += ` D2LightLv${cmdObj.D2LightLv}`;
                                                    msg += ` - ${cmdObj.command}`;
                                                } else {
                                                    msg += `${cmdObj.cmdMsg} - ${cmdObj.command}`;
                                                }
                                                return (
                                                    <DivInline key={k} sx={{ borderBottom: "1px solid grey" }}>
                                                        <Typography variant="caption" >{msg}</Typography>
                                                        <Workspaces color={gasConfirmedArray[k] ? "success" : "error"} sx={{ fontSize: "1.5vw" }} />
                                                        <IconButtonDelete onBtnClick={async () => await handleDeleteGroupAction(s, cmdObj.settingNum, cmdObj.command)} />

                                                    </DivInline>
                                                )
                                            })}
                                    </Box>


                                </Box>
                            )
                        })
                    }
                </ModalSM >
            </AdminContainer>
        </>
    )

}