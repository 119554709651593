import { useState, useContext, useEffect } from "react";
import { IconButton, Typography, Box } from "@mui/material";
import { SMContext } from "context/smContext";
import DivExist from "components/DivExist";
import Divider10 from "components/Divider10";
import { Print, Summarize } from "@mui/icons-material";
import ResultIcon from "components/ResultIcon";
import BasicTable from "components/BasicTable";
import { printPdf } from "actions/generalActions";
import { testType } from "asset/string/string";
import { mgFindByMatch } from "actions/mongoApiActions";
import ModalSM from "components/ModalSM";
import DivInline from "components/DivInline";
import IconButtonBack from "components/IconButtonBack";
import ChartVI2 from "./ChartVI2";
import { CSVLink, CSVDownload } from "react-csv";

export default function SingleReportModal({ open, onClose }) {
  const [state] = useContext(SMContext);
  const {
    activeSerial,
    lightObjAll,
    activeReportID,
    activeReportObj,
    mapObjAll,
  } = state;
  const [reportData, setReportData] = useState([]);
  const lightObj = lightObjAll[activeSerial] || {};
  const { description, mapID } = lightObj;
  let mapList = [];
  if (mapID) {
    const mapListStr = JSON.stringify(mapID);
    mapList = JSON.parse(mapListStr);
  }
  useEffect(() => {
    const fetchSerialLog = async () => {
      let arr = await mgFindByMatch(`serialLogs`, "reportID", activeReportID);
      console.log("fetchSerialLog", arr);
      setReportData(arr);
    };
    if (open) fetchSerialLog();
  }, [activeReportID, activeSerial, open]);

  let chartDataArray = [
    [
      "time(min)",
      "voltage(V)",
      "current(A)",
      "Min Voltage(V)",
      "Min Discharge Current(A)",
    ],
  ];
  const reverseI = lightObj?.reversePolarityI;
  const initTS = reportData[0]?.timeStamp || 0;

  const {
    minV,
    minI,
    warningV,
    warningI,
    result,
    time_pretest,
    time_cutOff,
    time_recharge,
    time_interval,
    reportType,
    deviceTestStart_timeString,
    deviceTestEnd_timeString,
    resultData,
  } = activeReportObj || {};
  const { dataCount, testMinV, testMinI, rechargeIAvg, resultComment } =
    resultData || {};
  let dataVArr = [];
  let dataIArr = [];
  let dataTArr = [];
  let dataVminArr = [];
  let dataIminArr = [];
  reportData.forEach((data) => {
    let duration = (data.timeStamp - initTS) / 1000 / 60;
    duration = Math.round(duration * 100) / 100;
    const dataI = reverseI ? data.current * -1 : data.current;
    const dataMinI = reverseI ? minI * -1 : minI;
    const item = [duration, data.voltage, dataI, minV || 0, dataMinI || 0];
    chartDataArray = [...chartDataArray, item];
    dataTArr = [...dataTArr, duration];
    dataVArr = [...dataVArr, data.voltage];
    dataIArr = [...dataIArr, dataI];
    dataVminArr = [...dataVminArr, minV || 0];
    dataIminArr = [...dataIminArr, dataMinI || 0];
  });
  let tableDataArray = reportData.map((item) => {
    let tableTime = (item.testTime / 60).toFixed(2);
    if (isNaN(tableTime)) tableTime = 0;
    const tableDataI = reverseI ? item.current * -1 : item.current;
    const tableMinI = reverseI ? minI * -1 : minI;
    return [
      tableTime,
      item.voltage,
      tableDataI,
      minV?.toFixed(1),
      tableMinI?.toFixed(2),
      //   warningV,
      //   warningI,
      item.onoffstatusL ? "Disconnect" : "Connect",
    ];
  });
  tableDataArray.unshift([
    "time(min)",
    "voltage(V)",
    "current(A)",
    "min voltage(V)",
    "min current(A)",
    // "warning voltage(V)",
    // "warning current(A)",
    "Power main supply status",
  ]);
  const handlePrint = () => {
    var content = document.getElementById("reportBody").innerHTML;
    printPdf(
      "Skymon Systems",
      "Emergency Battery Light Automatic Testing System",
      content
    );
  };

  return (
    <ModalSM height="94vh" width="88vw" open={open} onClose={onClose}>
      <DivInline justifyContent="space-between">
        <DivInline>
          <IconButtonBack onBtnClick={onClose} />
          <Summarize />
          <Typography>{`Report of ${activeReportObj?.serial} [${description}]`}</Typography>
        </DivInline>
        <IconButton
          onClick={handlePrint}
          style={{ marginRight: 10 }}
          size="large"
        >
          <Print />
        </IconButton>
      </DivInline>
      <div
        id="reportBody"
        style={{
          background: "#fff",
          color: "#000",
          width: "88%",
          height: "92%",
          overflowY: "scroll",
          margin: "auto",
          padding: 5,
        }}
      >
        <h3>
          {testType[activeReportObj?.reportType]} Report - {description}
        </h3>
        <hr />
        <div>
          <table style={{ width: "95%" }}>
            <tbody>
              <tr>
                <TitleTd title="Report ID" />
                <DataTd data={activeReportID} />
              </tr>
              <tr>
                <TitleTd title="Report Type" />
                <DataTd data={reportType} />
              </tr>
              <tr>
                <TitleTd title="Light serial no." />
                <DataTd data={activeReportObj?.serial} />
              </tr>
              <tr>
                <TitleTd title="Light description" />
                <DataTd data={description} />
              </tr>
              <tr>
                <TitleTd title="In Map" />
                <DataTd
                  data={mapList.map((mid) => (
                    <span key={mid}>{mapObjAll[mid]?.mapName}</span>
                  ))}
                />
              </tr>
              <tr>
                <TitleTd title="Schedule no." />
                <DataTd data={activeReportObj?.no} />
              </tr>
              <tr>
                <TitleTd
                  title={
                    activeReportObj?.no === "-" || !activeReportObj?.no
                      ? "Test command time"
                      : "Schedule time"
                  }
                />
                <DataTd data={activeReportObj?.schedule_timeString} />
              </tr>
              <tr>
                <TitleTd title="Minimum battery voltage required" />
                <DataTd data={minV?.toFixed(1) + "V"} />
              </tr>
              <tr>
                <TitleTd title="Minimum discharge current required" />
                <DataTd data={minI?.toFixed(2) + "A"} />
              </tr>
              <DivExist show={warningV ? true : false}>
                <tr>
                  <TitleTd title="Warning battery voltage" />
                  <DataTd data={warningV?.toFixed(1) + "V"} />
                </tr>
              </DivExist>
              <DivExist show={warningI ? true : false}>
                <tr>
                  <TitleTd title="Minimum discharge current" />
                  <DataTd data={warningI?.toFixed(2) + "A"} />
                </tr>
              </DivExist>
              <tr>
                <TitleTd title="Test duration" />
                <DataTd
                  data={`${time_pretest}-${time_cutOff}-${time_recharge} minutes`}
                />
              </tr>
              <tr>
                <TitleTd title="Data sampling interval" />
                <DataTd data={time_interval + " seconds"} />
              </tr>
              <tr>
                <TitleTd title="Test start time" />
                <DataTd data={deviceTestStart_timeString} />
              </tr>
              <tr>
                <TitleTd title="Test finish time" />
                <DataTd data={deviceTestEnd_timeString} />
              </tr>
            </tbody>
          </table>
        </div>

        <Divider10 space={20} />
        <table
          style={{
            margin: "auto",
            width: "95%",
            border: "1px solid grey",
            borderCollapse: "collapse",
          }}
        >
          <tbody>
            <tr>
              <td></td>
              <td style={tStyle}>Data count</td>
              <td style={tStyle}>Tested min voltage</td>
              <td style={tStyle}>Tested min discharge current</td>
              <td style={tStyle}>Tested average recharge current</td>
              <td style={tStyle}>Overall result</td>
            </tr>
            <tr>
              <td style={tStyle}>Requirement</td>
              <td style={tStyle}></td>
              <td style={tStyle}>{`>${minV?.toFixed(1)}V`}</td>
              <td style={tStyle}>{`>${Math.abs(minI)?.toFixed(2)}A`}</td>
              <td style={tStyle}>{">0A"}</td>
              <td rowSpan={2} style={{ ...tStyle, textAlign: "center" }}>
                <ResultIcon result={result} size="3em" />
              </td>
            </tr>
            <tr>
              <td style={tStyle}>Tested data</td>
              <td style={tStyle}>
                <h4>{dataCount}</h4>
              </td>
              <td style={tStyle}>
                <h4 style={{ color: testMinV > minV ? "green" : "red" }}>
                  {testMinV}V
                </h4>
              </td>
              <td style={tStyle}>
                <h4
                  style={{
                    color:
                      Math.abs(testMinI) > Math.abs(minI) ? "green" : "red",
                  }}
                >
                  {Math.abs(testMinI)}A
                </h4>
              </td>
              <td style={tStyle}>
                <h4
                  style={{
                    color: Math.abs(rechargeIAvg) > 0 ? "green" : "red",
                  }}
                >
                  {Math.abs(rechargeIAvg)}A
                </h4>
              </td>
            </tr>

            {result !== "OK" && (
              <tr>
                <td colSpan={99}>
                  {resultComment?.map((er, key) => (
                    <div
                      style={{
                        color: "red",
                        textAlign: "right",
                        fontSize: "small",
                      }}
                      key={key}
                    >
                      {er}
                    </div>
                  ))}
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <Divider10 />
        <Box sx={{ width: "100%", marginBottom: "1vh" }}>
          <ChartVI2
            chartDataArray={[dataVArr, dataIArr, dataVminArr, dataIminArr]}
            chartLabel={dataTArr}
          />
          {/* <ChartVI
            chartDataArray={chartDataArray}
            voltageAxesColor="green"
            voltageCurveColor="green"
            currentAxesColor="#D35400"
            currentCurveColor="#D35400"
            titleTextColor="#000"
            legendTextColor="#000"
            chartHeight={400}
            // chartWidth="85%"
          /> */}
        </Box>
        <div style={{ marginBottom: 50 }}>
          <DivExist show={reportData.length > 0}>
            <BasicTable data={tableDataArray} tableWidth="95%" />
          </DivExist>
        </div>
      </div>
    </ModalSM>
  );
}

function TitleTd({ title }) {
  return <td style={{ width: "50%" }}>{title}</td>;
}
function DataTd({ data }) {
  return <td style={{ width: "50%", textAlign: "right" }}>{data}</td>;
}

const tStyle = {
  border: "1px solid grey",
  borderCollapse: "collapse",
  paddingLeft: 3,
  textAlign: "left",
};
const tStyleXS = {
  border: "1px solid grey",
  borderCollapse: "collapse",
  paddingLeft: 3,
  fontSize: "x-small",
};
